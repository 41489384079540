export const ADD_DEAL_TO_SHORTLIST_ERROR =
  'Add deal to shortlist unsuccessfully'

export const ADD_DEAL_TO_ACTIVE_INTROS_SUCCESS =
  'Add deal to active intros successfully'

export const ADD_DEAL_TO_ACTIVE_INTROS_ERROR =
  'Add deal to active intros unsuccessfully'

export const ADD_DEAL_TO_SHORTLIST_SUCCESS =
  'Add deal to shortlist successfully'

export const REMOVE_DEAL_FROM_SHORTLIST_ERROR =
  'Remove deal from shortlist unsuccessfully'

export const REMOVE_DEAL_FROM_SHORTLIST_SUCCESS =
  'Remove deal from shortlist successfully'

export const REMOVE_DEAL_FROM_ACTIVE_INTROS_SUCCESS =
  'Remove deal from shortlist successfully'

export const FORMALIZE_OFFER_SUCCESS = 'Formalize offer deal successfully'
export const FORMALIZE_OFFER_ERROR = 'Formalize offer deal unsuccessfully'
export const RESCIND_INTEREST_SUCCESS =
  'Rescind interest on this deal successfully'
export const RESCIND_INTEREST_ERROR =
  'Rescind interest on this deal unsuccessfully'

export const ADD_DEAL_TO_SHORTLIST_DUPLICATE_ERROR =
  'This deal is already added to shortlist'
