import { ReactNode } from 'react'

import { StackProps, VStack } from '@chakra-ui/react'

const WrapFieldSection = ({
  children,
  ...rest
}: { children: ReactNode } & StackProps) => {
  return (
    <VStack
      as="section"
      w="full"
      gap={['20px', '20px', '25px', '25px', '25px']}
      p="20px"
      bg="darkMain.500"
      borderRadius="9.6px"
      alignItems="start"
      {...rest}
    >
      {children}
    </VStack>
  )
}

export default WrapFieldSection
