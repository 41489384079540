import { Icon, IconProps } from '@chakra-ui/react'

const ChevronDown = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16667 8.47617C4.16667 8.7195 4.27251 8.96117 4.47751 9.12617L9.4775 13.1495C9.78667 13.3978 10.2283 13.3945 10.5333 13.1403L15.5333 8.97367C15.8867 8.67951 15.9342 8.15367 15.64 7.79951C15.3458 7.44617 14.82 7.39867 14.4667 7.69367L9.99084 11.4228L5.5225 7.827C5.16417 7.53867 4.63917 7.59534 4.35084 7.9545C4.22667 8.10784 4.16667 8.29284 4.16667 8.47617Z"
        fill="#8B9DFB"
      />
      <mask
        id="mask0_143_213"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="4"
        y="7"
        width="12"
        height="7"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.16667 8.47617C4.16667 8.7195 4.27251 8.96117 4.47751 9.12617L9.4775 13.1495C9.78667 13.3978 10.2283 13.3945 10.5333 13.1403L15.5333 8.97367C15.8867 8.67951 15.9342 8.15367 15.64 7.79951C15.3458 7.44617 14.82 7.39867 14.4667 7.69367L9.99084 11.4228L5.5225 7.827C5.16417 7.53867 4.63917 7.59534 4.35084 7.9545C4.22667 8.10784 4.16667 8.29284 4.16667 8.47617Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_143_213)"></g>
    </Icon>
  )
}

export default ChevronDown
