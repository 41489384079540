import { ReactNode } from 'react'

import { Stack } from '@chakra-ui/react'

import { AnimatePresence, motion } from 'framer-motion'

import { SETTING_ITEMS_MAP, TSettingItem } from '../constants/user-settings'
import { TUserSetting } from '../hooks/useUserSettings'
import UserProfileLeftBar from './UserProfileLeftBar'

const UserProfile = ({ children }: { children: ReactNode }) => {
  return (
    <Stack w="full" h="full">
      {children}
    </Stack>
  )
}

UserProfile.LeftBar = ({ children }: { children: ReactNode }) => {
  return <UserProfileLeftBar>{children}</UserProfileLeftBar>
}

UserProfile.Content = ({
  selectedSetting,
  userSettings,
}: {
  selectedSetting: TSettingItem
  userSettings: TUserSetting | null | undefined
}) => {
  const CurrentSetting = SETTING_ITEMS_MAP[selectedSetting.text]
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={selectedSetting.text}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
        style={{ height: '100%' }}
      >
        <CurrentSetting userSettings={userSettings} />
      </motion.div>
    </AnimatePresence>
  )
}

export default UserProfile
