import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'
import { getColor, transparentize } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(({ theme }) => ({
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    bgColor: 'rgba(255, 255, 255, 0.04)',
    border: 'input.normal',
    _disabled: {
      opacity: 1,
      cursor: 'not-allowed',
      color: getColor(theme, 'dark.300'),
      bg: getColor(theme, 'light.500'),
      border: 'none',
    },
    _placeholder: {
      color: getColor(theme, 'dark.300'),
      // opacity: 1
    },
  },
  addon: {
    transitionProperty: 'common',
    transitionDuration: 'normal',
  },
  element: {
    color: 'accent.main01',
  },
}))

const size = {
  lg: defineStyle({
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 4,
  }),
  md: defineStyle({
    fontSize: 'md',
    px: 3,
    h: 10,
    borderRadius: 4,
  }),
  sm: defineStyle({
    fontSize: 'sm',
    px: 2.5,
    h: 8,
    borderRadius: 2.5,
  }),
  xs: defineStyle({
    fontSize: 'sm',
    px: 2,
    height: 5,
    borderRadius: 1.5,
  }),
}

const sizes = {
  lg: definePartsStyle({
    field: size.lg,
    addon: size.lg,
  }),
  md: definePartsStyle({
    field: size.md,
    addon: size.md,
  }),
  sm: definePartsStyle({
    field: size.sm,
    addon: size.sm,
  }),
  xs: definePartsStyle({
    field: size.xs,
    addon: size.xs,
  }),
}

const variantOutline = definePartsStyle((props) => {
  const { theme } = props
  return {
    field: {
      bg: 'input.background',
      borderColor: 'unset',
      border: 'input.normal',
      _hover: {
        borderColor: 'input.borderColor',
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'input.borderColor',
        boxShadow: 'none',
        color: 'inherit',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
        color: 'dark.700',
      },
      _invalid: {
        border: 'input.invalid',
        boxShadow: 'none',
        bg: transparentize('negative.300', 0.33)(theme),
        color: 'negative.500',
        _focusVisible: {
          // borderColor: 'negative.500'
        },
        _placeholder: {
          color: 'negative.500',
          opacity: 1,
        },
      },
      _valid: {
        borderColor: 'transparent',
        boxShadow: 'none',
        bg: transparentize('positive.300', 0.33)(theme),
      },
      '&[aria-valid="true"]': {
        boxShadow: 'none',
        _dark: {
          color: '#101010',
          bg: 'rgba(16, 16, 16, 0.04)',
        },
        _light: {
          color: '#fff',
          bg: 'rgba(255, 255, 255, 0.25)',
        },
      },
      _placeholder: {
        fontSize: 'sm',
      },
    },
    addon: {
      border: '1px solid',
      borderColor: 'dark.300',
      bgColor: 'light.700',
      color: 'dark.500',
      _invalid: {
        borderColor: 'negative.500',
      },
      '&[aria-valid="true"]': {
        borderColor: 'positive.500',
      },
    },
  }
})

const variantSelectOutline = definePartsStyle((props) => {
  const outline = variantOutline(props)

  return {
    ...outline,
    field: {
      ...outline.field,
      color: 'darkBlue',
      bgColor: 'rgba(255, 255, 255, 0.25)',
    },
  }
})

const variantUserAccount = definePartsStyle((props) => {
  const outline = variantOutline(props)
  const colorMode = props?.colorMode
  return {
    ...outline,
    field: {
      ...outline.field,
      borderRadius: '1rem !important',

      '&[aria-valid="true"]': {
        border: '1px solid #8B9DFB',
        color: colorMode === 'light' ? 'white' : '#101010',
        opacity: 0.6,
        fontSize: '14px',
        bg: 'rgba(16, 16, 16, 0.04)',
      },
    },
  }
})

const variants = {
  outline: variantOutline,
  selectOutline: variantSelectOutline,
  userAccount: variantUserAccount,
}

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
})
