export const UPDATE_ACCOUNT_DETAILS_ERROR =
  'Update account details unsuccessfully'
export const UPDATE_ACCOUNT_DETAILS_SUCCESS =
  'Update account details successfully'

export const CURRENT_PASSWORD_IS_NOT_CORRECT = 'Current password is not correct'
export const PASSWORD_DOES_NOT_MATCH = 'Passwords do not match'
export const UPDATE_PASSWORD_SUCCESS = 'Update password successfully'
export const UPDATE_PASSWORD_ERROR = 'Update password unsuccessfully'

export const UPDATE_CONTACT_INFO_ERROR = 'Update contact info unsuccessfully'
export const UPDATE_CONTACT_INFO_SUCCESS = 'Update contact info successfully'
