import { Button, Flex } from '@chakra-ui/react'

import { RESET_FORM_FIELDS } from '../constants/reset-page'
import { FormBody, FormContainer, FormHeader } from './other'

const ResetPasswordForm = () => {
  return (
    <>
      <FormContainer
        minW="18.75rem"
        maxW={[20, 20, 20, '25rem', '25rem']}
        m="auto"
        width="calc(100vw / 3)"
        p={[4, 4, 6, 6]}
        gap={{ base: 6, md: 8 }}
      >
        <FormHeader />
        <FormBody fields={RESET_FORM_FIELDS} />
        <Flex justifyContent="center" alignItems="center" w="full">
          <Button
            w="full"
            type="submit"
            fontWeight="700"
            fontSize="sm"
            borderRadius={12}
            bgColor="inherit"
            border="common"
            padding={[5]}
            color="white"
            textTransform="capitalize"
            lineHeight={0}
            _hover={{
              opacity: 0.5,
            }}
          >
            Reset Password
          </Button>
        </Flex>
      </FormContainer>
    </>
  )
}

export default ResetPasswordForm
