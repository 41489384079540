export const SUPABASE_EVENT_PASSWORD_RECOVERY = 'PASSWORD_RECOVERY'
export const SUPABASE_EVENT_SIGNED_IN = 'SIGNED_IN'
export const SUPABASE_EVENT_SIGNED_OUT = 'SIGNED_OUT'

//HTTP CODES

export const ERROR_CODE_CHANGE_PASSWORD = 422
export const ERROR_CODE_400_CHANGE_PASSWORD = 400

export const ERROR_MESSAGE_CHANGE_PASSWORD =
  'New password should be different from the old password'

export const ERROR_COMMON_MESSAGE_CHANGE_PASSWORD =
  'Error in updating password. Please try again'

export const SUCCESSFULLY_UPDATE_PASSWORD_MESSAGE =
  'Updated your password successfully'
