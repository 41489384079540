import { ISelectOption } from 'interfaces/select'

export const showArrayAsStr = (
  arr: ISelectOption[] | string[] | null | string | undefined,
  sign: string = ', ',
) => {
  if (typeof arr === 'string' || typeof arr === 'number') return arr
  if (!arr) return ''
  return Array.isArray(arr)
    ? arr
        ?.map((item) => (typeof item === 'string' ? item : item?.value || ''))
        ?.join(sign) || ''
    : ''
}

export const formatOptionsFromStr = (inputString: string) =>
  inputString.split(',').map((item) => {
    const trimmedItem = item.trim()
    return { label: trimmedItem, value: trimmedItem }
  })

export const showSingleSelectValue = (
  selectedItem: ISelectOption | ISelectOption[] | null | string | undefined,
) => {
  if (typeof selectedItem === 'string') return selectedItem
  if (!selectedItem) return selectedItem
  return Array.isArray(selectedItem)
    ? selectedItem[0]?.value
    : selectedItem?.value
}
