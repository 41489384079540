import { ReactNode, forwardRef } from 'react'

import { VStack } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

const Grid = forwardRef(({ children }: { children: ReactNode }, ref) => {
  return (
    <WithCenterLayout
      component={VStack}
      gap="8px"
      alignItems="start"
      minW="fit-content"
    >
      {children}
    </WithCenterLayout>
  )
})

export default Grid
