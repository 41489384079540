import { Text } from '@chakra-ui/react'

const CommentText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text fontSize="md" fontWeight="normal" color="#F9FAFA">
      {children}
    </Text>
  )
}

export default CommentText
