import { NAVBAR } from 'constants/navbar'
import { TRoute } from 'types/route'

// import ScreenRoom from '../pages'
import ShortList from '../pages/shortlist'

const ScreenRoomRoutes: TRoute[] = [
  {
    path: NAVBAR.PATHS.SCREEN_ROOM,
    profile: 'Investor',
    feature: 'ViewScreenRoomPage',
    exact: true,
    // component: ScreenRoom,
    component: ShortList,
  },
]

export default ScreenRoomRoutes
