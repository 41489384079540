import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'
import { getColor, mode } from '@chakra-ui/theme-tools'

import { runIfFn } from '../utils/run-if-fn'

const baseStyle = defineStyle(({ theme }) => ({
  lineHeight: '1.2',
  fontWeight: 'normal',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 1,
    boxShadow: 'none',
  },
  _hover: {},
  _focus: {
    outline: 'none',
  },
  _active: {},
}))

const variantGhost = defineStyle((props) => {
  const { colorScheme: c, theme } = props

  if (c === 'gray') {
    return {
      color: getColor(theme, mode(`inherit`, `whiteAlpha.900`)(props)),
    }
  }

  return {
    color: getColor(theme, 'dark.700'),
    _hover: {
      // bg: '#ea1b82',
      _disabled: {
        borderColor: getColor(theme, 'dark.300'),
      },
    },
    _active: {
      // bg: getColor(theme, mode(`${c}.300`, darkActiveBg)(props)),
      _disabled: {
        borderColor: getColor(theme, 'dark.300'),
      },
    },
    _disabled: {
      borderColor: getColor(theme, 'dark.300'),
    },
  }
})

const variantOutline = defineStyle((props) => {
  const { colorScheme: c, theme } = props

  return {
    ...runIfFn(variantGhost, props),
    color: 'general.white',
    border: '2px solid',
    borderColor: 'accent.main01',
    lineHeight: '140%',
    borderRadius: 4,
    _hover: {
      ...variantGhost(props)._hover,
      bg: 'accent.main02Hover',
      borderColor: 'accent.main02Hover',
      borderWidth: 0,
      _disabled: {
        borderWidth: '2px',
      },
    },
    _active: {
      ...variantGhost(props)._active,
      borderColor: getColor(theme, `${c}.500`),
    },
    _disabled: {
      color: 'shape.lightMode',
      bg: '2px solid accent.main02Hover',
      borderColor: 'accent.main02Hover',
    },
  }
})

const variantFilled = defineStyle((props) => {
  const { colorScheme: c, theme } = props

  return {
    ...runIfFn(variantGhost, props),
    bg: 'accent.main02',
    borderRadius: 4,
    color: 'general.white',
    lineHeight: '140%',
    _hover: {
      ...variantGhost(props)._hover,
      bg: 'accent.main02Hover',
    },
    _active: {
      ...variantGhost(props)._active,
      borderColor: getColor(theme, `${c}.500`),
    },
    _disabled: {
      color: 'shape.lightMode',
      bg: 'shape.lightMode',
    },
  }
})

const variantLending = defineStyle((props) => {
  const { colorScheme: c, theme } = props
  return {
    ...runIfFn(variantGhost, props),
    minH: '56px',

    borderRadius: '60px',
    lineHeight: '24px',
    fontSize: 'lg',
    fontWeight: 'medium',
    p: '16px 20px',
    gap: '12px',
    '.chakra-button__icon': { ml: 0 },
    svg: {
      width: '1.25rem',
      height: '1.25rem',
      transform: 'rotate(-45deg)',
      color: '#101010',
      bg: 'white',
      borderRadius: '50px',
      _dark: {
        color: 'white',
        bg: '#101010',
      },
      _light: {
        // color: 'white',
        // bg: '#101010',
      },
    },
    _dark: {
      border: '1px solid #101010',
      bg: 'transparent',
      color: '#101010',
    },
    _light: {
      border: '1px solid #FFF',
      bg: '#0C0C10',
      color: 'white',
    },
    _hover: {
      ...variantGhost(props)._hover,

      _dark: {
        bg: 'white',
        color: '#101010',
      },
      _light: {
        bg: 'accent.main02',
        color: 'white',
      },
      svg: {
        bg: '#101010',
        color: 'white',
      },
    },
    _active: {
      ...variantGhost(props)._active,
      borderColor: getColor(theme, `${c}.500`),
    },
    _disabled: {
      color: 'rgba(255, 255, 255, 0.40)',
      bg: 'rgba(255, 255, 255, 0.40)',
    },
  }
})

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  filled: variantFilled,
  lending: variantLending,
}

const sizes = {
  '2xl': defineStyle({
    fontSize: '1rem',
    fontWeight: 'bold',
    p: '1.5rem',
    borderRadius: '1.25rem',
  }),
  xl: defineStyle({
    h: '2.5625rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    p: '1.5rem',
    borderRadius: '1.25rem',
  }),
  lg: defineStyle({
    h: '2.125rem',
    fontSize: '0.75rem',
    p: '1.5rem',
    borderRadius: '1.25rem',
  }),
  md: defineStyle({
    // h: '2.125rem',
    fontSize: '0.75rem',
    p: '0.75rem 1.625rem',
    borderRadius: '1.25rem',
  }),
  sm: defineStyle({
    minW: '6',
    fontSize: '0.75rem',
    px: '3',
    borderRadius: '1.25rem',
  }),
  xs: defineStyle({
    h: '6',
    minW: '6',
    fontSize: 'xs',
    px: '2',
    borderRadius: '1.25rem',
  }),
  base: defineStyle({
    h: '6',
    minW: '30%',
    fontSize: '0,75rem',
    px: '2',
    borderRadius: '1.25rem',
  }),
}

// const sizes = {
//   '2xl': defineStyle({
//     fontSize: '0.75rem',
//     fontWeight: 'bold',
//     p: '1rem 1.5rem',
//     borderRadius: '1rem',
//   }),
//   xl: defineStyle({
//     h: '2.5625rem',
//     fontSize: '0.75rem',
//     fontWeight: 'bold',
//     p: '0.75rem 1.625rem',
//     borderRadius: '0.75rem',
//   }),
//   lg: defineStyle({
//     h: '2.125rem',
//     fontSize: '0.75rem',
//     p: '0.625rem 0.5rem',
//     borderRadius: '0.75rem',
//   }),
//   md: defineStyle({
//     h: '2.125rem',
//     fontSize: '0.625rem',
//     p: '0.625rem 0.5rem',
//     borderRadius: '0.375rem',
//   }),
//   sm: defineStyle({
//     h: '6',
//     minW: '6',
//     fontSize: '0.75rem',
//     px: '3',
//     borderRadius: '0.375rem',
//   }),
//   xs: defineStyle({
//     h: '6',
//     minW: '6',
//     fontSize: 'xs',
//     px: '2',
//     borderRadius: '0.375rem',
//   }),
// }

export default defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'filled',
    size: 'md',
    colorScheme: 'main',
  },
})
