import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@chakra-ui/icons'

import { TextInput } from 'components/common/DLInput'
import { CircleError, Warning } from 'components/common/Icons'
import { WithResponsiveLayout } from 'components/hoc'

import Select from 'modules/dashboard/components/other/Select'

export const WarningAlert = ({
  warningText,
  onClose,
}: {
  warningText?: string
  onClose?: () => void
}) => (
  <Alert
    backdropFilter="blur(84px)"
    status="warning"
    w="full"
    borderRadius={4}
    border="alert.warning"
    bg="darkMode.500"
    justifyContent="space-between"
    alignItems={['start', 'start', 'start', 'center', 'center']}
    color="white"
  >
    <Warning
      mt={['0.7rem', '0.7rem', '0.7rem', 0, 0]}
      mr={2.75}
      color="#FED02C"
      w="1.25rem"
      h="1.25rem"
    />
    <WithResponsiveLayout
      gap={['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.75rem']}
      alignItems="start"
      flex="1 1 100%"
    >
      <WithResponsiveLayout
        gap={['0', '0', '0', '0', '0.75rem']}
        alignItems="start"
        flex="1 1 100%"
      >
        <Text color="#FED02C" fontSize="md">
          Warning
        </Text>
        <Text fontSize="sm">{warningText}</Text>
      </WithResponsiveLayout>
      {/* <Link color="main01.500" gap="0" display="flex">
        <Text fontSize="md">Button</Text>
        <ChevronRightIcon color="main01.500" w="1.25rem" h="1.25rem" />
      </Link> */}
    </WithResponsiveLayout>

    <HStack gap="1.5rem">
      <CloseButton color="white" onClick={onClose} />
    </HStack>
  </Alert>
)

export const SuccessAlert = ({
  text,
  onClose,
}: {
  text?: string
  onClose?: () => void
}) => (
  <Alert
    backdropFilter="blur(84px)"
    status="success"
    w="full"
    borderRadius={4}
    border="alert.success"
    bg="darkMain.500"
    justifyContent="space-between"
    alignItems={['start', 'start', 'start', 'center', 'center']}
  >
    <AlertIcon color="#2FEA00" mt={['0.7rem', '0.7rem', '0.7rem', 0, 0]} />
    <WithResponsiveLayout
      gap={['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.75rem']}
      alignItems="start"
      flex="1 1 100%"
    >
      <WithResponsiveLayout
        gap={['0', '0', '0', '0', '0.75rem']}
        alignItems="start"
        flex="1 1 100%"
      >
        <Text color="#2FEA00" fontSize="md">
          Success
        </Text>
        <Text fontSize="sm" color="white">
          {text}
        </Text>
      </WithResponsiveLayout>
      {/* <Link color="main01.500" gap="0" display="flex">
        <Text fontSize="md">Button</Text>
        <ChevronRightIcon color="main01.500" w="1.25rem" h="1.25rem" />
      </Link> */}
    </WithResponsiveLayout>
    <HStack gap="1.5rem">
      <CloseButton color="white" onClick={onClose} />
    </HStack>
  </Alert>
)

export const ErrorAlert = ({
  warningText,
  onClose,
}: {
  warningText?: string
  onClose?: () => void
}) => (
  <Alert
    backdropFilter="blur(84px)"
    status="error"
    w="full"
    borderRadius={4}
    border="alert.error"
    bg="darkMode.500"
    justifyContent="space-between"
    alignItems={['start', 'start', 'start', 'center', 'center']}
    color="white"
  >
    <CircleError
      mt={['0.7rem', '0.7rem', '0.7rem', 0, 0]}
      mr={2.75}
      color="#FE2C2C"
      w="1.25rem"
      h="1.25rem"
    />
    <WithResponsiveLayout
      gap={['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.75rem']}
      alignItems="start"
      flex="1 1 100%"
    >
      <WithResponsiveLayout
        gap={['0', '0', '0', '0', '0.75rem']}
        alignItems="start"
        flex="1 1 100%"
      >
        <Text color="#FE2C2C" fontSize="md">
          Error
        </Text>
        <Text fontSize="sm">{warningText}</Text>
      </WithResponsiveLayout>
    </WithResponsiveLayout>

    <HStack gap="1.5rem">
      <CloseButton color="white" onClick={onClose} />
    </HStack>
  </Alert>
)

// const ErrorAlert = () => (
//   <Alert
//     backdropFilter="blur(84px)"
//     status="error"
//     w="full"
//     borderRadius={4}
//     border="alert.error"
//     alignItems={['start', 'start', 'start', 'center', 'center']}
//     bgImage="linear-gradient(to left top, #101010, #3a213f, #7d2159, #c60c53, #fe2c2c)"
//     justifyContent="space-between"
//   >
//     <HStack gap="0.75rem">
//       <CircleError color="#FE2C2C" w="1.25rem" h="1.25rem" />
//       <Text color="#FE2C2C" fontSize="md" fontWeight="bold">
//         Error
//       </Text>
//       <Text fontSize="sm">Some description</Text>
//     </HStack>

//     <HStack gap="1.5rem">
//       <Link color="main01.500" gap="0" display="flex">
//         <Text fontSize="md">Button</Text>
//         <ChevronRightIcon color="main01.500" w="1.25rem" h="1.25rem" />
//       </Link>
//       <CloseButton color="white" />
//     </HStack>
//   </Alert>
// )

const Elements = () => {
  const toast = useToast()

  return (
    <VStack gap={10} w="full" bg="general.black" p={10} borderRadius={4}>
      <VStack color="white" w="full" alignItems="start" gap={5}>
        <Heading size="md">Filled buttons</Heading>
        <HStack gap={5}>
          <Button variant="filled" size="lg">
            Sign In
          </Button>

          <Button variant="filled" size="lg" isDisabled>
            Sign In
          </Button>
        </HStack>
      </VStack>

      <VStack color="white" w="full" alignItems="start" gap={5}>
        <Heading size="md">Outline buttons</Heading>
        <HStack gap={5}>
          <Button variant="outline" size="lg">
            Sign In
          </Button>

          <Button variant="outline" size="lg" isDisabled>
            Sign In
          </Button>
        </HStack>
      </VStack>

      <VStack color="white" w="full" alignItems="start" gap={5}>
        <Heading size="md">Input</Heading>
        <HStack gap={5}>
          <TextInput />
          <FormControl isInvalid>
            <TextInput />
            <FormErrorMessage justifyContent="end" color="solid.orange">
              Invalid value
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </VStack>

      <VStack color="white" w="full" alignItems="start" gap={5}>
        <Heading size="md">Select</Heading>
        <Select
          w={5}
          placeholder="Filter by domain"
          options={[
            { label: 'test', value: 'test' },
            { label: 'test22', value: 'test22' },
          ]}
          onChange={() => {}}
        />
      </VStack>

      <VStack color="white" w="full" alignItems="start" gap={5}>
        <Heading size="md">Alert</Heading>

        <Alert
          backdropFilter="blur(84px)"
          status="info"
          w="full"
          borderRadius={4}
          border="alert.info"
          bg="darkMode.500"
          justifyContent="space-between"
          alignItems={['start', 'start', 'start', 'center', 'center']}
        >
          <AlertIcon
            color="#8B9DFB"
            mt={['0.7rem', '0.7rem', '0.7rem', 0, 0]}
          />
          <WithResponsiveLayout
            gap={['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.75rem']}
            alignItems="start"
            flex="1 1 100%"
          >
            <WithResponsiveLayout
              gap={['0', '0', '0', '0', '0.75rem']}
              alignItems="start"
              flex="1 1 100%"
            >
              <Text color="#8B9DFB" fontSize="md">
                Notification
              </Text>

              <Text fontSize="sm">Some description</Text>
            </WithResponsiveLayout>
            <Link color="main01.500" gap="0" display="flex">
              <Text fontSize="md">Button</Text>
              <ChevronRightIcon color="main01.500" w="1.25rem" h="1.25rem" />
            </Link>
          </WithResponsiveLayout>

          <HStack gap="1.5rem">
            <CloseButton color="white" />
          </HStack>
        </Alert>
        <WarningAlert />
        <Button
          variant="filled"
          onClick={() =>
            toast({
              render: () => <SuccessAlert text="Test warning toast" />,
              duration: 55555,
              isClosable: true,
              position: 'top',
              containerStyle: {
                minWidth: '90vw',
              },
            })
          }
        >
          Show Toast
        </Button>
      </VStack>
    </VStack>
  )
}

export default Elements
