import { CSSReset, ChakraProvider } from '@chakra-ui/react'

import queryClient from 'config/query'
import { AuthProvider } from 'contexts/AuthContext'
import { GlobalProvider } from 'contexts/GlobalContext'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Routes from 'routers/Routes'

import { PPObjectFont } from 'components/common/DLFonts'

import theme from './theme/index'

const App = () => {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{ defaultOptions: { position: 'top' } }}
    >
      <CSSReset />
      <PPObjectFont />
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </GlobalProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
