import { useEffect, useState } from 'react'

import {
  Button,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'

import { SingleValue } from 'chakra-react-select'
import supabase from 'config/supabase-client'
import dayjs from 'dayjs'
import { useAuth, useDLToast } from 'hooks'
import { ISelectOption } from 'interfaces/select'

import CollectionEmpty from 'components/common/other/CollectionEmpty'
import { DHeading, TextValue } from 'components/common/other/deal-details'
import { Card, PlusSection } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithSpinnerLoading } from 'components/hoc'

import DealDetail from 'modules/dashboard/components/DealDetail'
import Select from 'modules/dashboard/components/other/Select'
import { dealPriorityOptions } from 'modules/dashboard/constants/select'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'
import { ConfirmDisclaimerModal } from 'modules/screen-rooms/components'
import { SHORTLIST_TABLE } from 'modules/screen-rooms/constants'
import {
  UPDATE_PRIORITY_SUCCESSFUL,
  UPDATE_PRIORITY_UNSUCCESSFUL,
} from 'modules/screen-rooms/constants/messages'
import { useShortList } from 'modules/screen-rooms/hooks'

import { ContactFounder, RescindInterestModal } from '../components'

const PageActions = ({
  deal,
  defaultPriority,
  fetchDeals,
}: {
  deal: IDealRow
  defaultPriority: string
  fetchDeals: () => Promise<void>
}) => {
  const { getUser } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenCF,
    onOpen: onOpenCF,
    onClose: onCloseCF,
  } = useDisclosure()
  /**
   *  helper for showing error toast
   * **/
  const { showErrorToast, showSuccessToast } = useDLToast()
  const [priority, setPriority] = useState<
    ISelectOption | string | null | undefined
  >(defaultPriority)

  /**
   * Function to add a deal to the shortlist.
   *
   * @param {} -
   * @return {Promise<void>}
   */
  const handlePriorityChange = async (newValue: SingleValue<ISelectOption>) => {
    try {
      setPriority(newValue?.value)
      const payload = {
        priority: newValue?.value,
      }

      const { error } = await supabase
        .from(SHORTLIST_TABLE)
        .update(payload)
        .eq('deal_id', deal?.deal_id)
        .eq('user_id', getUser()?.user?.id)

      if (error) {
        showErrorToast(UPDATE_PRIORITY_UNSUCCESSFUL)
        return
      }
      fetchDeals?.()
      showSuccessToast(UPDATE_PRIORITY_SUCCESSFUL)
    } catch (err) {
      showErrorToast(UPDATE_PRIORITY_UNSUCCESSFUL)
    }
  }

  useEffect(() => {
    defaultPriority && setPriority(defaultPriority)
  }, [defaultPriority])

  return (
    <WithCenterLayout
      as="section"
      w="full"
      justifyContent="space-between"
      color="white"
      alignItems="start"
      flexDirection={['column', 'column', 'row', 'row', 'row']}
    >
      <VStack alignItems="start">
        <DHeading fontWeight="bold" fontSize="2xl" color="white">
          Deal Insights
        </DHeading>
        <Text color="#A0AEC0" fontSize="1rem" fontWeight="normal">
          Manage everything you know about this company.
        </Text>
      </VStack>

      <WithCenterLayout gap={{ base: '10px', md: '30px' }}>
        {/* <Select
          placeholder="Priority"
          options={dealPriorityOptions}
          onChange={handlePriorityChange}
          value={dealPriorityOptions.filter((p) => p?.value === priority)}
          isClearable
          customStyles={{
            container: {
              flex: '0 0 auto',
            },
            control: { border: '1px solid #8B9DFB !important' },
            menu: { border: '1px solid #8B9DFB !important' },
            option: (state: any) => {
              return {
                color: state?.data?.color,
              }
            },
          }}
        /> */}

        <Button
          // size={['sm', 'sm', 'md', 'md', 'md', 'xl']}
          // w={['full', 'full', 'calc((100% - 2*1.5rem) / 3)']}
          variant="filled"
          borderRadius={['8px !important']}
          p={['15px 54px', '15px 54px', '15px 54px', '15px 54px', '15px 54px']}
          onClick={onOpenCF}
        >
          Request to Contact Founder
        </Button>
      </WithCenterLayout>

      <ConfirmDisclaimerModal
        onClose={onClose}
        isOpen={isOpen}
        dealID={deal?.deal_id}
      />

      <ContactFounder deal={deal ?? {}} isOpen={isOpenCF} onClose={onCloseCF} />
    </WithCenterLayout>
  )
}

const Pipelines = () => {
  const { getUser } = useAuth()
  const [selectedDeal, setSelectedDeal] = useState<IDealRow | undefined>({})
  const { isLoading, fetchedDeals, fetchDeals } = useShortList(true)
  const {
    isOpen: isOpenRemovingDealPopup,
    onOpen: onOpenRemovingDealPopup,
    onClose: onCloseRemovingDealPopup,
  } = useDisclosure()
  // const { teamNotes, loadTeamNotes } = useTeamNotes({
  //   dealId: selectedDeal?.deal_id,
  //   teamId: selectedDeal?.users?.investor_teams?.[0]?.team_id,
  // })

  useEffect(() => {
    if (!isLoading) {
      setSelectedDeal(fetchedDeals?.[0])
    }
  }, [fetchedDeals, isLoading])

  return (
    <WithSpinnerLoading isLoading={isLoading}>
      {(!fetchedDeals || fetchedDeals?.length === 0) && !isLoading && (
        <WithCenterLayout w="full" h="full">
          <Stack
            p="122.5px 204px"
            borderRadius="24px"
            bg="rgba(19, 20, 31, 0.80)"
          >
            <CollectionEmpty />
          </Stack>
        </WithCenterLayout>
      )}
      {fetchedDeals && fetchedDeals?.length > 0 && !isLoading && (
        <Grid
          w="full"
          h="full"
          templateColumns={['1fr', '1fr', '1fr', '1fr 2.5fr', '1fr 2.5fr']}
          templateRows="repeat(1, 1fr)"
          pos="absolute"
          top="0"
          right="0"
          bottom="0"
          gridGap={['16px', '16px', '16px', '16px', '16px', '16px']}
          pl={['0px', '0px', '0px', '20px', '20px']}
          overflow={['auto', 'auto', 'auto', 'hidden', 'hidden', 'hidden']}
        >
          <GridItem
            as="section"
            pt="60px"
            px={['10px', '10px', '10px', '0px', '0px', '0px']}
            h="100vh"
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'grey.300',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'grey',
                borderRadius: '6px',
                '&:hover': {
                  background: '#555',
                },
              },
            }}
          >
            <VStack w="full" h="full" alignItems="start" gap="24px">
              <DHeading fontWeight="bold" fontSize="2xl" color="white">
                Your Pipeline
              </DHeading>
              {fetchedDeals?.map((deal: IDealRow) => {
                return (
                  <Card
                    key={deal?.deal_id}
                    deal={deal}
                    shouldBlurAvatar={false}
                    isActivated={selectedDeal?.deal_id === deal?.deal_id}
                    onClick={setSelectedDeal}
                  />
                )
              })}

              <PlusSection />
            </VStack>
          </GridItem>
          <GridItem
            as="section"
            bg="#2D3748"
            // borderTopLeftRadius={['0px', '0px', '0px', '40px', '40px']}
            // borderBottomLeftRadius={['0px', '0px', '0px', '40px', '40px']}
            p={['10px', '10px', '20px', '20px', '20px']}
            py="50px"
            h="100vh"
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'grey.300',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'grey',
                borderRadius: '6px',
                '&:hover': {
                  background: '#555',
                },
              },
            }}
          >
            <VStack gap="20px">
              <PageActions
                deal={selectedDeal ?? {}}
                defaultPriority={selectedDeal?.priority}
                fetchDeals={fetchDeals}
              />
              {selectedDeal && (
                <DealDetail
                  deal={selectedDeal}
                  shouldBlurAvatar={false}
                  onBack={fetchDeals}
                />
              )}
              <HStack
                w="full"
                justifyContent="space-between"
                alignItems="start"
                flexDir={['column', 'column', 'row', 'row', 'row']}
              >
                <VStack alignItems="start">
                  <HStack flexWrap="wrap">
                    <TextValue
                      fontWeight="semibold"
                      fontSize={['md', 'md', 'md', 'md', 'lg']}
                    >
                      Added to Pipeline by:
                    </TextValue>
                    <TextValue
                      fontWeight="normal"
                      fontSize={['md', 'md', 'md', 'md', 'lg']}
                    >
                      {getUser()?.user?.email}
                    </TextValue>
                  </HStack>
                  <HStack fontSize={['md', 'md', 'md', 'md', 'lg']}>
                    <TextValue
                      fontWeight="semibold"
                      fontSize={['md', 'md', 'md', 'md', 'lg']}
                    >
                      Date Added:
                    </TextValue>
                    <TextValue
                      fontWeight="normal"
                      fontSize={['md', 'md', 'md', 'md', 'lg']}
                    >
                      {dayjs(selectedDeal?.created_at ?? new Date())?.format(
                        'MMMM D, YYYY h:mmA',
                      )}
                    </TextValue>
                  </HStack>
                </VStack>

                <HStack gap="0.5rem" justifyContent="end" flexWrap="wrap">
                  <Button
                    size={['sm', 'sm', 'sm', 'md', 'md', 'xl']}
                    variant="outline"
                    borderRadius={['1rem !important']}
                    p={['15px', '15px', '15px', '15px 54px', '15px 54px']}
                    border="0.801px solid #FF7D83"
                    onClick={onOpenRemovingDealPopup}
                  >
                    Rescind Interest
                  </Button>
                </HStack>
              </HStack>

              {/* <CommentList
                loadTeamNotes={loadTeamNotes}
                comments={teamNotes}
                dealID={selectedDeal?.deal_id}
                teamID={selectedDeal?.users?.investor_teams?.[0]?.team_id}
              /> */}
            </VStack>
          </GridItem>
        </Grid>
      )}

      <RescindInterestModal
        dealID={selectedDeal?.deal_id}
        isOpen={isOpenRemovingDealPopup}
        onClose={onCloseRemovingDealPopup}
      />
    </WithSpinnerLoading>
  )
}

export default Pipelines
