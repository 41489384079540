import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import SmallText from './SmallText'

const EffortlessSynchronization = () => {
  return (
    <Stack
      bg="#0F172A"
      alignItems="center"
      justifyContent="center"
      p={['20px', '20px', '20px', '40px', '40px', '40px']}
      w="full"
    >
      <WithResponsiveLayout
        maxW="1600px"
        gap={['40px', '40px', '40px', '80px', '80px', '80px']}
        p={['20px', '20px', '20px', '40px', '40px', '40px']}
      >
        <VStack gap="32px">
          <VStack gap="8px" alignItems="start" w="full">
            <Text
              color="#FFF"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Intelligence management.
            </Text>
            <Text
              color="#8B9DFB"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Effortless synchronization.
            </Text>
          </VStack>

          <SmallText>
            Combine team sentiments and expert analyses on one platform so you
            can manage a single source of truth for every deal.
          </SmallText>

          <Stack gap="4px" w="full">
            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>
              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Integrate
              </Text>
            </HStack>

            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>
              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Filter
              </Text>
            </HStack>

            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>

              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Compare
              </Text>
            </HStack>

            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>

              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Aggregate
              </Text>
            </HStack>
          </Stack>
        </VStack>

        <WithCenterLayout alignSelf="stretch">
          <Image src="images/effortless-synchronization.png" />
        </WithCenterLayout>
      </WithResponsiveLayout>
    </Stack>
  )
}

export default EffortlessSynchronization
