import { Icon } from '@chakra-ui/react'

const Warning = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.07173 9C7.34532 5.06204 8.48211 3.09306 9.96626 2.43227C11.2608 1.85591 12.7391 1.85591 14.0336 2.43227C15.5178 3.09306 16.6546 5.06204 18.9281 9C21.2017 12.938 22.3385 14.9069 22.1687 16.5226C22.0206 17.9319 21.2814 19.2122 20.135 20.0451C18.8207 21 16.5471 21 11.9999 21C7.45277 21 5.17919 21 3.86486 20.0451C2.71845 19.2122 1.9793 17.9319 1.83118 16.5226C1.66136 14.9069 2.79815 12.938 5.07173 9ZM11.9999 6.25C12.4141 6.25 12.7499 6.58579 12.7499 7V13C12.7499 13.4142 12.4141 13.75 11.9999 13.75C11.5857 13.75 11.2499 13.4142 11.2499 13V7C11.2499 6.58579 11.5857 6.25 11.9999 6.25ZM11.9999 17C12.5522 17 12.9999 16.5523 12.9999 16C12.9999 15.4477 12.5522 15 11.9999 15C11.4476 15 10.9999 15.4477 10.9999 16C10.9999 16.5523 11.4476 17 11.9999 17Z"
        fill="#FED02C"
      />
    </Icon>
  )
}

export default Warning
