import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { PageNotFound } from 'components/layout'

import PrivateRoutes from './PrivateRoutes'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import PublicRoutes from './PublicRoutes'

const Routes = () => {
  return (
    <Router>
      <Switch>
        {PublicRoutes.map((props) => (
          <PublicRoute key={props.path} {...props} />
        ))}
        {PrivateRoutes.map((props) => (
          <ProtectedRoute key={props.path} {...props} />
        ))}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
