import { useState } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  Textarea,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import { useAuth } from 'hooks'
import { TTeamNotes } from 'hooks/useTeamNotes'

import { WithCenterLayout } from 'components/hoc'

const CommentReplyActions = ({
  isEditCommentState = false,
  isChild = false,
  comment,
  handleEditComment,
  resetState,
  refetchRepliesOnNotes,
}: {
  isEditCommentState?: boolean
  comment?: TTeamNotes
  isChild?: boolean | undefined
  handleEditComment?: (comment: string) => void
  resetState?: () => void
  refetchRepliesOnNotes?: () => void
}) => {
  const { getUser } = useAuth()
  const currentUser = getUser() || {}
  const [newComment, setNewComment] = useState<string | null>()
  const commenter = `${currentUser?.metadata?.first_name} ${currentUser?.metadata?.last_name}`

  const handleChangeComment: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event: any,
  ) => {
    setNewComment(event?.target?.value || '')
  }

  const handleSubmitComment = async () => {
    if (isEditCommentState) {
      handleEditComment?.((newComment || comment?.note_text) ?? '')
    } else {
      await supabase?.from(TABLES_NAME.team_notes).insert({
        note_text: newComment,
        user_id: currentUser?.user?.id,
        parent_note_id: !comment?.note_id ? null : comment?.note_id,
        deal_id: comment?.deal_id,
        team_id: comment?.team_id,
      })
    }
    resetState?.()
    !isChild && refetchRepliesOnNotes?.()
    setNewComment('')
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      handleSubmitComment()
    }
  }

  const placeholder = comment?.asPlaceholder
    ? comment?.asPlaceholder
    : !isEditCommentState
      ? `Comment as ${commenter} ........`
      : comment?.note_text ?? ''

  return (
    <Flex pos="relative" w="full">
      <Textarea
        minW="full"
        minH="150px"
        placeholder={placeholder}
        fontSize="1rem"
        fontWeight="normal"
        bg="rgba(255, 255, 255, 0.20)"
        color="#9E9EA8"
        borderRadius={3}
        p="23px"
        border="unset"
        onChange={handleChangeComment}
        value={newComment ?? ''}
        // value={(!isEditCommentState ? newComment : comment?.note_text) ?? ''}
        onKeyDown={handleKeyPress}
      />

      <Box
        pos="absolute"
        w="full"
        // right="15px"
        bottom="15px"
        zIndex={99}
        pr="1rem"
      >
        <HStack w="full" justifyContent="end">
          {/* <HStack w="full" gap="0" display="none">
            <Button
              w="32px"
              h="32px"
              variant="outline"
              size="sm"
              color="darkMode"
              borderRadius="50%"
              fontWeight="bold"
              border="unset"
              p={2}
              _hover={{ bg: 'grey', opacity: 0.8 }}
            >
              <Box
                minW="16px"
                h="16px"
                bgSize="auto"
                bgRepeat="no-repeat"
                as="i"
                bgImage="url(images/reaction.png)"
                bgPosition="0px -1205px"
                borderRadius="0"
                display="inline-block"
                filter="invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
              />
            </Button>

            <Button
              w="32px"
              h="32px"
              variant="outline"
              size="sm"
              color="darkMode"
              borderRadius="50%"
              fontWeight="bold"
              border="unset"
              p={2}
              _hover={{ bg: 'grey', opacity: 0.8 }}
            >
              <Image
                src="images/file-upload.png"
                w="24px"
                borderRadius="0"
                display="inline-block"
              />
            </Button>

            <Button
              w="32px"
              h="32px"
              variant="outline"
              size="sm"
              color="darkMode"
              borderRadius="50%"
              fontWeight="bold"
              border="unset"
              p={2}
              _hover={{ bg: 'grey', opacity: 0.8 }}
            >
              <Box
                minW="16px"
                h="16px"
                bgSize="auto"
                bgRepeat="no-repeat"
                as="i"
                bgImage="url(images/reaction.png)"
                bgPosition="0px -1154px"
                borderRadius="0"
                display="inline-block"
                filter="invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
              />
            </Button>
          </HStack> */}

          <Button
            w="76px"
            h="36px"
            variant="outline"
            size="md"
            color="#FFF"
            borderRadius="1rem"
            bg="#718096"
            fontWeight="medium"
            border="unset"
            fontSize="lg"
            p={2}
            _hover={{ bg: 'grey', opacity: 0.8 }}
            onClick={handleSubmitComment}
          >
            {/* <Box
              minW="16px"
              h="16px"
              bgSize="auto"
              bgRepeat="no-repeat"
              as="i"
              bgImage="url(images/reaction.png)"
              bgPosition="0px -1290px"
              borderRadius="0"
              display="inline-block"
              filter={
                newComment
                  ? 'invert(27%) sepia(95%) saturate(3116%) hue-rotate(212deg) brightness(99%) contrast(105%)'
                  : 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)'
              }
            /> */}
            Send
          </Button>
          {/* <Button
            minW="32px"
            variant="outline"
            size="sm"
            color="darkMode"
            borderRadius="50%"
            fontWeight="bold"
            border="unset"
            p={2}
            _hover={{ bg: 'grey', opacity: 0.8 }}
            onClick={handleSubmitComment}
          >
            <Box
              minW="16px"
              h="16px"
              bgSize="auto"
              bgRepeat="no-repeat"
              as="i"
              bgImage="url(images/reaction.png)"
              bgPosition="0px -1290px"
              borderRadius="0"
              display="inline-block"
              filter={
                newComment
                  ? 'invert(27%) sepia(95%) saturate(3116%) hue-rotate(212deg) brightness(99%) contrast(105%)'
                  : 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)'
              }
            />
          </Button> */}
        </HStack>
      </Box>
    </Flex>
  )
}

export default CommentReplyActions
