import { Resolver } from 'react-hook-form'
import * as yup from 'yup'

import { ExpressForm } from '../components/ConfirmDisclaimerModal'

const yupNumberFieldValidation = (field: string) =>
  yup
    .mixed()
    .nullable()
    .transform((originalValue) => (originalValue === '' ? NaN : originalValue))
    .test({
      name: 'is-number',
      message: `${field} must be a number`,
      test: (value) => !isNaN(value),
    })
    .test({
      name: 'is-non-negative',
      message: `${field} is required`,
      test: (value) => (value ?? 0) >= 0,
    })

const formRules = {
  bid_amount: yupNumberFieldValidation('The interest number'),
}

export const formSchema = yup.object(formRules)

export const expressFormResolver: Resolver<ExpressForm> = async (values) => {
  try {
    await formSchema.validate(values, { abortEarly: false })
    return { values, errors: {} }
  } catch (validationErrors: any) {
    return {
      values: {},
      errors: validationErrors?.inner?.reduce(
        (allErrors: any, currentError: any) => ({
          ...allErrors,
          [currentError.path!]: currentError.message,
        }),
        {},
      ),
    }
  }
}
