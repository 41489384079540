/* eslint-disable no-unused-vars */
import { Fragment } from 'react'

import { useBoolean, useToast } from '@chakra-ui/react'

import { AuthTokenResponse } from '@supabase/supabase-js'
import { NAVBAR } from 'constants/navbar'
import { useAuth, useDLToast } from 'hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { ErrorAlert } from 'modules/landing/pages/elements'

import { LoginForm } from '../components'
import {
  LOGIN_DEFAULT_VALUES,
  LOGIN_FAILED_ERROR_MESSAGE,
} from '../constants/login'
import { ILoginForm } from '../interfaces/login'

/**
 *  Login component
 * **/
const Login = () => {
  /**
   *  Defining all variables here
   * **/
  const history = useHistory()
  const [isLoading, toggle] = useBoolean()
  const { login, isInvestor, writeLogWhenUserLoggedIn } = useAuth()

  /**
   *  helper for showing error toast
   * **/
  const { showErrorToast } = useDLToast()
  const toast = useToast()

  const methods = useForm<ILoginForm>({
    defaultValues: LOGIN_DEFAULT_VALUES,
  })

  /**
   *  Handling submit login
   * **/
  const handleSubmit = async () => {
    try {
      toggle.on()
      const { email, password } = methods.getValues()
      const response: AuthTokenResponse = await login(email, password)

      if (!isInvestor()) {
        toast({
          render: () => (
            <ErrorAlert
              warningText="Only investors can login"
              onClose={toast.closeAll}
            />
          ),
          duration: 55555,
          isClosable: true,
          position: 'top',
          containerStyle: {
            minWidth: '90vw',
          },
        })
        return
      }

      if (response?.error) {
        showErrorToast(response.error.message || LOGIN_FAILED_ERROR_MESSAGE)
        return
      }

      if (response?.data?.session) {
        writeLogWhenUserLoggedIn({ userID: response?.data?.session.user.id })
        history.push(NAVBAR.PATHS.HOME)
      }
    } catch (e) {
      showErrorToast(LOGIN_FAILED_ERROR_MESSAGE)
    } finally {
      toggle.off()
    }
  }

  /**
   *  Rendering component
   * **/
  return (
    <Fragment>
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods?.handleSubmit(handleSubmit)}
          autoComplete="off"
        >
          <LoginForm isLoading={isLoading} />
        </form>
      </FormProvider>
    </Fragment>
  )
}

export default Login
