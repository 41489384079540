import React, { ReactNode, useCallback, useEffect } from 'react'

import {
  Box,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import { NAVBAR_WIDTH } from 'constants/common'
import { NAVBAR, NAV_ITEMS } from 'constants/navbar'
import { motion } from 'framer-motion'
import { useLocalStorage, useMobile } from 'hooks'
import { useHistory } from 'react-router'

import { UpgradeVenture } from 'modules/dashboard/components/dashboard'

import NewNavBar, { INavBarItem } from './NewNavBar'

const MainLayout = ({ children }: { children: ReactNode }) => {
  const history = useHistory()
  const isFullRightLayout = ['/home-page'].includes(history.location.pathname)
  const [isClickedNavBar, toggle] = useBoolean()
  const isMobile = useMobile()
  const [activatedNavBar, setActivatedNavBar] = useLocalStorage(
    'activatedNavBar',
    { index: 0, navbar: NAV_ITEMS[0] },
  )

  const bgOfHomePage = isFullRightLayout
    ? 'gradientBg.normalDarkMode'
    : 'gradientBg.normalDarkMode'
  const bgByMode = useColorModeValue(bgOfHomePage, 'gradientBg.lightMode')

  const handleNavBarClick = useCallback(
    (navbar: INavBarItem, index: number) => {
      setActivatedNavBar({ index, navbar })
      toggle.on()
    },
    [setActivatedNavBar, toggle],
  )

  useEffect(() => {
    if (isClickedNavBar && activatedNavBar?.navbar?.path) {
      history.push(activatedNavBar?.navbar?.path)
    }
  }, [activatedNavBar?.navbar?.path, history, isClickedNavBar])

  useEffect(() => {
    const pathname = history.location.pathname
    let foundIndex = null
    if (pathname !== activatedNavBar?.navbar?.path) {
      const navbar = NAV_ITEMS.find(({ path }, index) => {
        foundIndex = index
        return pathname === path
      })
      navbar?.path && setActivatedNavBar({ index: foundIndex, navbar })
    }
  }, [activatedNavBar?.navbar?.path, history, setActivatedNavBar])

  return (
    <React.Fragment>
      <Grid
        as={motion.div}
        layout
        templateColumns={['1fr', '1fr', '1fr', '1fr', `${NAVBAR_WIDTH}px 1fr`]}
        templateRows={[
          '1fr calc(3.5rem + 10px)',
          '1fr calc(3.5rem + 10px)',
          '1fr calc(3.5rem + 10px)',
          '1fr calc(3.5rem + 10px)',
          'auto',
        ]}
        bg={bgByMode}
        bgSize="cover"
        h="100vh"
      >
        {isMobile && (
          <GridItem overflow="hidden auto">
            <Box
              as="main"
              p={isFullRightLayout ? '0 !important' : [3, 3, 3, 5, 5, 10]}
              pos="relative"
              overflowX="auto"
              h={['100%']}
            >
              {children}
            </Box>
          </GridItem>
        )}
        {/** NavBar **/}
        <GridItem>
          <NewNavBar>
            <NewNavBar.Logo
            // borderBottomRightRadius={
            //   0 === activatedNavBar?.['index']
            //     ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
            //     : '0'
            // }
            />
            <GridItem as="nav">
              <VStack
                as="ul"
                gap={0}
                flexDirection={['row', 'row', 'row', 'row', 'column']}
                w="full"
                h={['full', 'full', 'full', 'full', 'auto']}
                bg="#101010"
              >
                {NAV_ITEMS.map((navBarItem: INavBarItem, index) => (
                  <NewNavBar.Item
                    key={navBarItem?.text}
                    {...navBarItem}
                    index={index}
                    onClick={handleNavBarClick}
                    activatedNavBar={activatedNavBar}
                    stylesProps={{ bgColor: 'newBgColor', w: 'full' }}
                  />
                ))}
              </VStack>
              <Box
                // borderTopRightRadius={
                //   NAV_ITEMS?.length - 1 === activatedNavBar?.['index']
                //     ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
                //     : '0'
                // }
                flexGrow={1}
                w={[0, 0, 0, 0, 'full']}
                h={[0, 0, 0, 0, 'full']}
                bg="newBgColor"
              ></Box>
            </GridItem>

            {!isMobile && (
              <GridItem bg="newBgColor">
                <Stack px="15px" h="full" w="full">
                  <UpgradeVenture />
                </Stack>
              </GridItem>
            )}

            <NewNavBar.Footer
              shouldHasRadius={
                activatedNavBar?.['index'] === NAV_ITEMS.length - 1
              }
            />
          </NewNavBar>
        </GridItem>
        {/** Main Layout **/}
        {!isMobile && (
          <GridItem overflow="hidden auto">
            {activatedNavBar?.navbar?.title && (
              <HStack
                p="28px 40px"
                w="full"
                justifyContent="space-between"
                bg="#101010"
              >
                <VStack color="white" gap="0" alignItems="start">
                  <Heading fontSize="32px" fontWeight="bold">
                    {activatedNavBar?.navbar?.title}
                  </Heading>
                  <Text color="#A0AEC0" fontSize="1rem" fontWeight="normal">
                    {activatedNavBar?.navbar?.subTitle}
                  </Text>
                </VStack>
              </HStack>
            )}
            <Box
              as="main"
              p={isFullRightLayout ? '0 !important' : [3, 3, 3, 5, 5, 10]}
              h="100%"
              pos="relative"
              overflowX="auto"
            >
              {children}
            </Box>
          </GridItem>
        )}
      </Grid>
    </React.Fragment>
  )
}

export default MainLayout
