import {
  ActiveDealIcon,
  HomeIcon,
  ScreenRoomIcon,
  SettingIcon,
  WaveIcon,
} from 'components/common/Icons'

export const NAVBAR = {
  BORDER_RADIUS_RESPONSIVE_VALUES: ['20px', '20px', '20px', '20px', '50px'],

  PATHS: {
    HOME: '/home-page',
    SCREEN_ROOM: '/screen-room',
    LEADER_BOARD: '/leaderboard',
    ACTIVE_DEALS: '/active-intros',
    SETTINGS: '/user-settings',
  },
}

export const NAV_ITEMS = [
  {
    text: 'Home',
    icon: HomeIcon,
    path: NAVBAR.PATHS.HOME,
    title: '',
    subTitle: '',
  },
  {
    text: 'Discover',
    icon: WaveIcon,
    path: NAVBAR.PATHS.LEADER_BOARD,
    title: 'Discover Deals',
    subTitle: 'Manage everything you know about this company.',
  },
  {
    text: 'Collection',
    icon: ScreenRoomIcon,
    path: NAVBAR.PATHS.SCREEN_ROOM,
    title: '',
    subTitle: '',
  },
  {
    text: 'Pipeline',
    icon: ActiveDealIcon,
    path: NAVBAR.PATHS.ACTIVE_DEALS,
    title: '',
    subTitle: '',
  },
  {
    text: 'Settings',
    icon: SettingIcon,
    path: NAVBAR.PATHS.SETTINGS,
    title: '',
    subTitle: '',
  },
]

export const NAV_FOOTER_ITEMS = [
  { text: 'Mode', icon: WaveIcon, path: '' },
  { text: 'Log', icon: WaveIcon, path: '' },
]

export const NAV_BAR_STYLES = {
  _before: {
    width: '20px',
    height: '20px',
    top: '-1px',
    left: 0,
    content: '""',
    position: 'absolute',
    background: '#101010',
    clipPath:
      'polygon(0 0, 80% 0%, 58% 5%, 38% 16%, 19% 31%, 5% 53%, 0% 80%, 0 0)',
    WebkitClipPath:
      'polygon(0 0, 80% 0%, 58% 5%, 38% 16%, 19% 31%, 5% 53%, 0% 80%, 0 0)',
  },
  _after: {
    width: '20px',
    height: '20px',
    left: 0,
    bottom: '-1px',
    content: '""',
    position: 'absolute',
    background: '#101010',
    clipPath:
      'polygon(5% 66%, 12% 79%, 25% 88%, 41% 95%, 63% 100%, 0 100%, 0 100%, 0 45%)',
    WebkitClipPath:
      'polygon(5% 66%, 12% 79%, 25% 88%, 41% 95%, 63% 100%, 0 100%, 0 100%, 0 45%)',
  },
}
