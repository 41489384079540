import { useContext } from 'react'

import {
  Avatar,
  Button,
  HStack,
  Link,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'

import { DEFAULT_AVATAR } from 'constants/common'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import { IDeal } from 'interfaces/models'
import { getFirstSevenCharacters } from 'utils/string'

import {
  DetailedMavenRating,
  FieldSection,
  Label,
  TextValue,
} from 'components/common/other/deal-details'
import FlexLayout, {
  FlexField,
} from 'components/common/other/deal-details/FlexLayout'
import { SectorsPath } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import { DashboardContext } from '../context'
import { useStartupSpotlightDetails } from '../hooks'
import { IDealRow } from '../interfaces/deal-table'

dayjs.extend(relativeTime)
dayjs.locale('us')

export const StartupSpotlightDetailCard = ({
  deal,
  shouldBlurAvatar,
  rankingFields,
  headFields,
  onExpandDetail,
  shouldHasExpandButton = false,
  ...rest
}: {
  deal: IDealRow | Record<keyof IDeal, string> | undefined
  shouldBlurAvatar: boolean
  rankingFields: FlexField[]
  headFields: FlexField[]
  onExpandDetail?: React.Dispatch<
    React.SetStateAction<IDealRow | null | undefined>
  >
  shouldHasExpandButton?: boolean | undefined
  rest?: StackProps
}) => {
  return (
    <FieldSection
      bg="darkMode"
      header={
        <VStack gap="20px">
          <WithResponsiveLayout
            w="full"
            justifyContent="space-between"
            alignItems="start"
          >
            <HStack gap="16px">
              <Avatar
                w="60px"
                h="60px"
                borderRadius="full"
                src={deal?.logo ?? DEFAULT_AVATAR}
                overflow="hidden"
                bg="#e3e3e36b"
                sx={{
                  '> img': {
                    filter: shouldBlurAvatar ? 'blur(7.5px)' : 'unset',
                    objectFit: 'contain',
                  },
                }}
              />
              <TextValue fontSize="xl">
                {shouldBlurAvatar
                  ? getFirstSevenCharacters(deal?.deal_id ?? '', '#')
                  : deal?.company_name}
              </TextValue>
            </HStack>
          </WithResponsiveLayout>

          <FlexLayout
            fields={rankingFields}
            labelProps={{
              fontSize: 'md',
              fontWeight: 'semibold',
              color: '#A2A8B4',
            }}
            wrapFieldProps={{ gap: '0' }}
            rowGap="1rem"
            numItemsPerRow={2}
          />
        </VStack>
      }
      fields={headFields}
      labelProps={{ fontSize: 'sm' }}
      borderRadius="30px"
      headerProps={{ color: 'lg' }}
      numItemsPerRow={2}
      cursor="pointer"
      onClick={() => {
        !shouldHasExpandButton && onExpandDetail?.(deal)
      }}
      {...rest}
    >
      {shouldHasExpandButton && (
        <WithCenterLayout w="full" mt={6}>
          <Button
            bg="#2A3BB7"
            borderRadius="100px"
            p="15px 40px"
            border="1px solid #DCE4FF"
            fontSize="16px"
            fontWeight="bold"
            size={['sm', 'sm', 'md', 'md', 'md']}
            onClick={() => {
              onExpandDetail?.(deal)
            }}
          >
            Expand Details
          </Button>
        </WithCenterLayout>
      )}
    </FieldSection>
  )
}

export const StartupSpotlightDetail = ({
  deal,
  onBack,
  onExpandDetail,
}: {
  deal: IDealRow
  onBack?: () => Promise<void> | undefined
  onExpandDetail?: React.Dispatch<
    React.SetStateAction<IDealRow | null | undefined>
  >
}) => {
  const context = useContext(DashboardContext)
  const fetchedSectorExperience = context?.scoutSectorExp

  const {
    rankingFields,
    headFields,
    ratingFields,
    companyInformationFields,
    fundraiseFields,
  } = useStartupSpotlightDetails({ deal, fetchedSectorExperience })

  return (
    <VStack
      gap="24.8px"
      borderTopLeftRadius="40px"
      w="full"
      h="full"
      as="section"
    >
      <StartupSpotlightDetailCard
        rankingFields={rankingFields}
        headFields={headFields}
        shouldBlurAvatar
        deal={deal?.deal_id ? deal : deal?.originalRow}
        shouldHasExpandButton
        onExpandDetail={onExpandDetail}
      />

      <FieldSection
        header={
          <VStack gap="20px" alignItems="start">
            <Label fontSize="lg">About this company</Label>
            <SectorsPath sectorsAndDomains={deal?.sectors_and_domains} />
          </VStack>
        }
        fields={companyInformationFields}
        numItemsPerRow={2}
        labelProps={{ fontSize: 'sm' }}
        bg="#2D3748"
        borderRadius="30px"
        headerProps={{ fontSize: 'lg' }}
      />

      <FieldSection
        header="Fundraise Details"
        fields={fundraiseFields}
        numItemsPerRow={2}
        bg="#2D3748"
        labelProps={{ fontSize: 'sm' }}
        borderRadius="30px"
        headerProps={{ fontSize: 'lg' }}
      />

      <DetailedMavenRating
        heading="Evaluation"
        avgRating={deal?.maven_rating_avg_in_sector}
        dataQuality={deal?.bs0_confidence_avg}
        ratingFields={ratingFields}
        numItemsPerRow={2}
        bg="#2D3748"
        borderRadius="30px"
        headerProps={{ fontSize: 'lg' }}
      />
    </VStack>
  )
}

export default StartupSpotlightDetail
