import { HStack, Stack, Text, VStack } from '@chakra-ui/react'

import { getFirstSevenCharacters } from 'utils/string'

import { WithResponsiveLayout } from 'components/hoc'

import { useCountUserStats } from 'modules/dashboard/hooks'
import { TLatestDealActivities } from 'modules/dashboard/interfaces/deal-detail'

import Label from '../Label'
import TextValue from '../TextValue'
import { DotTagForSectorPath } from './SectorsPath'
import UserID from './UserID'

/**
 * Renders the MavenProfile component based on the provided deal activity.
 *
 * @param {TLatestDealActivities | undefined} dealActivity - the latest deal activities
 * @return {JSX.Element} the rendered MavenProfile component
 */
const MavenProfile = ({
  dealActivity,
}: {
  dealActivity: TLatestDealActivities | undefined
}) => {
  const userStats = useCountUserStats({
    userId: dealActivity?.users?.user_id,
  })

  if (!dealActivity) return <></>

  return (
    <WithResponsiveLayout
      alignItems="start"
      p="1rem"
      borderRadius={6}
      bg="#0B1A38"
      justifyContent="space-between"
      w="full"
      gap={['1rem', '1rem', '1rem', '1rem', '1rem']}
    >
      <HStack flex="1">
        <UserID
          avatar={dealActivity?.users?.avatar}
          id={getFirstSevenCharacters(dealActivity?.users?.user_id ?? '')}
          location={dealActivity?.users?.location_name}
          avatarProps={{
            w: ['40px', '40px', '40px', '70px', '100px', '100px'],
            h: ['40px', '40px', '40px', '70px', '100px', '100px'],
            sx: { '> img': { filter: 'blur(2.5px)' } },
          }}
        />
      </HStack>
      <VStack
        flex="1"
        justifyContent="space-between"
        alignItems="start"
        gap="1rem"
      >
        <HStack gap={['1rem', '1rem', '1rem', '5rem', '5rem']} w="full">
          <Stack gap="0">
            <Label>Deals Sourced</Label>
            <TextValue fontSize="md">
              {userStats?.[0]?.count_intros ?? 0}
            </TextValue>
          </Stack>

          <Stack gap="0">
            <Label>Evaluation Completed</Label>
            <TextValue fontSize="md">
              {userStats?.[0]?.count_evals ?? 0}
            </TextValue>
          </Stack>
        </HStack>

        <HStack gap="0.5rem" flex="1" w="full" flexWrap="wrap">
          {dealActivity?.expInSectors?.map((sector) => {
            return (
              <DotTagForSectorPath
                key={`${sector?.exp_in_sector}${sector?.sector_name} Details (${sector?.maven_level})`}
                sector={sector?.sector_name}
              >
                <TextValue fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}>
                  {sector?.sector_name}
                </TextValue>
                <Text
                  fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}
                  fontWeight="normal"
                  lineHeight="normal"
                  opacity={0.8}
                >
                  {sector?.exp_in_sector} XP
                </Text>
              </DotTagForSectorPath>
            )
          })}
        </HStack>
      </VStack>
    </WithResponsiveLayout>
  )
}

export default MavenProfile
