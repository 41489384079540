export const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 2600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 2048,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const FUNDRAISE_STEPS = [
  {
    title: 'You’ve been invited!',
    description:
      'A Deploy Scout has identified your company as a promising startup for Deploy’s network of investors. ',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M3.8916 17.228C3.36952 16.7451 3.65311 15.8723 4.35928 15.7886L14.3652 14.6018C14.653 14.5677 14.903 14.3869 15.0244 14.1237L19.2448 4.9741C19.5426 4.32838 20.4606 4.32825 20.7585 4.97398L24.9788 14.1236C25.1002 14.3867 25.3486 14.5679 25.6364 14.6021L35.6429 15.7886C36.3491 15.8723 36.6319 16.7454 36.1098 17.2282L28.7129 24.0698C28.5002 24.2666 28.4054 24.5595 28.4619 24.8438L30.425 34.7266C30.5636 35.4241 29.8213 35.9645 29.2008 35.6171L20.4086 30.6943C20.1557 30.5528 19.8484 30.5534 19.5955 30.695L10.8024 35.6159C10.1819 35.9632 9.43824 35.424 9.57682 34.7266L11.5402 24.8444C11.5967 24.5601 11.5022 24.2666 11.2894 24.0698L3.8916 17.228Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Work with your scout',
    description:
      'Your Scout acts as your sponsor in front of investors. Once you’ve submitted the fundraising terms for review, they will work with you to make any adjustments to better your Deploy experience. ',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M11.6663 20L19.9159 28.2496L37.5951 10.5721M3.41602 20.0838L11.6656 28.3334M29.3431 10.6559L20.5042 19.4948"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Receive Feedback',
    description:
      'Once the scout approves and assigns industry sectors for your company to be evaluated under, experts in that sector will independently evaluate your company based on your submission.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M28.3333 33.3333C28.3333 30.5719 24.6024 28.3333 20 28.3333C15.3976 28.3333 11.6667 30.5719 11.6667 33.3333M35 28.334C35 26.2836 32.9432 24.5216 30 23.75M5 28.334C5 26.2836 7.05683 24.5216 10 23.75M30 17.0602C31.0229 16.1446 31.6667 14.8142 31.6667 13.3333C31.6667 10.5719 29.4281 8.33333 26.6667 8.33333C25.3861 8.33333 24.2179 8.81475 23.3333 9.60648M10 17.0602C8.97708 16.1446 8.33333 14.8142 8.33333 13.3333C8.33333 10.5719 10.5719 8.33333 13.3333 8.33333C14.6139 8.33333 15.7821 8.81475 16.6667 9.60648M20 23.3333C17.2386 23.3333 15 21.0948 15 18.3333C15 15.5719 17.2386 13.3333 20 13.3333C22.7614 13.3333 25 15.5719 25 18.3333C25 21.0948 22.7614 23.3333 20 23.3333Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Connect to Investors',
    description:
      'Deploy’s ranked leaderboard allows investors on the network to see and prioritize their due diligence. As interest comes in, Deploy facilitates a direct connection to the investor so your company can get funded.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M23.3333 10H10C8.44686 10 7.66986 10 7.05729 10.2537C6.24053 10.592 5.59205 11.2413 5.25373 12.0581C5 12.6707 5 13.4472 5 15.0004C7.76142 15.0004 10 17.2382 10 19.9996C10 22.761 7.76142 25 5 25C5 26.5531 5 27.3297 5.25373 27.9423C5.59205 28.759 6.24053 29.4078 7.05729 29.7461C7.66986 29.9998 8.44686 30 10 30H23.3333M23.3333 10H30C31.5531 10 32.3297 10 32.9423 10.2537C33.759 10.592 34.4078 11.2413 34.7461 12.0581C34.9998 12.6707 34.9998 13.4472 34.9998 15.0004C32.2384 15.0004 30 17.2386 30 20C30 22.7614 32.2384 25 34.9998 25C34.9998 26.5531 34.9998 27.3297 34.7461 27.9423C34.4078 28.759 33.759 29.4078 32.9423 29.7461C32.3297 29.9998 31.5531 30 30 30H23.3333M23.3333 10V30"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    title: 'Got Funded!',
    description:
      'Whether you’ve received funding elsewhere, or through the deploy network - you can offboard and still stay in the loop and continuously receive feedback. ',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M5 25.0004V28C5 29.8668 5 30.7996 5.36331 31.5126C5.68289 32.1398 6.19245 32.6508 6.81966 32.9704C7.532 33.3333 8.46499 33.3333 10.3282 33.3333H35.0003M5 25.0004V8.33331M5 25.0004L11.4223 19.6484L11.4276 19.6442C12.5894 18.676 13.1715 18.191 13.8026 17.994C14.5481 17.7612 15.3512 17.798 16.0726 18.0973C16.6842 18.351 17.2204 18.8873 18.2929 19.9598L18.3037 19.9705C19.3929 21.0597 19.9389 21.6057 20.5603 21.8589C21.2956 22.1584 22.1142 22.1842 22.8678 21.9343C23.5068 21.7223 24.0903 21.2126 25.2572 20.1916L34.9999 11.6666"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
]
