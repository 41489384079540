import { Icon } from '@chakra-ui/react'

const Moon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7891 15.5156C18.5 18.4687 15.6641 20.3125 12.2891 20.3125C7.52344 20.3125 4.05469 16.8281 4.05469 12.0625C4.05469 8.74219 6.09375 5.67969 9.07031 4.47656C9.33594 4.375 9.53906 4.33594 9.66406 4.33594C10.1328 4.33594 10.4375 4.70312 10.4375 5.10156C10.4375 5.3125 10.3828 5.50781 10.2344 5.78125C9.95312 6.33594 9.76562 7.32812 9.76562 8.41406C9.76562 12.2891 12.2578 14.6875 16.2656 14.6875C17.1328 14.6875 18.0312 14.5312 18.5938 14.2891C18.8594 14.1641 19.0469 14.125 19.2422 14.125C19.625 14.125 19.9531 14.4141 19.9531 14.8594C19.9531 15.0156 19.9141 15.2422 19.7891 15.5156ZM5.8125 12.0312C5.8125 15.7968 8.5625 18.5468 12.3203 18.5468C14.3906 18.5468 16.3203 17.6249 17.4141 16.0624C16.9609 16.2187 16.4844 16.2812 15.9297 16.2812C11.0781 16.2812 8.14062 13.3984 8.14062 8.65619C8.14062 7.99213 8.23438 7.39056 8.40625 6.83588C6.78906 8.00775 5.8125 9.99994 5.8125 12.0312Z"
      />
    </Icon>
  )
}

Moon.displayName = 'Moon'

export default Moon
