const shadows = {
  'slz-md': '0px 3px 6px #00000029',
  'slz-1md': '0px 3px 6px #00000033',
  'slz-2md': '0px 2px 8px #00000034',
  'slz-3md': '0px 2px 8px #00000033',
  'slz-4md': '0px 3px 8px #00000034',
  'slz-lg': '0px 2px 8px #00000067',
  'slz-xl': '0px 2px 16px #00000065',
  'slz-tooltip': '0px 1px 4px #00000026',
}

export default shadows
