import React from 'react'

import {
  Icon,
  useBoolean,
  useMultiStyleConfig,
  useTheme,
} from '@chakra-ui/react'

import { EyeCloseIcon, EyeIcon } from '../Icons'
import Input from './Input'

export default React.forwardRef(function PasswordInput(
  { size, isInvalid, isValid, onClear, onChange, ...props },
  ref,
) {
  const theme = useTheme()
  const inputStyles = useMultiStyleConfig('Input', { size })
  // const inputHeight = theme.sizes[inputStyles.field.h]
  const iconSize = theme.fontSizes[inputStyles.field.fontSize]
  const [showedPassword, setShowedPassword] = useBoolean()

  return (
    <Input.Group size={size}>
      <Input
        ref={ref}
        type={showedPassword ? 'text' : 'password'}
        isInvalid={isInvalid}
        isValid={isValid}
        onChange={onChange}
        {...props}
      />

      <Input.RightElement>
        <Icon
          as={showedPassword ? EyeIcon : EyeCloseIcon}
          fontSize={`calc(${iconSize} + 0.5rem)`}
          onClick={setShowedPassword.toggle}
        />
      </Input.RightElement>
    </Input.Group>
  )
})
