import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { useDLToast } from 'hooks'

import Disclaimer from 'components/common/Icons/Disclaimer'

import {
  REMOVE_DEAL_FROM_SHORTLIST_ERROR,
  REMOVE_DEAL_FROM_SHORTLIST_SUCCESS,
} from 'modules/dashboard/constants/messages'

import useAuth from '../../../hooks/useAuth'
import { SHORTLIST_TABLE } from '../constants'

const ConfirmRemovingDealModal = ({
  dealID,
  reset,
  isOpen,
  onClose,
}: {
  isOpen: boolean
  reset: () => void
  onClose: () => void
  dealID: string | undefined
}) => {
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { getUser } = useAuth()

  const handleClickRemoveDeal = async () => {
    if (!dealID) return
    try {
      const { error } = await supabase
        .from(SHORTLIST_TABLE)
        .delete()
        .eq('deal_id', dealID)
        .eq('user_id', getUser()?.user?.id)

      if (error) {
        showErrorToast(error?.message)
        return
      }

      onClose()
      showSuccessToast(REMOVE_DEAL_FROM_SHORTLIST_SUCCESS)
      await reset()
    } catch (err) {
      showErrorToast(REMOVE_DEAL_FROM_SHORTLIST_ERROR)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="white" isCentered>
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="2rem">
            <Disclaimer w="5.30488rem" h="4.94794rem" />
            <HStack gap="0.5rem" w="full">
              <Text fontSize="md" fontWeight="medium" opacity={1}>
                Are you sure you want to remove this deal from your shortlist?
              </Text>
            </HStack>

            <Button
              variant="filled"
              onClick={handleClickRemoveDeal}
              size={['md', 'md', 'lg']}
              w="full"
              sx={{
                _disabled: {
                  color: 'white',
                  bg: 'rgba(16, 16, 16, 0.20)',
                  _hover: {
                    color: 'white',
                    bg: 'rgba(16, 16, 16, 0.20)',
                  },
                },
              }}
            >
              Remove it
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmRemovingDealModal
