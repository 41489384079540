import { useEffect, useMemo, useState } from 'react'

import {
  Box,
  HStack,
  Heading,
  Stack,
  VStack,
  useBoolean,
} from '@chakra-ui/react'

import { NAVBAR_WIDTH } from 'constants/common'
import { sliderSettings } from 'constants/sliders'
import useMounted from 'hooks/useMounted'
import { IViewScoutInSector } from 'interfaces/views'
import uniqueId from 'lodash/uniqueId'
import { useHistory } from 'react-router'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { WithSpinnerLoading } from 'components/hoc'

import { useDashboardQuery, useSectorExperience } from 'modules/dashboard/hooks'
import { IFeaturedScouts } from 'modules/dashboard/interfaces/dashboash'

import { PreviousNextSection } from '../other'
import ScoutCard from './ScoutCard'

const _sliderSettings = {
  ...sliderSettings,
  responsive: [
    ...sliderSettings.responsive.filter(
      (rs) => ![2048, 1536].includes(rs.breakpoint),
    ),
    {
      breakpoint: 2048,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
  ],
}

const FeaturedScouts = () => {
  const history = useHistory()
  const [slider, setSlider] = useState<Slider | null>()
  const [scouts, setScouts] = useState<IFeaturedScouts[]>([])
  const [isLoading, toggle] = useBoolean()
  const { fetchTopFeaturedScouts } = useDashboardQuery()
  const fetchedSectorExperience = useSectorExperience()
  const isMounted = useMounted()

  useEffect(() => {
    const fetchData = async () => {
      if (scouts?.length === 0) {
        toggle.on()
        const fetchedDeals = await fetchTopFeaturedScouts()

        setScouts(fetchedDeals)
        toggle.off()
      }
    }

    isMounted && fetchData()
  }, [isMounted])

  const mergedScoutsAndSectorExp = useMemo(() => {
    if (scouts.length === 0 || fetchedSectorExperience?.length === 0) return []
    return scouts.map((scout) => {
      return {
        ...scout,
        expInSectors: fetchedSectorExperience
          ?.filter((exp) => exp.scout_id === scout.maven_user_id)
          ?.slice(0, 2),
      }
    })
  }, [scouts, fetchedSectorExperience])

  return (
    <WithSpinnerLoading isLoading={isLoading}>
      <VStack
        gap="1rem"
        as="section"
        color="white"
        alignItems="start"
        bg="transparent"
        // borderRadius="18px"
        // p="24px"
        w="full"
      >
        <HStack w="full" justifyContent="space-between">
          <Heading fontSize="20px" fontWeight="bold">
            Featured Experts
          </Heading>
          <PreviousNextSection
            onNext={slider?.slickNext}
            onPrevious={slider?.slickPrev}
          />
        </HStack>

        <Stack
          w={[
            'calc(100vw - 20px)',
            'calc(100vw - 20px)',
            'calc(100vw - 80px)',
            'calc(100vw - 80px)',
            `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
            `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
          ]}
        >
          <Slider
            {..._sliderSettings}
            ref={(slider) => {
              setSlider(slider)
            }}
          >
            {mergedScoutsAndSectorExp?.map(
              (
                scout: IFeaturedScouts & { expInSectors: IViewScoutInSector[] },
              ) => {
                return (
                  <Box w="full" px="0.5rem" key={uniqueId()}>
                    <ScoutCard
                      rank={scout?.maven_rank ?? 0}
                      numComments={scout?.num_comments_made ?? 0}
                      user={{
                        avatar: scout?.avatar,
                        user_id: scout?.maven_user_id,
                        location_name: scout?.location_name,
                      }}
                      userStats={{
                        count_intros: scout?.count_intros,
                        count_evals: scout?.num_evals_completed,
                      }}
                      expInSectors={scout.expInSectors}
                      key={uniqueId()}
                    />
                  </Box>
                )
              },
            )}

            {/* {Array.from([1, 1, 1, 1, 1, 1, 1])?.map((d: number) => {
              return (
                <Box w="full" px="0.5rem">
                  <Scouts key={uniqueId()} />
                </Box>
              )
            })} */}
          </Slider>
        </Stack>
      </VStack>
    </WithSpinnerLoading>
  )
}

export default FeaturedScouts
