import { ReactNode } from 'react'

import { StackProps, VStack } from '@chakra-ui/react'

const WrapperBox = ({
  children,
  ...rest
}: { children: ReactNode } & StackProps) => {
  return (
    <VStack
      gap="1rem"
      //   as="section"
      color="white"
      alignItems="start"
      bg="#171923"
      borderRadius="18px"
      p="24px"
      //   w="full"
      {...rest}
    >
      {children}
    </VStack>
  )
}

export default WrapperBox
