import { Icon } from '@chakra-ui/react'

const Email = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M4.6069 7.78744L11.631 12.5599C11.8552 12.7116 12.1448 12.7116 12.3672 12.5599L19.3914 7.78744C19.7431 7.54951 19.5741 6.99951 19.15 6.99951H4.84828C4.42414 6.99951 4.25518 7.54779 4.6069 7.78744ZM19.3259 8.54089L15.05 11.4461L19.269 15.5219C19.5431 15.7857 20 15.5926 20 15.2116V8.89779C19.9983 8.55124 19.6121 8.34779 19.3259 8.54089ZM4.72932 15.5219L8.94828 11.4461L4.67242 8.54089C4.38621 8.34606 4 8.55124 4 8.89779V15.2116C3.99828 15.5926 4.45518 15.7857 4.72932 15.5219Z"
        fill="black"
      />
      <path
        d="M18.4975 16.6472C18.8855 16.6472 19.0751 16.1748 18.7975 15.9058L14.5389 11.792L12.6527 13.073C12.4544 13.2075 12.2269 13.2748 11.9993 13.2748C11.7717 13.2748 11.5441 13.2075 11.3458 13.073L9.45789 11.792L5.19926 15.9058C4.91995 16.1748 5.11133 16.6472 5.49926 16.6472H18.4975Z"
        fill="black"
      />
    </Icon>
  )
}

export default Email
