import { ReactNode } from 'react'

import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react'

import uniqueId from 'lodash/uniqueId'

import { WithResponsiveLayout } from 'components/hoc'

import { Footer } from '../components/v2/Footer'
import { DEPLOY_VENTURE_OS, TDEPLOY_VENTURE } from '../constants/about'

const AboutText = ({
  children,
  ...rest
}: { children: string | ReactNode } & TextProps) => {
  return (
    <Text
      color="#CBD5E1"
      fontSize="20px"
      fontWeight="normal"
      lineHeight="160%"
      {...rest}
    >
      {children}
    </Text>
  )
}

const AboutUs = () => {
  return (
    <VStack gap={['40px', '40px', '40px', '80px', '80px', '80px']}>
      <VStack gap="2rem">
        <VStack alignItems="center" textAlign="center" gap="8px">
          <Text
            textTransform="uppercase"
            color="#8B9DFB"
            fontSize="20px"
            fontWeight="medium"
          >
            about us
          </Text>

          <Text
            color="#FFF"
            fontSize={['48px', '48px', '48px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Why Deploy?
          </Text>
        </VStack>
        <AboutText textAlign="center">
          Deploy’s movement stems from our mission to optimize and balance the
          opaque nature of private markets, so investors can discover and
          capture the most promising players in the venture ecosystem.
        </AboutText>
      </VStack>
      <Box
        border="3px solid #0d99ff"
        borderRadius="20px"
        h={['auto', 'auto', 'auto', 'auto', '600px', '600px']}
        w="full"
        overflow="hidden"
      >
        <Image src="images/discover-deals.png" borderRadius="20px" />
      </Box>
    </VStack>
  )
}

const IdeasAndTalent = () => {
  return (
    <WithResponsiveLayout
      p={['20px', '20px', '20px', '40px', '40px', '40px']}
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="start"
    >
      <VStack
        gap="8px"
        fontWeight="bold"
        fontSize="48px"
        lineHeight="110%"
        flex="1"
        alignItems="start"
      >
        <Text color="#FFF">Ideas and talent</Text>
        <Text color="#8B9DFB">Equally Distributed.</Text>
      </VStack>
      <VStack gap="37px" flex="1">
        <AboutText color="#E2E8F0">
          Deploy’s believes that everyone can innovate when they are allowed to
          participate, our goal is to lower the barriers to entrepreneurship,
          venture investing, and genuine expertise
        </AboutText>

        <AboutText color="#E2E8F0">
          Our platform is rethinking the start-up scouting and evaluation model
          based on two core tenets: transparency and diversity.
        </AboutText>
      </VStack>
    </WithResponsiveLayout>
  )
}

const DeployVentureOS = () => {
  return (
    <VStack
      w="full"
      gap={['32px', '32px', '32px', '48px', '48px', '48px']}
      py={['40px', '40px', '40px', '80px', '80px', '80px']}
      bg="#0F172A"
      justifyContent="center"
    >
      <VStack gap="24px" textAlign="center" alignItems="center">
        <Text
          bg="linear-gradient(90deg, #582CFF 0%, #8B9DFB 67%)"
          color="#8B9DFB"
          fontSize={['32px', '32px', '32px', '56px', '56px', '56px']}
          fontWeight="bold"
          lineHeight="normal"
          backgroundClip="text"
        >
          The Deploy Venture OS
        </Text>
        <AboutText color="#A0AEC0">
          Introducing an entirely new way of capturing new private market
          opportunities and intelligence.
        </AboutText>
      </VStack>
      <Flex
        gap="3rem"
        flexWrap="wrap"
        justifyContent="center"
        px={['20px', '20px', '20px', '0px', '0x', '0px']}
      >
        {DEPLOY_VENTURE_OS.map((os: TDEPLOY_VENTURE) => {
          return (
            <VStack gap="1rem" key={uniqueId()} maxW="448px" alignItems="start">
              {os.icon}
              <Text
                color="#FFF"
                fontSize="24px"
                fontWeight="bold"
                lineHeight="110%"
              >
                {os.title}
              </Text>
              <AboutText color="#A0AEC0" fontSize="18px" textAlign="start">
                {os.content}
              </AboutText>
            </VStack>
          )
        })}
      </Flex>
    </VStack>
  )
}

const CombiningInsightsToPowerInnovation = () => {
  return (
    <VStack
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['20px', '20px', '20px', '80px', '80px', '80px']}
    >
      <VStack gap="24px" px={['20px', '20px', '20px', '80px', '80px', '80px']}>
        <Text
          fontSize={['48px', '48px', '48px', '56px', '56px', '56px']}
          fontWeight="bold"
          lineHeight="110%"
          color="#FFF"
        >
          Combining Insights to Power Innovation
        </Text>
        <AboutText>
          Our founders and advisors bring a unique combination of corporate
          innovation and tech start-up experience, balancing both sides of the
          equation to how startups raise capital. We’re on a mission to give
          everyone a better chance of success by building the tools to lower the
          barrier of access to capital and knowledge. The venture game is
          changing, and we hope you’ll shape the future with us.
        </AboutText>
      </VStack>

      <WithResponsiveLayout
        gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      >
        <Image src="images/shopiya-jordan.png" borderRadius="20px" />
        <VStack gap="24px">
          <VStack
            gap="0px"
            alignItems="start"
            fontSize="48px"
            lineHeight="110%"
            fontWeight="bold"
            w="full"
          >
            <Text color="#FFF">Deploys,</Text>
            <Text color="#8B9DFB">United by a shared vision.</Text>
          </VStack>

          <VStack gap="24px" alignItems="start">
            <AboutText>
              Meet Sophiya Chiang (left) and Jordan Kostelac (right) — from
              opposite ends of the venture spectrum, they bonded over shared
              frustrations and decided to shake things up.
            </AboutText>
            <AboutText>Spoiler: It’s been a wild ride!</AboutText>
          </VStack>
        </VStack>
      </WithResponsiveLayout>
    </VStack>
  )
}

const AboutPage = () => {
  return (
    <>
      <Stack maxW="1600px">
        <VStack
          gap={['40px', '40px', '40px', '80px', '80px', '80px']}
          h="fit-content"
          p={['40px', '40px', '40px', '80px', '80px', '80px']}
        >
          <AboutUs />
          <IdeasAndTalent />
        </VStack>
        <DeployVentureOS />
        <CombiningInsightsToPowerInnovation />
      </Stack>
      <Footer />
    </>
  )
}

export default AboutPage
