import { useContext, useState } from 'react'

import {
  Box,
  HStack,
  Heading,
  Stack,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'

import { NAVBAR_WIDTH } from 'constants/common'
import { sliderSettings } from 'constants/sliders'
import { IDeal } from 'interfaces/models'
import uniqueId from 'lodash/uniqueId'
import Slider from 'react-slick'

import { FlexField } from 'components/common/other/deal-details/FlexLayout'

import { DashboardContext } from 'modules/dashboard/context'
import { useStartupSpotlightDetails } from 'modules/dashboard/hooks'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import { StartupSpotlightDetailCard } from '../StartupSpotlightDetail'
import PreviousNextSection from './PreviousNextSection'

const spotlightSettings = {
  ...sliderSettings,
  responsive: sliderSettings.responsive
    .filter((item) => item.breakpoint !== 1536)
    .concat({
      breakpoint: 1536,
      settings: {
        ...sliderSettings,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    }),
}

const SportLightDetail = ({
  deal,
  children,
}: {
  deal: Record<keyof IDeal, string> | undefined
  children: any
}) => {
  const context = useContext(DashboardContext)
  const fetchedSectorExperience = context?.scoutSectorExp
  const { rankingFields, headFields } = useStartupSpotlightDetails({
    deal,
    fetchedSectorExperience,
  })
  return children?.({ rankingFields, headFields })
}

const StartupsSpotlight = ({
  startups,
  onExpandDetail,
}: {
  startups?: IDealRow[]
  onExpandDetail?: React.Dispatch<
    React.SetStateAction<IDealRow | null | undefined>
  >
}) => {
  const [slider, setSlider] = useState<Slider | null>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  //   const [currentDeal, setCurrentDeal] = useState<IDealRow | null>()
  return (
    <VStack w="full" color="white" gap="20px">
      <HStack w="full" justifyContent="space-between">
        <Heading fontSize="20px" fontWeight="bold">
          Startup Spotlight
        </Heading>
        <PreviousNextSection
          onNext={slider?.slickNext}
          onPrevious={slider?.slickPrev}
        />
      </HStack>
      <Stack
        w={[
          'calc(100vw - 20px)',
          'calc(100vw - 20px)',
          'calc(100vw - 80px)',
          'calc(100vw - 80px)',
          `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
          `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
        ]}
      >
        <Slider
          {...spotlightSettings}
          ref={(slider) => {
            setSlider(slider)
          }}
        >
          {startups?.map((startup: IDealRow) => {
            return (
              <Box w="full" px="0.5rem" key={uniqueId()}>
                <SportLightDetail deal={startup?.originalRow}>
                  {({
                    rankingFields,
                    headFields,
                  }: {
                    rankingFields: FlexField[]
                    headFields: FlexField[]
                  }) => {
                    return (
                      <StartupSpotlightDetailCard
                        rankingFields={rankingFields}
                        headFields={headFields}
                        shouldBlurAvatar
                        deal={startup?.originalRow}
                        onExpandDetail={onExpandDetail}
                      />
                    )
                  }}
                </SportLightDetail>

                {/* <Startups onClick={onOpen} /> */}
              </Box>
            )
          })}
        </Slider>
      </Stack>

      {/* <DealSidePanel currentDeal={deal} isOpen={isOpen} onClose={onClose} /> */}
    </VStack>
  )
}

export default StartupsSpotlight
