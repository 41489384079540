import { Button, Flex } from '@chakra-ui/react'

import { LOGIN_FORM_FIELDS } from '../constants/login'
import { FormBody, FormContainer } from './other'

export const LoginForm = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>
      <FormContainer bgColor="white" p="0" gap={{ base: 6, md: 8 }}>
        <FormBody
          fieldProps={{
            bgColor: 'rgba(16, 16, 16, 0.06) !important',
            color: 'darkMode !important',
            borderColor: 'transparent',
            labelStyleProps: { color: 'darkMode' },
          }}
          stackProps={{ gap: '0.5rem' }}
          fields={LOGIN_FORM_FIELDS}
        />
        <Flex justifyContent="center" alignItems="center" w="full">
          <Button
            variant="filled"
            isLoading={isLoading}
            w="full"
            type="submit"
            fontWeight="700"
            fontSize="sm"
            borderRadius={12}
            // bgColor="rgba(16, 16, 16, 0.06)"
            border="common"
            padding={[5]}
            // color="darkMode"
            textTransform="capitalize"
            lineHeight={0}
            _hover={{
              opacity: 0.5,
            }}
          >
            Login
          </Button>
        </Flex>
      </FormContainer>
    </>
  )
}

export default LoginForm
