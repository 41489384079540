import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { VIEWS } from 'constants/tables'
import useMounted from 'hooks/useMounted'
import { IViewScoutInSector } from 'interfaces/views'

const useSectorExperience = () => {
  const isMounted = useMounted()

  const [fetchedSectorExperience, setFetchedSectorExperience] = useState<
    IViewScoutInSector[] | []
  >([])

  /**
   * Fetches sector experience data from the database.
   *
   * @return {Promise<IViewScoutInSector[] | []>} The sector experience data or an empty array.
   */
  const fetchSectorExperience = async (): Promise<
    IViewScoutInSector[] | []
  > => {
    try {
      const {
        data: scoutExpInSector,
        error,
      }: PostgrestResponse<IViewScoutInSector> = await supabase
        .from(VIEWS.view_scout_exp_in_sector)
        .select('*')

      if (error) {
        return []
      }

      return scoutExpInSector
    } catch (error) {
      return []
    }
  }

  const fetchData = async () => {
    const fetchedScoutExp = await fetchSectorExperience()
    setFetchedSectorExperience(fetchedScoutExp)
  }

  useEffect(() => {
    isMounted && fetchData()
  }, [isMounted])

  return fetchedSectorExperience
}

export default useSectorExperience
