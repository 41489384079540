import { Badge, BadgeProps } from '@chakra-ui/react'

export const EllipseNumber = ({
  number,
  ...rest
}: { number: number | string } & BadgeProps) => {
  return (
    <Badge
      // borderRadius="full"
      // minW="1.5rem"
      // maxW="1.5rem"
      // minH="1.5rem"
      fontSize="md"
      fontWeight="bold"
      // textAlign="center"
      // bg="accent.main02"
      color="accent.main01"
      bg="transparent"
      {...rest}
    >
      {number}
    </Badge>
  )
}
