import {
  ResponsiveValue,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
  ChakraStylesConfig,
  CreatableSelect,
  GroupBase,
  OptionBase,
  Select,
  components,
} from 'chakra-react-select'

const chakraStyles: ChakraStylesConfig = {
  option: (provide, state) => ({
    ...provide,
    color: 'dark.500 !important',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'dark.300',
    fontSize: 'sm',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: 'white',
    fontSize: 'sm',
  }),
  container: (provided, state) => ({
    ...provided,
    '> div[data-disabled]': {
      background: 'input.disabled',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'white',
    px: 1,
  }),
  menuList: (provided) => ({
    ...provided,
    color: 'dark.500',
  }),
  menu: (provided) => ({
    ...provided,
    '> div': {
      '> div': {
        wordWrap: 'break-word',

        '&:hover': {
          color: 'dark.500',
          background: 'main.300',
        },
      },
    },
  }),
}

export const DefaultIndicator = (props: any): JSX.Element => {
  const { selectProps } = props
  const { menuIsOpen = false }: { menuIsOpen: boolean; size: string } =
    selectProps

  const iconSize: ResponsiveValue<number> = {
    base: 3,
    xs: 3,
    sm: 3,
    md: 3,
    lg: 4,
  }

  const boxSize = useBreakpointValue(iconSize, {
    fallback: 'md',
    ssr: false,
  })

  const Icon = props?.icon || ChevronDownIcon

  const IndicatorIcon = (
    <Icon
      transitionDuration="0.3s"
      transform={`rotate(${menuIsOpen ? 180 : 0}deg)`}
      boxSize={boxSize}
      color="white"
    />
  )

  return (
    <components.DropdownIndicator {...props}>
      {IndicatorIcon}
    </components.DropdownIndicator>
  )
}

const DLSelect = ({ styles, ...rest }: any) => {
  const bgMode = useColorModeValue(
    'rgba(16, 16, 16, 0.20)',
    'rgba(255, 255, 255, 0.20)',
  )

  const isCreatable = !!rest?.isCreatable
  const SelectComponent = isCreatable ? CreatableSelect : Select
  return (
    <SelectComponent<OptionBase, true, GroupBase<OptionBase>>
      components={{ DropdownIndicator: DefaultIndicator }}
      variant="selectOutline"
      styles={{
        menuPortal: (provided: any) => {
          return {
            ...provided,
            zIndex: 9999,
            ' > div': {
              background: bgMode,
              backdropFilter: 'blur(10px)',
            },
          }
        },
      }}
      chakraStyles={{ ...chakraStyles, ...styles }}
      {...rest}
    />
  )
}

DLSelect.Multiple = (props: any) => {
  return <DLSelect isMulti {...props} />
}

export default DLSelect
