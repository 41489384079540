import { ITicketCalculatorForm } from 'modules/screen-rooms/interfaces/express-interest-modal'

import { IDealRow } from '../interfaces/deal-table'

export const HEAD_FIELDS = {
  maven_rating_avg_in_sector: 'Overall Maven Rating',
  deal_rank: 'Ranking',
  headquarters: 'Headquarters',
  year_founded: 'Year Founded',
  current_raise: 'Current Raise',
  fundraising_round: 'Round',
  available_equity: 'Available Equity',
  available_tickets: 'Number of Tickets Available',
} as Record<string, string>

export const COMPANY_FIELDS = {
  business_model: 'Business Model',
  product_type: 'Product Type',
  tech_used: 'Technology Focus',
  current_geo: 'Current Operating Geography',
  target_geo: 'Target Geographies',
} as Record<keyof IDealRow, string>

export const INDICATED_INTEREST_FIELDS = {
  available_tickets: 'Available Tickets',
  tickets_reserved: 'Number of Tickets Reserved',
  minimum_ticket_size: 'Min. Ticket Size',
  bid_amount: 'Total Bid Amount',
} as Record<
  keyof Omit<ITicketCalculatorForm, 'investor_tickets_reserved'>,
  string
>

export const FUNDRAISE_FIELDS = {
  pre_money_valuation: 'Pre-Money Valuation',
  post_money_valuation: 'Post-Money Valuation',
  company_maturity: 'Company Maturity',
  estimated_arr: 'Estimated ARR',
  origin_of_funds: 'Current Origin of Funds',
  preferred_instrument: 'Preferred Instrument',
  consider_instruments: 'Considered Instrument(s)',
  minimum_ticket_size: 'Minimum Ticket Size',
} as Record<keyof IDealRow, string>

export const RATING_LABELS = {
  b1_mission_avg: 'First Impression',
  b2_bizmodel_avg: 'Business Model',
  b8_team_avg: 'Team',
  b4_product_avg: 'Product',
  b5_differentiation_avg: 'Differentiation',
  b7_go2market_avg: 'Go-to-Market Strategy',
  b3_market_avg: 'Overall market',
  b6_timing_avg: 'Timing',
  b9_traction_avg: 'Traction',
  b10_risks_avg: 'Risk Mitigation',
} as Record<string, string>

export const RATING_DETAIL_LABELS = {
  bs1_mission: 'First Impression',
  bs2_bizmodel: 'Business Model',
  bs8_team: 'Team',
  bs4_product: 'Product',
  bs5_differentiation: 'Differentiation',
  bs7_go2market: 'Go-to-Market Strategy',
  bs3_market: 'Overall market',
  bs6_timing: 'Timing',
  bs9_traction: 'Traction',
  bs10_risks: 'Risk Mitigation',
} as Record<string, string>

export const CURRENCY_FIELDS = [
  'estimated_arr',
  'current_raise',
  'pre_money_valuation',
  'post_money_valuation',
  'minimum_ticket_size',
]
