/* eslint-disable react-hooks/rules-of-hooks */
import {
  Badge,
  Box,
  HStack,
  StackProps,
  Tag,
  TagLabel,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { DEFAULT_AVATAR } from 'constants/common'
import { formatCurrency, formatDecimal } from 'utils/numbers'
import { getFirstSevenCharacters } from 'utils/string'

import { Startups as StartupSkeleton } from 'modules/dashboard/components/skeletons'
import { CURRENCY_FIELDS } from 'modules/dashboard/constants/deal-details'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'
import { showArrayAsStr } from 'modules/deals/utils'

import FlexLayout, { FlexField } from '../FlexLayout'
import SectorsPath from './SectorsPath'
import UserID from './UserID'

const FIELDS = {
  year_founded: 'Founded',
  fundraising_round: 'Round',
  current_raise: 'Current Raise',
  company_maturity: 'Company Maturity',
  tech_used: 'Technology Used',
} as Record<string, string>

const ActivatedMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17C17.4477 17 17 17.4477 17 18Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 18C5 18.5523 5.44772 19 6 19C6.55228 19 7 18.5523 7 18C7 17.4477 6.55228 17 6 17C5.44772 17 5 17.4477 5 18Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5C17.4477 5 17 5.44772 17 6Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 6C5 6.55228 5.44772 7 6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

const DeactivatedMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <rect
      x="0.75"
      y="1.13477"
      width="24.5"
      height="24.5"
      rx="4.25"
      stroke="#CBD5E0"
      stroke-width="0.5"
    />
    <path
      d="M18 13.3848C18 13.9371 18.4477 14.3848 19 14.3848C19.5523 14.3848 20 13.9371 20 13.3848C20 12.8325 19.5523 12.3848 19 12.3848C18.4477 12.3848 18 12.8325 18 13.3848Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 13.3848C12 13.9371 12.4477 14.3848 13 14.3848C13.5523 14.3848 14 13.9371 14 13.3848C14 12.8325 13.5523 12.3848 13 12.3848C12.4477 12.3848 12 12.8325 12 13.3848Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 13.3848C6 13.9371 6.44772 14.3848 7 14.3848C7.55228 14.3848 8 13.9371 8 13.3848C8 12.8325 7.55228 12.3848 7 12.3848C6.44772 12.3848 6 12.8325 6 13.3848Z"
      stroke="#CBD5E0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const CARD_STATUSES = {
  Medium: {
    label: 'Monitor',
    icon: '👀',
    color: '#F0C151',
  },
  High: {
    label: 'Prioritize',
    icon: '🎯',
    color: '#FF7D83',
  },
  True: {
    label: 'In Pipeline',
    icon: '✅',
    color: '#BAB8B4',
  },
  Low: {
    label: 'Wait & See',
    icon: '🤔',
    color: '#BAB8B4',
  },
} as Record<string, { label: string; icon: string; color: string }>

const getCardStatus = (deal: IDealRow | undefined) => {
  if (!deal?.priority && !deal?.in_pipeline) {
    return {
      label: 'More Intel',
      icon: '',
      color: '#8B9DFB',
    }
  }

  // if (!!deal?.in_pipeline) {
  //   return CARD_STATUSES?.['True']
  // }

  return CARD_STATUSES?.[deal?.priority]
}

const Card = ({
  deal,
  isActivated,
  shouldBlurAvatar = true,
  onClick,
  isLoading,
  ...rest
}: {
  deal: IDealRow | undefined
  isActivated?: boolean | undefined
  shouldBlurAvatar?: boolean | undefined
  isLoading?: boolean | undefined
  onClick?:
    | React.Dispatch<React.SetStateAction<IDealRow | undefined>>
    | ((deal: IDealRow | undefined) => void)
} & StackProps) => {
  if (isLoading) {
    return <StartupSkeleton />
  }

  const fields: FlexField[] = Object.entries(deal || {})
    .filter(([key, value]) => key in FIELDS)
    .map(([key, value]) => ({
      label: FIELDS?.[key] ?? '',
      value:
        typeof value === 'number' && key !== 'year_founded'
          ? CURRENCY_FIELDS.includes(key)
            ? formatCurrency(value)
            : formatDecimal(value)
          : showArrayAsStr(value),
    }))

  const pipeLineStatus = !!deal?.in_pipeline ? CARD_STATUSES?.['True'] : null
  const status = getCardStatus(deal)
  return (
    <VStack
      w="full"
      gap="1.05rem"
      bg="#0B1A38"
      borderRadius="20px"
      p="1rem"
      alignItems="start"
      alignSelf="stretch"
      justifyContent="space-between"
      as="article"
      sx={
        isActivated
          ? // eslint-disable-next-line react-hooks/rules-of-hooks
            {
              bg: useColorModeValue('rgba(74, 85, 104, 0.40)', 'rgb(52 53 55)'),
              border: '3px solid #00C9FF',
            }
          : {}
      }
      cursor="pointer"
      onClick={() => onClick?.(deal)}
      {...rest}
    >
      <HStack justifyContent="space-between" alignItems="start" w="full">
        <UserID
          avatar={deal?.logo ?? DEFAULT_AVATAR}
          id={
            shouldBlurAvatar
              ? getFirstSevenCharacters(deal?.deal_id, '#')
              : deal?.company_name
          }
          location={deal?.headquarters}
          avatarProps={{
            w: '48px',
            h: '48px',
            sx: {
              '> img': {
                filter: shouldBlurAvatar ? 'blur(7.5px)' : 'unset',
                objectFit: 'contain',
              },
            },
          }}
        />
        {isActivated ? <ActivatedMenuIcon /> : <DeactivatedMenuIcon />}
      </HStack>

      {deal?.sectors_and_domains && (
        <SectorsPath
          sectorsAndDomains={deal?.sectors_and_domains ?? ''}
          flexDir="column"
          sectorProps={{ fontSize: 'sm', fontWeight: 'semibold' }}
          domainProps={{ fontSize: 'sm', fontWeight: 'normal' }}
        />
      )}

      <FlexLayout
        fields={fields}
        labelProps={{ fontSize: 'xs', fontWeight: 'normal' }}
        textProps={{ fontSize: 'md' }}
        wrapFieldProps={{ gap: '0' }}
        rowGap="1rem"
      />

      <HStack justifyContent="space-between" w="full">
        <HStack gap="20px" flex="2">
          <Tag
            variant="outline"
            border="0px solid #8B9DFB"
            color="#8B9DFB"
            borderRadius="8px"
            gap="8px"
            p="4px 8px"
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M6 8.1027V14.1027M6 8.1027H3.06641C2.69304 8.1027 2.50654 8.1027 2.36393 8.17536C2.23849 8.23928 2.13658 8.34119 2.07266 8.46663C2 8.60924 2 8.79606 2 9.16943V14.1027H6M6 8.1027V4.50277C6 4.1294 6 3.94258 6.07266 3.79997C6.13658 3.67453 6.23849 3.57261 6.36393 3.5087C6.50654 3.43604 6.69304 3.43604 7.06641 3.43604H8.93307C9.30644 3.43604 9.49352 3.43604 9.63613 3.5087C9.76157 3.57261 9.86317 3.67453 9.92708 3.79997C9.99975 3.94258 10 4.1294 10 4.50277V6.1027M6 14.1027H10M10 14.1027L14 14.1028V7.16943C14 6.79607 13.9997 6.60924 13.9271 6.46663C13.8632 6.34119 13.762 6.23928 13.6365 6.17536C13.4939 6.1027 13.3067 6.1027 12.9333 6.1027H10M10 14.1027V6.1027"
                  stroke="#8B9DFB"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <TagLabel>{deal?.deal_rank}</TagLabel>
          </Tag>

          <Tag
            variant="outline"
            border="0px solid #8B9DFB"
            color="#8B9DFB"
            borderRadius="8px"
            gap="8px"
            p="4px 8px"
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M1.55664 7.66071C1.34781 7.46759 1.46125 7.11846 1.74371 7.08497L5.74609 6.61024C5.86122 6.59659 5.96121 6.5243 6.00977 6.41903L7.69792 2.75917C7.81705 2.50088 8.18425 2.50083 8.30339 2.75912L9.99154 6.41895C10.0401 6.52422 10.1394 6.59671 10.2546 6.61036L14.2572 7.08497C14.5396 7.11846 14.6527 7.46769 14.4439 7.66082L11.4852 10.3975C11.4001 10.4762 11.3622 10.5933 11.3848 10.707L12.17 14.6602C12.2254 14.9392 11.9285 15.1553 11.6803 15.0164L8.16343 13.0473C8.06227 12.9906 7.93938 12.9909 7.83822 13.0475L4.32096 15.0159C4.07275 15.1548 3.77529 14.9391 3.83073 14.6602L4.6161 10.7073C4.63869 10.5936 4.60089 10.4762 4.51578 10.3974L1.55664 7.66071Z"
                  stroke="#8B9DFB"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <TagLabel>
              {parseFloat(deal?.maven_rating_avg_in_sector).toFixed(1)}
            </TagLabel>
          </Tag>
        </HStack>

        <HStack flexWrap="wrap" flex="1" justifyContent="end">
          {pipeLineStatus && (
            <Badge variant="dealStatus" size="lg" bg={pipeLineStatus?.color}>
              {pipeLineStatus?.icon} {pipeLineStatus?.label}
            </Badge>
          )}
          <Badge variant="dealStatus" size="lg" bg={status?.color}>
            {status?.icon} {status?.label}
          </Badge>
        </HStack>
      </HStack>
    </VStack>
  )
}
export default Card
