const colors = {
  primary: '#303134',
  secondary: '#676A74',
  disabled: '#C3C4CB',
}

const fontWeight = {
  400: '400',
  500: 'normal',
  700: 'bold',
}

const globalStyles = {
  layerStyles: {
    selected: {},
  },
  global: {
    html: {
      fontSize: {
        base: '10px',
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '16px',
        xl: '16px',
      },
    },
    body: {
      overflowX: 'hidden',
      fontSize: '1rem',
      lineHeight: {
        base: '140%',
        xs: '140%',
        sm: '140%',
        md: '140%',
        lg: '140%',
        xl: '140%',
      },
      letterSpacing: {
        base: '0',
        xs: '0.1px',
        sm: '0',
        md: '-0.16px',
        lg: '-0.16px',
        xl: '-0.16px',
      },
      color: 'dark.700',
      bg: 'light.300',
    },
  },
  textStyles: {
    h1: {
      fontSize: ['32px'],
      colors,
      fontWeight,
      letterSpacing: '-0.64px',
    },
    h2: {
      fontSize: ['24px'],
      colors,
      fontWeight,
      letterSpacing: '-0.36px',
    },
    h3: {
      fontSize: ['20px'],
      colors,
      fontWeight,
      letterSpacing: '-0.4px',
    },
    h4: {
      fontSize: ['17px'],
      colors,
      fontWeight,
      letterSpacing: '-0.34px',
    },
    h5: {
      fontSize: ['16px'],
      colors,
      fontWeight,
      letterSpacing: '-0.32px',
    },
    h6: {
      fontSize: ['12px'],
      colors,
      fontWeight,
      letterSpacing: '-0.24px',
    },
  },
}

export default globalStyles
