import { useEffect } from 'react'

import { useBoolean } from '@chakra-ui/react'

const useHover = () => {
  const [isHovered, toggle] = useBoolean()

  const handleMouseEnter = () => {
    toggle.on()
  }

  const handleMouseLeave = () => {
    toggle.off()
  }

  useEffect(() => {
    const cleanup = () => toggle.off()

    return cleanup
  }, [])

  return {
    isHovered,
    events: { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave },
  }
}

export default useHover
