import { Icon } from '@chakra-ui/react'

const EyeCloseIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.407 17.993L20.707 19.293C21.098 19.684 21.098 20.316 20.707 20.707C20.512 20.902 20.256 21 20 21C19.744 21 19.488 20.902 19.293 20.707L16.586 18L16.118 17.532C14.8945 18.0137 13.5124 18.3199 12.0034 18.3199C6.08545 18.3199 2.01318 13.4933 2.01318 12.0138C2.01318 11.1107 3.51813 8.97562 6.02422 7.43822L5.53201 6.94601L3.29301 4.70701C2.90201 4.31601 2.90201 3.68401 3.29301 3.29301C3.68401 2.90201 4.31601 2.90201 4.70701 3.29301L6.42001 5.00601L7.88501 6.47101L7.90786 6.49386C9.13178 6.0088 10.5091 5.70029 12.0034 5.70029C17.9873 5.70029 21.9863 10.5269 21.9863 12.0138C21.9863 12.9137 20.5093 15.0523 18.0035 16.5895L19.407 17.993ZM15.4216 14.0076C15.7724 13.4191 15.9739 12.7361 15.9739 12.0141C15.9739 9.80947 14.1941 8.06631 12.0041 8.06631C11.2746 8.06631 10.5925 8.25717 10.0064 8.59245L11.9163 10.5023C11.944 10.5008 11.9719 10.5 12 10.5C12.3975 10.5 12.7594 10.6555 13.0281 10.9088C13.3283 11.1852 13.5182 11.5794 13.5182 12.0141C13.5182 12.0434 13.5173 12.0726 13.5155 12.1015L15.4216 14.0076ZM10.49 11.904C10.4873 11.9403 10.4859 11.9771 10.4859 12.0141C10.4859 12.8344 11.1671 13.5155 12.0021 13.5155C12.0344 13.5155 12.0665 13.5145 12.0984 13.5124L14.0032 15.4172C13.4159 15.7624 12.7327 15.9618 12.0041 15.9618C9.79953 15.9618 8.04172 14.1601 8.0344 12.0141C8.03193 11.2723 8.23098 10.5827 8.58019 9.99419L10.49 11.904Z"
      />
    </Icon>
  )
}

export default EyeCloseIcon
