import { TRoute } from 'types/route'

import { DEAL_DETAIL_PATHNAME } from '../constants'
import { Deal } from '../pages'

const DealRoutes: TRoute[] = [
  {
    path: DEAL_DETAIL_PATHNAME,
    profile: 'Investor',
    feature: 'ViewDetailDeal',
    exact: true,
    component: Deal,
  },
]

export default DealRoutes
