import { useEffect, useState } from 'react'

import {
  Box,
  HStack,
  Heading,
  StackProps,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react'

import { SECTORS_MAP_DOT_COLORS } from 'constants/common'
import useMounted from 'hooks/useMounted'
import 'leaflet/dist/leaflet.css'
import { formatDecimal } from 'utils/numbers'

import { PlusSection } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithSpinnerLoading } from 'components/hoc'

import { useDashboardQuery } from 'modules/dashboard/hooks'
import { ISectorDistribution } from 'modules/dashboard/interfaces/dashboash'

import PieChartBySectors from './PieChartBySectors'

const SECTORS = [
  'Real Estate',
  'Finance',
  'Sustainability',
  'Enterprise',
  'Media',
]
const TextStatus = ({
  textStatus,
  value,
  ...rest
}: {
  textStatus: string
  value: number | null
} & StackProps) => {
  return (
    <VStack
      alignItems="start"
      gap="3px"
      color="white"
      fontSize={{ base: 'md', md: 'md', lg: 'lg' }}
      {...rest}
    >
      <Text fontWeight="normal">{textStatus}</Text>
      <Text fontWeight="bold">{formatDecimal(value ?? 0)}</Text>
    </VStack>
  )
}

const DeployStatus = () => {
  const [sectorDistribution, setSectorDistribution] = useState<
    ISectorDistribution[] | []
  >([])
  const [countUser, setCountUser] = useState<{
    totalScouts: number | null
    totalStartups: number | null
  }>({ totalScouts: 0, totalStartups: 0 })
  const { fetchSectorDistribution, getTotalScoutsAndStartups } =
    useDashboardQuery()
  const [isLoading, toggle] = useBoolean()
  const isMounted = useMounted()

  useEffect(() => {
    const fetchData = async () => {
      if (sectorDistribution?.length === 0) {
        toggle.on()
        setCountUser(await getTotalScoutsAndStartups())
        const fetchedSectorDistribution = await fetchSectorDistribution()
        setSectorDistribution(fetchedSectorDistribution)
        toggle.off()
      }
    }

    isMounted && fetchData()
  }, [isMounted])

  return (
    <VStack
      gap="1rem"
      as="section"
      color="white"
      alignItems="start"
      bg="#171923"
      borderRadius="18px"
      p="20px"
      w={['100%', '100%', '100%', '100%', '100%']}
      h="full"
      flex={1}
    >
      <WithSpinnerLoading isLoading={isLoading}>
        <Heading fontSize="20px" fontWeight="bold">
          Deploy Status
        </Heading>

        <HStack gap="24px" w="full" flexWrap="wrap">
          {/* <Flex w={{ base: 'full', sm: 'full', md: '60%' }}>
            <BubbleMap />
          </Flex> */}
          <VStack
            flex="1"
            gap="20px"
            alignItems="start"
            w={{ base: 'full', sm: '60%', md: '100%' }}
          >
            <HStack
              // flexWrap="wrap"
              w="full"
              flex="1.5"
              gap={['3rem', '3rem', '3rem', '3rem', '1.5rem', '5rem']}
              pos="relative"
              justifyContent={{ base: 'center', sm: 'center' }}
            >
              <WithCenterLayout>
                <PieChartBySectors data={sectorDistribution} />
              </WithCenterLayout>

              <VStack
                gap="1rem"
                alignItems={{ base: 'center', sm: 'center', md: 'start' }}
                pos="relative"
                left={{ base: 0, lg: 0 }}
                // w={{ base: 'full', md: 'calc((100% - 1rem) / 2)' }}
              >
                <Text fontSize="1rem" fontWeight="semibold">
                  Sector Distribution
                </Text>
                <VStack
                  as="ul"
                  flexDir={{ base: 'row', sm: 'column' }}
                  alignItems={{ base: 'start', lg: 'start' }}
                  flexWrap="wrap"
                >
                  {SECTORS.map((sector: string, idx: number) => (
                    <HStack as="li" key={`${idx}@${sector}`} gap="6px">
                      <Box
                        w="10px"
                        h="10px"
                        borderRadius="full"
                        bg={SECTORS_MAP_DOT_COLORS?.[sector]}
                      />
                      <Text fontSize="sm" fontWeight="normal">
                        {sector}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            </HStack>
            <WithCenterLayout gap="20px" w="full">
              <PlusSection
                p="8px 16px"
                w="auto"
                text={
                  <TextStatus
                    alignItems="center"
                    textStatus="Total Scouts"
                    value={countUser?.totalScouts}
                  />
                }
              />
              <PlusSection
                p="8px 16px"
                w="auto"
                text={
                  <TextStatus
                    alignItems="center"
                    textStatus="Startups"
                    value={countUser?.totalStartups}
                  />
                }
              />
            </WithCenterLayout>
          </VStack>
        </HStack>
      </WithSpinnerLoading>
    </VStack>
  )
}

export default DeployStatus
