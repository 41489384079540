import AuthRoutes from 'modules/authentication/configs/routes'
import AboutPage from 'modules/landing/pages/about'
import ConnectPage from 'modules/landing/pages/connect'
import ExpertsPage from 'modules/landing/pages/experts'
import FoundersPage from 'modules/landing/pages/founders'
import { NewLandingPage } from 'modules/landing/pages/new-landing-page'
import { NewLandingPageV2 } from 'modules/landing/pages/new-landing-page-ver2'

import InvestorsPage from '../modules/landing/pages/investors'

const PublicRoutes = [
  {
    path: '/',
    profile: 'Investor',
    feature: 'Landing page',
    exact: true,
    component: NewLandingPageV2,
  },
  {
    path: '/about',
    profile: 'About',
    feature: 'About page',
    exact: true,
    component: AboutPage,
  },
  {
    path: '/experts',
    profile: 'Expert',
    feature: 'Expert page',
    exact: true,
    component: ExpertsPage,
  },
  {
    path: '/investor',
    profile: 'Investor',
    feature: 'Investor page',
    exact: true,
    component: InvestorsPage,
  },
  {
    path: '/founders',
    profile: 'Founders',
    feature: 'Founder page',
    exact: true,
    component: FoundersPage,
  },
  {
    path: '/connect',
    profile: 'Connect',
    feature: 'Connect page',
    exact: true,
    component: ConnectPage,
  },
  {
    path: '/ldp-v2',
    profile: 'Investor',
    feature: 'Landing page',
    exact: true,
    component: NewLandingPage,
  },
  ...AuthRoutes,
]

export default PublicRoutes
