import borderRadius from './borderRadius'
import borders from './borders'
import breakpoints from './breakpoints'
import colors from './colors'
import shadows from './shadows'
import sizes from './size'
import spacing from './spacing'
import typography from './typography'
import zIndices from './z-index'

export const foundations = {
  breakpoints,
  colors,
  shadows,
  borders,
  zIndices,
  ...typography,
  ...spacing,
  ...borderRadius,
  ...sizes,
}
