import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import StartupSpotlightDetail from '../StartupSpotlightDetail'

const DealSidePanel = ({
  currentDeal,
  isOpen,
  onClose,
  addDealToShortlist,
  setCurrentDeal,
}: {
  currentDeal?: IDealRow | null
  isOpen: boolean
  onClose: () => void
  addDealToShortlist?: (args?: any) => void
  setCurrentDeal?: React.Dispatch<
    React.SetStateAction<IDealRow | null | undefined>
  >
}) => {
  if (!currentDeal?.deal_id) {
    return <></>
  }

  return (
    <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent
        bg="#0E0F1D"
        p="36px 20px"
        boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'grey.300',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'grey',
            borderRadius: '6px',
            '&:hover': {
              background: '#555',
            },
          },
        }}
        overflow="auto"
      >
        <DrawerHeader borderRadius="100px" bg="#2D3748" p="24px 36px">
          <HStack
            gap="20px"
            justifyContent="space-between"
            color="#FFFFFF"
            onClick={() => addDealToShortlist?.(currentDeal?.deal_id)}
            cursor="pointer"
          >
            <Stack gap="16px">
              <Text fontSize="24px" fontWeight="semibold">
                Quick Add
              </Text>
              <Text fontSize="14px" fontWeight="normal">
                Add this deal to your collection
              </Text>
            </Stack>

            <WithCenterLayout
              w="75px"
              h="75px"
              p="20px"
              bg="#8B9DFB"
              borderRadius="50px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="37"
                viewBox="0 0 36 37"
                fill="none"
              >
                <path
                  d="M18 12.042C15 5.0003 4.5 5.75031 4.5 14.7504C4.5 23.7504 18 31.2506 18 31.2506C18 31.2506 31.5 23.7504 31.5 14.7504C31.5 5.75031 21 5.0003 18 12.042Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </WithCenterLayout>
          </HStack>
        </DrawerHeader>
        <DrawerBody
          p="0"
          py="24.8px"
          overflow="unset"
          // sx={{
          //   '&::-webkit-scrollbar': {
          //     width: '6px',
          //   },
          //   '&::-webkit-scrollbar-track': {
          //     background: 'grey.300',
          //     borderRadius: '8px',
          //   },
          //   '&::-webkit-scrollbar-thumb': {
          //     background: 'grey',
          //     borderRadius: '6px',
          //     '&:hover': {
          //       background: '#555',
          //     },
          //   },
          // }}
        >
          <StartupSpotlightDetail
            deal={currentDeal}
            onExpandDetail={(deal: IDealRow | null | undefined) => {
              onClose()
              deal?.deal_id && setCurrentDeal?.(deal)
            }}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DealSidePanel
