import React, { ReactNode } from 'react'

import { StackProps, Text } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

const PlusSection = ({
  showPlusIcon = false,
  text = 'View Leaderboard',
  onClick,
  ...rest
}: {
  showPlusIcon?: boolean | undefined
  text?: string | ReactNode
  onClick?: () => void
} & StackProps) => {
  return (
    <WithCenterLayout
      w="full"
      p="25px"
      bg="#0c111c"
      gap="32px"
      justifyContent="space-between"
      borderRadius="12px"
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
      }}
      {...rest}
    >
      {React.isValidElement(text) ? (
        text
      ) : (
        <Text
          fontSize="lg"
          fontWeight="medium"
          color="white"
          textAlign="center"
        >
          {text}
        </Text>
      )}

      {showPlusIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 41"
          fill="none"
        >
          <rect y="0.538574" width="40" height="40" rx="20" fill="#05080E" />
          <path
            d="M20 11.5386V29.5386M11 20.5386H29"
            stroke="#7986A3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </WithCenterLayout>
  )
}

export default PlusSection
