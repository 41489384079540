import { ReactNode, Suspense, lazy } from 'react'

import { VStack, useColorModeValue } from '@chakra-ui/react'

import { motion, useScroll, useSpring } from 'framer-motion'

// import Capitalization from '../components/Capitalization'
// import FindVenture from '../components/FindVenture'
import { Footer } from '../components/Footer'
import GradientBackground from '../components/GradientBackground'

// import Innovation from '../components/Innovation'
// import LearnMore from '../components/LearnMore'

// import OurStory from '../components/OurStory'

const PageWrapper = ({ children }: { children: ReactNode }) => {
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })
  const bgByMode = useColorModeValue('darkMain.500', 'white')

  return (
    <>
      <motion.div
        style={{
          background: '#00c9ff',
          height: '5px',
          transformOrigin: '0',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          scaleX,
        }}
      />
      <VStack
        maxW="1366px"
        as="main"
        bg={bgByMode}
        w="full"
        h="fit-content"
        gap="100px"
        overflowX="hidden"
      >
        <VStack
          px={['1rem', '1rem', '1rem', '100px', '100px']}
          gap="100px"
          pos="relative"
        >
          {children}
        </VStack>
      </VStack>
    </>
  )
}

const FindVenture = lazy(() => import('../components/FindVenture'))
const Capitalization = lazy(() => import('../components/Capitalization'))
const OurStory = lazy(() => import('../components/OurStory'))
const Innovation = lazy(() => import('../components/Innovation'))
const LearnMore = lazy(() => import('../components/LearnMore'))

export const NewLandingPage = () => {
  return (
    <Suspense fallback={null}>
      <PageWrapper>
        <GradientBackground />
        <FindVenture />
        <Capitalization />
        <OurStory />
        <Innovation />
        <LearnMore />
      </PageWrapper>
      <Footer />
    </Suspense>
  )
}
