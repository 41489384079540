import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const boxy = defineStyle({
  borderRadius: 3,
  fontWeight: 'bold',
  lineHeight: '140%',
  bg: 'badge.color.500',
  color: '#FFF',
  textTransform: 'capitalize',
})

const dealStatus = defineStyle({
  borderRadius: '0.5rem',
  fontWeight: 'semibold',
  lineHeight: '1.3',
  bg: '#F0C151',
  color: 'white',
  textTransform: 'capitalize',
  p: '0.25rem 1rem !important',
  h: 'full',
  maxH: '1.5rem',
})

const sizes = {
  xl: defineStyle({
    p: '0.2rem 1rem',
    fontSize: 'sm',
  }),
  lg: defineStyle({
    p: '0.2rem 1rem',
    fontSize: 'sm',
  }),
  md: defineStyle({
    p: '0.1rem 0.5rem',
    fontSize: 'xs',
  }),
  sm: defineStyle({
    p: '0.1rem 0.5rem',
    fontSize: 'xs',
  }),
  xs: defineStyle({
    p: '0.1rem 0.5rem',
    fontSize: 'xs',
  }),
}

export default defineStyleConfig({
  sizes,
  variants: { boxy, dealStatus },
})
