import { useAuth } from 'hooks'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { PublicLayout } from 'components/layout'

type TPublicRoute = {
  feature?: string
  profile?: string
  component: React.FC<any>
} & RouteProps

const PublicRoute = ({ component: Component, ...rest }: TPublicRoute) => {
  const { isLogged } = useAuth()
  const isAuthenticated = isLogged()

  return (
    <Route
      render={(props) =>
        !isAuthenticated ? (
          <PublicLayout>
            <Component {...props} />
          </PublicLayout>
        ) : (
          <Redirect to="/leaderboard" />
        )
      }
      {...rest}
    />
  )
}

export default PublicRoute
