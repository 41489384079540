import { HStack, Heading, Text, VStack } from '@chakra-ui/react'

import { useAuth } from 'hooks'

const TopBar = () => {
  const { getUser } = useAuth()
  const user = getUser()

  return (
    <HStack
      w="full"
      as="section"
      color="white"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={{ base: '1rem', md: '1rem' }}
    >
      <VStack alignItems="start" lineHeight="140%">
        <Heading
          fontSize={['lg', '2xl', '2xl', '2xl', '32px', '32px']}
          fontWeight="bold"
        >
          Welcome Back,{' '}
          {user?.metadata?.first_name + ' ' + user?.metadata?.last_name}
        </Heading>
        <Text fontSize={['sm', 'md']} fontWeight="normal">
          {/* You’re signed in as: {user?.user?.email} */}
          Dashboard Overview
        </Text>
      </VStack>
      {/* <HStack
        flexWrap="wrap"
        gap="24px"
        p="16px 24px"
        bg="#2D3B75"
        borderRadius="8px"
        justifyContent="space-between"
      >
        <HStack gap="4px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M14.2725 5.99426H6.27246C5.34058 5.99426 4.87438 5.99426 4.50684 6.1465C4.01678 6.34949 3.62769 6.73907 3.4247 7.22913C3.27246 7.59667 3.27246 8.06261 3.27246 8.99449C4.92932 8.99449 6.27246 10.3372 6.27246 11.994C6.27246 13.6509 4.92932 14.9943 3.27246 14.9943C3.27246 15.9261 3.27246 16.3921 3.4247 16.7596C3.62769 17.2497 4.01678 17.6389 4.50684 17.8419C4.87438 17.9942 5.34058 17.9943 6.27246 17.9943H14.2725M14.2725 5.99426H18.2725C19.2043 5.99426 19.6703 5.99426 20.0378 6.1465C20.5279 6.34949 20.9171 6.73907 21.1201 7.22913C21.2724 7.59667 21.2724 8.06261 21.2724 8.99449C19.6155 8.99449 18.2725 10.3374 18.2725 11.9943C18.2725 13.6511 19.6155 14.9943 21.2724 14.9943C21.2724 15.9261 21.2724 16.3921 21.1201 16.7596C20.9171 17.2497 20.5279 17.6389 20.0378 17.8419C19.6703 17.9942 19.2043 17.9943 18.2725 17.9943H14.2725M14.2725 5.99426V17.9943"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Text
            lineHeight="normal"
            fontSize={{ base: 'md', md: '20px' }}
            fontWeight="bold"
          >
            Angel Tier
          </Text>
        </HStack>
        <Link href={`mailto:${EMAIL_TO_REQUEST_UPGRADE}`}>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            fontWeight="normal"
            color="#8B9DFB"
          >
            Request Upgrade
          </Text>
        </Link>
      </HStack> */}
    </HStack>
  )
}

export default TopBar
