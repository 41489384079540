export const HTTP_CODE_100 = 100 // Continue
export const HTTP_CODE_101 = 101 // Switching Protocols
export const HTTP_CODE_200 = 200 // OK
export const HTTP_CODE_201 = 201 // Created
export const HTTP_CODE_202 = 202 // Accepted
export const HTTP_CODE_203 = 203 // Non-Authoritative Information
export const HTTP_CODE_204 = 204 // No Content
export const HTTP_CODE_205 = 205 // Reset Content
export const HTTP_CODE_206 = 206 // Partial Content
export const HTTP_CODE_300 = 300 // Multiple Choices
export const HTTP_CODE_301 = 301 // Moved Permanently
export const HTTP_CODE_302 = 302 // Found
export const HTTP_CODE_303 = 303 // See Other
export const HTTP_CODE_304 = 304 // Not Modified
export const HTTP_CODE_305 = 305 // Use Proxy
export const HTTP_CODE_307 = 307 // Temporary Redirect
export const HTTP_CODE_400 = 400 // Bad Request
export const HTTP_CODE_401 = 401 // Unauthorized
export const HTTP_CODE_402 = 402 // Payment Required
export const HTTP_CODE_403 = 403 // Forbidden
export const HTTP_CODE_404 = 404 // Not Found
export const HTTP_CODE_405 = 405 // Method Not Allowed
export const HTTP_CODE_406 = 406 // Not Acceptable
export const HTTP_CODE_407 = 407 // Proxy Authentication Required
export const HTTP_CODE_408 = 408 // Request Timeout
export const HTTP_CODE_409 = 409 // Conflict
export const HTTP_CODE_410 = 410 // Gone
export const HTTP_CODE_411 = 411 // Length Required
export const HTTP_CODE_412 = 412 // Precondition Failed
export const HTTP_CODE_413 = 413 // Payload Too Large
export const HTTP_CODE_414 = 414 // URI Too Long
export const HTTP_CODE_415 = 415 // Unsupported Media Type
export const HTTP_CODE_416 = 416 // Range Not Satisfiable
export const HTTP_CODE_417 = 417 // Expectation Failed
export const HTTP_CODE_418 = 418 // I'm a Teapot
export const HTTP_CODE_422 = 422 // Unprocessable Entity
export const HTTP_CODE_426 = 426 // Upgrade Required
export const HTTP_CODE_428 = 428 // Precondition Required
export const HTTP_CODE_429 = 429 // Too Many Requests
export const HTTP_CODE_431 = 431 // Request Header Fields Too Large
export const HTTP_CODE_500 = 500 // Internal Server Error
export const HTTP_CODE_501 = 501 // Not Implemented
export const HTTP_CODE_502 = 502 // Bad Gateway
export const HTTP_CODE_503 = 503 // Service Unavailable
export const HTTP_CODE_504 = 504 // Gateway Timeout
export const HTTP_CODE_505 = 505 // HTTP Version Not Supported
export const HTTP_CODE_511 = 511 // Network Authentication Required

//SUPABASE

export const HTTP_CODE_23505 = '23505' // DUPLICATE RECORDS
