import { extendTheme } from '@chakra-ui/react'

import { DLBadge, DLButton, DLInput, DLModal } from './components'
import { foundations } from './foundations'
import globalStyles from './styles'

const theme = extendTheme({
  globalStyles,
  components: {
    Button: DLButton,
    Input: DLInput,
    Badge: DLBadge,
    Modal: DLModal,
  },
  config: {
    cssVarPrefix: 'deploy-capital',
  },
  ...foundations,
})

export default theme
