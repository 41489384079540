import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import { ITeamNotes } from 'interfaces/models'

import { useInvestorTeams } from 'modules/dashboard/hooks'

import useMounted from './useMounted'

export type TTeamNotes = ITeamNotes & {
  users?: {
    avatar: string | null
    first_name: string | null
    last_name: string | null
  }
  asPlaceholder?: string
  deal_id?: string | null
  reactions?: { reaction_type: string }[]
}

const useTeamNotes = ({
  dealId,
  teamId,
}: {
  dealId: string
  teamId?: string | null
}) => {
  const [teamNotes, setTeamNotes] = useState<TTeamNotes[] | null>()
  const [teamID, setTeamID] = useState<string>(teamId ?? '')
  const investorTeams = useInvestorTeams()
  const isMounted = useMounted()

  const fetchTeamNotes = async (dealID: string, teamID: string | null) => {
    if (!teamID || !dealID) return []
    const { data }: PostgrestResponse<TTeamNotes> = await supabase
      .from(TABLES_NAME.team_notes)
      .select(
        '*, users(avatar, first_name, last_name), reactions(reaction_type)',
      )
      .eq('team_id', teamID)
      .eq('deal_id', dealID)
      .is('parent_note_id', null)
      .order('created_at', { ascending: true })

    return data
  }

  const fetchRepliesOnNote = async (
    noteID: string,
    dealID: string,
    teamID: string | null,
  ) => {
    if (!teamID || !dealID || !noteID) return
    const { data }: PostgrestResponse<TTeamNotes> = await supabase
      .from(TABLES_NAME.team_notes)
      .select(
        '*, users(avatar, first_name, last_name), reactions(reaction_type)',
      )
      .eq('team_id', teamID)
      .eq('deal_id', dealID)
      .eq('parent_note_id', noteID)
      .order('created_at', { ascending: true })

    return data
  }

  const loadTeamNotes = async () => {
    let teamNotes = await fetchTeamNotes(dealId, teamID)
    if (teamNotes && teamNotes?.length > 0) {
      teamNotes?.map((note) => note.deal_id == dealId)
      teamNotes && teamNotes?.length > 0 && setTeamNotes(teamNotes)
    }
  }

  useEffect(() => {
    if (investorTeams?.[0]?.investor_teams?.[0]?.team_id) {
      setTeamID(investorTeams?.[0]?.investor_teams?.[0]?.team_id)
    }
  }, [investorTeams?.[0]?.investor_teams?.[0]?.team_id])

  useEffect(() => {
    if (dealId && teamID) {
      loadTeamNotes()
    }
  }, [dealId, teamID])

  useEffect(() => {
    let chatsWatcher: any

    // eslint-disable-next-line react-hooks/exhaustive-deps
    chatsWatcher = supabase
      .channel('team-notes:messages')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: TABLES_NAME.team_notes },
        async () => {
          if (dealId) {
            loadTeamNotes()
          }
        },
      )
      .subscribe()

    return () => {
      chatsWatcher?.unsubscribe()
    }
  }, [])

  return {
    teamID,
    teamNotes,
    loadTeamNotes,
    fetchTeamNotes,
    fetchRepliesOnNote,
  }
}

export default useTeamNotes
