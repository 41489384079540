import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'

import { TTeamNotes } from 'hooks/useTeamNotes'

import { WithCenterLayout } from 'components/hoc'

const CommentAction = ({
  comment,
  onReaction,
  onCancel,
  onReply,
  isLike = false,
  isShowingReplyBox,
}: {
  comment?: TTeamNotes
  onReaction: () => void
  onCancel: () => void
  onReply: () => void
  isLike?: boolean
  isShowingReplyBox?: boolean
}) => {
  return (
    <HStack
      gap="0"
      w="full"
      borderRadius="0.5rem"
      justifyContent="space-between"
    >
      <WithCenterLayout gap="0">
        {comment?.reactions && comment?.reactions?.length > 0 && (
          <WithCenterLayout gap="0.2rem" color="white">
            <Text fontSize={['sm', 'sm', 'sm', 'sm', 'md']} color="#8991A0">
              {comment?.reactions?.length ?? undefined} Likes
            </Text>
          </WithCenterLayout>
        )}
        {!isShowingReplyBox && (
          <Button
            variant="outline"
            size="sm"
            onClick={onReply}
            color="darkMode"
            fontWeight="bold"
            border="unset"
            borderRadius="0"
            gap="4px"
            fontSize="md"
            _hover={{ bg: 'unset', opacity: 0.8 }}
          >
            <Flex
              gap="8px"
              justifyContent="center"
              alignItems="center"
              color="#8991A0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
              >
                <path
                  d="M12.8496 12.5144L17.1283 8.23573L12.8496 3.95703"
                  stroke="#8991A0"
                  stroke-width="1.71148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.43652 17.6475V11.6573C3.43652 10.7495 3.79716 9.87886 4.43908 9.23694C5.08101 8.59501 5.95165 8.23438 6.85948 8.23438H17.1283"
                  stroke="#8991A0"
                  stroke-width="1.71148"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Reply
            </Flex>
          </Button>
        )}
      </WithCenterLayout>

      <Button
        variant="outline"
        size="sm"
        color="darkMode"
        borderRadius="0"
        fontWeight="bold"
        border="unset"
        _hover={{ bg: 'unset', opacity: 0.8 }}
        onClick={onReaction}
      >
        <Box
          w="20px"
          h="20px"
          bgSize="auto"
          bgRepeat="no-repeat"
          as="i"
          bgImage="url(images/reaction.png)"
          bgPosition="0px -740px"
          borderRadius="0"
          filter={
            isLike
              ? 'unset'
              : 'invert(27%) sepia(95%) saturate(16%) hue-rotate(212deg) brightness(99%) contrast(105%)'
          }
          sx={
            isLike
              ? {
                  bgPosition: '0px -740px',
                }
              : {
                  bgPosition: '0px -781px',
                }
          }
        />
      </Button>
      {/* {isShowingReplyBox && (
        <Button
          border="unset"
          _hover={{ bg: '#f79f9f', opacity: 0.8 }}
          variant="outline"
          size="sm"
          onClick={onCancel}
          fontWeight="bold"
          borderRadius="0"
          borderTopRightRadius="0.5rem"
          borderBottomRightRadius="0.5rem"
          color="#8991A0"
        >
          Cancel
        </Button>
      )} */}
    </HStack>
  )
}

export default CommentAction
