import { ReactNode, useState } from 'react'

import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CALENDAR_LINK } from 'constants/common'
import uniqueId from 'lodash/uniqueId'
import Slider from 'react-slick'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import SmallText from '../components/common/SmallText'
import UnlockingPotentialPrivateCapital from '../components/common/UnlockingPotentialPrivateCapital'
import { Footer } from '../components/v2/Footer'
import { FUNDRAISE_STEPS, sliderSettings } from '../constants/founders'

const AlternativeCapital = () => {
  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      bg="#191143"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      w="full"
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#8B9DFB"
            fontSize="20px"
            fontWeight="medium"
            lineHeight="110%"
            textTransform="uppercase"
            textAlign="center"
          >
            Get transparent feedback and recruit advisors
          </Text>

          <Text
            color="#FFF"
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
            textAlign="center"
          >
            Access Alternative Capital
          </Text>
        </VStack>

        <SmallText>
          Deploy is building a new Venture Operating System to rewire the
          innovation economy.
        </SmallText>

        <Link href={CALENDAR_LINK}>
          <Button
            minW="300px"
            color="#1A202A"
            bg="#fff"
            minH="60px"
            borderRadius="60px"
            fontSize="20px"
          >
            request to join
          </Button>
        </Link>
      </VStack>
    </VStack>
  )
}

const steps = [
  {
    title: 'Install App',
    color: '#4299E1',
    content:
      'Deploy is an invite-only expert network. If you’ve been invited by another Expert, install our mobile app to create an account with the invite code.',
  },
  {
    title: 'Scout',
    color: '#76E4F7',
    content:
      'When you meet and introduce a promising startup to Deploy, you’ll get rewarded if the company ends up getting invested in.',
  },
  {
    title: 'Evaluate',
    color: '#0BC5EA',
    content:
      'Review other startups in your domain or location, and get rewarded for your time and efforts. ',
  },
  {
    title: 'New Opportunities',
    color: '#582CFF',
    content:
      'Connect with investors and startup founders who are interested in your unique insights and experiences. ',
  },
]

const FundraiseStepCard = ({
  idx,
  icon,
  title,
  description,
  isActivated,
}: {
  idx: number
  icon: ReactNode
  title: string
  description: string
  isActivated?: boolean | undefined
}) => {
  return (
    <VStack
      bg={isActivated ? '#102d64' : '#252B36'}
      borderRadius="8px"
      p="20px"
      flex="1"
      gap="8px"
      border={isActivated ? '1px solid white' : 'unset'}
      justifyContent="space-between"
    >
      <VStack gap="8px">
        <WithCenterLayout>
          <WithCenterLayout
            borderRadius="16px"
            border="1px solid #fff"
            bg="rgba(255, 255, 255, 0.00)"
            w="24px"
            h="24px"
          >
            <Text fontSize="16px" fontWeight="normal">
              {idx}
            </Text>
          </WithCenterLayout>
        </WithCenterLayout>
        <Text fontSize="16px" fontWeight="semibold" color="white">
          {title}
        </Text>
        <Text
          color="#818181"
          fontSize="14px"
          fontWeight="300"
          lineHeight="normal"
        >
          {description}
        </Text>
      </VStack>

      <WithCenterLayout
        borderRadius="20px"
        w={['50px', '50px', '50px', '60px', '80px', '80px']}
        h={['50px', '50px', '50px', '60px', '80px', '80px']}
        bg="linear-gradient(135deg, #2B3FF2 8.12%, rgba(43, 63, 242, 0.21) 93.26%)"
      >
        <WithCenterLayout w="40px" h="40px">
          {icon}
        </WithCenterLayout>
      </WithCenterLayout>
    </VStack>
  )
}

const GetRewardedForIndustry = () => {
  const [slider, setSlider] = useState<Slider | null>()

  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#fff"
            fontSize={['32px', '32px', '32px', '56px', '56px', '56px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Don’t waste time -
          </Text>

          <Text
            color="#818CF8"
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
            textAlign="center"
          >
            Join Deploy’s way of fundraising.
          </Text>
        </VStack>

        <SmallText>A few easy steps to get started.</SmallText>

        <WithResponsiveLayout
          gap="20px"
          w="full"
          pt={['40px', '40px', '40px', '80px', '80px', '80px']}
          color="white"
          alignItems="stretch"
        >
          {/* <Slider
            {...sliderSettings}
            ref={(slider) => {
              setSlider(slider)
            }}
          > */}
          {FUNDRAISE_STEPS.map((fundraise, idx) => (
            <FundraiseStepCard key={uniqueId()} {...fundraise} idx={idx + 1} />
          ))}
          {/* </Slider> */}
        </WithResponsiveLayout>
      </VStack>
    </VStack>
  )
}

const GetRewarded = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <Box
        pos="relative"
        flex="1"
        alignSelf="stretch"
        maxW="610px"
        minH="400px"
      >
        <Image
          src="images/outlier1.png"
          pos="absolute"
          top="13%"
          zIndex="1"
          left="0"
        />
        <Image src="images/outlier2.png" pos="absolute" top="0" right="0" />
      </Box>

      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#fff"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="110%"
          >
            Leverage your expertise
          </Text>
          <Text
            color="#8B9DFB"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="110%"
          >
            Get rewarded
          </Text>
        </VStack>

        <SmallText>
          Deploy Experts are an invite only pool of active industry
          professionals who are passionate about what they do. They come in all
          shapes and sizes, with only one pre-requisite: deep sector knowledge.
        </SmallText>

        <SmallText>
          Join as an Expert and level up each time you introduce or review a
          deal. Your comments are shared with the startup and investors, and you
          are rewarded through investment carry or commission.
        </SmallText>
      </VStack>
    </WithResponsiveLayout>
  )
}

const EffortlessSynchronization = () => {
  return (
    <Stack bg="#0F172A" w="full" alignItems="center" justifyContent="center">
      <WithResponsiveLayout
        maxW="1600px"
        gap={['40px', '40px', '40px', '80px', '80px', '80px']}
        p={['40px', '40px', '40px', '80px', '80px', '80px']}
      >
        <WithCenterLayout flex="1" alignSelf="stretch">
          <Image src="images/backing-business.png" />
        </WithCenterLayout>

        <VStack gap="32px" flex="1">
          <VStack gap="8px" alignItems="start" w="full">
            <Text
              color="#FFF"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Expertise & Insights
            </Text>
            <Text
              color="#8B9DFB"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Backing your business.
            </Text>
          </VStack>

          <SmallText>
            Every startup accepted onto our system gets evaluated by relevant
            experts in the field you are seeking to disrupt. They use our
            proprietary scoring system and do so in isolation so we collect
            consistent feedback and their comments are available to both
            founders and potential investors.
          </SmallText>

          <SmallText>
            If your deal gets the support of our Experts, then Investors see it
            too - it comes pre-vetted to them, giving them a wider set of due
            diligence and a higher degree of confidence.
          </SmallText>

          <SmallText>
            Our goal is to broaden opinions with influence - beyond VCs to a
            wider set of experts with equally valuable insights.
          </SmallText>

          <SmallText>
            As a founder, you can also see the Expert feedback, giving you a
            larger data set of comments and a better understanding of what
            people really think. In future, you can access individual Experts to
            understand their thoughts and request help.
          </SmallText>
        </VStack>
      </WithResponsiveLayout>
    </Stack>
  )
}

const BuildingYourBusiness = () => {
  return (
    <Stack w="full" alignItems="center" justifyContent="center">
      <WithResponsiveLayout
        maxW="1600px"
        gap={['40px', '40px', '40px', '80px', '80px', '80px']}
        p={['40px', '40px', '40px', '80px', '80px', '80px']}
      >
        <VStack gap="32px" flex="1" alignItems="start">
          <VStack gap="8px" alignItems="start" w="full">
            <Text
              color="#FFF"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Spend more time
            </Text>
            <Text
              color="#8B9DFB"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Building your business.
            </Text>
          </VStack>

          <SmallText>
            Connect with global investors to grow accelerate your startup’s
            growth.
          </SmallText>

          <Stack gap="4px" w="full">
            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>
              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Expert Feedback & Mentorship
              </Text>
            </HStack>

            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>
              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Access to Deploy Investor Network
              </Text>
            </HStack>

            <HStack gap="8px" w="full" justifyContent="start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                  fill="#8B9DFB"
                />
              </svg>

              <Text color="#fff" fontWeight="normal" fontSize="18px">
                Streamlined Assessment Process
              </Text>
            </HStack>
          </Stack>
          <Link href={CALENDAR_LINK}>
            <Button
              color="#fff"
              bg="#4F46E5"
              minH="60px"
              borderRadius="8px"
              fontSize="20px"
              fontWeight="medium"
              lineHeight="24px"
              p="1rem"
            >
              Book a Demo
            </Button>
          </Link>
        </VStack>

        <WithCenterLayout flex="1" alignSelf="stretch">
          <Image src="images/build-your-business.png" />
        </WithCenterLayout>
      </WithResponsiveLayout>
    </Stack>
  )
}

const FoundersPage = () => {
  return (
    <>
      <AlternativeCapital />
      <VStack gap="20px" maxW="1600px">
        <GetRewardedForIndustry />
      </VStack>
      <EffortlessSynchronization />
      <BuildingYourBusiness />
      <UnlockingPotentialPrivateCapital />
      <Footer />
    </>
  )
}

export default FoundersPage
