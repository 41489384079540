import {
  ResponsiveValue,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import { InputProps, components } from 'chakra-react-select'

import { DLSelect } from 'components/common/DLSelect'
import { ChevronDown } from 'components/common/Icons'

const Indicator = (props: any): JSX.Element => {
  const { selectProps } = props
  const { menuIsOpen = false }: { menuIsOpen: boolean; size: string } =
    selectProps

  const iconSize: ResponsiveValue<number> = {
    base: 4.5,
    xs: 4.5,
    sm: 5,
    md: 5,
    lg: 5,
  }

  const boxSize = useBreakpointValue(iconSize, {
    fallback: 'md',
    ssr: false,
  })

  const IndicatorIcon = (
    <ChevronDown
      transitionDuration="0.3s"
      transform={`rotate(${menuIsOpen ? 180 : 0}deg)`}
      boxSize={boxSize}
      color="white"
    />
  )

  return (
    <components.DropdownIndicator {...props}>
      {IndicatorIcon}
    </components.DropdownIndicator>
  )
}

export const Select = (props: any & InputProps) => {
  const bgMode = useColorModeValue(
    'rgba(16, 16, 16, 0.20)',
    'rgba(255, 255, 255, 0.20)',
  )

  const borderMode = useColorModeValue(
    '1px solid rgba(255, 255, 255, 0.12) !important',
    '1px solid rgba(16, 16, 16, 0.12) !important',
  )

  const textMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )

  return (
    <DLSelect
      components={{ DropdownIndicator: Indicator }}
      menuPortalTarget={document.body}
      styles={{
        option: (provided: any, state: any) => {
          const customOption = props?.customStyles?.option
          const styles = {
            ...provided,
            bg: 'inherit',
            color: textMode,
            border: 'unset',
            fontSize: 'sm',
            ...(typeof customOption === 'function'
              ? customOption(state)
              : customOption),
          }
          return styles
        },

        menu: (provided: any) => ({
          ...provided,
          m: 0,
          p: 0,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          border: borderMode,
          borderTop: '0 !important',
          zIndex: 99999,
        }),
        menuList: (provided: any) => ({
          ...provided,
          m: 0,
          p: 0,
          bg: 'inherit',
          borderRadius: 'inherit',
          border: 'unset',
        }),
        control: (provided: any, { selectProps }: { selectProps: any }) => {
          return {
            ...provided,
            borderRadius: 3,
            border: borderMode,
            color: textMode,
            ...(selectProps?.menuIsOpen
              ? {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: '0 !important',
                }
              : {}),
            '> div': {
              color: textMode,
            },
          }
        },
        placeholder: (provided: any) => ({
          ...provided,
          color: textMode,
          fontSize: '0.75rem !important',
          fontWeight: '500',
        }),
        container: (provided: any, { selectProps }: { selectProps: any }) => {
          const containerStyles = {
            ...provided,
            bgColor: bgMode,
            flex: [
              '0 0 calc((100% - 1rem) / 2)',
              '0 0 calc((100% - 1rem) / 2)',
              '0 0 calc((100% - 1rem) / 2)',
              '0 0 calc((100% - 1rem) / 2)',
              '0 0 200px',
            ],
            borderRadius: 3,
            ...(selectProps?.menuIsOpen
              ? {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: 0,
                }
              : {}),
            '> div': {
              bg: 'inherit',
              color: textMode,
            },
            ...props?.customStyles?.container,
          }

          return containerStyles
        },
      }}
      {...props}
    />
  )
}

export default Select
