import {
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  forwardRef,
  memo,
} from 'react'

import { Grid, useColorModeValue } from '@chakra-ui/react'

import { AnimatePresence, motion } from 'framer-motion'
import uniqueId from 'lodash/uniqueId'

export interface IGridRow {
  numCols?: number
  isOpen?: boolean
  accordions?: JSX.Element | ReactElement<any, any>
  children?: ReactNode
}

const GridRow: ForwardRefRenderFunction<
  HTMLDivElement,
  IGridRow & {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
  }
> = ({ numCols, isOpen, accordions, children, onClick }, ref) => {
  const colorByMode = useColorModeValue(
    !isOpen ? 'row.bg' : 'row.activatedBackground',
    'rgba(255, 255, 255, 0.40)',
  )
  return (
    <Grid
      ref={ref}
      as="li"
      onClick={onClick}
      templateColumns={[
        `2.81rem repeat(${(numCols as number) - 2}, minmax(80px, 1fr)) 24px`,
        `20px 1fr 1fr 2fr 16px`,
        `2.81rem repeat(${(numCols as number) - 2}, minmax(90px, 1fr)) 24px`,
        `2.81rem repeat(${(numCols as number) - 2}, minmax(90px, 1fr)) 24px`,
        `2.81rem repeat(${(numCols as number) - 2}, minmax(90px, 1fr)) 24px`,
        `2.81rem repeat(${(numCols as number) - 2}, minmax(100px, 1fr)) 24px`,
      ]} //need to replace 65px outside
      bg={colorByMode}
      w="full"
      p={[4, '0.75rem 1rem', 4, 4, 4]}
      gap={[4, 4, 4, '0.25rem', 4, 6]}
      borderRadius={6}
    >
      {children}

      {/* {accordions && (
        <Collapse
          in={isOpen}
          animateOpacity
          style={{ gridColumn: `span ${numCols}/span ${numCols}` }}
        >
          {accordions}
        </Collapse>
      )} */}
      {accordions && isOpen && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={uniqueId()}
            layout
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{
              // width: '100%',
              // height: '100%',
              gridColumn: `span ${numCols}/span ${numCols}`,
            }}
          >
            {accordions}
          </motion.div>
        </AnimatePresence>
      )}
    </Grid>
  )
}

export default forwardRef(GridRow)
