import { Icon } from '@chakra-ui/react'

// const ScreenRoomIcon = (props) => {
//   return (
//     <Icon
//       xmlns="http://www.w3.org/2000/svg"
//       width="36"
//       height="36"
//       viewBox="0 0 36 36"
//       fill="currentColor"
//       {...props}
//     >
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M29.7715 24.8263C29.7715 26.9552 28.6484 28.0782 26.4902 28.0782H9.52734C7.36914 28.0782 6.23633 26.9552 6.23633 24.8263V15.2071C6.23633 13.0685 7.36914 11.9454 9.52734 11.9454H11.9785V10.881C11.9785 9.26962 12.9941 8.34189 14.752 8.34189H21.2559C23.0137 8.34189 24.0391 9.26962 24.0391 10.881V11.9454H26.4902C28.6484 11.9454 29.7715 13.0685 29.7715 15.2071V24.8263ZM14.1465 11.9454H21.8711V10.9591C21.8711 10.5392 21.5879 10.2755 21.1094 10.2755H14.9082C14.4297 10.2755 14.1465 10.5392 14.1465 10.9591V11.9454Z"
//         // fill="white"
//       />
//     </Icon>
//   )
// }

const ScreenRoomIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="currentColor"
      pos="relative"
      top="3px"
      {...props}
    >
      <path
        d="M12 7.69334C10 2.9989 3 3.4989 3 9.49893C3 15.499 12 20.4991 12 20.4991C12 20.4991 21 15.499 21 9.49893C21 3.4989 14 2.9989 12 7.69334Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  )
}

export default ScreenRoomIcon
