const borderRadius = {
  radii: {
    0.25: '0.0625rem', // 1px
    0.75: '0.1875rem', // 3px
    1: '0.25rem',
    1.5: '0.375rem',
    2.5: '0.625rem', // 10px
    3: '0.75rem',
    4: '1rem', // 16px
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.813rem',
    12: '3.215rem',
  },
}

export default borderRadius
