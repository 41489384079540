import { ReactNode, Suspense, lazy, useEffect } from 'react'

import { Helmet } from 'react-helmet';

import { VStack, useColorMode, useColorModeValue } from '@chakra-ui/react'

import { motion, useScroll, useSpring } from 'framer-motion'

import { Footer } from '../components/v2/Footer'

const PageWrapper = ({ children }: { children: ReactNode }) => {
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })
  const bgByMode = useColorModeValue('darkMain.500', 'white')

  return (
    <>
      <Helmet>
        <title>Deploy.</title>
        <meta name="description" content="Deploy is a global intelligence network powering the future of venture dealflow & research. We provide unparalleled access to industry experts, data, and insights for better decisions." />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Deploy Dev" />
        <meta property="og:title" content="Deploy." />
        <meta property="og:description" content="Deploy is a global intelligence network powering the future of venture dealflow & research. We provide unparalleled access to industry experts, data, and insights for better decisions." />
        <meta name="twitter:title" content="Deploy. with us" />
        <meta name="twitter:description" content="Deploy is a global intelligence network powering the future of venture dealflow & research. We provide unparalleled access to industry experts, data, and insights for better decisions." />
      </Helmet>
      <motion.div
        style={{
          background: '#00c9ff',
          height: '5px',
          transformOrigin: '0',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          scaleX,
        }}
      />
      <VStack
        maxW="1600px"
        as="main"
        mt={['10px', '10px', '40px', '40px', '40px']}
        bg={bgByMode}
        w="full"
        h="fit-content"
        p={['0 20px', '0 20px', '0 40px', '0 40px', '0 80px']}
        gap={['120px', '120px', '120px', '200px', '200px', '200px']}
      >
        {children}
      </VStack>
    </>
  )
}

const FindVenture = lazy(() => import('../components/v2/FindVenture'))
const PlaceSmarterBets = lazy(() => import('../components/v2/PlaceSmarterBets'))
const Capitalization = lazy(() => import('../components/v2/Capitalization'))
const FoundingTeam = lazy(() => import('../components/v2/FoundingTeam'))
const ProductDemo = lazy(() => import('../components/v2/ProductDemo'))

export const NewLandingPageV2 = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    if (colorMode === 'dark') toggleColorMode()
  }, [])

  return (
    <Suspense fallback={null}>
      <PageWrapper>
        {/* <GradientBackground /> */}
        <FindVenture />
        <PlaceSmarterBets />
        <Capitalization />
        <ProductDemo />
        <FoundingTeam />
        {/* <Capitalization />
        <OurStory />
        <Innovation />
        <LearnMore /> */}
      </PageWrapper>
      <Footer />
    </Suspense>
  )
}
