import React, { FunctionComponent, ReactNode } from 'react'

import { HStack, StackProps } from '@chakra-ui/react'

type WithCenterLayoutProps = {
  children: ReactNode
  component?: FunctionComponent<StackProps>
} & StackProps

const WithCenterLayout = ({
  component: Component = HStack,
  children,
  ...rest
}: WithCenterLayoutProps) => (
  <Component justifyContent="center" alignItems="center" {...rest}>
    {children}
  </Component>
)

export default WithCenterLayout
