import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { ROLES } from 'constants/roles'
import { TABLES_NAME } from 'constants/tables'
import { IDealRating } from 'interfaces/models'

import {
  VIEW_DEALS_SECTOR_DISTRIBUTION,
  VIEW_USER_ROLES,
} from '../constants/dashboard'
import {
  VIEW_DEALS_LEADER_BOARD_MAVEN_RATING,
  VIEW_FEATURED_SCOUTS,
} from '../constants/leaderboard'
import { IFeaturedScouts, ISectorDistribution } from '../interfaces/dashboash'
import { IDealRow } from '../interfaces/deal-table'

function useDashboardQuery() {
  const fetchSectorDistribution = async (): Promise<
    ISectorDistribution[] | []
  > => {
    try {
      const { data, error }: PostgrestResponse<ISectorDistribution> =
        await supabase.from(VIEW_DEALS_SECTOR_DISTRIBUTION).select('*')

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const fetchLatestMavenInsight = async (): Promise<
    (IDealRating & { users: { avatar: string } })[] | []
  > => {
    try {
      const {
        data,
        error,
      }: PostgrestResponse<IDealRating & { users: { avatar: string } }> =
        await supabase
          .from(TABLES_NAME.deal_maven_ratings)
          .select('*, users(avatar)')
          .order('created_at', { ascending: false })
          .limit(5)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const getDealQuery = () =>
    supabase
      .from(VIEW_DEALS_LEADER_BOARD_MAVEN_RATING)
      .select('*, deal_subdomains(subdomain_id)')

  const getTopFeaturedScouts = () =>
    supabase.from(VIEW_FEATURED_SCOUTS).select('*')

  const fetchTopStartups = async () => {
    try {
      const { data, error }: PostgrestResponse<IDealRow> =
        await getDealQuery().limit(10)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const fetchTopFeaturedScouts = async () => {
    try {
      const { data, error }: PostgrestResponse<IFeaturedScouts> =
        await getTopFeaturedScouts().limit(5)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const getTotalScoutsAndStartups = async () => {
    try {
      const { count: totalScouts }: PostgrestResponse<Record<string, any>> =
        await supabase
          .from(VIEW_USER_ROLES)
          .select('*', { count: 'exact', head: true })
          .eq('role', ROLES.scout)

      // const { count: totalStartups }: PostgrestResponse<Record<string, any>> =
      //   await supabase
      //     .from(VIEW_USER_ROLES)
      //     .select('*', { count: 'exact', head: true })
      //     .eq('role', ROLES.startup_founder)

      const { count: totalStartups }: PostgrestResponse<Record<string, any>> =
        await supabase.from('deals').select('*', { count: 'exact', head: true })

      return {
        totalScouts,
        totalStartups,
      }
    } catch (error) {
      return {
        totalScouts: 0,
        totalStartups: 0,
      }
    }
  }

  return {
    fetchSectorDistribution,
    fetchLatestMavenInsight,
    fetchTopStartups,
    fetchTopFeaturedScouts,
    getTotalScoutsAndStartups,
  }
}

export default useDashboardQuery
