import { ReactNode } from 'react'

import {
  HStack,
  StackProps,
  TagProps,
  Text,
  TextProps,
  useColorModeValue,
} from '@chakra-ui/react'

import { SECTORS_MAP_DOT_COLORS } from 'constants/common'

import TagWithDot from '../TagWithDot'

export const DotTagForSectorPath = ({
  sector,
  children,
  ...rest
}: {
  sector: string
  children?: ReactNode
} & TagProps) => {
  return (
    <TagWithDot
      dotColor={SECTORS_MAP_DOT_COLORS?.[sector] as string}
      p={['4px', '4px 8px', '4px 8px', '4px 8px', '4px 6px', '4px 12px']}
      bg={useColorModeValue('rgba(208, 220, 245, 0.08)', '#0000009e')}
      {...rest}
    >
      {children ?? sector}
    </TagWithDot>
  )
}

const SectorsPath = ({
  sectorsAndDomains,
  sectorProps,
  domainProps,
  ...rest
}: {
  sectorsAndDomains: string
  sectorProps?: TextProps
  domainProps?: TextProps
} & StackProps) => {
  return (
    <HStack alignItems="start" {...rest} flexWrap="wrap">
      {sectorsAndDomains?.split(',')?.map((sad: string, index: number) => {
        const [sector, _, subdomain] = sad?.split(' > ')
        return (
          <DotTagForSectorPath sector={sector} key={`${sad}@${index}`}>
            <HStack
              w="full"
              gap={['4px', '4px', '4px', '4px', '8px', '8px']}
              rowGap={[0, 0, 0, 0, 0, 0]}
              flexWrap="wrap"
            >
              <Text
                fontSize={['sm', 'sm', 'sm', 'sm', 'md', 'md']}
                fontWeight="bold"
                lineHeight="normal"
                {...sectorProps}
              >
                {sector}
              </Text>
              <Text
                fontSize={['sm', 'sm', 'sm', 'sm', 'md', 'md']}
                fontWeight="normal"
                lineHeight="normal"
                opacity={0.8}
                {...domainProps}
              >
                {subdomain}
              </Text>
            </HStack>
          </DotTagForSectorPath>
        )
      })}
    </HStack>
  )
}

export default SectorsPath
