import * as React from 'react'
import { useEffect, useState } from 'react'

import { useBoolean, useBreakpointValue } from '@chakra-ui/react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { useInView } from 'framer-motion'
import useMounted from 'hooks/useMounted'
import { IDeal } from 'interfaces/models'
import isEmpty from 'lodash/isEmpty'
import { breakpointValues } from 'theme/foundations/breakpoints'
import { getFirstSevenCharacters } from 'utils/string'

import AccordionDeal from '../components/other/DealAccordion'
import {
  DEAL_ACCORDION_FIELDS,
  DEAL_ADDITIONAL_ACCORDION_FIELDS,
  FIELDS_RENDER,
  TLeaderBoardFilter,
  TTicketSizeFilter,
  VIEW_DEALS_LEADER_BOARD_MAVEN_RATING,
  VIEW_DOMAINS_FULL_PATH,
} from '../constants/leaderboard'
import { IDealRow } from '../interfaces/deal-table'

export type TDealLeaderBoard = IDeal & {
  maven_rating_avg_in_sector: number
  deal_rank: number
  total_num_mavens_for_deal: number
  count_maven_rating: number
  percent_mavens_have_evaluated: number
  deal_subdomains: { subdomain_id: number | string }[]
  sectors_and_domains?: string
  num_of_investors_added_deal: number
}

export type TDealSector = {
  subdomain_id: string
  path: string
}

/**
 * Transforms an object by modifying its properties and returns the transformed object.
 *
 * @param {TDealLeaderBoard} originalObject - The original object to be transformed.
 * @return {Record<string, any>} The transformed object.
 */
function transformObject(
  originalObject: TDealLeaderBoard,
  domains: TDealSector[] | null,
) {
  const subdomains = originalObject?.deal_subdomains ?? []
  originalObject.sectors_and_domains = subdomains
    .map(
      (
        domain, // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ) =>
        domains?.filter(
          ({ subdomain_id }) => subdomain_id === domain.subdomain_id,
        )[0]?.path,
    )
    ?.join(',')

  return Object.entries(originalObject).reduce(
    (acc: Record<string, any>, [key, value]) => {
      if (key === 'company_name') {
        acc[key] = {
          title: '',
          value: getFirstSevenCharacters(originalObject.deal_id),
        }
      } else if (key === 'maven_rating_avg_in_sector') {
        const {
          maven_rating_avg_in_sector,
          percent_mavens_have_evaluated,
          count_maven_rating,
        } = originalObject
        acc[key] = {
          title: '',
          value: {
            maven_rating_avg_in_sector,
            percent_mavens_have_evaluated,
            total_num_mavens_for_deal: count_maven_rating,
          },
        }
      } else {
        acc[key] = {
          title: '',
          value,
        }
      }

      const subdomains = originalObject?.deal_subdomains ?? []
      acc['domain'] = {
        title: '',
        value: subdomains
          .map(
            (
              domain, // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            ) =>
              domains?.filter(
                ({ subdomain_id }) => subdomain_id === domain.subdomain_id,
              )[0]?.path,
          )
          ?.join(','),
      }

      // acc['sector'] = {
      //   title: '',
      //   value: subdomains
      //     .map(
      //       (
      //         domain,
      //       ) =>
      //         domains
      //           ?.filter(
      //             ({ subdomain_id }) => subdomain_id === domain.subdomain_id,
      //           )[0]
      //           ?.path?.split(' > ')?.[0],
      //     )
      //     ?.join(','),
      //   render: (FIELDS_RENDER as Record<string, any>)?.['sector'],
      // }

      const render = (FIELDS_RENDER as Record<string, any>)?.[key] ?? null
      if (render) {
        acc[key]['render'] = render
      }

      acc.originalRow = originalObject

      return acc
    },
    {},
  )
}

/**
 * Transforms the rows of deals into an array of IDealRow objects.
 *
 * @param {string | undefined} currentBreakpoint - The current breakpoint.
 * @param {(TDealLeaderBoard)[]} deals - The array of deals.
 * @return {IDealRow[]} The transformed array of IDealRow objects.
 */
const transformRows = (
  currentBreakpoint: string | undefined,
  deals: TDealLeaderBoard[],
  domains: TDealSector[] | null,
  callback: React.Dispatch<React.SetStateAction<IDealRow | null | undefined>>,
): IDealRow[] => {
  const keyGetAdditionalField = ['base', 'sm'].includes(
    currentBreakpoint as string,
  )
    ? 'LessThan480'
    : 'GreaterThan768'

  return deals.map((deal) => {
    // const dealRow: IDealRow = {
    //   accordions: <></>,
    //   ...transformObject(deal, domains),
    // }

    return {
      accordions: (
        <AccordionDeal
          deal={deal}
          callback={callback}
          showedFields={DEAL_ACCORDION_FIELDS}
          additionalFields={
            DEAL_ADDITIONAL_ACCORDION_FIELDS[keyGetAdditionalField]
          }
        />
      ),
      ...transformObject(deal, domains),
    }
  })
}

const LIMIT = 15
const SUPABASE_OPERATOR = {
  lessThan: 'lt',
  greaterThan: 'gt',
  lessThanOrEqualTo: 'lte',
  greaterThanOrEqualTo: 'gte',
  between: 'between',
}

const getDealQuery = () =>
  supabase
    .from(VIEW_DEALS_LEADER_BOARD_MAVEN_RATING)
    .select('*, deal_subdomains!inner(subdomain_id), locations!inner(region)')

const getDomainQuery = () =>
  supabase.from(VIEW_DOMAINS_FULL_PATH).select('subdomain_id, path')

const useLeaderBoardQueries = (
  filters: TLeaderBoardFilter,
  sorting: Record<string, { desc: boolean }>,
  callback: React.Dispatch<React.SetStateAction<IDealRow | null | undefined>>,
  ref: React.MutableRefObject<null>,
) => {
  const currentBreakpoint = useBreakpointValue(breakpointValues, { ssr: false })
  const [fetchedDeals, setFetchedDeals] = useState<{
    rows: IDealRow[]
  }>({ rows: [] })

  const [domains, setDomains] = useState<TDealSector[] | null>([])
  const [isLoading, toggle] = useBoolean()
  const [hasMore, setHasMore] = useBoolean(true)
  const isMounted = useMounted()
  const isInView = useInView(ref, { margin: '200px 200px 200px 200px' })

  let offset = fetchedDeals?.rows?.length ?? 0
  // const params = useQueryParams()
  const dealQuery = getDealQuery()
  // .order('maven_rating_avg_in_sector', { ascending: false })

  const domainQuery = getDomainQuery()
  /**
   * Appends a query to the `dealQuery` object based on the `minimum_ticket_size` filter.
   *
   * @return {void}
   */
  const appendQueryByTicketSize = () => {
    const searchByField = 'minimum_ticket_size'
    const { operator, value } = (filters as TLeaderBoardFilter)?.[
      searchByField
    ] as TTicketSizeFilter
    if (operator === SUPABASE_OPERATOR.lessThan) {
      dealQuery.lt(searchByField, value)
    }

    if (operator === SUPABASE_OPERATOR.greaterThan) {
      dealQuery.gt(searchByField, value)
    }

    if (operator === SUPABASE_OPERATOR.between) {
      const [start, end] = value as number[]
      dealQuery.lte(searchByField, end)
      dealQuery.gte(searchByField, start)
    }
  }

  /**
   * Append query by location.
   *  @return {void}

   */
  const appendQueryByLocation = async () => {
    const searchByField = 'hq_id'
    dealQuery.eq(searchByField, filters?.[searchByField])
  }

  /**
   * Append query by region.
   *  @return {void}

   */
  const appendQueryByRegion = async () => {
    const searchByField = 'region_id'
    dealQuery.eq('locations.region', filters?.[searchByField])
  }

  /**
   * Append query by domain.
   *  @return {void}

   */
  const appendQueryByDomain = async () => {
    const searchByField = 'subdomain_id'
    dealQuery.eq('deal_subdomains.subdomain_id', filters?.[searchByField])
  }

  /**
   * Appends a query filter by sector ID to the deal search query.
   *  @return {void}
   */
  const appendQueryBySector = async () => {
    const searchByField = 'sector_id'
    const { data } = await supabase.rpc('get_distinct_deals_by_sector', {
      sector_id: filters?.[searchByField],
    })

    const dealIds = data?.map((deal: { deal_id: string }) => deal?.deal_id)
    dealQuery.in('deal_id', dealIds)
  }

  /**
   * Appends sorting to the dealQuery object based on the sorting object.
   *
   * @param {object} sorting - The sorting object containing the sorting configuration.
   */
  const appendSorting = () => {
    for (const key in sorting) {
      if (sorting.hasOwnProperty(key)) {
        dealQuery.order(key, { ascending: !sorting[key].desc })
      }
    }
  }

  const fetchDeals = async () => {
    toggle.on()
    try {
      if (!isEmpty(sorting)) {
        appendSorting()
      } else {
        //if there is no sorting, sort by maven_rating_avg_in_sector
        // dealQuery.order('maven_rating_avg_in_sector', { ascending: false })
      }

      if (!isEmpty(filters)) {
        filters?.['minimum_ticket_size'] && appendQueryByTicketSize()
        filters?.['subdomain_id'] && appendQueryByDomain()
        filters?.['sector_id'] && (await appendQueryBySector())
        filters?.['hq_id'] && (await appendQueryByLocation())
        filters?.['region_id'] && (await appendQueryByRegion())
      }

      let allDomains = domains
      if (domains?.length === 0 || fetchedDeals.rows.length === 0) {
        const { data: fetchedDomains }: PostgrestResponse<TDealSector> =
          await domainQuery
        allDomains = fetchedDomains
        setDomains(fetchedDomains)
      }

      // await supabase
      //   .from('matview_subdomain_with_full_path')
      //   .select('*, domain_taxonomy(*)')

      const { data, error } = await dealQuery.range(offset, offset + LIMIT)
      error || data.length === 0 ? setHasMore.off() : setHasMore.on()
      console.log(
        transformRows(currentBreakpoint, data ?? [], allDomains, callback),
      )

      setFetchedDeals({
        rows: [
          ...(offset > 0 ? fetchedDeals?.rows : []),
          ...transformRows(currentBreakpoint, data ?? [], allDomains, callback),
        ],
      })
    } catch (e) {
      setFetchedDeals({ rows: [] })
    } finally {
      toggle.off()
    }
  }

  useEffect(() => {
    offset = 0
    isMounted && fetchDeals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, filters, sorting])

  useEffect(() => {
    if (isInView && hasMore) {
      isMounted && fetchDeals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, isInView])

  return { isLoading, fetchedDeals }
}

export default useLeaderBoardQueries
