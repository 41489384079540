import { AuthTokenResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { USER_STORAGE_KEY } from 'constants/common'
import { ROLES } from 'constants/roles'
import { TABLES_NAME } from 'constants/tables'
import dayjs from 'dayjs'
import { useHistory } from 'react-router'

import useLocalStorage from './useLocalStorage'

type AuthResponse = Omit<AuthTokenResponse, 'error'>['data']

export default function useAuth() {
  const [user] = useLocalStorage(USER_STORAGE_KEY, null)
  const history = useHistory()

  /**
   * getting user from local storage
   * **/
  const getUser = (): AuthResponse & {
    role: string | null
    metadata:
      | {
          first_name: any
          last_name: any
          locations: {
            iso2: any
            iso3: any
          }
        }
      | undefined
  } => {
    let user = { user: null, session: null, role: null, metadata: undefined }
    try {
      if (window.localStorage) {
        user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) ?? '')
      }
    } catch (error: any) {
      console.log(error?.message)
    }

    return user
  }

  /**
   * storing user in local storage
   * **/
  const setUser = (
    auth:
      | (AuthResponse & {
          role: string | null
          metadata:
            | {
                first_name: any
                last_name: any
              }
            | undefined
        })
      | null,
  ): void => {
    if (auth?.user) {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(auth))
    }
  }

  /**
   * check whether the user has permission to access the given feature
   * **/
  const hasPermission = (feature: string | undefined): boolean => {
    const user = getUser()
    return user && user?.user?.role === 'authenticated'
  }

  /**
   * check whether the user is authenticated or not
   * **/
  const isLogged = (): boolean => {
    const user: AuthResponse = getUser()
    const expiredTime = new Date((user?.session?.expires_at ?? 0) * 1000)
    return !!user?.session?.access_token && dayjs().isBefore(dayjs(expiredTime))
  }

  /**
   * logout the user from investor site
   * **/
  const onLogout = async () => {
    await supabase.auth.signOut()
    localStorage.clear()
    history && history.push('/')
  }

  /**
   * sign up a new user
   * @param email
   * @param password
   * **/
  const signUp = async (email: string, password: string) => {
    await supabase.auth.signUp({ email, password })
  }

  /**
   * login a new user
   * @param email
   * @param password
   * @return res: AuthTokenResponse
   * **/
  const login = async (email: string, password: string) => {
    const res: AuthTokenResponse = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    const roles = await getUserRole(res?.data?.user?.id)
    const hasInvestorRole = roles?.some(
      (obj) => obj.roles.role === ROLES.investor,
    )
    const role = hasInvestorRole ? ROLES.investor : 'authenticated'

    const metadata = await getUserMetadata(res?.data?.user?.id)

    isInvestor(role) &&
      setUser({
        ...res?.data,
        role: role || '',
        metadata,
      })

    return res
  }

  const getUserRole = async (id: string | undefined) => {
    if (!id) return
    const { data } = await supabase
      .from('user_roles')
      .select('*, roles(role)')
      .eq('user_id', id)

    return data
  }

  const getUserMetadata = async (id: string | undefined) => {
    if (!id) return
    const { data } = await supabase
      .from('users')
      .select('first_name, last_name, locations(iso2, iso3)')
      .eq('user_id', id)
      .limit(1)

    return data?.[0] || { first_name: '', last_name: '' }
  }

  const isInvestor = (role?: string | undefined) =>
    role || getUser()?.role === ROLES.investor

  const writeLogWhenUserLoggedIn = async ({ userID }: { userID: string }) => {
    userID &&
      (await supabase.from(TABLES_NAME.investor_action_logs).insert({
        action_type: 'investor_has_logged_in',
        investor_id: userID,
      }))
  }

  return {
    user,
    getUser,
    getUserRole,
    setUser,
    hasPermission,
    isLogged,
    onLogout,
    signUp,
    login,
    isInvestor,
    writeLogWhenUserLoggedIn,
  }
}
