/* eslint-disable no-unused-vars */
import { Grid, GridItem, HStack } from '@chakra-ui/react'

import {
  DeployStatus,
  FeaturedScouts,
  GeographicalReach,
  LatestMavenInsights,
  TopBar,
  TopStartups,
  UpgradeVenture,
} from '../components/dashboard'

/**
 *  Dashboard component
 * **/
const Dashboard = () => {
  /**
   *  Rendering component
   * **/
  return (
    <Grid
      gap="28px"
      p={{ base: '10px', md: '40px' }}
      templateRows="repeat(3 1fr)"
      templateColumns={[
        'repeat(1 1fr)',
        'repeat(1 1fr)',
        'repeat(1 1fr)',
        'repeat(1 1fr)',
        'repeat(6 1fr)',
        'repeat(6 1fr)',
      ]}
    >
      <GridItem colSpan={[1, 1, 1, 1, 1, 3]}>
        <TopBar />
      </GridItem>
      <GridItem colSpan={[1, 1, 1, 1, 1, 3]}>
        <HStack
          gap="20px"
          flexDirection={['column', 'column', 'column', 'column', 'row']}
          alignItems="stretch"
        >
          {/* <HStack
            w="full"
            gap="20px"
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          >
            <UpgradeVenture />
            <DeployStatus />
            <GeographicalReach />
          </HStack> */}
          <DeployStatus />
          <GeographicalReach />
          <LatestMavenInsights />
        </HStack>
      </GridItem>
      <GridItem colSpan={[1, 1, 1, 1, 1, 3]}>
        <TopStartups />
      </GridItem>
      <GridItem colSpan={[1, 1, 1, 1, 1, 3]}>
        <FeaturedScouts />
      </GridItem>
    </Grid>
  )
}

export default Dashboard
