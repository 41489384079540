import React from 'react'

import { useNumberInput } from '@chakra-ui/react'

import Input from './Input'

export default React.forwardRef(function NumberInput(
  { size, placeholder, isInvalid, isValid, onClear, sx, ...props },
  ref,
) {
  const { getInputProps } = useNumberInput(props)

  const inputProps = { ...getInputProps({}, ref) }
  inputProps.pattern = undefined

  return (
    <Input.Group size={size}>
      <Input
        isInvalid={isInvalid}
        isValid={isValid}
        placeholder={placeholder}
        {...inputProps}
        {...sx}
      />
      {/* {isInvalid && (
				<Input.RightElement>
					<Icon as={VscChromeClose} color="negative.500" onClick={onClear} />
				</Input.RightElement>
			)}
			{isValid && (
				<Input.RightElement>
					<Icon as={HiCheck} color="positive.500" />
				</Input.RightElement>
			)} */}
    </Input.Group>
  )
})
