import { ButtonGroup, ButtonGroupProps, useColorMode } from '@chakra-ui/react'

import { motion } from 'framer-motion'

import { Moon, Sun } from '../Icons'

/**
 * Generates the active mode styles based on the given mode.
 *
 * @param {string} mode - The mode to generate the styles for. Can be 'light' or any other value.
 * @return {object} The generated styles object with the appropriate properties based on the mode.
 */
const activeModeStyles = (mode: string) =>
  mode === 'light'
    ? {
        borderRadius: '60px',
        border: '1px solid rgba(255, 255, 255, 0.13)',
        backgroundColor: '#fff',
        color: '#101010',
        inactiveColor: '#fff',
      }
    : {
        borderRadius: '60px',
        border: '1px solid rgba(255, 255, 255, 0.13)',
        backgroundColor: '#101010',
        color: '#fff',
        inactiveColor: '#101010',
      }

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

const MODE_BUTTONS = {
  Sun: 'dark',
  Moon: 'light',
} as Record<string, string>

/**
 * Renders a toggle mode component.
 *
 * @return {JSX.Element} The rendered toggle mode component.
 */
const ToggleMode = (
  props?: ButtonGroupProps & {
    customActiveModeStyles?: (mode: string) => Record<string, any>
  },
) => {
  const { toggleColorMode, colorMode } = useColorMode()
  const getActiveModeStyles = props?.customActiveModeStyles ?? activeModeStyles
  return (
    <ButtonGroup
      p="4px 8px"
      gap="0px"
      borderRadius="60px"
      border={colorMode === 'dark' ? 'commonInLightMode' : 'common'}
      {...props}
    >
      {[Moon, Sun].map((Btn: any) => {
        return (
          <motion.button
            key={Btn.toString()}
            layout
            transition={spring}
            onClick={toggleColorMode}
            className={
              colorMode === MODE_BUTTONS[Btn?.displayName]
                ? `active-${colorMode}`
                : ''
            }
            style={{
              padding: '2px 8px',
              ...(colorMode === MODE_BUTTONS[Btn?.displayName]
                ? getActiveModeStyles?.(colorMode)
                : {}),
            }}
          >
            <Btn
              w="24px"
              h="24px"
              color={
                colorMode === MODE_BUTTONS[Btn?.displayName]
                  ? getActiveModeStyles?.(colorMode)?.color
                  : getActiveModeStyles?.(colorMode)?.inactiveColor
              }
            />
          </motion.button>
        )
      })}
    </ButtonGroup>
  )
}

export default ToggleMode
