import { HStack, Link } from '@chakra-ui/react'

const PreviousNextSection = ({
  onNext,
  onPrevious,
}: {
  onNext?: () => void
  onPrevious?: () => void
}) => {
  return (
    <HStack gap="10px">
      <Link onClick={onPrevious}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.9998 19.9181L8.47984 13.3981C7.70984 12.6281 7.70984 11.3681 8.47984 10.5981L14.9998 4.07812"
            stroke="#EDF2F7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Link>

      <Link onClick={onNext}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.90991 19.9181L15.4299 13.3981C16.1999 12.6281 16.1999 11.3681 15.4299 10.5981L8.90991 4.07812"
            stroke="#EDF2F7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Link>
    </HStack>
  )
}

export default PreviousNextSection
