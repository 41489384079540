import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    // bg: 'blackAlpha.200', //change the background
  },
  dialog: {
    borderRadius: 'md',
    padding: ['1rem', '1rem', '1rem'],
  },
})

const white = definePartsStyle({
  dialogContainer: {},
  header: {
    fontSize: '1.5rem',
    color: 'background.darkMode',
    p: 0,
    mb: '2rem',
  },
  closeButton: { color: 'rgba(16, 16, 16, 0.4)' },
  body: {
    p: 0,

    input: {
      bgColor: 'rgba(16, 16, 16, 0.04) !important',
    },
  },
  dialog: {
    // w: '23.5rem',
    borderRadius: 6,
    bg: `white`,
    color: 'background.darkMode',

    _dark: {
      // bg: `purple.600`,
      // color: 'white',
    },
  },
})

const dark = definePartsStyle({
  dialogContainer: {},
  header: {
    fontSize: '1.5rem',
    color: 'background.darkMode',
    p: 0,
    mb: '2rem',
  },
  closeButton: { color: 'rgba(16, 16, 16, 0.4)', _light: { color: 'white' } },
  body: {
    p: 0,

    input: {
      bgColor: 'rgba(16, 16, 16, 0.04) !important',
    },
  },
  dialog: {
    // w: '23.5rem',
    borderRadius: 6,
    bg: `darkMain.500`,
    color: 'background.darkMode',

    _dark: {
      bg: `white`,
      color: 'background.lightMode',
    },
  },
})

export default defineMultiStyleConfig({
  baseStyle,
  variants: { white, dark },
})
