import { useToast } from '@chakra-ui/react'

import { ErrorAlert, SuccessAlert } from 'modules/landing/pages/elements'

const useDLToast = () => {
  const toast = useToast()

  return {
    showErrorToast: (message: string, needCloseAll = true) => {
      needCloseAll && toast.closeAll()
      toast({
        render: () => (
          <ErrorAlert warningText={message} onClose={toast.closeAll} />
        ),
        duration: 55555,
        isClosable: true,
        position: 'top',
        containerStyle: {
          minWidth: '90vw',
        },
      })
    },
    showSuccessToast: (message: string, needCloseAll = true) => {
      needCloseAll && toast.closeAll()
      toast({
        render: () => <SuccessAlert text={message} onClose={toast.closeAll} />,
        duration: 55555,
        isClosable: true,
        position: 'top',
        containerStyle: {
          minWidth: '90vw',
        },
      })
    },
  }
}

export default useDLToast
