import { RESET_PW_PATH } from 'constants/auth'
import { NAVBAR } from 'constants/navbar'
import { TRoute } from 'types/route'

import { ResetPassword } from 'modules/authentication/pages'

import { Dashboard, LeaderBoard } from '../pages'

const DashboardRoutes: TRoute[] = [
  {
    path: NAVBAR.PATHS.HOME,
    profile: 'Investor',
    feature: 'ViewHomePage',
    exact: true,
    component: Dashboard,
  },
  {
    path: NAVBAR.PATHS.LEADER_BOARD,
    profile: 'Investor',
    feature: 'ViewLeaderBoard',
    exact: true,
    component: LeaderBoard,
  },
  {
    path: RESET_PW_PATH,
    component: ResetPassword,
    exact: true,
  },
  //.....create more routes for dashboard here...
]

export default DashboardRoutes
