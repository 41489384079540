export const AUTHENTICATION_QUERY = '/startup-forms/query'
export const BASE_ENDPOINT = process.env.REACT_APP_SUPEBASE_URL
export const DEAL_TERM_ENDPOINT = 'https://www.deploy.tips/dealterms'
export const STARTUP_API = '/rest/v1/startup_directory'
export const SCOUT_API = '/rest/v1/users'
export const ALL_SCOUT_API = '/rest/v1/users'
export const EMAIL_API =
  'https://njspcmr2p3.execute-api.ap-east-1.amazonaws.com/v2/execute'
export const DEAL_TERM_API = '/rest/v1/deals'
export const DEAL_COMMENTS_API = '/rest/v1/deal_comments'
export const DEAL_FIELDS_API = '/rest/v1/deal_fields'
export const ALL_DEALS_INFO_API = '/rest/v1/all_deal_info'
export const DOMAINS_API = '/rest/v1/domains'
export const DEPLOY_TIPS_URL = 'https://www.deploy.tips'
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/deploycapital-online/'
export const TWITTER_URL = 'https://twitter.com/deployXYZ'
export const EMAIL_TO = 'hello@deploycapital.online'
export const EMAIL_TO_REQUEST_UPGRADE = 'iwantto@deploycapital.online'
export const WHAT_APPS_LINK = 'https://chat.whatsapp.com/CJm3ALW4uVK1fyuJYz3EeT'
