import { ReactNode } from 'react'

import {
  Grid,
  GridItem,
  GridProps,
  HStack,
  Icon,
  Image,
  StackProps,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { LOGO_PATH, NAVBAR_WIDTH } from 'constants/common'
import { NAVBAR, NAV_BAR_STYLES } from 'constants/navbar'
import { motion } from 'framer-motion'
import { useAuth, useMobile } from 'hooks'
import { HiLogout } from 'react-icons/hi'

import { WithCenterLayout } from 'components/hoc'

export interface INavBarItem {
  text: string
  icon: any
  path?: string
  title?: string
  subTitle?: string
}

const activeModeStyles = (mode: string) =>
  mode === 'light'
    ? {
        borderRadius: '60px',
        border: '1px solid rgba(255, 255, 255, 0.13)',
        backgroundColor: '#fff',
        color: '#101010',
        inactiveColor: '#fff',
      }
    : {
        borderRadius: '60px',
        border: '1px solid rgba(255, 255, 255, 0.13)',
        backgroundColor: '#fff',
        color: '#101010',
        inactiveColor: '#fff',
      }

const getItemRadius = (
  isMobile: boolean | undefined,
  isLeftActivatedItem: boolean | undefined,
  isRightActivatedItem: boolean | undefined,
) => {
  const borderBottomRightRadius = isLeftActivatedItem
    ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
    : '0'

  if (isMobile) {
    const borderTopLeftRadius = isRightActivatedItem
      ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
      : '0'

    const borderTopRightRadius = isLeftActivatedItem
      ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
      : '0'

    return {
      borderTopRightRadius,
      borderTopLeftRadius,
    }
  }

  const borderTopRightRadius = isRightActivatedItem
    ? NAVBAR.BORDER_RADIUS_RESPONSIVE_VALUES
    : '0'

  return {
    borderBottomRightRadius,
    borderTopRightRadius,
  }
}

const NewNavBar = ({ children }: { children: ReactNode }) => {
  const isMobile = useMobile()
  return (
    <Grid
      as={motion.aside}
      layout
      templateRows={[
        '3.5rem 10px',
        '3.5rem 10px',
        '3.5rem 10px',
        '3.5rem 10px',
        '15% auto auto auto',
      ]}
      templateColumns={[
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        `0px ${NAVBAR_WIDTH}px`,
      ]}
      transition="width 0.2s ease"
      h={['auto', 'auto', 'auto', 'auto', '100vh']}
      w={['100vw', '100vw', '100vw', '100vw', 'auto']}
      pos="absolute"
      zIndex={2}
      overflow="hidden"
      overflowX={['auto', 'auto', 'hidden', 'hidden', 'hidden']}
    >
      {!isMobile && <GridItem rowSpan={4} bg="newBgColor" />}
      {children}
      {isMobile && <GridItem colSpan={2} bg="newBgColor" />}
    </Grid>
  )
}

NewNavBar.Logo = (props: GridProps) => (
  <GridItem
    bg="newBgColor"
    pos="relative"
    _before={{
      width: '50px',
      height: '50px',
      content: '""',
      right: '-50px',
      position: 'absolute',
      background: '#101010',
      clipPath:
        'polygon(0 0, 80% 0%, 58% 5%, 38% 16%, 19% 31%, 5% 53%, 0% 80%, 0 0)',
      WebkitClipPath:
        'polygon(0 0, 80% 0%, 58% 5%, 38% 16%, 19% 31%, 5% 53%, 0% 80%, 0 0)',
    }}
    display={['none', 'none', 'none', 'none', 'unset']}
    {...props}
  >
    <WithCenterLayout
      w="full"
      h="full"
      ml={[0, 0, '-20px']}
      px={['10px', '10px', '10px', '10px', '0px']}
    >
      <Image
        loading="lazy"
        src={LOGO_PATH}
        alt="Deploy."
        width="9.25rem"
      />
    </WithCenterLayout>
  </GridItem>
)

NewNavBar.Item = ({
  icon: Icon,
  text,
  index,
  path,
  onClick,
  activatedNavBar,
  ...rest
}: INavBarItem & {
  index: number
  stylesProps?: StackProps
  activatedNavBar: Record<string, number | INavBarItem> | undefined
  onClick: (navbar: INavBarItem, index: number) => void
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const textByMode = useColorModeValue('navBar.iconColor', 'navBar.iconColor')

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = useMobile()
  const isActivated = index === activatedNavBar?.['index']
  const isLeftActivatedItem = index + 1 === activatedNavBar?.['index']
  const isRightActivatedItem = index - 1 === activatedNavBar?.['index']
  const isFirstActivatedItem = 0 === activatedNavBar?.['index']

  return (
    <HStack
      as="li"
      px={['3', '3', '3', '6', '6']}
      w="full"
      h="full"
      flex="1"
      padding={[
        '0.425rem 0.45rem',
        '0.425rem 0.45rem',
        '0.625rem 0.75rem',
        '0.625rem 0.75rem',
        '0.625rem 0.75rem',
      ]}
      // maxH={['full', 'full', 'full', 'full', '56px']}
      cursor="pointer"
      pos="relative"
      onClick={(e) => onClick({ text, icon: Icon, path, ...rest }, index)}
      bg={isActivated ? 'inherit' : 'newBgColor'}
      _before={
        isActivated
          ? {
              ...NAV_BAR_STYLES?._before,
              ...(isMobile
                ? {
                    transform: 'rotate(-90deg)',
                    left: 0,
                    bottom: 0,
                    top: 'unset',
                    ...(isFirstActivatedItem
                      ? {
                          w: 0,
                          h: 0,
                        }
                      : {}),
                  }
                : {}),
            }
          : {}
      }
      _after={
        isActivated
          ? {
              ...NAV_BAR_STYLES?._after,
              ...(isMobile
                ? {
                    transform: 'rotate(270deg)',
                    right: 0,
                    bottom: 0,
                    left: 'unset',
                  }
                : {}),
            }
          : {}
      }
      // {...getItemRadius(isMobile, isLeftActivatedItem, isRightActivatedItem)}
    >
      <WithCenterLayout
        w="full"
        h="full"
        justifyContent={['center', 'center', 'center', 'center', 'start']}
        gap="2px"
        p="2px 12px"
        sx={
          isActivated
            ? {
                bg: '#1F1F2F',
                borderRadius: '8px',
              }
            : {}
        }
      >
        <Icon
          boxSize={['2.25rem', '2.25rem']}
          color={!isActivated ? 'navBar.iconColor' : textByMode}
        />
        {!isMobile && (
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={!isActivated ? 'navBar.textColor' : textByMode}
          >
            {text}
          </Text>
        )}
      </WithCenterLayout>
    </HStack>
  )
}

NewNavBar.Footer = ({ shouldHasRadius }: { shouldHasRadius: boolean }) => {
  return (
    <GridItem
      bg="newBgColor"
      borderTopLeftRadius={shouldHasRadius ? '20px' : 0}
    >
      <VStack
        h="full"
        w="full"
        justifyContent={['center', 'end', 'end', 'end', 'start']}
        alignItems={['center', 'center', 'center', 'center', 'start']}
        flexDirection={['row', 'row', 'row', 'row', 'column']}
        gap={['12px', '12px', '24px', '24px', '24px']}
        // px={[2.75, 2.75, 2.75]}
        px={[7.5, 7.5, 7.5]}
      >
        {/* <ToggleMode border="common" customActiveModeStyles={activeModeStyles} /> */}
        <Logout />
      </VStack>
    </GridItem>
  )
}

const Logout = () => {
  const { onLogout } = useAuth()
  const isMobile = useMobile()
  return (
    <WithCenterLayout
      as="button"
      onClick={onLogout}
      gap={3}
      justifyContent={['center', 'center', 'center', 'center', 'start']}
    >
      <Icon
        as={HiLogout}
        boxSize={7.5}
        color="white"
        transform="rotate(180deg)"
      />
      {!isMobile && (
        <Text
          display={!isMobile ? 'auto' : 'none'}
          fontSize="lg"
          fontWeight="bold"
          color="navBar.textColor"
        >
          Logout
        </Text>
      )}
    </WithCenterLayout>
  )
}

export default NewNavBar
