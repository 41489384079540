import { ReactNode } from 'react'

import { Text, TextProps } from '@chakra-ui/react'

const Label = ({ children, ...rest }: { children: ReactNode } & TextProps) => {
  return (
    <Text
      color="#717A8C"
      fontSize="md"
      fontWeight="semibold"
      lineHeight="normal"
      {...rest}
      sx={{
        textWrap: ['nowrap', 'nowrap', 'nowrap', 'nowrap', 'nowrap', 'nowrap'],
      }}
    >
      {children}
    </Text>
  )
}

export default Label
