import { useEffect, useState } from 'react'

import { useBoolean } from '@chakra-ui/react'

import { PostgrestResponse, Session, User } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { useAuth, useMounted } from 'hooks'
import { IUser } from 'interfaces/models'

export type TUserSetting = (
  | {
      user: User
      session: Session
    }
  | {
      user: null
      session: null
    }
) & {
  role: string | null
} & IUser

const useUserSettings = () => {
  const { getUser } = useAuth()
  const [userSettings, setUserSettings] = useState<TUserSetting | null>()
  const [isLoading, setIsLoading] = useBoolean()
  const isMounted = useMounted()
  const user = getUser()
  const settingQuery = supabase
    .from('users')
    .select('*')
    .eq('user_id', user?.user?.id)
    .limit(1)

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        setIsLoading.on()
        const { data, error }: PostgrestResponse<IUser> = await settingQuery
        if (error) throw error

        setUserSettings({
          ...user,
          ...data?.[0],
        })
      } catch (e) {
        setUserSettings(null)
      } finally {
        setIsLoading.off()
      }
    }

    isMounted && fetchUserSettings()
  }, [isMounted])

  return { userSettings, isLoading }
}

export default useUserSettings
