import { Icon } from '@chakra-ui/react'

const LinkedIn = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.44434 8.89008C6.64341 8.89008 6 8.24178 6 7.44434C6 6.64761 6.64411 6 7.44434 6C8.24248 6 8.88938 6.64761 8.88938 7.44434C8.88938 8.24178 8.24178 8.89008 7.44434 8.89008ZM18.0234 18.0052H15.5352V14.1063C15.5352 13.1765 15.5162 11.98 14.2385 11.98C12.9412 11.98 12.7431 12.9917 12.7431 14.0377V18.0052H10.2549V9.98748H12.6451V11.0804H12.6773C13.0112 10.4503 13.8234 9.78514 15.0367 9.78514C17.5578 9.78514 18.0241 11.4444 18.0241 13.6043V18.0052H18.0234ZM6.19696 18.0053H8.69217V9.98757H6.19696V18.0053Z"
        fill="black"
      />
    </Icon>
  )
}

export default LinkedIn
