import { Resolver } from 'react-hook-form'
import * as yup from 'yup'

import { IContactInfo } from '../components/ContactInfo'

const companyFormRules = {
  phone_number: yup.string().nullable(),
  // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
  // .required('Phone number is required'),
}

export const companyFormSchema = yup.object(companyFormRules)

export const resolver: Resolver<IContactInfo> = async (values) => {
  try {
    await companyFormSchema.validate(values, { abortEarly: false })
    return { values, errors: {} }
  } catch (validationErrors: any) {
    return {
      values: {},
      errors: validationErrors?.inner?.reduce(
        (allErrors: any, currentError: any) => ({
          ...allErrors,
          [currentError.path!]: currentError.message,
        }),
        {},
      ),
    }
  }
}
