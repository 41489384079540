import React from 'react'

import { HStack, StackProps, VStack } from '@chakra-ui/react'

import useMobile from 'hooks/useMobile'

type TWithResponsiveLayout = {
  children: React.ReactNode
  point?: string
}

const WithResponsiveLayout: React.FC<TWithResponsiveLayout & StackProps> = ({
  children,
  point,
  ...rest
}) => {
  const isMobile = useMobile(point)

  const Layout = isMobile ? VStack : HStack
  return <Layout {...rest}>{children}</Layout>
}

export default WithResponsiveLayout
