import {
  Badge,
  HStack,
  Progress,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { ISelectOption } from 'interfaces/select'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import { CellText } from 'components/common/DLGrid/GridCell'
import { EllipseNumber } from 'components/common/other/EllipseNumber'
import { MavenRating } from 'components/common/other/MavenRating'
import { DotTagForSectorPath } from 'components/common/other/deal-details/display/SectorsPath'
import { WithCenterLayout } from 'components/hoc'

import { showDomains } from '../utils'

export const FIELDS_RENDER = {
  deal_rank: (rowValue: string) => {
    return (
      <WithCenterLayout gap={1} alignItems="center" p={4}>
        <EllipseNumber
          number={rowValue}
          fontSize={['lg', 'lg', 'lg', 'xl', 'xl', 'xl']}
        />
      </WithCenterLayout>
    )
  },
  maven_rating_avg_in_sector: ({
    maven_rating_avg_in_sector,
    percent_mavens_have_evaluated,
    total_num_mavens_for_deal,
  }: {
    total_num_mavens_for_deal: number
    maven_rating_avg_in_sector: number
    percent_mavens_have_evaluated: number
  }) => {
    return (
      <WithCenterLayout gap={1} alignItems="center" p={4}>
        <MavenRating
          rate={maven_rating_avg_in_sector}
          percent={percent_mavens_have_evaluated}
          total_num_mavens_for_deal={total_num_mavens_for_deal}
        />
      </WithCenterLayout>
    )
  },
  company_name: (rowValue: string) => {
    return (
      <WithCenterLayout gap={1} alignItems="center" p={4}>
        <CellText text={rowValue} />
      </WithCenterLayout>
    )
  },
  num_of_investors_added_deal: (rowValue: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const clByMode = useColorModeValue(
      'background.lightMode',
      'background.darkMode',
    )
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const rightColorTextByMode = useColorModeValue(
      'rgba(255, 255, 255, 0.60)',
      'rgba(16, 16, 16, 0.60)',
    )
    return (
      <WithCenterLayout gap={1} alignItems="center">
        <VStack gap={1} w="full">
          <HStack
            // fontSize="0.625rem"
            fontWeight="bold"
            // lineHeight="140%"
            // gap={1}
          >
            <Text color={clByMode}>
              {/* {parseFloat(rowValue ?? 0).toFixed(1) ?? 0}% */}
              {rowValue ?? 0}
            </Text>
            {/* <Text color={rightColorTextByMode}>left</Text> */}
          </HStack>
          {/* <Progress
            w={['full', 'full', '60%', '60%', '60%']}
            colorScheme="progressColor"
            size="sm"
            borderRadius="full"
            value={100 - parseFloat(rowValue)}
            sx={{
              bg: 'rgb(0 0 0 / 16%)',
              _light: { bg: 'white' },
            }}
          /> */}
        </VStack>
      </WithCenterLayout>
    )
  },
  sector: (rowValue: string) => {
    const badges = uniq(showDomains(rowValue))?.filter((item: string) => !!item)
    return (
      <WithCenterLayout gap={0} p={4}>
        <VStack alignItems="center" gap={1}>
          {badges?.map((badge: string) => {
            return (
              <Badge
                variant="boxy"
                bgColor="#3498db"
                key={badge}
                whiteSpace="pretty"
                size={['xs', 'sm', 'md', 'md', 'xl']}
              >
                <Text>{badge}</Text>
              </Badge>
            )
          })}
        </VStack>
      </WithCenterLayout>
    )
  },
}

const getUniqueFirstSegments = (array: string[]): string[] => {
  return uniqBy(
    array.map((str) => str.split(' > ')[0]),
    (segment) => segment,
  )
}

export const showDomainsCell = (rowValue: string) => {
  const badges = uniq(showDomains(rowValue))?.filter((item: string) => !!item)

  return (
    <VStack alignItems="center" justifyContent="center" gap={2} w="full">
      {getUniqueFirstSegments(badges)?.map((badge: string) => {
        return (
          <VStack gap={0} alignItems="center" justifyContent="center" w="full">
            <DotTagForSectorPath sector={badge} />
            {/* <Badge
              variant="boxy"
              key={badge}
              whiteSpace="pretty"
              size={['xs', 'sm', 'md', 'md', 'xl']}
              bgColor="#3498db"
            >
              <Text>{badge}</Text>
            </Badge> */}
          </VStack>
        )
      })}
    </VStack>
  )
}

export const FIELDS = [
  {
    field: 'deal_rank',
    label: 'Rank',
    hasSorting: true,
  },
  {
    field: 'company_name',
    label: 'Company Name',
  },
  {
    field: 'maven_rating_avg_in_sector',
    label: 'Expert Rating (/5)',
    hasSorting: true,
  },
  // {
  //   field: 'confidence',
  //   label: 'Confidence',
  // },
  {
    field: 'fundraising_round',
    label: 'Round',
  },
  {
    field: 'preferred_instrument',
    label: 'Instrument',
  },
  {
    field: 'current_raise',
    label: 'Current Raise',
    type: 'number',
    hasSorting: true,
  },
  {
    field: 'minimum_ticket_size',
    label: 'Min. Ticket Size',
    type: 'number',
  },
  {
    field: 'num_of_investors_added_deal',
    label: 'Demand',
  },
  // {
  //   field: 'sector',
  //   label: 'Sector',
  // },
  {
    field: 'domain',
    label: 'Sector',
  },
]

export const DEAL_FIELDS_992px = FIELDS.filter(
  (field) =>
    !['current_raise', 'minimum_ticket_size', 'available_tickets'].includes(
      field?.field,
    ),
)

export const DEAL_FIELDS_480px = FIELDS.filter((field) =>
  ['deal_rank', 'company_name', 'maven_rating', 'domain'].includes(
    field?.field,
  ),
)

export const DEAL_ACCORDION_FIELDS = {
  consider_instruments: 'consider_instruments',
  estimated_arr: 'estimated_arr',
  post_money_valuation: 'post_money_valuation',
  pre_money_valuation: 'pre_money_valuation',
  preferred_instrument: 'preferred_instrument',
  available_tickets: 'Available Tickets',
  company_maturity: 'Company Maturity',
  tech_used: 'Tech Used',
  origin_of_funds: 'Fundraising',
  business_model: 'Business Model',
  product_type: 'Product Type',
}

export const DEAL_ADDITIONAL_ACCORDION_FIELDS = {
  GreaterThan768: {
    current_raise: 'Current Raise',
    minimum_ticket_size: 'Min. Ticket Size',
    available_tickets: 'Available Tickets',
  },
  LessThan480: {
    fundraising_round: 'Round',
    preferred_instrument: 'Preferred Instrument',
    current_raise: 'Current Raise',
    minimum_ticket_size: 'Min. Ticket Size',
    available_tickets: 'Available Tickets',
  },
} as Record<string, any>

export const FILTER_BY_DOMAIN = 'domain'

export const VIEW_DEALS_LEADER_BOARD_MAVEN_RATING =
  // 'web_investor_view_deal_avg_maven_rating'
  'web_view_expanded_deal_avg_maven_rating'

export const VIEW_FEATURED_SCOUTS = 'web_view_featured_scouts_section'

export const VIEW_DEALS_LEADER_BOARD_BY_SECTOR = 'web_view_deal_avg_by_sector'
export const VIEW_DOMAINS = 'view_deal_sectors'
export const VIEW_DOMAINS_FULL_PATH = 'matview_subdomain_with_full_path'

export const SORT_OPTIONS: ISelectOption[] = [
  { label: 'Rank', value: 'deal_rank' },
  { label: 'Expert Rating', value: 'rating' },
  { label: 'Fundraising', value: 'fundraising' },
]

export const MIN_TICKET_SIZE_OPTIONS: ISelectOption[] = [
  { label: '< 100,000', value: '<100000' },
  { label: '100,000 - 500,000', value: '100000-500000' },
  { label: '500,000+', value: '500,000+' },
]

export type TTicketSizeFilter = Record<string, string | number | number[]>
export type TLeaderBoardFilter = Record<
  string,
  TTicketSizeFilter | string | number
>

export const TICKET_SIZE_FILTER_MAPPING: TLeaderBoardFilter = {
  '<100000': {
    operator: 'lt',
    value: 100000,
  },
  '100000-500000': {
    operator: 'between',
    value: [100000, 500000],
  },
  '500,000+': {
    operator: 'gt',
    value: 500000,
  },
}
