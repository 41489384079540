import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { EMAIL_TO, LINKEDIN_URL, WHAT_APPS_LINK } from 'constants/endpoints'
import useDLToast from 'hooks/useDLToast'
import uniqueId from 'lodash/uniqueId'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { ControlledField, Field } from 'components/common/DLForms'
import { TextInput } from 'components/common/DLInput'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import { TABLES_NAME } from '../../../constants/tables'
import SmallText from '../components/common/SmallText'
import UnlockingPotentialPrivateCapital from '../components/common/UnlockingPotentialPrivateCapital'
import { Footer } from '../components/v2/Footer'
import {
  CONTACT_FORM_DEFAULT_VALUES,
  FAQ,
  IContactInfo,
  REGISTER_CONTACT_FAIL_MESSAGE,
  REGISTER_CONTACT_SUCCESS_MESSAGE,
} from '../constants/connect'
import { resolver } from '../validation/contact-form'

const IntelligenceNetwork = () => {
  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      w="full"
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#8B9DFB"
            fontSize="20px"
            fontWeight="medium"
            lineHeight="110%"
            textAlign="center"
          >
            The future of venture deal sourcing & research
          </Text>

          <Text
            color="#FFF"
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
            textAlign="center"
          >
            Deploy with us.
          </Text>
        </VStack>

        <Button
          minW="300px"
          color="#1A202A"
          bg="#fff"
          minH="60px"
          borderRadius="60px"
          fontSize="20px"
        >
          request demo
        </Button>
      </VStack>

      <WithCenterLayout w="full">
        <Image
          src="images/deploy-withus.png"
          w={['80%', '80%', '80%', 'auto', 'auto', 'auto']}
        />
      </WithCenterLayout>

      {/* <Box
        border="5px solid rgba(79, 70, 229, 0.40)"
        w="256px"
        h="256px"
        zIndex={0}
        pos="relative"
        mt="40px"
      >
        <Box
          border="5px solid rgba(79, 70, 229, 0.60)"
          w="232px"
          h="232px"
          zIndex={1}
          transform="rotate(75deg)"
          transformOrigin="center"
          pos="absolute"
        />

        <Box
          border="5px solid rgba(79, 70, 229, 0.80)"
          w="208px"
          h="208px"
          zIndex={2}
          transform="rotate(60deg)"
          transformOrigin="center"
          pos="absolute"
        />

        <Box
          border="5px solid #582CFF"
          w="184px"
          h="184px"
          zIndex={3}
          transform="rotate(45deg)"
          transformOrigin="center"
          pos="absolute"
        />
        <Box
          border="5px solid #312E81"
          bg="#312E81"
          w="160px"
          h="160px"
          zIndex={4}
          transform="rotate(30deg)"
          transformOrigin="center"
          pos="absolute"
        />
      </Box> */}
    </VStack>
  )
}

const ContactForm = () => {
  const { showErrorToast, showSuccessToast } = useDLToast()
  const methods = useForm<IContactInfo>({
    defaultValues: CONTACT_FORM_DEFAULT_VALUES,
    resolver,
    mode: 'onChange',
    shouldFocusError: true,
  })

  const {
    formState: { errors },
  } = methods

  const handleSubmit: SubmitHandler<IContactInfo> = async (
    formValues: IContactInfo,
  ) => {
    try {
      const { is_read_term_and_conditions, ...payload } = formValues

      const { error } = await supabase
        .from(TABLES_NAME.contact_forms)
        .insert(payload)

      if (error) {
        showErrorToast(REGISTER_CONTACT_FAIL_MESSAGE)
        return
      }
      methods?.reset()
      showSuccessToast(REGISTER_CONTACT_SUCCESS_MESSAGE)
    } catch (err) {
      showErrorToast(REGISTER_CONTACT_FAIL_MESSAGE)
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ maxWidth: '800px' }}
        onSubmit={methods?.handleSubmit(handleSubmit)}
        autoComplete="off"
      >
        <VStack gap={7} p={['0px', '0px', '20px', '40px', '40px', '40px']}>
          <WithResponsiveLayout gap="28px" w="full">
            <Field
              label="Given Name *"
              name="first_name"
              component={TextInput}
              size={['sm', 'md', 'md']}
              border="unset !important"
              borderRadius="6px !important"
            />

            <Field
              label="Last Name *"
              name="last_name"
              component={TextInput}
              size={['sm', 'md', 'md']}
              border="unset !important"
              borderRadius="6px !important"
            />
          </WithResponsiveLayout>

          <WithResponsiveLayout gap="28px" w="full">
            <Field
              label="Email Address *"
              name="email"
              type="email"
              component={TextInput}
              size={['sm', 'md', 'md']}
              autoComplete="false"
              border="unset !important"
              borderRadius="6px !important"
            />

            <Field
              label="Phone Number *"
              name="phone_number"
              component={TextInput}
              size={['sm', 'md', 'md']}
              border="unset !important"
              borderRadius="6px !important"
            />
          </WithResponsiveLayout>

          <WithResponsiveLayout gap="28px" w="full">
            <Field
              label="Company *"
              name="company_name"
              component={TextInput}
              size={['sm', 'md', 'md']}
              border="unset !important"
              borderRadius="6px !important"
            />

            <Field
              label="Job Title *"
              name="job_title"
              component={TextInput}
              size={['sm', 'md', 'md']}
              border="unset !important"
              borderRadius="6px !important"
            />
          </WithResponsiveLayout>

          <WithResponsiveLayout gap="28px" w="full">
            <VStack w="full" alignItems="start">
              <Field
                label="Message *"
                name="message"
                badgeForLabel={
                  <Text fontSize="10px" color="#A0AEC0" fontWeight="400">
                    500 Character Limit
                  </Text>
                }
                component={Textarea}
                // placeholder="What is your elevator pitch? (500 characters or less)"
                borderRadius="md"
                _placeholder={{
                  color: 'dark.300',
                  fontSize: '12px',
                }}
                size={['sm', 'md', 'md', 'lg', 'lg', 'lg']}
                sx={{
                  h: '200px',
                  bg: 'rgba(255, 255, 255, 0.25)',
                  border: 'unset',
                }}
              />
            </VStack>
          </WithResponsiveLayout>

          <ControlledField
            component={({ value, ...rest }: { value: any }) => {
              return (
                <Checkbox
                  sx={{
                    '&>[data-checked].chakra-checkbox__control': {
                      bg: 'accent.main01',
                      borderColor: 'accent.main01',
                    },
                    '&>.chakra-checkbox__control': {
                      borderColor: 'background.lightMode',
                    },
                  }}
                  size="sm"
                  value={value}
                  {...rest}
                >
                  <Text color="white">
                    I understand and accept Deploy’s Terms and Conditions and
                    Privacy Policy.
                  </Text>
                </Checkbox>
              )
            }}
            name="is_read_term_and_conditions"
          />

          <SmallText fontSize={['md', 'md', 'md', '18px', '18px', '18px']}>
            By clicking submit below, you consent to allow Deploy to store and
            process the personal information submitted above to provide you the
            content requested.
          </SmallText>

          <Button
            minW="300px"
            color="#1A202A"
            bg="#fff"
            minH="60px"
            borderRadius="60px"
            fontSize="20px"
            type="submit"
          >
            Submit
          </Button>
        </VStack>
      </form>
    </FormProvider>
  )
}

const ContactUs = () => {
  return (
    <VStack
      bg="#1F1F2F"
      gap="32px"
      alignItems="center"
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="8px" alignItems="center">
        <Text
          color="#FFF"
          fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
          fontWeight="bold"
          lineHeight="110%"
        >
          Contact Us
        </Text>
        <SmallText textAlign="center">
          If you’re interested in joining the movement to revolutionize the
          innovation economy, shoot us a message!
        </SmallText>
      </VStack>

      <WithResponsiveLayout
        p={['0px', '0px', '20px', '40px', '40px', '40px']}
        gap={['20px', '20px', '20px', '40px', '40px', '40px']}
        alignItems="start"
      >
        <ContactForm />
        <VStack
          gap={['12px', '12px', '12px', '20px', '24px', '24px']}
          alignItems="start"
          lineHeight="normal"
        >
          <VStack
            gap="8px"
            fontSize={['24px', '24px', '24px', '32px', '32px', '32px']}
            fontWeight="semibold"
            alignItems="start"
            py={['10px', '10px', '10px', '20px', '20px', '20px']}
          >
            <Text color="#fff" lineHeight="110%">
              Want to reach us directly?
            </Text>

            <Text color="#818CF8" lineHeight="110%" textAlign="center">
              Get in touch:
            </Text>
          </VStack>

          <Link href={`mailto:${EMAIL_TO}`}>
            <HStack gap={['20px', '20px', '20px', '40px', '40px', '40px']}>
              <WithCenterLayout>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 72 72"
                  fill="none"
                >
                  <circle cx="36" cy="36" r="36" fill="#1F1F2F" />
                  <path
                    d="M46.2905 22.6668H24.3775C22.6966 22.6668 21.334 24.0344 21.334 25.7213V28.1649C21.3339 28.3885 21.4556 28.5942 21.6511 28.7013L35.0424 36.0322C35.2241 36.1317 35.4438 36.1317 35.6255 36.0322L49.0168 28.7013C49.2124 28.5942 49.334 28.3885 49.334 28.1649V25.7213C49.334 24.0344 47.9714 22.6668 46.2905 22.6668Z"
                    fill="#8B9DFB"
                  />
                  <path
                    d="M36.2087 37.5624C35.6641 37.855 35.0063 37.855 34.4617 37.5624L21.348 30.5081L21.334 30.5171V43.1859C21.334 44.844 22.6966 46.1881 24.3775 46.1881H46.2905C47.9714 46.1881 49.334 44.844 49.334 43.1859V30.5171L49.32 30.5087L36.2087 37.5624Z"
                    fill="#8B9DFB"
                  />
                </svg>
              </WithCenterLayout>

              <Text
                color="#fff"
                fontWeight="bold"
                fontSize={['20px', '20px', '20px', '24px', '24px', '24px']}
              >
                Email us
              </Text>
            </HStack>
          </Link>
          <Link href={LINKEDIN_URL}>
            <HStack gap={['20px', '20px', '20px', '40px', '40px', '40px']}>
              <WithCenterLayout w="72px" h="72px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <path
                    d="M26.023 0.859375H2.16289C1.01992 0.859375 0.0957031 1.76172 0.0957031 2.87734V26.8359C0.0957031 27.9516 1.01992 28.8594 2.16289 28.8594H26.023C27.166 28.8594 28.0957 27.9516 28.0957 26.8414V2.87734C28.0957 1.76172 27.166 0.859375 26.023 0.859375ZM8.40273 24.7195H4.24648V11.3539H8.40273V24.7195ZM6.32461 9.53281C4.99023 9.53281 3.91289 8.45547 3.91289 7.12656C3.91289 5.79766 4.99023 4.72031 6.32461 4.72031C7.65352 4.72031 8.73086 5.79766 8.73086 7.12656C8.73086 8.45 7.65352 9.53281 6.32461 9.53281ZM23.9559 24.7195H19.8051V18.2227C19.8051 16.675 19.7777 14.6789 17.6449 14.6789C15.4848 14.6789 15.1566 16.3687 15.1566 18.1133V24.7195H11.0113V11.3539H14.9926V13.1805H15.0473C15.5996 12.1305 16.9559 11.0203 18.9738 11.0203C23.1793 11.0203 23.9559 13.7875 23.9559 17.3859V24.7195V24.7195Z"
                    fill="#8B9DFB"
                  />
                </svg>
              </WithCenterLayout>

              <Text
                color="#fff"
                fontWeight="bold"
                fontSize={['20px', '20px', '20px', '24px', '24px', '24px']}
              >
                Follow us
              </Text>
            </HStack>
          </Link>
          <Link href={`mailto:${WHAT_APPS_LINK}`}>
            <HStack gap={['20px', '20px', '20px', '40px', '40px', '40px']}>
              <WithCenterLayout w="72px" h="72px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_743_17690)">
                    <path
                      d="M26.8335 24.5V22.1667C26.8327 21.1327 26.4886 20.1282 25.8551 19.311C25.2216 18.4938 24.3346 17.9102 23.3335 17.6517"
                      stroke="#8B9DFB"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.8332 24.5V22.1667C19.8332 20.929 19.3415 19.742 18.4663 18.8668C17.5912 17.9917 16.4042 17.5 15.1665 17.5H5.83317C4.59549 17.5 3.40851 17.9917 2.53334 18.8668C1.65817 19.742 1.1665 20.929 1.1665 22.1667V24.5"
                      stroke="#8B9DFB"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.6665 3.65167C19.6703 3.90869 20.56 4.49249 21.1954 5.31103C21.8308 6.12958 22.1757 7.13631 22.1757 8.17251C22.1757 9.2087 21.8308 10.2154 21.1954 11.034C20.56 11.8525 19.6703 12.4363 18.6665 12.6933"
                      stroke="#8B9DFB"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5002 12.8333C13.0775 12.8333 15.1668 10.744 15.1668 8.16667C15.1668 5.58934 13.0775 3.5 10.5002 3.5C7.92283 3.5 5.8335 5.58934 5.8335 8.16667C5.8335 10.744 7.92283 12.8333 10.5002 12.8333Z"
                      stroke="#8B9DFB"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_743_17690">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </WithCenterLayout>
              <Text
                color="#fff"
                fontWeight="bold"
                fontSize={['20px', '20px', '20px', '24px', '24px', '24px']}
              >
                Join our Whatsapp Group
              </Text>
            </HStack>
          </Link>
        </VStack>
      </WithResponsiveLayout>
    </VStack>
  )
}

const FAQs = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      w="full"
    >
      <VStack gap="80px" flex="1">
        <VStack gap="8px" alignItems="center" w="full" textAlign="center">
          <Text
            color="#8B9DFB"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="110%"
            bg="linear-gradient(90deg, #582CFF 0%, #8B9DFB 67%)"
            backgroundClip="text"
          >
            FAQs
          </Text>
          <SmallText>
            If you can’t find what you’re looking for, get in touch with us.
          </SmallText>
        </VStack>

        <VStack gap="0" w="full" color="white">
          <Accordion allowToggle justifyContent="space-between" w="full">
            {FAQ.map(({ question, content }, index) => (
              <AccordionItem
                p="32px 8px"
                border="unset"
                borderBottom="1px solid rgba(255, 255, 255, 0.20)"
                key={uniqueId()}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize={[
                            '20px',
                            '20px',
                            '20px',
                            '28px',
                            '28px',
                            '28px',
                          ]}
                          letterSpacing="0.28px"
                          lineHeight="30px"
                        >
                          {question}
                        </Box>

                        <Box w="27px" h="27px">
                          {!isExpanded && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="27"
                              height="28"
                              viewBox="0 0 27 28"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_845_600)">
                                <rect
                                  x="13"
                                  y="0.5"
                                  width="2"
                                  height="27"
                                  rx="1"
                                  fill="white"
                                />
                                <rect
                                  y="13.5"
                                  width="27"
                                  height="2"
                                  rx="1"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_845_600">
                                  <rect
                                    width="27"
                                    height="27"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                          {isExpanded && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="27"
                              height="28"
                              viewBox="0 0 27 28"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_845_563)">
                                <rect
                                  x="0.5"
                                  y="15"
                                  width="2"
                                  height="27"
                                  rx="1"
                                  transform="rotate(-90 0.5 15)"
                                  fill="white"
                                />
                                <rect
                                  y="13.5"
                                  width="27"
                                  height="2"
                                  rx="1"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_845_563">
                                  <rect
                                    width="27"
                                    height="27"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <SmallText color="white">{content}</SmallText>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>
      </VStack>
    </WithResponsiveLayout>
  )
}

const GetScoutNetWork = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <WithCenterLayout flex="1" alignSelf="stretch">
        <Image src="images/get-scout-network.png" />
      </WithCenterLayout>

      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#8B9DFB"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="110%"
          >
            Don’t miss deals.
          </Text>
          <Text
            color="#FFF"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="110%"
          >
            Get the most out of your scout network.
          </Text>
        </VStack>

        <SmallText>
          In our experience, many people contribute to the investment process
          along the way - introductions, referrals, expert advice, and mentoring
          - often not directly benefiting from their involvement. The venture
          capital and wider private equity industry is evolving and Deploy is
          building the tools to accelerate that movement.
        </SmallText>
        <SmallText>
          In an era, when simple acts like browsing photos or shopping online
          can create powerful data sets and insights about individuals, so too
          can the granular contributions of participants in nearly any process.
        </SmallText>

        <Stack gap="4px" w="full">
          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>
            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Data Integration - Plug into any existing workflows
            </Text>
          </HStack>

          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>
            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Custom Industry Reports - get instant insights on your deals
            </Text>
          </HStack>

          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>

            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Expand Network - instantly ramp up your resources
            </Text>
          </HStack>
        </Stack>
      </VStack>
    </WithResponsiveLayout>
  )
}

const ConnectPage = () => {
  return (
    <>
      <IntelligenceNetwork />
      <VStack gap="20px" maxW="1600px">
        <ContactUs />
        <FAQs />
        <UnlockingPotentialPrivateCapital />
      </VStack>
      <Footer />
    </>
  )
}

export default ConnectPage
