import React, { ReactNode } from 'react'

import { VStack, useColorModeValue } from '@chakra-ui/react'

import { useLocation } from 'react-router'

import Header, { HeaderLeft, HeaderRight } from './Header'

const PublicLayout = ({ children }: { children: ReactNode }) => {
  const bgByMode = useColorModeValue('darkMain.500', 'white')
  const location = useLocation()
  const isLoginPage = location.pathname === '/login'
  const BG_IMAGE =
    'linear-gradient(to top, #0d091f, #15193c, #19275c, #18357f, #0845a3)'

  return (
    <React.Fragment>
      <VStack
        gap="0"
        pos="relative"
        bg={!isLoginPage ? bgByMode : 'unset'}
        bgImage={isLoginPage ? BG_IMAGE : 'unset'}
      >
        {!isLoginPage && (
          <Header>
            <HeaderLeft />
            <HeaderRight />
          </Header>
        )}
        {children}
      </VStack>
    </React.Fragment>
  )
}

export default PublicLayout
