import { Fragment, ReactNode, memo } from 'react'

import { Box, GridItem, Text, useColorModeValue } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

import { useFormatCell } from 'modules/dashboard/hooks'

export interface IGridCell {
  title?: string
  value?: string
  shouldBeGridItem?: boolean
  render?: JSX.Element | React.FC<string | number | undefined>
  children?: ReactNode
  cellStyles?: { [key: string]: string | number }
  type?: string
}

export const CellText = ({ text }: { text: string }) => {
  const colorByMode = useColorModeValue('row.color', '#101010')

  return (
    <Text
      fontSize={['sm', 'sm', 'sm', 'sm', 'lg']}
      color={colorByMode}
      fontWeight="bold"
      textAlign="center"
    >
      {text}
    </Text>
  )
}

const GridCell = ({
  title,
  value,
  children,
  render,
  shouldBeGridItem = false,
  cellStyles,
  type,
  onClick,
}: IGridCell & {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const formattedValue = useFormatCell(value, type ?? '')

  if (render) {
    return (
      <WithCenterLayout cursor="pointer" onClick={onClick}>
        {typeof render === 'function' ? render(value, children) : render}
      </WithCenterLayout>
    )
  }

  const cellJsx = (
    <WithCenterLayout
      // p={4}
      gap={4}
      bg="grid.header.bg"
      borderRadius="md"
      w="full"
      h="full"
      // justifyContent="start"
      {...cellStyles}
    >
      {value && <CellText text={formattedValue} />}
      {children}
    </WithCenterLayout>
  )

  const Wrap = shouldBeGridItem ? GridItem : Fragment

  return (
    <Wrap
      onClick={onClick}
      cursor={typeof onClick === 'function' ? 'pointer' : undefined}
    >
      {cellJsx}
    </Wrap>
  )
}

export default memo(GridCell)
