import { useEffect } from 'react'

import { AuthTokenResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { useAuth } from 'hooks'

export default function useAuthSupeBase() {
  const { setUser } = useAuth()

  // Login function
  const login = async (email: string, password: string) => {
    const res: AuthTokenResponse = await supabase.auth.signInWithPassword({
      email,
      password,
    })
    setUser({
      ...res?.data,
      role: '',
      metadata: undefined,
    })

    return res
  }

  // Logout function
  const logout = async () => {
    await supabase.auth.signOut()
    setUser(null)
  }

  // Sign up function
  const signUp = async (email: string, password: string) => {
    await supabase.auth.signUp({ email, password })
  }

  const updatePassword = async (password: string) =>
    supabase.auth.updateUser({ password })

  // Check user authentication on initial load
  useEffect(() => {
    // const session = supabase.auth.getSession()
    // setUser({ user: null, session })
  }, [setUser])

  return { login, logout, signUp, updatePassword }
}
