import { ReactNode } from 'react'

import { Box, HStack, Text, VStack } from '@chakra-ui/react'

import { getFirstSevenCharacters } from 'utils/string'

import { UserID } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import { SECTORS_MAP_DOT_COLORS } from '../../../../constants/common'
import { TLatestDealActivities } from '../../interfaces/deal-detail'

const Domain = ({ sectorName }: { sectorName: string }) => {
  const color = SECTORS_MAP_DOT_COLORS?.[sectorName] as string

  return (
    <HStack gap="8px">
      <Box borderRadius="50%" bg={color} minW="12px" minH="12px" />
      <Text fontSize="10px" fontWeight="normal" color="#FFF">
        {sectorName}
      </Text>
    </HStack>
  )
}

const TopDomain = ({ children }: { children: ReactNode }) => {
  return (
    <VStack gap="4px" alignItems="start">
      <Text fontSize="sm" fontWeight="extrabold">
        Top Domains
      </Text>
      {children}
    </VStack>
  )
}

const Experience = ({
  value,
  text,
}: {
  value: string | number
  text: string
}) => {
  return (
    <HStack gap="8px">
      <Text fontSize="12px" fontWeight="bold" color="#FFF">
        {value}
      </Text>
      <Text fontSize="10px" fontWeight="normal" color="#FFF">
        {text}
      </Text>
    </HStack>
  )
}

const Experiences = ({ children }: { children: ReactNode }) => {
  return (
    <VStack gap="4px" alignItems="start">
      <Text fontSize="sm" fontWeight="extrabold">
        Experiences
      </Text>
      {children}
    </VStack>
  )
}

const ReviewerProfile = ({ children }: { children: ReactNode }) => {
  return (
    <VStack gap="1rem" alignItems="start" minW="150px">
      {children}
    </VStack>
  )
}

const ReviewerProfileAndOpinions = ({ children }: { children: ReactNode }) => {
  return (
    <VStack gap="1rem" alignItems="start" w="full">
      {children}
    </VStack>
  )
}

const Rate = ({ rate }: { rate: number | string }) => {
  return (
    <WithCenterLayout
      p="4px 12px"
      borderRadius="8px"
      border="2px solid #F0C151"
      bg="rgba(240, 193, 81, 0.15)"
    >
      <Text fontSize="lg" fontWeight="semibold">
        {rate}
      </Text>
    </WithCenterLayout>
  )
}

const Flag = () => {
  return (
    <VStack gap="6px" color="rgba(255, 255, 255, 0.50)" alignItems="start">
      <HStack p="4px 8px" bg="#747D80" borderRadius="4px">
        <Text fontSize="10px" fontWeight="normal">
          🇯🇵 Japan
        </Text>
      </HStack>

      <Text fontSize="8px" fontWeight="normal">
        July 25, 2021 2:30 PM
      </Text>
    </VStack>
  )
}

const Overview = ({
  children,
  rate,
}: {
  rate: number | string
  children?: ReactNode
}) => {
  return (
    <HStack gap="1rem" justifyContent="space-between" w="full">
      <HStack gap="24px">
        <Rate rate={rate} />
        {/* <Flag /> */}
      </HStack>
      <Box w="20px" h="20px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M9.99967 2.85938C9.08301 2.85938 8.33301 3.60937 8.33301 4.52604C8.33301 5.44271 9.08301 6.19271 9.99967 6.19271C10.9163 6.19271 11.6663 5.44271 11.6663 4.52604C11.6663 3.60937 10.9163 2.85938 9.99967 2.85938ZM9.99967 14.526C9.08301 14.526 8.33301 15.276 8.33301 16.1927C8.33301 17.1094 9.08301 17.8594 9.99967 17.8594C10.9163 17.8594 11.6663 17.1094 11.6663 16.1927C11.6663 15.276 10.9163 14.526 9.99967 14.526ZM9.99967 8.69271C9.08301 8.69271 8.33301 9.44271 8.33301 10.3594C8.33301 11.276 9.08301 12.026 9.99967 12.026C10.9163 12.026 11.6663 11.276 11.6663 10.3594C11.6663 9.44271 10.9163 8.69271 9.99967 8.69271Z"
            fill="white"
          />
        </svg>
      </Box>
      {children}
    </HStack>
  )
}

const ICONS = {
  positives: (
    <Box minW="12px" minH="12px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M9 6.02604L6.33333 8.69271L5 7.35938M7 13.3594C3.68629 13.3594 1 10.6731 1 7.35938C1 4.04567 3.68629 1.35938 7 1.35938C10.3137 1.35938 13 4.04567 13 7.35938C13 10.6731 10.3137 13.3594 7 13.3594Z"
          stroke="#8DFD7C"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  ),
  toImprove: (
    <Box w="12px" h="12px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M7 4.99251V7.65918M3.88782 1.98438L1.625 4.2472L1.62295 4.24912C1.39371 4.47836 1.27882 4.59325 1.19661 4.7274C1.12351 4.8467 1.0695 4.97691 1.03684 5.11296C1 5.26641 1 5.42941 1 5.75553V8.96322C1 9.28934 1 9.45239 1.03684 9.60584C1.0695 9.74189 1.12351 9.87191 1.19661 9.99121C1.27904 10.1257 1.39455 10.2412 1.625 10.4717L3.88782 12.7345C4.11842 12.9651 4.23347 13.0802 4.36803 13.1626C4.48732 13.2357 4.61721 13.2899 4.75326 13.3226C4.90647 13.3594 5.06922 13.3594 5.39434 13.3594H8.60501C8.93014 13.3594 9.09357 13.3594 9.24679 13.3226C9.38284 13.2899 9.51287 13.2357 9.63216 13.1626C9.76672 13.0802 9.88203 12.9651 10.1126 12.7345L12.3754 10.4717C12.6061 10.2411 12.7211 10.1258 12.8036 9.99121C12.8767 9.87191 12.9302 9.74189 12.9629 9.60584C12.9997 9.45239 13 9.28935 13 8.96323V5.75553C13 5.42941 12.9997 5.26641 12.9629 5.11296C12.9302 4.97691 12.8767 4.8467 12.8036 4.72741C12.7211 4.59285 12.606 4.47778 12.3754 4.24719L10.1126 1.98438C9.88217 1.75392 9.76667 1.63842 9.63216 1.55599C9.51287 1.48288 9.38284 1.42888 9.24679 1.39622C9.09334 1.35938 8.92964 1.35938 8.60352 1.35938H5.39583C5.06971 1.35938 4.90671 1.35938 4.75326 1.39622C4.61721 1.42888 4.48732 1.48288 4.36803 1.55599C4.23469 1.6377 4.12071 1.75169 3.89427 1.97813L3.88782 1.98438ZM7.0332 9.65918V9.72585L6.9668 9.72598V9.65918H7.0332Z"
          stroke="#FF7D83"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  ),
}

const FeedBackLabel = {
  toImprove: 'To Improve',
  positives: 'Positives',
}

const HelpfulOrNot = () => {
  return (
    <HStack gap="1rem">
      <HStack gap="4px">
        <Box w="1rem" h="1rem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M6 14.3594H10M8 2.35938C5.79086 2.35938 4 4.15024 4 6.35938C4 7.16904 4.24056 7.92252 4.65411 8.55224C5.29005 9.52059 5.60774 10.0045 5.64901 10.0768C6.01619 10.7201 5.94858 10.4932 5.99479 11.2324C5.99999 11.3155 6 11.4413 6 11.6927C6 12.0609 6.29848 12.3594 6.66667 12.3594L9.33333 12.3594C9.70152 12.3594 10 12.0609 10 11.6927C10 11.4413 10 11.3155 10.0052 11.2324C10.0514 10.4932 9.98341 10.7201 10.3506 10.0768C10.3919 10.0045 10.7101 9.52059 11.346 8.55224C11.7596 7.92252 12.0001 7.16904 12.0001 6.35938C12.0001 4.15024 10.2091 2.35938 8 2.35938Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Box>
        <Text fontWeight="semibold" fontSize="sm">
          Helpful
        </Text>
      </HStack>

      <HStack gap="4px">
        <Box w="1rem" h="1rem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M6 6.35938L7.99996 8.35933M7.99996 8.35933L9.99992 10.3593M7.99996 8.35933L6 10.3593M7.99996 8.35933L9.99992 6.35938M8 14.3594C4.68629 14.3594 2 11.6731 2 8.35938C2 5.04567 4.68629 2.35938 8 2.35938C11.3137 2.35938 14 5.04567 14 8.35938C14 11.6731 11.3137 14.3594 8 14.3594Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Box>
        <Text fontWeight="semibold" fontSize="sm">
          Not Helpful
        </Text>
      </HStack>
    </HStack>
  )
}

const ReviewContent = ({
  feedbackCategory,
  review,
}: {
  feedbackCategory: 'positives' | 'toImprove'
  review: string
}) => {
  return (
    <VStack gap="8px" alignItems="start" fontWeight="semibold" fontSize="sm">
      <HStack gap="8px">
        {ICONS[feedbackCategory]} <Text>{FeedBackLabel[feedbackCategory]}</Text>
      </HStack>
      <Text fontWeight="normal" lineHeight="normal">
        {review}
      </Text>
    </VStack>
  )
}

const Review = ({
  dealActivity,
}: {
  dealActivity: TLatestDealActivities | undefined
}) => {
  return (
    <WithResponsiveLayout
      gap="20px"
      w="full"
      p="20px"
      color="#FFF"
      lineHeight="normal"
      alignItems="start"
      overflowX="auto"
    >
      <ReviewerProfile>
        <UserID
          avatar={dealActivity?.users?.avatar}
          id={getFirstSevenCharacters(dealActivity?.users?.user_id ?? '')}
          location={dealActivity?.users?.location_name}
          avatarProps={{
            w: ['40px', '40px', '40px', '40px', '40px', '40px'],
            h: ['40px', '40px', '40px', '40px', '40px', '40px'],
            sx: { '> img': { border: '2px solid #FFF' } },
          }}
        />

        <TopDomain>
          {dealActivity?.expInSectors
            ?.slice(0, 2)
            ?.map((sector) => <Domain sectorName={sector?.sector_name} />)}
        </TopDomain>

        <Experiences>
          <Experience
            value={dealActivity?.view_count_user_stats?.count_intros ?? 0}
            text="Deals Sourced"
          />
          <Experience
            value={dealActivity?.view_count_user_stats?.count_evals ?? 0}
            text="Evaluation Completed"
          />
        </Experiences>
      </ReviewerProfile>

      <ReviewerProfileAndOpinions>
        <Overview rate={dealActivity?.bs_average ?? 0} />
        {dealActivity?.negative_comment && (
          <ReviewContent
            feedbackCategory="toImprove"
            review={dealActivity?.negative_comment}
          />
        )}
        {dealActivity?.positive_comment && (
          <ReviewContent
            feedbackCategory="positives"
            review={dealActivity?.positive_comment}
          />
        )}

        <HelpfulOrNot />
      </ReviewerProfileAndOpinions>
    </WithResponsiveLayout>
  )
}

export default Review
