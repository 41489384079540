import { Portal, Text } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

const ForbiddenPage = () => {
  return (
    <Portal>
      <WithCenterLayout pos="absolute" top="0" right="0" w="100vw" h="100vh">
        <Text color="white">You don't have permission to access this page</Text>
      </WithCenterLayout>
    </Portal>
  )
}

export default ForbiddenPage
