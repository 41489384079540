import { StackProps, VStack } from '@chakra-ui/react'

import { TField } from 'types/form-fields'

import { Field } from 'components/common/DLForms'

const FormBody = ({
  fields = [],
  fieldProps,
  stackProps,
}: {
  fields: TField[]
  fieldProps?: any
  stackProps?: StackProps
}) => {
  return (
    <VStack
      w="full"
      gap={8}
      alignItems="center"
      justifyContent="center"
      {...stackProps}
    >
      {fields?.map((field) => {
        return <Field key={field.name} {...field} {...fieldProps} />
      })}
    </VStack>
  )
}

export default FormBody
