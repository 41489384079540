import {
  Box,
  Collapse,
  HStack,
  Heading,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react'

import { ChevronDown } from 'components/common/Icons'

import { TDealExtraQuestions } from 'modules/dashboard/hooks/useDealBonusQuestions'

const Question = ({ question }: { question: TDealExtraQuestions }) => {
  return (
    <VStack gap="6px" alignItems="start">
      <Text fontSize="sm" fontWeight="semibold" color="#717A8C">
        {question?.deal_extra_qs?.question_text}
      </Text>

      <Box bg="#1A202A" p="1rem" borderRadius="0.5rem">
        <Text
          color="#BEE3F8"
          fontSize="md"
          fontWeight="medium"
          dangerouslySetInnerHTML={{ __html: question?.answer_text ?? '' }}
        ></Text>
      </Box>
    </VStack>
  )
}

const ViewBonusQuestions = ({
  questions,
}: {
  questions: TDealExtraQuestions[]
}) => {
  const [shouldCollapse, setShouldCollapse] = useBoolean()

  if (!questions?.length) return null

  return (
    <VStack
      bg="#101010"
      gap="20px"
      color="#A2A8B4"
      alignItems="start"
      p="20px"
      borderRadius="9.6px"
      w="full"
    >
      <HStack w="full" justifyContent="space-between">
        <Heading
          fontSize="14px"
          w="full"
          aria-expanded={!shouldCollapse}
          onClick={setShouldCollapse.toggle}
          cursor={'pointer'}
        >
          Bonus Questions
        </Heading>
        <ChevronDown
          transitionDuration="0.3s"
          transform={`rotate(${shouldCollapse ? 180 : 0}deg)`}
          boxSize={[4, 4, 4, 6, 6, 6]}
          color="accent.main01"
        />
      </HStack>

      <Collapse in={!shouldCollapse} animateOpacity>
        <VStack gap="24px" alignItems="start">
          {questions?.map((question) => (
            <Question
              key={question?.deal_extra_qs?.question_text}
              question={question}
            />
          ))}
        </VStack>
      </Collapse>
    </VStack>
  )
}

export default ViewBonusQuestions
