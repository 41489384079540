import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import { Card } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

const ContactFounderRequest = ({
  deal,
  isOpen,
  onClose,
}: {
  deal: IDealRow
  isOpen: boolean
  onClose: () => void
}) => {
  const handleSubmit = () => {}

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="white" isCentered>
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent maxW="1000px" bg="#13141F">
        <ModalCloseButton color="white" />
        <ModalBody
          p={['10px', '10px', '10px', '40px', '40px', '40px']}
          pb="10px"
        >
          <VStack gap="40px" alignItems="start" w="full">
            <WithResponsiveLayout
              w="full"
              justifyContent="start"
              alignItems="start"
              color="white"
              gap="46px"
            >
              <Box minW="350px" alignSelf="stretch">
                <Card deal={deal} onClick={() => {}} w="full" />
              </Box>
              <VStack gap="24px" alignItems="start" lineHeight="normal">
                <Text fontSize="32px" fontWeight="bold">
                  Contact Founder Request
                </Text>
                <Text fontSize="lg" fontWeight="semibold">
                  By requesting to contact the founder of this startup, you have
                  read and understood the following:
                </Text>

                <Text fontSize="sm" fontWeight="normal">
                  Some information about you / your fund will be disclosed to
                  the startup founder to initiate the connection. This will
                  include the name of your fund, your name, and where you’re
                  based. Your initial investment bid will be disclosed to the
                  founder, but this is non-binding at this stage, and will be
                  used as an indication of interest to the founder.
                </Text>
                <Text fontSize="sm" fontWeight="normal">
                  The founder of this start up will have to opt-in to start the
                  conversation.
                </Text>
                <Text fontSize="sm" fontWeight="normal">
                  You will receive an email notification from Deploy once the
                  startup founder has agreed to proceed with the introduction.
                </Text>
              </VStack>
            </WithResponsiveLayout>

            <WithCenterLayout w="full">
              <Button
                size={['sm', 'sm', 'md', 'xl']}
                variant="filled"
                borderRadius={['0.5rem', '0.5rem', '0.5rem', '0.5rem']}
                p={['15px 54px']}
                bg="#00C9FF"
                fontSize="lg"
                onClick={handleSubmit}
              >
                Request Introduction
              </Button>
            </WithCenterLayout>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ContactFounderRequest
