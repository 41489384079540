import { TRoute } from 'types/route'

import { WithCenterLayout } from 'components/hoc'

import { LOGIN } from '../constants'
import { Login } from '../pages'

const AuthRoutes: TRoute[] = [
  {
    path: LOGIN,
    profile: 'Investor',
    feature: 'Login',
    exact: true,
    component: () => {
      return (
        <WithCenterLayout h="100vh" w="full">
          <WithCenterLayout
            w={['90%', '80%', '80%', 'calc(100% / 3)', 'calc(100% / 4)']}
            gap="1rem"
            flexDir="column"
            h="fit-content"
            bg="white"
            borderRadius="24px"
            p="20px"
            alignItems="start"
          >
            <Login />
          </WithCenterLayout>
        </WithCenterLayout>
      )
    },
  },
]

export default AuthRoutes
