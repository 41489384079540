import { Icon } from '@chakra-ui/react'

const Disclaimer = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="80"
      viewBox="0 0 86 80"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.132 30.0007C23.6053 13.5925 28.3419 5.38838 34.5259 2.63512C39.9198 0.233606 46.0793 0.233606 51.4732 2.63512C57.6572 5.38838 62.3938 13.5925 71.8671 30.0007C81.3403 46.4088 86.077 54.6129 85.3694 61.345C84.7522 67.217 81.6724 72.5513 76.8957 76.0218C71.4194 80.0006 61.9461 80.0006 42.9995 80.0006C24.053 80.0006 14.5798 80.0006 9.10339 76.0218C4.32667 72.5513 1.24689 67.217 0.629714 61.345C-0.0778586 54.6129 4.65877 46.4088 14.132 30.0007ZM42.9993 18.5423C44.7252 18.5423 46.1243 19.9414 46.1243 21.6673V46.6673C46.1243 48.3932 44.7252 49.7923 42.9993 49.7923C41.2734 49.7923 39.8743 48.3932 39.8743 46.6673V21.6673C39.8743 19.9414 41.2734 18.5423 42.9993 18.5423ZM42.9993 63.334C45.3005 63.334 47.166 61.4685 47.166 59.1673C47.166 56.8661 45.3005 55.0006 42.9993 55.0006C40.6981 55.0006 38.8326 56.8661 38.8326 59.1673C38.8326 61.4685 40.6981 63.334 42.9993 63.334Z"
        fill="url(#paint0_linear_981_1171)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_981_1171"
          x1="4.65977"
          y1="-11.0411"
          x2="138.419"
          y2="-5.48819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B9DFB" />
          <stop offset="1" stop-color="#4764FB" />
        </linearGradient>
      </defs>
    </Icon>
  )
}

export default Disclaimer
