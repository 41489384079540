const sizes = {
  sizes: {
    3: '0.75rem',
    4.5: '1.12rem',
    7: '1.75rem', //28px
    7.5: '1.875rem', // 30px,
    11.5: '2.875rem', // 46px
    15: '3.75rem', // 60px
    16: '4.125rem',
    18: '4.375rem', // 70px,
    19: '5rem',
    25: '6.25rem', // 100px
    35.5: '8.75rem', // 140px
    50.75: '12.688rem', //203px
    70: '17.5rem', //280 px
    120: '35rem', // 560px,
  },
}

export default sizes
