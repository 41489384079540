import { Box, HStack, Text } from '@chakra-ui/react'

import { SECTORS_MAP_DOT_COLORS } from 'constants/common'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import {
  ISectorDistribution,
  ISectorDistributionChart,
} from 'modules/dashboard/interfaces/dashboash'

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload as ISectorDistributionChart
    return (
      <HStack
        sx={{
          backgroundColor: 'white',
          p: '5px',
          border: '1px solid #ccc',
          color: 'darkMode',
          zIndex: 1000,
          borderRadius: 3,
        }}
      >
        <Box
          w="10px"
          h="10px"
          borderRadius="full"
          bg={SECTORS_MAP_DOT_COLORS?.[dataItem?.name]}
        />
        <Text fontSize="md">{`${dataItem.name}: ${(
          (dataItem.value / (dataItem?.sum ?? 1)) *
          100
        ).toFixed(2)}%`}</Text>
      </HStack>
    )
  }

  return null
}
const PieChartBySectors = ({ data }: { data: ISectorDistribution[] }) => {
  const sum = data.reduce((acc, curr) => acc + curr.count, 0)
  const transformedData: ISectorDistributionChart[] = data.map(
    (item: ISectorDistribution) => {
      return {
        name: item.sector_name,
        value: item.count,
        color: SECTORS_MAP_DOT_COLORS[item.sector_name],
        sum,
      }
    },
  )

  return (
    <ResponsiveContainer minWidth={160} width={160} height={220}>
      <PieChart width={160} height={220}>
        <Pie
          dataKey="value"
          data={transformedData}
          innerRadius={55}
          outerRadius={80}
          fill="#8884d8"
          minAngle={0}
          stroke="0"
          strokeWidth={2}
        >
          {transformedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartBySectors
