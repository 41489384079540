import { ReactNode } from 'react'

import { Skeleton, SkeletonProps } from '@chakra-ui/react'

const SW15 = () => (
  <Skeleton
    w="15px"
    height="15px"
    startColor="rgba(84, 111, 255, 0.32)"
    endColor="#1E233D"
    borderRadius="20px"
  />
)

const SW80 = () => (
  <Skeleton
    w="80px"
    height="15px"
    startColor="rgba(84, 111, 255, 0.32)"
    endColor="#1E233D"
    borderRadius="20px"
  />
)

const SWH15 = () => (
  <Skeleton
    w="15px"
    height="15px"
    startColor="rgba(84, 111, 255, 0.32)"
    endColor="#1E233D"
    borderRadius="20px"
  />
)

const SW148 = () => (
  <Skeleton
    w="148px"
    height="15px"
    borderRadius="20px"
    startColor="rgba(84, 111, 255, 0.32)"
    endColor="#1E233D"
  />
)

const SFW = ({
  children,
  ...rest
}: { children?: ReactNode } & SkeletonProps) => (
  <Skeleton
    w="full"
    height="15px"
    borderRadius="20px"
    startColor="rgba(84, 111, 255, 0.32)"
    endColor="#1E233D"
    {...rest}
  />
)

const SFW80P = ({
  children,
  ...rest
}: { children?: ReactNode } & SkeletonProps) => (
  <Skeleton
    w="80%"
    height="15px"
    borderRadius="20px"
    startColor="#546FFF"
    endColor="#546FFF"
    {...rest}
  />
)

export { SW15, SW80, SWH15, SW148, SFW, SFW80P }
