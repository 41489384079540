import { ReactNode } from 'react'

import { Box, Tag, TagProps, Text } from '@chakra-ui/react'

const TagWithDot = ({
  children,
  dotColor,
  ...rest
}: {
  children: ReactNode
  dotColor: string
} & TagProps) => {
  return (
    <Tag
      gap={['4px', '4px', '4px', '4px', '4px', '9.6px']}
      p={[
        '4.8px 8px',
        '4.8px 8px',
        '4.8px 8px',
        '4.8px 10px',
        '4.8px 14.4px',
        '4.8px 14.4px',
      ]}
      borderRadius="9.6px"
      bg="rgba(208, 220, 245, 0.08)"
      flexDir="row"
      overflow="hidden"
      {...rest}
    >
      <Box borderRadius="full" minW="7.2px" minH="7.2px" bg={dotColor}></Box>
      <Text
        fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}
        opacity={0.8}
        lineHeight={['normal', 'normal', 'normal', 'normal', 'normal', 'md']}
        fontWeight="light"
        color="#FFF"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="nowrap"
        gap="4px"
      >
        {children}
      </Text>
    </Tag>
  )
}

export default TagWithDot
