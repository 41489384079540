import { Box, Button } from '@chakra-ui/react'

const CommentTextAction = ({
  shouldShow = false,
  children,
}: {
  shouldShow?: boolean
  children?: React.ReactNode
}) => {
  if (!shouldShow) return <></>
  return (
    <Button
      w="32px"
      h="32px"
      variant="outline"
      size="sm"
      color="darkMode"
      borderRadius="50%"
      fontWeight="bold"
      border="unset"
      p={2}
      _hover={{ bg: 'grey', opacity: 0.8 }}
    >
      <Box
        minW="16px"
        h="16px"
        bgSize="auto"
        bgRepeat="no-repeat"
        as="i"
        bgImage="url(images/reaction-2.png)"
        bgPosition="0px -747px"
        borderRadius="0"
        display="inline-block"
        filter="invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
      />
    </Button>
  )
}

export default CommentTextAction
