import { Icon } from '@chakra-ui/react'

const HomeIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4252 7.64607C16.0364 7.22519 16.7595 7 17.5 7C18.2405 7 18.9636 7.22519 19.5748 7.64607L26.3998 12.3482C26.8929 12.6878 27.2964 13.1433 27.5752 13.6753C27.8541 14.2072 27.9999 14.7996 28 15.4012V24.3001C28 25.2813 27.6128 26.2224 26.9236 26.9163C26.2344 27.6102 25.2997 28 24.325 28H10.675C9.70033 28 8.76558 27.6102 8.07638 26.9163C7.38719 26.2224 7 25.2813 7 24.3001V15.4012C7.00014 14.7996 7.14595 14.2072 7.42478 13.6753C7.70362 13.1433 8.10707 12.6878 8.6002 12.3482L15.4252 7.64607ZM14.875 23.2429C14.7358 23.2429 14.6022 23.2986 14.5038 23.3977C14.4053 23.4969 14.35 23.6313 14.35 23.7715C14.35 23.9117 14.4053 24.0461 14.5038 24.1452C14.6022 24.2444 14.7358 24.3001 14.875 24.3001H20.125C20.2642 24.3001 20.3978 24.2444 20.4962 24.1452C20.5947 24.0461 20.65 23.9117 20.65 23.7715C20.65 23.6313 20.5947 23.4969 20.4962 23.3977C20.3978 23.2986 20.2642 23.2429 20.125 23.2429H14.875Z"
        // fill="white"
      />
    </Icon>
  )
}

export default HomeIcon
