import { Button, HStack, Text, VStack } from '@chakra-ui/react'

import BubbleMap from './BubbleMap'

const GeographicalReach = () => {
  return (
    <VStack
      alignItems="start"
      justifyContent="space-between"
      gap="1rem"
      p="1rem"
      borderRadius={4}
      bg="#171923"
      color="white"
      alignSelf="stretch"
      w={['100%', '100%', '100%', '100%', '40%']}
      flex={1}
    >
      <VStack w="full" alignItems="start" gap="1rem">
        <Text fontSize="20px" fontWeight="bold">
          Geographical Reach
        </Text>
        <BubbleMap />
      </VStack>

      <HStack justifyContent="space-between" w="full">
        <VStack gap="3px" alignItems="start">
          <Text fontSize="14px" fontWeight="normal">
            Top Countries
          </Text>
          <Text fontSize="16px" fontWeight="bold">
            Hong Kong, Singapore, Japan
          </Text>
        </VStack>

        <Button
          color="#DCE4FF"
          bg="transparent"
          minH={['32px', '32px', '40px', '40px', '40px']}
          borderRadius="100px"
          border="1px solid #DCE4FF"
          fontSize="16px"
          fontWeight="bold"
          // p="0px 29px"
          size={['sm', 'sm', 'md', 'md', 'md']}
        >
          Explore
        </Button>
      </HStack>
    </VStack>
  )
}

export default GeographicalReach
