const formatCurrency = (value: number) => {
  if (typeof value === 'number' || !isNaN(Number(value))) {
    // If the value is a number or a string representing a number
    const formattedValue = parseFloat(String(value)).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    const finalResult = formattedValue.replace(/\.00$/, '')
    return `${finalResult}`
  } else {
    // If the value is not a number or a string representing a number
    return null // or any default value or message as needed
  }
}

/**
 * Format a numeric year to ensure a minimum of 4 digits with no fractional part.
 *
 * @param number - The numeric value representing a year to be formatted.
 * @returns A string representing the formatted year with a minimum of 4 digits and no fractional part.
 *
 * @example
 * ```typescript
 * console.log(formatYear(2022)); // Output: "2022"
 * console.log(formatYear(99));    // Output: "0099"
 * console.log(formatYear(20230));  // Output: "20230"
 * ```
 */
const formatYear = (number: number) =>
  new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 4,
    maximumFractionDigits: 0,
  }).format(number)

const formatDecimal = (number: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(number)

/**
 * Format a numeric or string value as a currency in USD.
 *
 * @param value - The numeric value or string representing a number to be formatted.
 * @returns A string representing the formatted currency in the format "$1,111 USD", or `null` if the input is not a valid number.
 *
 * @example
 * ```typescript
 * console.log(formatCurrency(1234.56)); // Output: "$1,234.56 USD"
 * console.log(formatCurrency("7890"));   // Output: "$7,890 USD"
 * console.log(formatCurrency("invalid")); // Output: null (or any default value)
 * ```
 */
const formatCurrencyAsStr = (value: number | string): string | null => {
  if (typeof value === 'number' || !isNaN(Number(value))) {
    // If the value is a number or a string representing a number
    const formattedValue = parseFloat(String(value)).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    const finalResult = formattedValue.replace(/\.00$/, '')
    return `${finalResult} USD`
  } else {
    // If the value is not a number or a string representing a number
    return null // or any default value or message as needed
  }
}

export { formatCurrency, formatYear, formatDecimal, formatCurrencyAsStr }
