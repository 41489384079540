import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import { IDomainTaxonomy } from 'interfaces/models'

import useMounted from './useMounted'

type TSector = Pick<IDomainTaxonomy, 'domain_name' | 'taxonomy_id'>
const LAYER_SECTOR = 1
export default function useSectors() {
  const [sectors, setSectors] = useState<TSector[] | null>([])
  const isMounted = useMounted()
  const formatSectorsAsSelections = (sectors: TSector[] | null | undefined) => {
    return (
      sectors?.map(({ domain_name, taxonomy_id }) => ({
        label: domain_name,
        value: taxonomy_id,
      })) ?? []
    )
  }

  useEffect(() => {
    /**
     * Fetches all the sectors from the 'domain_taxonomy' table in the database.
     *
     * @return {Promise<void>} Resolves with no value.
     */
    const fetchSubSectors = async () => {
      const SELECTED_FIELDS = 'taxonomy_id, domain_name'
      try {
        const { data, error }: PostgrestResponse<TSector> = await supabase
          .from(TABLES_NAME.domain_taxonomy)
          .select(SELECTED_FIELDS)
          .eq('layer_type', LAYER_SECTOR)

        if (error) throw error

        setSectors(data)
      } catch (e) {}
    }

    isMounted && fetchSubSectors()
  }, [isMounted])

  return { sectors, formatSectorsAsSelections }
}
