export enum EBusinessModel {
  B2B = 'B2B',
  B2C = 'B2C',
  B2G = 'B2G',
  B2B2C = 'B2B2C',
  C2C = 'C2C',
  C2B = 'C2B',
  P2P = 'P2P',
  Other = 'Other',
}

export enum ECompanyMaturity {
  IdeaStage = 'Idea Stage',
  Prototyping = 'Prototyping',
  PreLaunch = 'Pre-Launch',
  PreRevenue = 'Pre-Revenue',
  Clients = 'Clients',
  Growth = 'Growth',
}

export enum EFundingOrigin {
  Bootstrap = 'Bootstrap',
  Accelerator = 'Accelerator',
  Angel = 'Angel',
  Seed = 'Seed',
  Debt = 'Debt',
  FriendsFamily = 'Friends & Family',
  None = 'None',
  SeriesAOrLater = 'Series A or Later',
  Other = 'Other',
}

export enum EInvestmentInstruments {
  ConvertibleNote = 'Convertible Note',
  SAFE = 'SAFE',
  Equity = 'Equity',
}

export enum EProductType {
  SaaS = 'SaaS',
  Software = 'Software - Marketplace / Network',
  OtherSoftware = 'Software - Other',
  Hardware = 'Hardware',
  PhysicalGoods = 'Physical Goods',
  Services = 'Services',
  DigitalGoodsContent = 'Digital Goods / Content',
  Experiences = 'Experiences',
  Other = 'Other',
}

export enum ERegion {
  Africa = 'Africa',
  // Antarctica = 'Antarctica',
  Asia = 'Asia',
  Europe = 'Europe',
  Oceania = 'Oceania',
  NORAM = 'NORAM',
  LATAM = 'LATAM',
  MENA = 'MENA',
}

export enum ERound {
  Angel = 'Angel',
  Bridge = 'Bridge',
  PreSeed = 'Pre-Seed',
  Seed = 'Seed',
  SeriesA = 'Series A',
  Other = 'Other',
}

export enum ETechnology {
  Technology3D = '3D Technology',
  Analytics = 'Analytics',
  APIs = 'APIs',
  AR = 'AR',
  Automation = 'Automation',
  BigData = 'Big Data',
  Blockchain = 'Blockchain',
  ComputerVision = 'Computer Vision',
  Cybersecurity = 'Cybersecurity',
  DevOps = 'DevOps',
  DigitalTwin = 'Digital Twin',
  Geospatial = 'Geospatial',
  Hardware = 'Hardware',
  IoT = 'IoT',
  ML_AI = 'ML & AI',
  Manufacturing = 'Manufacturing',
  MobileApp = 'Mobile App',
  NanoTech = 'NanoTech',
  NLP = 'NLP',
  Network = 'Network',
  Quantum = 'Quantum',
  Recognition = 'Recognition',
  Robotics = 'Robotics',
  Search = 'Search',
  VR = 'VR',
  WebApp = 'Web App',
}

export enum EDealPriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}
