import { formatCurrencyAsStr, formatDecimal } from 'utils/numbers'

const useFormatCell = (value: any, type: string) => {
  let formattedValue = value ?? ''
  if (type === 'currency') {
    formattedValue = formatCurrencyAsStr(formattedValue) ?? ''
  }

  if (type === 'number') {
    formattedValue = formatDecimal(parseInt(formattedValue)) ?? ''
  }

  if (Array.isArray(value)) {
    formattedValue = value.join(', ')
  }

  return formattedValue ?? 'N/A'
}

export default useFormatCell
