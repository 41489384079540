import {
  HStack,
  Progress,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import { formatCurrency } from 'utils/numbers'

import { Star } from 'components/common/Icons'
import { EllipseNumber } from 'components/common/other/EllipseNumber'

const cardStyles = {
  dark: {
    bg: 'background.lightMode',
    color: 'background.darkMode',
    border: 'screenRoom.lightMode',
    activated: {
      border: 'activeIntros.activatedInLightMode',
      bg: '#EDFAFD',
    },
    progressBg: 'rgb(0 0 0 / 16%)',
  },
  light: {
    bg: 'background.darkMode',
    color: 'background.lightMode',
    border: 'screenRoom.normal',
    activated: {
      border: 'activeIntros.activatedInLightMode',
      bg: '#2A3E55',
    },
    progressBg: '#2f2f2f',
  },
} as Record<string, any>

const CardInformation = ({
  rank,
  rate,
  percent_left,
  progress_value,
  currentRaise,
  index,
  isActivated,
  onClick,
}: {
  rank: string | number
  rate: string | number
  currentRaise: number
  index: number
  percent_left: number
  progress_value: number
  isActivated?: boolean | undefined
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const { colorMode } = useColorMode()
  const { border, bg, color, activated, progressBg } = cardStyles[colorMode]
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clByMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rightColorTextByMode = useColorModeValue(
    'rgba(255, 255, 255, 0.60)',
    'rgba(16, 16, 16, 0.60)',
  )

  const offset = index === 0 ? 0 : 5

  return (
    <VStack
      w="full"
      h="8.25rem"
      bg={bg}
      color={color}
      p="0.75rem"
      borderRadius="1.5rem"
      border={border}
      fontSize="lg"
      fontWeight="bold"
      pos="absolute"
      top={`${index * offset}rem`}
      sx={isActivated ? activated : undefined}
      onClick={onClick}
    >
      <HStack
        gap="2rem"
        alignItems="flex-start"
        justifyContent="center"
        w="full"
      >
        <EllipseNumber flex="0.5" number={rank} />
        <HStack
          flex="1"
          gap="0.25rem"
          fontWeight="bold"
          justifyContent="center"
        >
          <Star w="1.375rem" />
          <Text as="output">{rate}</Text>
        </HStack>
        <Text flex="1" fontSize="md" fontWeight="bold" textAlign="center">
          {formatCurrency(currentRaise)}
        </Text>
      </HStack>

      <HStack gap={6} w="full">
        <HStack
          fontSize="0.625rem"
          fontWeight="medium"
          lineHeight="140%"
          gap={1}
        >
          <Text color={clByMode}>
            ${parseFloat(percent_left + '').toFixed(1)}%
          </Text>
          <Text color={rightColorTextByMode}>left</Text>
        </HStack>
        <Progress
          w="full"
          colorScheme="progressActiveIntrosColor"
          bg={progressBg}
          size="sm"
          borderRadius="full"
          value={progress_value}
        />
      </HStack>
    </VStack>
  )
}

export default CardInformation
