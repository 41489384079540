import { Box } from '@chakra-ui/react'

import { FIXED_BACKGROUND_IMAGE } from 'constants/common'

const FixedContainer = () => {
  return (
    <Box
      w="100vw"
      h="100vh"
      pos="fixed"
      top="0"
      left="-2px"
      zIndex="-1"
      bgSize="cover"
      bgImg={FIXED_BACKGROUND_IMAGE}
    />
  )
}

export default FixedContainer
