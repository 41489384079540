import { HStack, Progress, VStack } from '@chakra-ui/react'

import { IViewScoutInSector } from 'interfaces/views'
import { formatCurrency, formatDecimal } from 'utils/numbers'

import {
  Label,
  ProminentNumber,
  TagWithDot,
  TextValue,
} from 'components/common/other/deal-details'
import { FlexField } from 'components/common/other/deal-details/FlexLayout'
import { getRateColor } from 'components/common/other/deal-details/utils'

import { showArrayAsStr } from 'modules/deals/utils'

import {
  COMPANY_FIELDS,
  CURRENCY_FIELDS,
  FUNDRAISE_FIELDS,
  HEAD_FIELDS,
  INDICATED_INTEREST_FIELDS,
  RANKING_FIELDS,
  RATING_LABELS,
} from '../constants/startup-spotlight-details'
import { IDealRow } from '../interfaces/deal-table'

/**
 * Transforms the provided map of fields to a flexible field format based on the given deal.
 *
 * @param {Record<string, string>} mapFields - The map of fields to transform.
 * @param {IDealRow} deal - The deal row to use for transformation.
 * @return {Array<{ label: string, value: string | number }>} The transformed flexible fields.
 */
const transformToFlexField = (
  mapFields: Record<string, string>,
  deal: IDealRow | undefined | null,
) => {
  return Object.entries(mapFields).map(([key, label]) => {
    return {
      label,
      value:
        typeof deal?.[key as keyof IDealRow] === 'number'
          ? CURRENCY_FIELDS.includes(key)
            ? formatCurrency(deal[key as keyof IDealRow])
            : formatDecimal(deal[key as keyof IDealRow])
          : showArrayAsStr(deal?.[key as keyof IDealRow]),
    }
  })
}

/**
 * Custom hook for extracting deal details.
 *
 * @param {{ deal: IDealRow }} deal - the deal object
 * @return {{ sectors: string[], domains: string[], headFields: FlexField[], ratingFields: FlexField[], companyInformationFields: FlexField[], fundraiseFields: FlexField[] }} extracted deal details
 */
const useStartupSpotlightDetails = ({
  deal,
  fetchedSectorExperience,
}: {
  deal: IDealRow | null | undefined
  fetchedSectorExperience: IViewScoutInSector[] | []
}) => {
  const indicatedInterestFields: FlexField[] = transformToFlexField(
    INDICATED_INTEREST_FIELDS,
    deal,
  )

  const fundraiseFields: FlexField[] = transformToFlexField(
    FUNDRAISE_FIELDS,
    deal,
  )

  const companyInformationFields: FlexField[] = transformToFlexField(
    COMPANY_FIELDS,
    deal,
  )

  const rankingFields: FlexField[] = Object.entries(RANKING_FIELDS).map(
    ([key, label]) => {
      return {
        label,
        value: (() => {
          const value = deal?.[key as keyof IDealRow]
          if (key === 'maven_rating_avg_in_sector') {
            return (
              <HStack gap="12px" mt="6px">
                <ProminentNumber>
                  {parseFloat((value ?? 0) + '')?.toFixed(1) ?? 0}
                </ProminentNumber>
                <Label>
                  {deal?.percent_mavens_have_evaluated ?? 0} Reviews
                </Label>
              </HStack>
            )
          }

          if (key === 'deal_rank') {
            return <ProminentNumber mt="6px">{value ?? 0}</ProminentNumber>
          }
        })(),
      }
    },
  )

  const headFields: FlexField[] = Object.entries(HEAD_FIELDS).map(
    ([key, label]) => {
      return {
        label,
        value: (() => {
          const value = deal?.[key as keyof IDealRow]
          if (key === 'maven_rating_avg_in_sector') {
            return (
              <HStack gap="12px">
                <ProminentNumber>
                  {parseFloat(value + '')?.toFixed(1) ?? 0}
                </ProminentNumber>
                <Label>
                  {deal?.percent_mavens_have_evaluated ?? 0}% Quorum
                </Label>
              </HStack>
            )
          }

          if (key === 'deal_rank') {
            return <ProminentNumber>{value ?? 0}</ProminentNumber>
          }

          if (key === 'available_tickets') {
            return (
              <HStack gap="12px">
                <TextValue>{parseFloat(value ?? 0)?.toFixed(1) ?? 0}</TextValue>

                <VStack alignItems="start" gap={0}>
                  <TextValue fontSize="11.2px">
                    {parseFloat(deal?.percent_left ?? 0)?.toFixed(1) ?? 0} %
                    left
                  </TextValue>
                  <Progress
                    colorScheme="progressActiveIntrosColor"
                    w="100px"
                    bg={'#876CEA'}
                    size="sm"
                    borderRadius="full"
                    h="2.4px"
                    value={100 - parseFloat(deal?.percent_left ?? 0)}
                  />
                </VStack>
              </HStack>
            )
          }

          if (typeof value === 'number' && key !== 'year_founded') {
            return CURRENCY_FIELDS.includes(key)
              ? formatCurrency(value)
              : formatDecimal(value)
          }

          return showArrayAsStr(value)
        })(),
      }
    },
  )

  const ratingFields = Object.entries(RATING_LABELS).map(([key, label]) => {
    return {
      label,
      value: (
        <TagWithDot
          dotColor={getRateColor(
            (deal?.[key as keyof IDealRow] ?? 0) as number,
          )}
        >
          {parseFloat(deal?.[key as keyof IDealRow] + '')?.toFixed(1) ??
            deal?.[key as keyof IDealRow]}
        </TagWithDot>
      ),
    }
  })

  return {
    rankingFields,
    indicatedInterestFields,
    headFields,
    ratingFields,
    companyInformationFields,
    fundraiseFields,
  }
}

export default useStartupSpotlightDetails
