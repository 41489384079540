import React from 'react'

import { useAuth } from 'hooks'
import { Redirect, Route } from 'react-router-dom'

import { ForbiddenPage, MainLayout } from 'components/layout'

type TRedirectComponent = {
  feature?: string
  component: React.FC<any>
}

type TProtectedRoute = TRedirectComponent

/**
 *
 * Check current user has permission to access the component
 *
 */
const ShouldRedirectToComponent = ({
  feature,
  component: Component,
  ...rest
}: TRedirectComponent) => {
  const { isInvestor } = useAuth()

  let canAccess = isInvestor()
  return canAccess ? <Component {...rest} /> : <ForbiddenPage />
}

/**
 *
 * Check current user is authenticated or not to render component properly
 *
 */
const ProtectedRoute = ({ component, feature, ...rest }: TProtectedRoute) => {
  // const { showErrorToast } = useDLToast()
  const { isLogged } = useAuth()
  const isAuthenticated = isLogged()

  // if (!isAuthenticated) {
  //   showErrorToast(ONLY_INVESTOR_CAN_BE_LOG_IN)
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <MainLayout>
            <ShouldRedirectToComponent
              {...props}
              component={component}
              feature={feature}
            />
          </MainLayout>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }}
    />
  )
}

export default ProtectedRoute
