import { VStack } from '@chakra-ui/react'

export interface IContactInfo {
  phone_number: string | undefined | null
  email: string | undefined | null
  first_name: string | undefined | null
  last_name: string | undefined | null
  company_name: string | undefined | null
  message: string | null
  job_title: string | null
  is_read_term_and_conditions: boolean
}

export const CONTACT_FORM_DEFAULT_VALUES: IContactInfo = {
  phone_number: null,
  email: null,
  first_name: null,
  last_name: null,
  company_name: null,
  message: null,
  job_title: null,
  is_read_term_and_conditions: false,
}

export const FAQ1 = [
  {
    question: 'How do I contact support?',
    content:
      'You can contact our support team via email at support@example.com or by calling our toll-free number at 1-800-123-4567. Our support hours are Monday to Friday, 9 AM to 5 PM EST.',
  },
  {
    question: 'What is your refund policy?',
    content:
      'Our refund policy allows you to return any item within 30 days of purchase for a full refund. The item must be in its original condition and packaging. Please contact our support team to initiate a return.',
  },
  {
    question: 'How can I track my order?',
    content:
      'Once your order has been shipped, you will receive an email with a tracking number and a link to track your order. You can also track your order by logging into your account on our website and visiting the "My Orders" section.',
  },
  {
    question: 'Do you offer international shipping?',
    content:
      'Yes, we offer international shipping to most countries. Shipping costs and delivery times vary depending on the destination. Please visit our Shipping Policy page for more details on international shipping options and rates.',
  },
]

export const FAQ = [
  {
    question: 'What is Deploy?',
    content:
      'Deploy is an intelligence management platform that upgrades the quality of early-staged venture deal flow and research. Industry experts refer and peer-evaluate start-ups to give potential investors a wide range of insights. ',
  },
  {
    question: 'What’s in it for me?',
    content: (
      <VStack gap="0.5rem" alignItems="start">
        <p>
          By submitting deals, Experts have the opportunity to receive carry in
          the start-ups that are funded via the deploy platform.
        </p>
        <p>
          In evaluating deals, Experts earn XP to increase the credibility in
          the domains in which they contribute. The more your reputation grows
          the more opportunities for ad-hoc advisory work for investors and
          start-ups alike.
        </p>
      </VStack>
    ),
  },
  {
    question: 'Can I invite my Friends?',
    content:
      'Definitely! Just not yet. This closed beta will allow to test a number of intricate interactions between experts, investors, and the business that go through our platform. A key part of your journey is building your reputation in our system. In the future, we will give you personalized invite codes that attribute new participants to you.',
  },
  {
    question: 'Should I submit any and all start-ups I know are fundraising?',
    content:
      'Nope. Our reputation management system continuously evaluates the contributions of scouts based on a number of factors. First is the quality of the data the start-up provides, this means you should be careful in the approval process to review their submission and ensure the data is of the highest quality so that other experts are confident in their review of the business. The second factor is the expert reviews, put simply: If your deals are getting rated poorly, your opportunity to submit new deals will decrease.',
  },
  {
    question: 'How do I become an Expert?',
    content:
      'Deploy works as an invite-only system, so reach out if you want to become an Expert. ',
  },
  {
    question: 'How do I evaluate when the start-up data is bad?',
    content: (
      <VStack gap="0.5rem" alignItems="start">
        <p>
          The same way you would if the data was fantastic. The first question
          in the evaluation is key.
        </p>
        <p>1 - This information is almost unusable.</p>
        <p>2 - Not terrible, but not great either.</p>
        <p>3 - Enough to work with here.</p>
        <p>4 - Great info, highly detailed.</p>
        <p>5 - This is perfect, all the info anyone could need is here.</p>
      </VStack>
    ),
  },
  {
    question: 'Why can I not see details of the fundraise when I evaluate?',
    content:
      'We are tapping your knowledge as a domain expert, as such you need to evaluate the start-up based on the impact that you believe the business can have on your field of expertise. Some details of their fundraise may be in their pitch deck, which you can view in the app. We aren’t trying to keep this information from you. However, you are evaluating the business, not the potential investment so do not factor their ask into your evaluation.',
  },
  {
    question: 'Can I see how other Experts have rated a start-up?',
    content:
      'Of course! But only after you have done your own review. We want to take the herd mentality out of the innovation ecosystem. So each deal is independently evaluated before information is shared.',
  },
  {
    question: 'Are Scouts able to evaluate their own deals?',
    content:
      'No, our system removes the originating scout from the evaluating pool of experts to reduce biases. Scouts can view in real time the progress of the evaluations.',
  },
  {
    question: 'Do my evaluations show as my real name?',
    content:
      'No, your name is masked as a snippet of your hashed userID in our system. The only way your identifying details are revealed is if a start-up or an investor would like to engage you for advisory work and only after both parties opt-in to the engagement.',
  },
]

export const REGISTER_CONTACT_SUCCESS_MESSAGE = 'Thank you for your interest!'
export const REGISTER_CONTACT_FAIL_MESSAGE =
  'Something went wrong, please try again'
