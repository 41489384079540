export const UPDATE_PRIORITY_SUCCESSFUL = 'Updated priority successfully'
export const UPDATE_PRIORITY_UNSUCCESSFUL = 'Failed to update priority'
export const UPDATE_PRIORITY_ERROR = 'Failed to update priority'

export const ADD_DEAL_TO_PIPELINE_SUCCESSFULLY = 'Add to pipeline successfully'
export const ADD_DEAL_TO_PIPELINE_UNSUCCESSFULLY =
  'Add to pipeline unsuccessfully'

export const NUMBER_OF_TICKET_RESERVED_GREATER_THAN_AVAILABLE_TICKETS =
  'The number of tickets reserved is currently greater than the available tickets of this deal, please reserve less than that number of tickets'

export const NUMBER_OF_TICKET_RESERVED_NOT_A_NEGATIVE_NUMBER =
  'The number of tickets reserved is not a positive number'
