import { useEffect, useState } from 'react'

import {
  Avatar,
  HStack,
  Heading,
  Tag,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react'

import { DEFAULT_AVATAR } from 'constants/common'
import useMounted from 'hooks/useMounted'
import { useHistory } from 'react-router'
import { getFirstSevenCharacters } from 'utils/string'

import { WithCenterLayout, WithSpinnerLoading } from 'components/hoc'

import { useDashboardQuery } from 'modules/dashboard/hooks'
import { ILatestMavenInsight } from 'modules/dashboard/interfaces/dashboash'

const Insight = ({
  avatar,
  mavenID,
  dealID,
  evalComment,
  rating,
}: ILatestMavenInsight) => {
  const history = useHistory()

  const handleClickDealID = (dealID: string | null) => {
    dealID && history.push(`/deal/${dealID}`)
  }

  return (
    <WithCenterLayout
      gap={{ base: '6px', sm: '20px' }}
      w="full"
      justifyContent="space-between"
      alignItems={{ base: 'start', md: 'center' }}
      cursor="pointer"
      onClick={() => handleClickDealID(dealID)}
    >
      <HStack w="full" alignItems={{ base: 'start', md: 'center' }}>
        <Avatar
          // borderRadius={1.5}
          src={avatar ?? DEFAULT_AVATAR}
          w={{ base: '35px', md: '45px' }}
          h={{ base: '35px', md: '45px' }}
          overflow="hidden"
          sx={{
            '> img': { filter: 'blur(7.5px)' },
          }}
        />
        <VStack
          gap="0"
          lineHeight={{ base: 'normal', md: '1' }}
          alignItems="start"
        >
          <HStack
            gap="8px"
            fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}
            justifyContent={{ base: 'start', sm: 'space-between' }}
            flexWrap="wrap"
            // sx={{ textWrap: 'nowrap' }}
          >
            <Text color="#8B9DFB" fontWeight="bold">
              {getFirstSevenCharacters(mavenID ?? '')}
            </Text>
            <HStack gap="6px" flexWrap="nowrap">
              <Text>just rated the company</Text>
              <Text color="#8DFD7C" fontWeight="bold">
                {getFirstSevenCharacters(dealID ?? '', '#')}
              </Text>
            </HStack>
          </HStack>
          <Text fontSize="sm" color="#A0AEC0" noOfLines={1}>
            {evalComment}
          </Text>
        </VStack>
      </HStack>
      <Tag
        variant="outline"
        fontSize="sm"
        fontWeight="semibold"
        color="#00C9FF"
        p="4px 8px"
        borderRadius="8px"
        border="0.5px solid #00C9FF"
        minW="40px"
        justifyContent="center"
      >
        <Text>{rating}</Text>
      </Tag>
    </WithCenterLayout>
  )
}

const LatestMavenInsights = () => {
  const [mavenInsights, setMavenInsights] = useState<
    ILatestMavenInsight[] | []
  >([])
  const [isLoading, toggle] = useBoolean()
  const { fetchLatestMavenInsight } = useDashboardQuery()
  const isMounted = useMounted()

  useEffect(() => {
    const fetchData = async () => {
      if (mavenInsights?.length === 0) {
        toggle.on()
        const fetchedMavenInsights = await fetchLatestMavenInsight()
        setMavenInsights(
          fetchedMavenInsights?.map((insight) => ({
            mavenID: insight?.maven_user_id,
            dealID: insight?.deal_id,
            evalComment: insight?.eval_comments,
            rating: insight?.bs_average,
            avatar: insight?.users?.avatar,
          })),
        )
        toggle.off()
      }
    }

    isMounted && fetchData()
  }, [isMounted])

  return (
    <VStack
      alignItems="start"
      w={['100%', '100%', '100%', '100%', '33%', '33%']}
      h="full"
      as="section"
      color="white"
      bg="#111C44"
      borderRadius="18px"
      gap="28px"
      p={{ base: '20px 10px', md: '20px 25px' }}
      flex={1}
    >
      <Heading
        fontSize={['1rem', '1rem', '1rem', '1rem', '20px', '20px']}
        fontWeight="bold"
      >
        Latest Expert Insights
      </Heading>
      <WithSpinnerLoading isLoading={isLoading}>
        <VStack gap="16px" w="full" alignItems="start">
          {mavenInsights?.map((insight) => (
            <Insight
              key={`${insight?.dealID}${insight?.mavenID}`}
              {...insight}
            />
          ))}
        </VStack>
      </WithSpinnerLoading>
    </VStack>
  )
}

export default LatestMavenInsights
