import React, { useState } from 'react'

function DeployErrorBoundary({ children }: { children: React.ReactNode }) {
  const [hasError, setHasError] = useState(false)

  const componentDidCatch = (error: any, errorInfo: any) => {
    console.error(error, errorInfo)
    setHasError(true)
  }

  if (hasError) {
    return <div>Something went wrong.</div>
  }

  return children
}

export default DeployErrorBoundary
