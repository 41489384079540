import { Resolver } from 'react-hook-form'
import * as yup from 'yup'

import { IContactInfo } from '../constants/connect'

const connectFormRules = {
  email: yup
    .string()
    .nullable()
    .required('Email is required')
    .email('Email is invalid'),
  company_name: yup.string().nullable().required('Company name is required'),
  first_name: yup.string().nullable().required('First name is required'),
  last_name: yup.string().nullable().required('Last name is required'),
  job_title: yup.string().nullable().required('Job title is required'),
  phone_number: yup
    .string()
    .nullable()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Phone number must contain only digits')
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number cannot exceed 15 digits'),
  is_read_term_and_conditions: yup
    .boolean()
    .oneOf([true], 'You must accept terms & conditions before submitting')
    .required('You must accept terms & conditions before submitting'),
  message: yup
    .string()
    .nullable()
    .max(500, 'Message must be 500 characters or fewer')
    .required('Message is required'),
}

export const connectFormSchema = yup.object(connectFormRules)

export const resolver: Resolver<IContactInfo> = async (values) => {
  try {
    await connectFormSchema.validate(values, { abortEarly: false })
    return { values, errors: {} }
  } catch (validationErrors: any) {
    return {
      values: {},
      errors: validationErrors?.inner?.reduce(
        (allErrors: any, currentError: any) => {
          return {
            ...allErrors,
            [currentError.path!]: currentError.message,
          }
        },
        {},
      ),
    }
  }
}
