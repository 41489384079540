import { Icon } from '@chakra-ui/react'

const ActiveDealIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4473 19.3868C26.4473 24.797 22.8828 28.3907 17.5312 28.3907C12.7363 28.3907 9.5625 25.627 9.5625 21.4766C9.5625 19.424 10.0996 18.1954 10.5733 17.1117C10.947 16.2568 11.2812 15.4922 11.2812 14.4845C11.2812 14.1923 11.2603 13.9122 11.2416 13.6622C11.2265 13.4611 11.2129 13.2795 11.2129 13.127C11.2129 12.5606 11.5547 12.2091 12.0723 12.2091C12.9121 12.2091 13.8203 12.8341 14.4355 14.1036C14.4551 13.9669 14.4648 13.8399 14.4648 13.7032C14.4453 11.877 13.3809 10.3634 12.1309 8.85945C11.8965 8.57625 11.7598 8.26375 11.7598 7.95125C11.7598 7.16023 12.4727 6.73055 13.5566 6.73055C18.8008 6.73055 26.4473 10.4708 26.4473 19.3868ZM14.7627 22.4337C14.7627 24.0548 15.9639 25.129 17.7705 25.129C19.9873 25.129 21.1885 23.547 21.1885 21.5841C21.1885 19.5821 20.0459 17.5314 17.9658 16.6036C17.8193 16.545 17.6826 16.6329 17.7217 16.7989C17.9072 18.2442 17.7607 19.5724 17.2725 20.3829C17.0576 19.7774 16.7744 19.2892 16.3643 18.9083C16.2275 18.7911 16.1006 18.8595 16.0811 19.006C16.0168 19.5498 15.7555 19.9565 15.4822 20.3818C15.1322 20.9264 14.7627 21.5015 14.7627 22.4337Z"
        // fill="white"
      />
    </Icon>
  )
}

export default ActiveDealIcon
