import { Divider, Heading, VStack } from '@chakra-ui/react'

import useTeamNotes, { TTeamNotes } from 'hooks/useTeamNotes'

import CommentBox from './CommentBox'
import CommentReplyActions from './CommentReplyActions'

export interface Comment {
  avatar: string
  name: string
  time: string
  text: string
  child?: Comment[]
}

const CommentList = ({ dealID }: { dealID: string }) => {
  const {
    teamID,
    teamNotes: comments,
    loadTeamNotes,
  } = useTeamNotes({
    dealId: dealID,
  })

  return (
    <VStack
      w="full"
      borderRadius={3}
      p={[5, 5, 5, 5, '23.4px', '23.4px']}
      bg="#0E0F1D"
      alignItems="start"
      gap="11.8px"
    >
      <Heading color="#F9FAFA" fontSize="24px" fontWeight="semibold">
        Team Notes
      </Heading>
      {/* {!comments ||
        (comments?.length === 0 && (
          <CommentReplyActions
            refetchRepliesOnNotes={loadTeamNotes}
            comment={{
              dealID,
              note_id: '',
              team_id: teamID,
              asPlaceholder: 'Write your note here',
            }}
          />
        ))} */}
      <>
        {comments &&
          comments?.length > 0 &&
          comments?.map((comment: TTeamNotes) => (
            <VStack
              key={`${comment?.deal_id}${comment?.note_id}`}
              w="full"
              gap="11.8px"
            >
              <CommentBox
                loadTeamNotes={loadTeamNotes}
                key={`${comment?.users?.avatar}${comment.created_at}`}
                comment={comment}
              />
              {/* <Divider bg="white" /> */}
            </VStack>
          ))}

        <CommentReplyActions
          refetchRepliesOnNotes={loadTeamNotes}
          comment={{
            deal_id: dealID,
            note_id: '',
            team_id: teamID,
            asPlaceholder: 'Add your comments...',
          }}
        />
      </>
    </VStack>
  )
}

export default CommentList
