import { useCallback, useState } from 'react'

import {
  Avatar,
  Button,
  Collapse,
  Divider,
  Grid,
  GridItem,
  HStack,
  Link,
  Stack,
  Text,
  VStack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { DEFAULT_AVATAR } from 'constants/common'
import { HTTP_CODE_23505 } from 'constants/http-codes'
import { NAVBAR } from 'constants/navbar'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useDLToast } from 'hooks'
import useAuth from 'hooks/useAuth'
import uniqueId from 'lodash/uniqueId'
import { useHistory } from 'react-router'
import { getFirstSevenCharacters } from 'utils/string'

import { ChevronDown } from 'components/common/Icons'
import YoutubePlayer from 'components/common/other/YoutubePlayer'
import { CommentList } from 'components/common/other/comments'
import {
  DHeading,
  DetailedMavenRating,
  FieldSection,
  Label,
  TextValue,
  WrapFieldSection,
} from 'components/common/other/deal-details'
import { SectorsPath } from 'components/common/other/deal-details/display'
import { MavenRatingDetail } from 'components/common/other/deal-details/modal'
import { WithResponsiveLayout } from 'components/hoc'

import { ChangeBidModal } from 'modules/active-intros/components'
import { SHORTLIST_TABLE } from 'modules/screen-rooms/constants'

import {
  ADD_DEAL_TO_SHORTLIST_DUPLICATE_ERROR,
  ADD_DEAL_TO_SHORTLIST_ERROR,
  ADD_DEAL_TO_SHORTLIST_SUCCESS,
} from '../constants/messages'
import { dealPriorityOptions } from '../constants/select'
import { useDealDetails } from '../hooks'
import { TLatestDealActivities } from '../interfaces/deal-detail'
import { IDealRow } from '../interfaces/deal-table'
import { DealStatus, Review, ViewBonusQuestions } from './other'

dayjs.extend(relativeTime)
dayjs.locale('us')

export const DealDetail = ({
  deal,
  shouldBlurAvatar = true,
  onBack,
}: {
  deal: IDealRow
  shouldBlurAvatar?: boolean | undefined
  onBack?: () => Promise<void> | undefined
}) => {
  const history = useHistory()
  const { getUser } = useAuth()
  const {
    indicatedInterestFields,
    headFields,
    ratingFields,
    companyInformationFields,
    fundraiseFields,
    dealActivities,
    bonusQuestions,
  } = useDealDetails({ deal })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenMavenDetail,
    onOpen: onOpenMavenDetail,
    onClose: onCloseMavenDetail,
  } = useDisclosure()
  const [selectedDealActivity, setSelectedDealActivity] =
    useState<TLatestDealActivities>()

  const [shouldCollapseAllReviews, setShouldCollapseAllReviews] = useBoolean()

  /**
   *  helper for showing error toast
   * **/
  const { showErrorToast, showSuccessToast } = useDLToast()

  const handleClickOnMavenDetail = (dealActivity: TLatestDealActivities) => {
    setSelectedDealActivity(dealActivity)
    onOpenMavenDetail()
  }

  return (
    <Grid
      templateRows="1fr"
      templateColumns={[
        '1fr',
        '1fr',
        '1fr',
        '1fr',
        !shouldBlurAvatar ? 'calc(100% - 372px - 2rem) 1fr' : '1fr',
        !shouldBlurAvatar ? 'calc(100% - 372px - 2rem) 1fr' : '1fr',
      ]}
      columnGap={['1rem', '1rem', '1rem', '1rem', '1rem', '2rem']}
      rowGap="2rem"
      w="full"
    >
      <GridItem>
        <VStack
          gap="24.8px"
          borderTopLeftRadius="40px"
          w="full"
          h="full"
          as="section"
        >
          <FieldSection
            bg="darkMode"
            canCollapsible
            header={
              <WithResponsiveLayout
                w="full"
                justifyContent="space-between"
                alignItems="start"
                flexDir={[
                  'column',
                  'column',
                  'column',
                  'column',
                  'column',
                  'row',
                ]}
              >
                <HStack gap="30px">
                  <Avatar
                    w="60px"
                    h="60px"
                    borderRadius="full"
                    src={deal?.logo ?? DEFAULT_AVATAR}
                    overflow="hidden"
                    bg="#e3e3e36b"
                    sx={{
                      '> img': {
                        filter: shouldBlurAvatar ? 'blur(7.5px)' : 'unset',
                        objectFit: 'contain',
                      },
                    }}
                  />
                  <TextValue fontSize="xl">
                    {shouldBlurAvatar
                      ? getFirstSevenCharacters(deal?.deal_id, '#')
                      : deal?.company_name}
                  </TextValue>
                </HStack>
              </WithResponsiveLayout>
            }
            fields={headFields}
          >
            <VStack m="1rem" ml="0" mb="0" alignItems="start">
              <SectorsPath sectorsAndDomains={deal?.sectors_and_domains} />
              {!shouldBlurAvatar && (
                <VStack alignItems="start" gap="6px">
                  <Label fontSize="md">Elevator Pitch</Label>
                  <TextValue
                    fontWeight="normal"
                    fontSize={['md', 'md', 'md', 'lg', 'lg', 'lg']}
                    mb="30px"
                  >
                    {deal?.elevator_pitch}
                  </TextValue>
                  <WithResponsiveLayout
                    justifyContent="space-between"
                    alignItems="end"
                    w="full"
                  >
                    <Link
                      color="white"
                      borderRadius="0.5rem"
                      fontSize={['md', 'md', 'md', 'lg', 'lg', 'lg']}
                      p={['8px 30px']}
                      border="0.5px solid #E2E8F0"
                      href={deal?.pitch_deck}
                      target="_blank"
                      fontWeight="bold"
                      download
                    >
                      Download Pitch Deck
                    </Link>
                    <Label>
                      Last updated: {dayjs(deal?.created_at).fromNow()}
                    </Label>
                  </WithResponsiveLayout>
                </VStack>
              )}
            </VStack>
          </FieldSection>
          {bonusQuestions?.[0]?.answer_text && !shouldBlurAvatar && (
            <YoutubePlayer url={bonusQuestions?.[0]?.answer_text} />
          )}
          {!shouldBlurAvatar && (
            <FieldSection
              canCollapsible
              header={
                <HStack
                  w="full"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Text fontSize="lg" fontWeight="semibold" color="#00C9FF">
                    Indicated Interest
                  </Text>
                </HStack>
              }
              fields={indicatedInterestFields}
              numItemsPerRow={4}
              isChildInFlex
            >
              <WithResponsiveLayout
                w="full"
                justifyContent="space-between"
                alignItems="end"
              >
                <Button
                  size={['sm', 'sm', 'md', 'xl']}
                  variant="filled"
                  borderRadius={['0.5rem', '0.5rem', '0.5rem', '0.5rem']}
                  p={['15px 54px']}
                  bg="#00C9FF"
                  fontSize="lg"
                  onClick={onOpen}
                >
                  Change Bid
                </Button>

                <Label>Last updated: {dayjs(deal?.updated_at).fromNow()}</Label>
              </WithResponsiveLayout>
            </FieldSection>
          )}
          <FieldSection
            canCollapsible
            header="Fundraise Details"
            fields={fundraiseFields}
          />
          <FieldSection
            canCollapsible
            header="Company Information"
            fields={companyInformationFields}
            numItemsPerRow={3}
          />

          {!shouldBlurAvatar && (
            <ViewBonusQuestions questions={bonusQuestions} />
          )}
          {/* 
      <WrapFieldSection w="full" gap="24.4px">
        <DHeading>Domains</DHeading>
        <HStack gap="1rem" flexWrap="wrap">
          {sectors.map((sector: string) => (
            <TagWithDot key={sector} color="green.500">
              {sector ?? ''}
            </TagWithDot>
          ))}
          {domains.map((domain: string) => (
            <DTag key={domain} color="#D432D7">
              {domain ?? ''}
            </DTag>
          ))}
        </HStack>
      </WrapFieldSection> */}

          <DetailedMavenRating
            avgRating={deal?.maven_rating_avg_in_sector}
            dataQuality={deal?.bs0_confidence_avg}
            ratingFields={ratingFields}
            heading="Expert Ratings"
            canCollapsible
          />
          {/* Latest Deal Activities
           */}
          <WrapFieldSection maxW="calc(100vw - 20px)" gap="0">
            <HStack justifyContent="space-between" w="full">
              <DHeading
                fontSize="md"
                w="full"
                aria-expanded={!shouldCollapseAllReviews}
                onClick={setShouldCollapseAllReviews.toggle}
                cursor={'pointer'}
              >
                {/* {!shouldBlurAvatar ? 'All Reviews' : 'Latest Deal Activities'} */}
                All Reviews
              </DHeading>
              <ChevronDown
                transitionDuration="0.3s"
                transform={`rotate(${shouldCollapseAllReviews ? 180 : 0}deg)`}
                boxSize={[4, 4, 4, 6, 6, 6]}
                color="accent.main01"
              />
            </HStack>
            <Stack maxW="calc(100vw - 20px)" gap="0">
              <Collapse in={shouldCollapseAllReviews} animateOpacity>
                {dealActivities?.map(
                  (dealActivity: TLatestDealActivities, index: number) => (
                    <VStack
                      gap="0"
                      w="full"
                      overflow="hidden"
                      onClick={() => {
                        handleClickOnMavenDetail(dealActivity)
                      }}
                      cursor="pointer"
                      _hover={{
                        bg: 'gray.700',
                      }}
                    >
                      <Review key={uniqueId()} dealActivity={dealActivity} />
                      {index !== dealActivities.length - 1 && (
                        <Divider
                          w="full"
                          h="0.8px"
                          color="#343A46"
                          opacity={0.4}
                        />
                      )}
                    </VStack>
                  ),
                )}
              </Collapse>
            </Stack>

            {/* {shouldBlurAvatar && (
              <TableContainer w="full">
                <Table variant="unstyled" sx={{ th: { py: '8px', pt: 0 } }}>
                  <Thead>
                    <Tr>
                      <Th>
                        <Label textTransform="none" fontSize="13px">
                          User ID
                        </Label>
                      </Th>
                      <Th>
                        <Label textTransform="none" fontSize="13px">
                          Sector Experience
                        </Label>
                      </Th>
                      <Th>
                        <Label textTransform="none" fontSize="13px">
                          Rating
                        </Label>
                      </Th>
                      <Th>
                        <Label textTransform="none" fontSize="13px">
                          Comment
                        </Label>
                      </Th>
                      <Th>
                        <Label textTransform="none" fontSize="13px">
                          Updated
                        </Label>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dealActivities?.map(
                      (dealActivity: TLatestDealActivities) => {
                        return (
                          <Tr
                            _hover={{ bg: '#3f444559' }}
                            // _active={{ bg: '#00C9FF' }}
                            cursor="pointer"
                            color="white"
                            key={uniqueId()}
                          >
                            <Td
                              onClick={() => {
                                handleClickOnMavenDetail(dealActivity)
                              }}
                              _hover={{
                                p: { color: 'blue.300' },
                              }}
                            >
                              <UserID
                                avatar={
                                  dealActivity.users.avatar ?? DEFAULT_AVATAR
                                }
                                id={getFirstSevenCharacters(
                                  dealActivity.maven_user_id ?? '',
                                )}
                              />
                            </Td>
                            <Td>
                              <VStack gap="8px" alignItems="start">
                                {dealActivity?.expInSectors.map(
                                  (expInSector) => {
                                    return (
                                      <SectorExperience
                                        key={`${expInSector?.exp_in_sector}${expInSector?.sector_name} Level (${expInSector?.maven_level})`}
                                        name={`${expInSector?.sector_name} (${expInSector?.exp_in_sector} XP)`}
                                        color={
                                          SECTORS_MAP_DOT_COLORS?.[
                                            expInSector?.sector_name
                                          ]
                                        }
                                      />
                                    )
                                  },
                                )}
                              </VStack>
                            </Td>
                            <Td isNumeric>
                              <TextValue
                                fontSize="15px"
                                fontWeight="normal"
                                color={getRateColor(
                                  dealActivity.bs_average ?? 0,
                                )}
                              >
                                {dealActivity.bs_average ?? 0}
                              </TextValue>
                            </Td>
                            <Td minW="400px">
                              <MavenComments
                                evalComment={dealActivity.eval_comments}
                                positiveComment={dealActivity?.positive_comment}
                                negativeComment={dealActivity?.negative_comment}
                                shouldWrapText
                                canExpandable
                              />
                            </Td>
                            <Td>
                              <TextValue fontSize="15px" fontWeight="normal">
                                {formatDateInDays(dealActivity.created_at)}
                              </TextValue>
                            </Td>
                          </Tr>
                        )
                      },
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            )} */}
          </WrapFieldSection>
          <ChangeBidModal
            deal={deal}
            isOpen={isOpen}
            onClose={() => {
              onClose?.()
              onBack?.()
            }}
          />
          <MavenRatingDetail
            dealActivity={selectedDealActivity}
            isOpen={isOpenMavenDetail}
            onClose={onCloseMavenDetail}
          />
        </VStack>
      </GridItem>
      {!shouldBlurAvatar && (
        <GridItem>
          <VStack gap={['1rem', '1rem', '1rem', '1rem', '2rem', '2rem']}>
            <DealStatus defaultPriority={deal?.priority} />

            <CommentList dealID={deal?.deal_id} />
          </VStack>
        </GridItem>
      )}
    </Grid>
  )
}

export default DealDetail
