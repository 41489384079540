import { useEffect, useState } from 'react'

import {
  Box,
  HStack,
  Heading,
  Stack,
  VStack,
  useBoolean,
} from '@chakra-ui/react'

import { NAVBAR_WIDTH } from 'constants/common'
import { sliderSettings } from 'constants/sliders'
import useMounted from 'hooks/useMounted'
import uniqueId from 'lodash/uniqueId'
import { useHistory } from 'react-router'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { FullSpanBannerCard } from 'components/common/other/deal-details/display'

import { useDashboardQuery } from 'modules/dashboard/hooks'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import { PreviousNextSection } from '../other'

const _sliderSettings = {
  ...sliderSettings,
  responsive: [
    ...sliderSettings.responsive.filter(
      (rs) => ![2048, 1536].includes(rs.breakpoint),
    ),
    {
      breakpoint: 2048,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
  ],
}

const TopStartups = () => {
  const history = useHistory()
  const [slider, setSlider] = useState<Slider | null>()
  const [deals, setDeals] = useState<IDealRow[] | []>([])
  const [isLoading, toggle] = useBoolean()
  const { fetchTopStartups } = useDashboardQuery()
  const isMounted = useMounted()

  const handleCardClick = (deal: IDealRow | undefined) => {
    deal?.deal_id && history.push(`/deal/${deal?.deal_id}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (deals?.length === 0) {
        toggle.on()
        const fetchedDeals = await fetchTopStartups()
        setDeals(fetchedDeals)
        toggle.off()
      }
    }

    isMounted && fetchData()
  }, [isMounted])

  return (
    // <WithSpinnerLoading isLoading={isLoading}>
    <VStack
      gap="1rem"
      as="section"
      color="white"
      alignItems="start"
      // bg="#171923"
      // borderRadius="18px"
      // p="24px"
      w="full"
    >
      <HStack w="full" justifyContent="space-between">
        <Heading fontSize="20px" fontWeight="bold">
          Top Startups
        </Heading>
        <PreviousNextSection
          onNext={slider?.slickNext}
          onPrevious={slider?.slickPrev}
        />
      </HStack>

      <Stack
        w={[
          'calc(100vw - 20px)',
          'calc(100vw - 20px)',
          'calc(100vw - 80px)',
          'calc(100vw - 80px)',
          `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
          `calc(100vw - 80px - ${NAVBAR_WIDTH}px)`,
        ]}
      >
        {/* remove aside width and paddings */}
        <Slider
          {..._sliderSettings}
          ref={(slider) => {
            setSlider(slider)
          }}
        >
          {deals?.map((deal: IDealRow) => {
            return (
              <Box w="full" px="0.5rem" key={uniqueId()}>
                {/* <Startups key={uniqueId()} /> */}
                {/* <Card
                  isLoading={isLoading}
                  flex="1"
                  key={uniqueId()}
                  deal={deal}
                  onClick={handleCardClick}
                /> */}
                <FullSpanBannerCard
                  isLoading={isLoading}
                  flex="1"
                  key={uniqueId()}
                  deal={deal}
                  onClick={handleCardClick}
                />
              </Box>
            )
          })}
        </Slider>
      </Stack>
    </VStack>
  )
}

export default TopStartups
