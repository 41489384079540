import {
  BadgeProps,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { Star } from '../Icons'

export const MavenRating = ({
  total_num_mavens_for_deal,
  percent,
  rate,
  isHorizontal,
  ...rest
}: {
  total_num_mavens_for_deal?: number | string
  rate: number | string | undefined
  percent: number | string
  isHorizontal?: boolean | undefined
} & BadgeProps) => {
  const textColor = useColorModeValue('row.color', '#101010')
  const percentColor = useColorModeValue(
    'rgba(255, 255, 255, 0.60)',
    'rgba(16, 16, 16, 0.60)',
  )
  const Wrap = isHorizontal ? HStack : VStack
  const WrapInner = isHorizontal ? VStack : HStack

  return (
    <Wrap gap="0.25rem">
      <HStack gap="0.25rem">
        <Text
          fontSize={['sm', 'sm', 'sm', 'sm', 'lg']}
          fontWeight="bold"
          lineHeight="140%"
          color={textColor}
          letterSpacing="0.04rem"
        >
          {parseFloat((rate ?? 0) + '')?.toFixed(1)}
        </Text>

        <Star w="1.375rem" h="1.375rem" />
      </HStack>
      <WrapInner
        fontSize="xs"
        lineHeight="110%"
        color={percentColor}
        gap={'2px'}
        alignItems="start"
        flexDir={['column', 'column', 'row', 'row', 'row']}
      >
        <Text color={textColor}>
          <strong>{total_num_mavens_for_deal}</strong>
        </Text>{' '}
        <Text>experts rated</Text>
      </WrapInner>
    </Wrap>
  )
}
