const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: '140%',
    tall: 1.625,
    taller: '2',
    3: '.75rem',
    3.5: '0.875rem', //14px
    4: '1rem',
    4.5: '1.188rem',
    5: '1.25rem',
    5.5: '1.313rem', //21px
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.688rem',
    big: '4.25rem',
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    heading: `'Pp Object Sans Variable', Open Sans, sans-serif`,
    body: `'Pp Object Sans Variable', Open Sans, sans-serif`,
  },

  fontSizes: {
    html: '1rem',
    xs: '0.625rem',
    sm: '0.75rem', //12px
    md: '0.875rem', // 14px
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.2375rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
    custom: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      '2md': '1.063rem', //17px
      lg: '1rem',
      '2lg': '1.188rem',
      xl: '1.25rem',
      '2xl': '2rem',
      big: '4.25rem',
    },
    deploy: {
      preview: {
        md: '1.125rem',
      },
      '4xl': '2.375rem',
    },
  },
}

export default typography
