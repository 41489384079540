import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CALENDAR_LINK } from 'constants/common'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import EffortlessSynchronization from '../components/common/EffortlessSynchronization'
import SmallText from '../components/common/SmallText'
import UnlockingPotentialPrivateCapital from '../components/common/UnlockingPotentialPrivateCapital'
import { Footer } from '../components/v2/Footer'

const IntelligenceNetwork = () => {
  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      bg="#1F1F2F"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      w="full"
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#8B9DFB"
            fontSize="20px"
            fontWeight="medium"
            lineHeight="110%"
            textAlign="center"
          >
            The future of venture deal sourcing & research
          </Text>

          <Text
            color="#FFF"
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
            textAlign="center"
          >
            Deploy’s Global Intelligence Network
          </Text>
        </VStack>

        <SmallText>
          Deploy is building a new Venture Operating System to rewire the
          innovation economy.
        </SmallText>

        <Link href={CALENDAR_LINK}>
          <Button
            minW="300px"
            color="#1A202A"
            bg="#fff"
            minH="60px"
            borderRadius="60px"
            fontSize="20px"
          >
            request demo
          </Button>
        </Link>
      </VStack>

      <WithCenterLayout w="full">
        <Image src="images/shapes.png" />
      </WithCenterLayout>

      {/* <Box
        border="5px solid rgba(79, 70, 229, 0.40)"
        w="256px"
        h="256px"
        zIndex={0}
        pos="relative"
        mt="40px"
      >
        <Box
          border="5px solid rgba(79, 70, 229, 0.60)"
          w="232px"
          h="232px"
          zIndex={1}
          transform="rotate(75deg)"
          transformOrigin="center"
          pos="absolute"
        />

        <Box
          border="5px solid rgba(79, 70, 229, 0.80)"
          w="208px"
          h="208px"
          zIndex={2}
          transform="rotate(60deg)"
          transformOrigin="center"
          pos="absolute"
        />

        <Box
          border="5px solid #582CFF"
          w="184px"
          h="184px"
          zIndex={3}
          transform="rotate(45deg)"
          transformOrigin="center"
          pos="absolute"
        />
        <Box
          border="5px solid #312E81"
          bg="#312E81"
          w="160px"
          h="160px"
          zIndex={4}
          transform="rotate(30deg)"
          transformOrigin="center"
          pos="absolute"
        />
      </Box> */}
    </VStack>
  )
}

const PrivateMarketInsights = () => {
  return (
    <VStack
      gap="32px"
      alignItems="center"
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="8px" alignItems="center">
        <Text
          color="#FFF"
          fontSize={['32px', '32px', '32px', '56px', '56px', '56px']}
          fontWeight="bold"
          lineHeight="110%"
        >
          Industry Experts + AI
        </Text>
        <Text
          color="#8B9DFB"
          fontSize="20px"
          fontWeight="medium"
          lineHeight="110%"
        >
          Unparalleled Private Market Insights
        </Text>
      </VStack>

      <SmallText>
        Unlock network effects to diversify into new verticals or geographies,
        providing unparalleled access into new opportunities.
      </SmallText>
    </VStack>
  )
}

const FindTheOutliers = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#8B9DFB"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Cast a wider net -
          </Text>
          <Text
            color="#FFF"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            First to find the outliers.
          </Text>
        </VStack>

        <SmallText>
          We’re building a new kind of social network where people can convert
          industry expertise and social capital into real capital. Deploy is
          powered by a community of connectors and industry experts - people who
          are deeply connected in their industries and therefore know who’s
          building what, and whether it works.
        </SmallText>
        <SmallText>
          At scale, this network creates a global scouting pool working in
          tandem with a diverse range of expertise to deliver deals and
          transparent analysis for all.
        </SmallText>
      </VStack>

      <Box
        pos="relative"
        flex="1"
        alignSelf="stretch"
        maxW="610px"
        minH="400px"
      >
        <Image
          src="images/outlier1.png"
          pos="absolute"
          top="13%"
          zIndex="1"
          left="0"
        />
        <Image src="images/outlier2.png" pos="absolute" top="0" right="0" />
      </Box>
    </WithResponsiveLayout>
  )
}

const GetScoutNetWork = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <WithCenterLayout flex="1" alignSelf="stretch">
        <Image src="images/get-scout-network.png" />
      </WithCenterLayout>

      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#8B9DFB"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Don’t miss deals.
          </Text>
          <Text
            color="#FFF"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Get the most out of your scout network.
          </Text>
        </VStack>

        <SmallText>
          In our experience, many people contribute to the investment process
          along the way - introductions, referrals, expert advice, and mentoring
          - often not directly benefiting from their involvement. The venture
          capital and wider private equity industry is evolving and Deploy is
          building the tools to accelerate that movement.
        </SmallText>
        <SmallText>
          In an era, when simple acts like browsing photos or shopping online
          can create powerful data sets and insights about individuals, so too
          can the granular contributions of participants in nearly any process.
        </SmallText>

        <Stack gap="4px" w="full">
          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>
            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Data Integration - Plug into any existing workflows
            </Text>
          </HStack>

          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>
            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Custom Industry Reports - get instant insights on your deals
            </Text>
          </HStack>

          <HStack gap="8px" w="full" justifyContent="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.486 17.3709C10.3547 17.3711 10.2247 17.3455 10.1034 17.2954C9.98204 17.2452 9.87181 17.1717 9.77899 17.0789L5.53699 12.8359C5.44141 12.7437 5.36516 12.6334 5.31266 12.5114C5.26017 12.3895 5.23249 12.2583 5.23125 12.1255C5.23 11.9927 5.25521 11.861 5.3054 11.7381C5.3556 11.6151 5.42977 11.5034 5.52359 11.4095C5.61742 11.3155 5.72902 11.2412 5.85188 11.1908C5.97474 11.1405 6.1064 11.1151 6.23918 11.1161C6.37196 11.1172 6.50319 11.1447 6.62523 11.197C6.74727 11.2493 6.85767 11.3254 6.94999 11.4209L10.485 14.9559L16.85 8.59285C17.0375 8.40521 17.2919 8.29975 17.5571 8.29965C17.8224 8.29956 18.0768 8.40485 18.2645 8.59235C18.4521 8.77986 18.5576 9.03423 18.5577 9.2995C18.5578 9.56477 18.4525 9.81921 18.265 10.0069L11.193 17.0789C11.1002 17.1717 10.9899 17.2452 10.8686 17.2954C10.7473 17.3455 10.6173 17.3711 10.486 17.3709Z"
                fill="#8B9DFB"
              />
            </svg>

            <Text color="#fff" fontWeight="normal" fontSize="18px">
              Expand Network - instantly ramp up your resources
            </Text>
          </HStack>
        </Stack>
      </VStack>
    </WithResponsiveLayout>
  )
}

const InvestorsPage = () => {
  return (
    <>
      <IntelligenceNetwork />
      <VStack gap="20px" maxW="1600px">
        <PrivateMarketInsights />
        <FindTheOutliers />
        <GetScoutNetWork />
        <EffortlessSynchronization />
        <UnlockingPotentialPrivateCapital />
      </VStack>
      <Footer />
    </>
  )
}

export default InvestorsPage
