import React, { useState } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { REACTION_TYPES } from 'constants/common'
import { TABLES_NAME } from 'constants/tables'
import dayjs from 'dayjs'
import { useAuth, useDLToast, useHover, useTeamNotes } from 'hooks'
import { TTeamNotes } from 'hooks/useTeamNotes'
import uniqueId from 'lodash/uniqueId'

import CommentAction from './CommentAction'
import CommentHeader from './CommentHeader'
import CommentReplyActions from './CommentReplyActions'
import CommentText from './CommentText'
import CommentTextAction from './CommentTextAction'

function DeleteCommentDialog({
  isOpen,
  onClose,
  onDelete,
}: {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}) {
  const cancelRef = React.useRef(null)

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" fontWeight="bold">
            Delete Comment
          </AlertDialogHeader>

          <AlertDialogBody fontSize="md">
            Are you sure you want to delete this message? This cannot be undone.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button borderRadius={1} onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="red"
              onClick={onDelete}
              ml={3}
              borderRadius={1}
              _hover={{ opacity: 0.8 }}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export const BOX_STATES = {
  edit: 'edit',
  delete: 'delete',
  reply: 'reply',
}

const fetchRepliesOnNote = async (
  noteID: string,
  dealID: string,
  teamID: string | null,
) => {
  const { data }: PostgrestResponse<TTeamNotes> = await supabase
    .from(TABLES_NAME.team_notes)
    .select('*, users(avatar, first_name, last_name), reactions(reaction_type)')
    .eq('team_id', teamID)
    .eq('deal_id', dealID)
    .eq('parent_note_id', noteID)
    .order('created_at', { ascending: true })

  return data
}

const CommentBox = ({
  comment,
  isChild = false,
  loadTeamNotes,
  refetchRepliesOnNotesFromParent,
}: {
  comment: TTeamNotes
  isChild?: boolean | undefined
  loadTeamNotes?: () => void
  refetchRepliesOnNotesFromParent?: () => void
}) => {
  const { getUser } = useAuth()
  const [showReplyBox, toggle] = useBoolean()
  const [isLike, toggleReaction] = useBoolean(
    comment?.reactions && comment?.reactions?.length > 0,
  )

  const [boxState, setBoxState] = useState<string>('')
  const [repliesNotes, setRepliesNotes] = useState<TTeamNotes[] | null>()
  const [isLoadingRepliesNotes, setLoadingNotesNotes] = useBoolean()
  const { isHovered, events } = useHover()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isEditCommentState = boxState === BOX_STATES.edit
  const canEditOrDeleteComment = comment?.user_id === getUser()?.user?.id
  //   let chatsWatcher: any
  const refetchRepliesOnNotes = async () => {
    setLoadingNotesNotes.on()
    if (comment?.deal_id) {
      const replies = await fetchRepliesOnNote(
        comment?.note_id,
        comment?.deal_id,
        comment?.team_id ?? null,
      )
      setRepliesNotes(replies)
    }
    setLoadingNotesNotes.off()
    toggle.on()
  }

  const handleEditComment = async (editedComment: string) => {
    try {
      const { error } = await supabase
        .from(TABLES_NAME.team_notes)
        .update({
          note_text: editedComment,
          updated_at: new Date(),
        })
        .eq('note_id', comment.note_id)

      if (error) {
        return
        // showErrorToast('Update comment unsuccessfully')
      }

      resetState()
      loadTeamNotes?.()
      !isChild && refetchRepliesOnNotes?.()
      isChild && refetchRepliesOnNotesFromParent?.()
      setRepliesNotes(() =>
        repliesNotes?.map((reply) =>
          reply.note_id === comment.note_id
            ? { ...reply, note_text: editedComment }
            : reply,
        ),
      )

      // showSuccessToast('Update comment successfully')
    } catch (err) {
      // showErrorToast('Update comment unsuccessfully')
    }
  }

  const handleDeleteComment = async () => {
    try {
      const { error } = await supabase
        .from(TABLES_NAME.team_notes)
        .delete()
        .eq('note_id', comment?.note_id)

      if (error) {
        // showErrorToast('Delete comment unsuccessfully')
        return
      }

      loadTeamNotes?.()
      !isChild && refetchRepliesOnNotes()
      isChild && refetchRepliesOnNotesFromParent?.()
      onClose()
      // showSuccessToast('Delete comment successfully')
    } catch (err) {
      // showErrorToast('Delete comment unsuccessfully')
    }
  }

  const resetState = () => {
    toggle.off()
    setBoxState('')
  }

  const handleReactionEvent = async () => {
    toggleReaction.toggle()
    const isLiked = !isLike
    try {
      if (isLiked) {
        await supabase.from('reactions').insert({
          user_id: getUser()?.user?.id,
          team_note_id: comment?.note_id,
          reaction_type: REACTION_TYPES.like,
        })
      } else {
        await supabase
          .from('reactions')
          .delete()
          .eq('user_id', getUser()?.user?.id)
          .eq('team_note_id', comment?.note_id)
      }

      loadTeamNotes?.()
      !isChild && refetchRepliesOnNotes()
      isChild && refetchRepliesOnNotesFromParent?.()
      // showSuccessToast('Delete comment successfully')
    } catch (err) {
      console.warn(err)
      // showErrorToast('Delete comment unsuccessfully')
    }
  }

  //   useEffect(() => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     chatsWatcher = supabase
  //       .channel('team-notes:replies')
  //       .on(
  //         'postgres_changes',
  //         { event: '*', schema: 'public', table: 'team_notes' },
  //         async () => {
  //           refetchRepliesOnNotes()
  //         },
  //       )
  //       .subscribe()

  //     return () => {
  //       chatsWatcher?.unsubscribe()
  //     }
  //   }, [])

  // useEffect(() => {
  // handleReactionEvent()
  // }, [isLike])

  return (
    <VStack
      gap={['6px', '6px', '6px', '7.5px', '7.5px', '7.5px']}
      alignItems="start"
      w="full"
      {...events}
    >
      <HStack
        gap={['0.5rem', '0.5rem', '0.5rem', '12.5px', '12.5px', '12.5px']}
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        <Avatar w="40px" h="40px" src={comment?.users?.avatar ?? ''} />
        <VStack
          gap="0.5rem"
          alignItems="center"
          w="full"
          justifyContent="center"
        >
          <VStack
            gap="0rem"
            alignItems="center"
            w="full"
            justifyContent="center"
          >
            <CommentHeader
              reactionCount={comment?.reactions?.length ?? undefined}
              name={`${comment?.users?.first_name} ${comment?.users?.last_name}`}
              time={comment?.created_at ?? ''}
              isUpdated={
                !dayjs(comment?.updated_at).isSame(dayjs(comment?.created_at))
              }
            />

            {/* </WithSpinnerLoading> */}
          </VStack>
        </VStack>
      </HStack>

      <VStack
        w="full"
        gap={['0.5rem', '0.5rem', '0.5rem', '1rem', '12.5px', '12.5px']}
        alignItems="start"
      >
        <HStack>
          <CommentText>{comment?.note_text}</CommentText>
          {/* <CommentTextAction shouldShow={isHovered} /> */}

          <Menu size="sm" isLazy placement="right">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<CommentTextAction shouldShow={isHovered} />}
              variant="outline"
              border="unset"
              bg="none"
              _hover={{ bg: 'unset' }}
            />
            <MenuList
              minW="fit-content"
              display="flex"
              p="0"
              border="none"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.2)"
            >
              <MenuItem
                w="fit-content"
                justifyContent="center"
                alignItems="center"
                borderTopLeftRadius="20%"
                borderBottomLeftRadius="20%"
                icon={
                  <Box
                    w="20px"
                    h="20px"
                    bgSize="auto"
                    bgRepeat="no-repeat"
                    as="i"
                    bgImage="url(images/reaction.png)"
                    bgPosition="0px -740px"
                    borderRadius="0"
                    filter="invert(27%) sepia(95%) saturate(3116%) hue-rotate(212deg) brightness(99%) contrast(105%)"
                    sx={
                      isLike
                        ? {
                            bgPosition: '0px -740px',
                          }
                        : {
                            bgPosition: '0px -781px',
                          }
                    }
                  />
                }
                fontSize="xl"
                onClick={() => {
                  handleReactionEvent()
                  setBoxState(BOX_STATES.edit)
                }}
                sx={{
                  '.chakra-menu__icon-wrapper': { mr: 0 },
                }}
              />
              {canEditOrDeleteComment && (
                <>
                  <MenuItem
                    w="fit-content"
                    justifyContent="center"
                    alignItems="center"
                    icon={
                      <EditIcon
                        w="20px"
                        h="20px"
                        filter="invert(27%) sepia(95%) saturate(3116%) hue-rotate(212deg) brightness(99%) contrast(105%)"
                        m="0 !important"
                        p="0"
                      />
                    }
                    fontSize="xl"
                    onClick={() => {
                      setBoxState(BOX_STATES.edit)
                    }}
                    sx={{
                      '.chakra-menu__icon-wrapper': { mr: 0 },
                    }}
                  />
                  <MenuItem
                    justifyContent="center"
                    alignItems="center"
                    w="fit-content"
                    icon={
                      <DeleteIcon
                        w="20px"
                        h="20px"
                        filter="invert(0%) sepia(100%) saturate(10000%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                        m="0 !important"
                        p="0"
                      />
                    }
                    fontSize="xl"
                    onClick={onOpen}
                    borderTopRightRadius="20%"
                    borderBottomRightRadius="20%"
                    sx={{
                      '.chakra-menu__icon-wrapper': { mr: 0 },
                    }}
                  />
                </>
              )}
            </MenuList>
          </Menu>
          <DeleteCommentDialog
            isOpen={isOpen}
            onClose={onClose}
            onDelete={handleDeleteComment}
          />
        </HStack>

        {/* <WithSpinnerLoading isLoading={isLoadingRepliesNotes}> */}
        {showReplyBox && repliesNotes && repliesNotes?.length > 0 && (
          <VStack gap="1rem" p={4} w="full">
            {repliesNotes?.map((teamNote: TTeamNotes) => (
              <CommentBox
                key={uniqueId()}
                comment={teamNote}
                isChild={true}
                refetchRepliesOnNotesFromParent={refetchRepliesOnNotes}
              />
            ))}
          </VStack>
        )}

        {(showReplyBox || isEditCommentState) && (
          <CommentReplyActions
            isEditCommentState={isEditCommentState}
            handleEditComment={handleEditComment}
            resetState={resetState}
            comment={comment}
            refetchRepliesOnNotes={
              isChild ? refetchRepliesOnNotesFromParent : refetchRepliesOnNotes
            }
          />
        )}
        {!isChild && (
          <CommentAction
            comment={comment}
            isShowingReplyBox={showReplyBox}
            isLike={isLike}
            onReaction={handleReactionEvent}
            onCancel={resetState}
            onReply={() => {
              setBoxState(BOX_STATES.reply)
              !isChild && refetchRepliesOnNotes()
              isChild && refetchRepliesOnNotesFromParent?.()
            }}
          />
        )}
      </VStack>
    </VStack>
  )
}
export default CommentBox
