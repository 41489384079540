import { Box, Image, Text } from '@chakra-ui/react'

import dayjs from 'dayjs'
import useDayjsWithUserTZ from 'hooks/useDayjsWithUserTZ'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

const LIKE_IMG =
  'https://scontent.fsgn2-5.fna.fbcdn.net/m1/v/t6/An-HX414PnqCVzyEq9OFFdayyrdj8c3jnyPbPcierija6hpzsUvw-1VPQ260B2M9EbxgmP7pYlNQSjYAXF782_vnvvpDLxvJQD74bwdWEJ0DhcErkDga6gazZZUYm_Q.png?ccb=10-5&oh=00_AfDHdb9tb70QLDjEDIkmXniDRx5rqyAb-fQrFN0xcv2zDA&oe=6631E0A3&_nc_sid=7da55a'
const CommentHeader = ({
  name,
  time,
  reactionCount,
  isUpdated = false,
}: {
  name: string
  time: string
  reactionCount?: number | undefined
  isUpdated?: boolean | undefined
}) => {
  return (
    <WithResponsiveLayout
      gap={['0', '0', '0', '0.5rem', '0.5rem', '0.5rem']}
      justifyContent="start"
      alignItems={['start', 'start', 'start', 'start', 'center', 'center']}
      w="full"
      color="white"
      lineHeight="normal"
    >
      <Text fontWeight="medium" fontSize={['lg', 'lg', 'lg', 'lg', 'lg']}>
        {name}
      </Text>

      <Text
        fontWeight="normal"
        fontSize={['sm', 'sm', 'sm', 'sm', 'md']}
        color="#94A3B8"
      >
        {/* {dayjs(time ?? new Date())?.format('MMMM D, YYYY h:mmA')} -{' '} */}
        {dayjs(time).fromNow()}
      </Text>
      {/* {reactionCount && (
        <WithCenterLayout gap="0.2rem" color="white">
          <Text fontSize={['sm', 'sm', 'sm', 'sm', 'lg']} color="white">
            {reactionCount}
          </Text>
          <Image
            src={LIKE_IMG}
            w={['14px', '14px', '14px', '18px', '18px', '18px']}
            h={['14px', '14px', '14px', '18px', '18px', '18px']}
          />
        </WithCenterLayout>
      )} */}
      {isUpdated && (
        <>
          <Box
            display={['none', 'none', 'none', 'inherit']}
            borderRadius="full"
            minW="4px"
            minH="4px"
            bg="grey"
            opacity={0.8}
          />
          <Text fontWeight="normal" color="white" fontSize="sm">
            Edited
          </Text>
        </>
      )}
    </WithResponsiveLayout>
  )
}

export default CommentHeader
