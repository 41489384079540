import React from 'react'

import { Spinner, Stack } from '@chakra-ui/react'

import WithCenterLayout from './WithCenterLayout'

const WithSpinnerLoading: React.FC<{
  children: React.ReactNode
  isLoading: boolean
}> = ({ children, isLoading }) => {
  return (
    <>
      {/* {!isLoading && children} */}
      {children}
      {isLoading && (
        <WithCenterLayout w="full" h="full" mt="8px">
          <Spinner color="solid.pink" size="lg" />
        </WithCenterLayout>
      )}
    </>
  )
}

export default WithSpinnerLoading
