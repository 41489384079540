import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CALENDAR_LINK } from 'constants/common'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import SmallText from './SmallText'

const IntellectualCapital = () => {
  return (
    <Stack
      bg="#0F172A"
      alignItems="center"
      justifyContent="center"
      p={['20px', '20px', '20px', '40px', '40px', '40px']}
      w="full"
    >
      <WithResponsiveLayout
        maxW="1600px"
        gap={['40px', '40px', '40px', '80px', '80px', '80px']}
        p={['20px', '20px', '20px', '40px', '40px', '40px']}
      >
        <VStack gap="32px" alignItems="start">
          <VStack gap="8px" alignItems="start" w="full">
            <Text
              color="#FFF"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Deploy
            </Text>
            <Text
              color="#8B9DFB"
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              fontWeight="bold"
              lineHeight="110%"
            >
              Intellectual Capital
            </Text>
          </VStack>

          <SmallText>
            Have certain domain expertise? You may qualify as a deploy expert!
            Schedule a call with one of our specialists to learn more.
          </SmallText>

          <Link href={CALENDAR_LINK}>
            <Button
              minW="300px"
              color="#1A202A"
              bg="#fff"
              minH="60px"
              borderRadius="60px"
              fontSize="20px"
            >
              Request Account
            </Button>
          </Link>
        </VStack>

        <WithCenterLayout alignSelf="stretch">
          <Image src="images/intellectual-capital.png" />
        </WithCenterLayout>
      </WithResponsiveLayout>
    </Stack>
  )
}

export default IntellectualCapital
