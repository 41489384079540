const borders = {
  common: '1px solid rgba(255, 255, 255, 0.13)',
  commonInLightMode: '1px solid rgba(16, 16, 16, 0.13)',
  transparent: '1px solid transparent',
  select: {
    darkMode: '1px solid rgba(255, 255, 255, 0.12)',
    lightMode: '1px solid rgba(16, 16, 16, 0.12)',
  },
  input: {
    normal: '1px solid #8B9DFB',
    focus: '1px solid main02',
    invalid: '1px solid #FF7D83',
  },
  screenRoom: {
    activatedInLightMode: '1px solid #00C9FF',
    activated: '1px solid #AEFE2C',
    normal: '1px solid rgba(255, 255, 255, 0.13)',
    lightMode: '1px solid rgba(16, 16, 16, 0.13)',
  },
  activeIntros: {
    activatedInLightMode: '1px solid #747D80',
    activated: '1px solid #FFFFFF',
    normal: '1px solid rgba(255, 255, 255, 0.13)',
    lightMode: '1px solid rgba(16, 16, 16, 0.13)',
  },
  alert: {
    success: '1px solid #2FEA00',
    error: '1px solid #FE2C2C',
    info: '1px solid #8B9DFB',
    warning: '1px solid #FED02C',
  },
}

export default borders
