import { ReactNode, memo } from 'react'

import {
  Button,
  Divider,
  HStack,
  StackProps,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import { useMobile } from 'hooks'
import { IDeal } from 'interfaces/models'
import { breakpointValues } from 'theme/foundations/breakpoints'

import { GridHeaderText } from 'components/common/DLGrid/GridHeader'
import { SectorsPath } from 'components/common/other/deal-details/display'

import { useFormatCell } from 'modules/dashboard/hooks'
import { TDealLeaderBoard } from 'modules/dashboard/hooks/useLeaderBoardQueries'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import DetailedDealInSmView from './DetailedDealInSmView'

const mappingDealFields = {
  fundraising_round: 'Round',
  operating_currency: 'Operating Currency',
  estimated_arr: 'Estimated ARR',
  pre_money_valuation: 'Pre-Money Valuation',
  post_money_valuation: 'Post-Money Valuation',
  product_maturity: 'Product Maturity',
  preferred_instrument: 'Preferred Instrument',
  consider_instruments: 'Considered Instrument(s)',
  minimum_ticket_size: 'Minimum Ticket Size',
  available_tickets: 'Number of Available Tickets',
  company_maturity: 'Company Maturity',
  current_raise: 'Current Raise',
  tech_used: 'Tech Used',
  business_model: 'Business Model',
  product_type: 'Product Type',
  origin_of_funds: 'Fundraising Round',
}

const fieldTypes = {
  estimated_arr: 'currency',
  pre_money_valuation: 'currency',
  post_money_valuation: 'currency',
  minimum_ticket_size: 'number',
  available_tickets: 'number',
  current_raise: 'number',
}

type TAccordionDeal = {
  estimated_arr: string
  pre_money_valuation: string
  post_money_valuation: string
  preferred_instrument: string
  consider_instruments: string
  available_tickets: string
  company_maturity: string
  tech_used: string
  business_model: string
  product_type: string
  origin_of_funds: string
}

type TAdditionalAccordionDeal = {
  current_raise: string
  minimum_ticket_size: string
  available_tickets: string
}

type ValueOf<T> = T[keyof T]

const AccordionItem = ({
  index,
  label,
  value,
  type,
  shouldBoldValue = undefined,
}: {
  index: number
  label: string
  value: ValueOf<IDeal>
  type?: string
  shouldBoldValue?: boolean
}) => {
  const colorByMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )

  const colorHeaderByMode = useColorModeValue(
    'rgba(255, 255, 255, 0.80)',
    'rgba(16, 16, 16, 0.80)',
  )
  const formattedValue = useFormatCell(value, type ?? '')

  return (
    <VStack
      alignItems="start"
      gap={['0.25rem', '0.25rem', '0.25rem', '0.25rem', '0.5rem']}
      flex={[
        '0 0 calc((100% - 4.5rem) / 2)',
        '0 0 calc((100% - 4.5rem) / 2)',
        '0 0 calc((100% - 3.75rem) / 4)',
        '0 0 calc((100% - 2.5rem) / 5)',
        '0 0 calc((100% - 2.5rem) / 5)',
      ]}
    >
      <GridHeaderText
        text={label}
        color={colorHeaderByMode}
        stackStyles={{ alignItems: 'start' }}
      />
      <GridHeaderText
        text={formattedValue}
        stackStyles={{ alignItems: 'start' }}
        fontWeight="bold"
        color={colorByMode}
      />
    </VStack>
  )
}

const AccordionWrapper = ({
  children,
  ...rest
}: { children: ReactNode } & StackProps) => {
  return (
    <HStack
      w="full"
      flexWrap="wrap"
      gap={[
        '0.75rem 4.25rem',
        '0.75rem 4.25rem',
        '0.75rem 3.75rem',
        '1.5rem 2.5rem',
        '1.5rem 2.5rem',
      ]}
      p={[0, 0, '1rem', '1.56rem 0.5rem', '1.56rem 0.5rem']}
      justifyContent="start"
      alignItems="start"
      {...rest}
    >
      {children}
    </HStack>
  )
}

const DealViewMap = {
  SMALL_VIEW: {
    wrap: DetailedDealInSmView,
    item: DetailedDealInSmView.Detail,
  },
  OTHER_VIEW: {
    wrap: AccordionWrapper,
    item: AccordionItem,
  },
} as Record<string, any>

const AccordionDeal = ({
  deal,
  callback,
  showedFields,
  additionalFields,
  ...rest
}: {
  deal?: IDeal | TDealLeaderBoard
  callback?: React.Dispatch<React.SetStateAction<IDealRow | null | undefined>>
  showedFields: TAccordionDeal
  additionalFields: TAdditionalAccordionDeal
} & StackProps) => {
  const isMobile = useMobile()
  const currentBreakpoint = useBreakpointValue(breakpointValues, {
    ssr: false,
  })

  const isSmallDevice = ['base', 'sm'].includes(currentBreakpoint as string)
  const isMediumDevice = ['md', 'lg', 'xl'].includes(
    currentBreakpoint as string,
  )

  const viewKey = isSmallDevice ? 'SMALL_VIEW' : 'OTHER'

  const dividerBg = useColorModeValue(
    'rgba(255, 255, 255, 0.06)',
    'rgba(16, 16, 16, 0.20)',
  )
  const transformedDeal = Object.entries(showedFields).map(([key]) => ({
    key,
    value: deal?.[key as keyof IDeal] ?? 'N/A',
    label: (mappingDealFields as any)?.[key] ?? '',
  }))

  const mobileFields = isMobile
    ? Object.entries(additionalFields).map(([key]) => ({
        key,
        value: deal?.[key as keyof IDeal] ?? 'N/A',
        label: (mappingDealFields as any)?.[key] ?? '',
      }))
    : []

  const AdditionalFieldWrap = DealViewMap?.[viewKey]?.wrap || AccordionWrapper
  const AdditionalItem = DealViewMap?.[viewKey]?.item || AccordionItem
  const DealFieldLayout = isSmallDevice
    ? VStack
    : isMediumDevice
      ? HStack
      : VStack

  const ActionsLayout = isSmallDevice
    ? HStack
    : isMediumDevice
      ? VStack
      : VStack

  return (
    <VStack
      as="section"
      gap="0.75rem"
      alignItems="start"
      w="full"
      px={['0.5rem', '0.5rem', '0.5rem', '1.81rem', '1.81rem']}
      {...rest}
    >
      {isMobile && (
        <AdditionalFieldWrap pb={isMobile ? 0 : 'inherit'}>
          {mobileFields.map(({ key, value, label }, index) => (
            <AdditionalItem
              type={(fieldTypes as Record<string, string>)[key]}
              key={key}
              label={label}
              value={value}
              index={index}
              shouldBoldValue
            />
          ))}
        </AdditionalFieldWrap>
      )}
      {isMobile && <Divider h="0.0625rem" bg={dividerBg} />}
      <DealFieldLayout
        alignItems="start"
        justifyContent="start"
        gap={isMediumDevice ? '1.4rem' : '1rem'}
        w="full"
      >
        <AccordionWrapper pt={isMobile ? 0 : undefined}>
          {transformedDeal.map(({ key, value, label }, index) => (
            <AccordionItem
              type={(fieldTypes as Record<string, string>)[key]}
              key={key}
              label={label}
              value={value}
              index={index}
            />
          ))}
          {deal && 'sectors_and_domains' in deal && (
            <SectorsPath sectorsAndDomains={deal?.sectors_and_domains ?? ''} />
          )}
        </AccordionWrapper>
        <ActionsLayout
          alignItems={['center', 'center', 'center', 'start', 'start']}
          minW="150px"
          p={[0, 0, '1rem', '1.56rem 0.5rem', '1.56rem 0.5rem']}
        >
          <Button
            variant="filled"
            borderRadius={3}
            size={['sm', 'sm', 'md']}
            w="full"
            onClick={() => callback?.(deal)}
          >
            Inspect Startup
          </Button>
        </ActionsLayout>
      </DealFieldLayout>
    </VStack>
  )
}

export default memo(AccordionDeal)
