/* eslint-disable no-unused-vars */
import { Fragment } from 'react'

import { Center } from '@chakra-ui/react'

import {
  ERROR_CODE_400_CHANGE_PASSWORD,
  ERROR_CODE_CHANGE_PASSWORD,
  ERROR_COMMON_MESSAGE_CHANGE_PASSWORD,
  ERROR_MESSAGE_CHANGE_PASSWORD,
  SUCCESSFULLY_UPDATE_PASSWORD_MESSAGE,
} from 'constants/supabase'
import { useDLToast } from 'hooks'
import useAuthSupeBase from 'hooks/useAuthSupeBase'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { ResetPasswordForm } from '../components'
import { ERROR_PASSWORD_DOES_NOT_MATCH_MESSAGE } from '../constants/messages'
import { RESET_DEFAULT_VALUES } from '../constants/reset-page'

/**
 *  Reset password page
 * **/
const ResetPassword = () => {
  /**
   *  Defining all variables here
   * **/
  const { updatePassword } = useAuthSupeBase()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const history = useHistory()
  const methods = useForm({
    defaultValues: RESET_DEFAULT_VALUES,
  })

  /**
   *  handle reset password form
   * **/
  const handleSubmit = async () => {
    const { password, confirmedPassword } = methods.getValues()

    if (password !== confirmedPassword) {
      showErrorToast(ERROR_PASSWORD_DOES_NOT_MATCH_MESSAGE)
      return
    }

    try {
      const { data, error } = await updatePassword(password)
      if (error?.status === ERROR_CODE_CHANGE_PASSWORD) {
        showErrorToast(ERROR_MESSAGE_CHANGE_PASSWORD)
        return
      }

      if (error?.status === ERROR_CODE_400_CHANGE_PASSWORD) {
        showErrorToast(ERROR_COMMON_MESSAGE_CHANGE_PASSWORD)
        return
      }

      data?.user && history.push('/password-confirmation')
      showSuccessToast(SUCCESSFULLY_UPDATE_PASSWORD_MESSAGE)
    } catch (e) {
      showErrorToast(ERROR_COMMON_MESSAGE_CHANGE_PASSWORD)
    }
  }

  /**
   *  Rendering component
   * **/
  return (
    <Fragment>
      <Helmet>
        <title>Reset Password Form</title>
      </Helmet>
      <Center w="full" h="full">
        <FormProvider {...methods}>
          <form
            onSubmit={methods?.handleSubmit(handleSubmit)}
            autoComplete="off"
          >
            <ResetPasswordForm />
          </form>
        </FormProvider>
      </Center>
    </Fragment>
  )
}

export default ResetPassword
