import { Tag, TagProps } from '@chakra-ui/react'

const ProminentNumber = ({
  children,
  ...rest
}: { children: React.ReactNode } & TagProps) => {
  return (
    <Tag
      color="#32D74B"
      fontSize="lg"
      fontWeight="semibold"
      lineHeight="25.6px"
      borderRadius="9.6px"
      border="1.8px solid #32D74B"
      bg="rgba(50, 215, 75, 0.15)"
      p="3.2px 12.8px"
      minW="40px"
      justifyContent="center"
      {...rest}
    >
      {children}
    </Tag>
  )
}

export default ProminentNumber
