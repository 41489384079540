import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { useAuth, useDLToast } from 'hooks'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { formatCurrency, formatDecimal } from 'utils/numbers'

import { ControlledField } from 'components/common/DLForms'
import { NumberInput } from 'components/common/DLInput'
import { FieldSection } from 'components/common/other/deal-details'
import { FlexField } from 'components/common/other/deal-details/FlexLayout'
import { Card } from 'components/common/other/deal-details/display'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import {
  CURRENCY_FIELDS,
  INDICATED_INTEREST_FIELDS,
} from 'modules/dashboard/constants/deal-details'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import { SHORTLIST_TABLE } from '../constants'
import {
  ADD_DEAL_TO_PIPELINE_SUCCESSFULLY,
  ADD_DEAL_TO_PIPELINE_UNSUCCESSFULLY,
  NUMBER_OF_TICKET_RESERVED_GREATER_THAN_AVAILABLE_TICKETS,
  NUMBER_OF_TICKET_RESERVED_NOT_A_NEGATIVE_NUMBER,
} from '../constants/messages'
import { ITicketCalculatorForm } from '../interfaces/express-interest-modal'

const ExpressInterestModal = ({
  deal,
  isOpen,
  onClose,
}: {
  deal: IDealRow
  isOpen: boolean
  onClose: () => void
}) => {
  /**
   *  helper for showing error toast
   * **/
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { getUser } = useAuth()
  const [fields, setFields] = useState<FlexField[]>([])
  const methods = useForm<ITicketCalculatorForm>({
    defaultValues: {
      investor_tickets_reserved: 0,
      bid_amount: deal?.bid_amount,
    },
  })

  const {
    formState: { errors },
  } = methods
  const getPayload = () => {
    const numOfTickets =
      (deal?.tickets_reserved ?? 0) -
      (deal?.investor_tickets_reserved ?? 0) +
      (methods?.getValues('investor_tickets_reserved') ?? 0)

    const totalBidAmount: number =
      ((numOfTickets as unknown as number) ?? 0) * deal?.minimum_ticket_size

    const payload = {
      investor_tickets_reserved: methods.getValues('investor_tickets_reserved'),
      bid_amount: totalBidAmount,
      in_pipeline: true,
    }

    return payload
  }

  const handleSubmit: SubmitHandler<ITicketCalculatorForm> = async () => {
    const investorTicketReserved =
      methods.getValues('investor_tickets_reserved') ?? 0
    if (
      investorTicketReserved >
      (deal?.available_tickets ?? 0) - (deal?.tickets_reserved ?? 0)
    ) {
      showErrorToast(NUMBER_OF_TICKET_RESERVED_GREATER_THAN_AVAILABLE_TICKETS)
      return
    }

    if (investorTicketReserved < 0) {
      showErrorToast(NUMBER_OF_TICKET_RESERVED_NOT_A_NEGATIVE_NUMBER)
      return
    }

    try {
      const payload = getPayload()
      const { error } = await supabase
        .from(SHORTLIST_TABLE)
        .update(payload)
        .eq('deal_id', deal?.deal_id)
        .eq('user_id', getUser()?.user?.id)

      if (error) {
        showErrorToast(ADD_DEAL_TO_PIPELINE_UNSUCCESSFULLY)
        return
      }
      methods?.reset()
      resetTicketCalculator()
      onClose()
      showSuccessToast(ADD_DEAL_TO_PIPELINE_SUCCESSFULLY)
    } catch (err) {
      showErrorToast(ADD_DEAL_TO_PIPELINE_UNSUCCESSFULLY)
    }
  }

  const resetTicketCalculator = () => {
    setFields(() =>
      Object.entries(INDICATED_INTEREST_FIELDS).map(([key, label]) => {
        if (key === 'bid_amount') {
          return {
            label,
            value: formatCurrency(
              deal?.tickets_reserved * deal?.minimum_ticket_size,
            ),
          }
        }

        return {
          label,
          value:
            typeof deal[key as keyof IDealRow] === 'number'
              ? CURRENCY_FIELDS.includes(key)
                ? formatCurrency(deal[key as keyof IDealRow])
                : formatDecimal(deal[key as keyof IDealRow])
              : deal[key as keyof IDealRow],
        }
      }),
    )
  }

  const validateNumberRange = (value: number) => {
    if (value < 0 || value > deal?.available_tickets - deal?.tickets_reserved) {
      return 'Number out of range'
    }

    return true
  }

  // useEffect(() => {
  //   const investorTicketReserved = parseInt(
  //     (methods.getValues('investor_tickets_reserved') ?? 0) + '',
  //   )
  //   if (
  //     investorTicketReserved >
  //     deal?.available_tickets - deal?.tickets_reserved
  //   ) {
  //     showErrorToast(NUMBER_OF_TICKET_RESERVED_GREATER_THAN_AVAILABLE_TICKETS)
  //     methods?.setValue('investor_tickets_reserved', 0)
  //     methods?.reset()
  //   }
  // }, [methods?.watch('investor_tickets_reserved')])

  // useEffect(() => {
  //   const numOfTickets = methods.getValues('investor_tickets_reserved') ?? 0
  //   const totalBidAmount: number = numOfTickets * deal?.minimum_ticket_size
  //   methods.setValue('bid_amount', totalBidAmount)

  //   setFields((prevField) => {
  //     return fields.map((field) => {
  //       if (field.label === 'Number of Tickets Reserved') {
  //         field.value = numOfTickets ?? 0
  //       }

  //       if (['Total Bid Amount'].includes(field.label)) {
  //         field.value = formatCurrency(totalBidAmount)
  //       }

  //       return field
  //     })
  //   })
  // }, [methods?.watch('investor_tickets_reserved')])

  useEffect(() => {
    resetTicketCalculator()
  }, [deal?.deal_id, deal?.investor_tickets_reserved])

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="white" isCentered>
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent maxW="1000px" bg="#13141F">
        <ModalCloseButton color="white" />
        <ModalBody
          p={['10px', '10px', '10px', '40px', '40px', '40px']}
          pb="10px"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(handleSubmit)}>
              <VStack gap="40px" alignItems="start" w="full">
                <WithResponsiveLayout
                  w="full"
                  justifyContent="start"
                  alignItems="start"
                  color="white"
                  gap="50px"
                >
                  <Box minW="350px" alignSelf="stretch">
                    <Card deal={deal} onClick={() => {}} h="full" />
                  </Box>
                  <VStack gap="24px" alignItems="start" lineHeight="normal">
                    <Text fontSize="32px" fontWeight="bold">
                      Make the first move.
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold">
                      By adding this startup, you have read and understood the
                      following:
                    </Text>

                    <Text fontSize="sm" fontWeight="normal">
                      This indicates the general level of interest towards the
                      startup and allows us to reserve the tickets for
                      interested investors to avoid oversubscription.{' '}
                    </Text>
                    <Text fontSize="sm" fontWeight="normal">
                      This calculation and pricing is not binding at this stage,
                      but provides a signal to other potential investors of the
                      level of interest towards a startup.
                    </Text>
                    <Text fontSize="sm" fontWeight="normal">
                      Once this bid has been submitted, you will be able to see
                      more information about the startup and the deal will
                      appear in your active pipeline.
                    </Text>
                    <Text fontSize="sm" fontWeight="normal">
                      If a company has been fully subscribed, other investors
                      will not be able to further reserve tickets. The
                      reservation will expire in 30 days if no action has been
                      taken to contact the startup.
                    </Text>
                  </VStack>
                </WithResponsiveLayout>

                <WithResponsiveLayout
                  w="full"
                  gap="50px"
                  justifyContent="start"
                  alignItems="start"
                  color="white"
                >
                  <Box w="350px">
                    <FieldSection
                      minW="350px"
                      header={
                        <HStack
                          w="full"
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Text fontSize="24px" fontWeight="bold" color="white">
                            Ticket Calculator
                          </Text>
                        </HStack>
                      }
                      fields={fields}
                    />
                  </Box>

                  <VStack gap="20px" alignItems="start" w="full">
                    <Text fontSize="lg" fontWeight="bold">
                      I want to reserve:
                    </Text>

                    <ControlledField
                      label=""
                      name="investor_tickets_reserved"
                      placeholder="Enter number of tickets you want reserve"
                      rules={{ validate: validateNumberRange }} // Apply custom validation rule
                      component={NumberInput}
                      // format={formatDecimal}
                      size={['sm', 'md', 'md']}
                    />
                    {errors.investor_tickets_reserved && (
                      <p>{errors.investor_tickets_reserved.message}</p>
                    )}

                    {/* <Controller
                      control={methods?.control}
                      name="investor_tickets_reserved"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          placeholder="Choose tickets"
                          options={generateTicketOptions(
                            deal?.available_tickets,
                          )}
                          onChange={onChange}
                          isClearable
                          isCreatable
                        />
                      )}
                    /> */}
                  </VStack>
                </WithResponsiveLayout>

                <WithCenterLayout w="full">
                  <Button
                    type="submit"
                    size={['sm', 'sm', 'md', 'xl']}
                    variant="filled"
                    borderRadius={['0.5rem', '0.5rem', '0.5rem', '0.5rem']}
                    p={[
                      '15px 54px',
                      '15px 54px',
                      '15px 54px',
                      '15px 54px',
                      '15px 54px',
                    ]}
                    bg="#00C9FF"
                    fontSize="lg"
                    // onClick={handleSubmit}
                  >
                    Add Startup to Pipeline
                  </Button>
                </WithCenterLayout>
              </VStack>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ExpressInterestModal
