import { ReactNode } from 'react'

import { IDeal } from './AllDeals'

//considering to move those type to another
type DealProps = {
  children: ReactNode
}

type RankProps = {
  deal: IDeal
  children?: ReactNode
}

type ReviewProps = {
  deal: IDeal
  children?: ReactNode
}

const Deal = ({ children }: DealProps) => {
  return <>{children}</>
}

Deal.Ranking = ({ children }: RankProps) => {
  return <>{children}</>
}

Deal.Review = ({ children }: ReviewProps) => {
  return <>{children}</>
}

export default Deal
