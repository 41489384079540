import { Heading, HeadingProps } from '@chakra-ui/react'

const DHeading = ({
  children,
  ...rest
}: { children: React.ReactNode } & HeadingProps) => {
  return (
    <Heading
      color="#A2A8B4"
      fontSize="md"
      fontWeight="semibold"
      lineHeight="24px"
      {...rest}
    >
      {children}
    </Heading>
  )
}

export default DHeading
