export const ACTIVE_NAVBAR_ITEM = 'ACTIVE_NAVBAR_ITEM'
export const SET_IS_NAVBAR_EXPANDED = 'SET_IS_NAVBAR_EXPANDED'
export const ACTIVE_USER_SETTING_MODAL = 'ACTIVE_USER_SETTING_MODAL'
export const SET_BREADCRUMB_LINKS = 'SET_BREADCRUMB_LINKS'

export const INPUT_STATUS = {
  ERROR: 'error',
  POSITIVE: 'positive',
}
export const DEFAULT_AVATAR = '/images/user-default.png'
export const LOGO_PATH = '/images/dl-logo.svg'
export const LOGO_LIGHT_MODE_PATH = 'images/dl-logo-light.svg'
export const FIXED_BACKGROUND_IMAGE = '/images/Deploy_Background_1.png'
export const LOGO_GE_ARROW_PINK = 'images/GEPINK.png'
export const LOGO_GE_ARROW_BLUE = 'images/GEBLUE.png'
export const CALENDAR_LINK = 'https://calendar.app.google/jw7VRixWTJ7V2Ag68'
export const NAVBAR_WIDTH = 300

export const NUMBER_INPUT_MAXIMUM = 9999
export const USER_STORAGE_KEY = 'user'

export const ROOT_HOME_PAGE = 'https://deploycapital.online'

export const SECTORS_MAP_DOT_COLORS = {
  Sustainability: '#8DFD7C',
  'Real Estate': '#F0C151',
  Finance: '#00C9FF',
  Enterprise: '#876CEA',
  Media: '#ea15e0',
} as Record<string, string>

export const APP_STORE_LINK_DOWNLOAD =
  'https://apps.apple.com/app/deploy/id6473771244'
export const GGPLAY_LINK_DOWNLOAD =
  'https://play.google.com/store/apps/details?id=com.deploycapital.deploy'

export const REACTION_TYPES = {
  like: 1,
  dislike: -1,
  neutral: 0,
  heart: 2,
  laugh: 3,
  confused: 4,
  angry: 5,
}
