import { Button, ButtonProps } from '@chakra-ui/react'

import uniqueId from 'lodash/uniqueId'

const DLButton = (props: ButtonProps) => {
  return (
    <Button id={uniqueId('DL-Button')} data-testid="DL-Button" {...props}>
      {props.children}
    </Button>
  )
}

export default DLButton
