export const SHORTLIST_TABLE = 'investor_shortlisted_deals'

export const SELECT_SHORTLIST_FIELDS =
  'user_id, bid_amount, investor_tickets_reserved,in_pipeline, metadata, priority, updated_at, users(investor_teams(team_id)), deals(*, deal_subdomains(*)), web_view_deal_avg_maven_rating(maven_rating_avg_in_sector), web_investor_view_deal_on_demand(percent_left, progress_value), web_view_expanded_deal_avg_maven_rating(deal_rank, total_num_mavens_for_deal, percent_mavens_have_evaluated, b1_mission_avg, b2_bizmodel_avg, b3_market_avg, b4_product_avg, b5_differentiation_avg, b6_timing_avg, b7_go2market_avg, b8_team_avg, b9_traction_avg, b10_risks_avg, bs0_confidence_avg)'

export const SELECT_ACTIVE_INTRO_FIELDS =
  'user_id, bid_amount, in_pipeline, metadata, deals(*, locations(location_name))'

export const SELECT_BID_AMOUNT_FIELDS = 'deal_id, bid_amount'

export const INTEREST_DEAL_TO_SHORTLIST_ERROR = 'Express this deal unsuccessful'

export const TICKET_CALCULATOR_DEFAULT_FORM_VALUES = {
  // num_of_tickets_reserved: 0,
  minimum_ticket_size: 0,
  bid_amount: 0,
}
