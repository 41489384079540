import { ReactNode } from 'react'

import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { APP_STORE_LINK_DOWNLOAD, GGPLAY_LINK_DOWNLOAD } from 'constants/common'
import uniqueId from 'lodash/uniqueId'

import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import EffortlessSynchronization from '../components/common/EffortlessSynchronization'
import IntellectualCapital from '../components/common/IntellectualCapital'
import SmallText from '../components/common/SmallText'
import UnlockingPotentialPrivateCapital from '../components/common/UnlockingPotentialPrivateCapital'
import { Footer } from '../components/v2/Footer'

const DeploySocialNetwork = () => {
  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      bg="#1F1F2F"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      w="full"
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#8B9DFB"
            fontSize="20px"
            fontWeight="medium"
            lineHeight="110%"
            textTransform="uppercase"
          >
            get rewarded for your experience and insights
          </Text>

          <Text
            color="#FFF"
            fontSize={['48px', '48px', '48px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Deploy your social capital.
          </Text>
        </VStack>

        <SmallText>
          Deploy’s expert network is at the heart of everything we do. Leverage
          your expertise and get rewarded for your contributions.
        </SmallText>

        <VStack gap="23px">
          <Text
            color="#F4F7FA"
            fontSize="14px"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Download our app
          </Text>

          <HStack gap="15px">
            <Link href={APP_STORE_LINK_DOWNLOAD}>
              <Image src="images/app-store.png" w="184px" />
            </Link>
            <Link href={GGPLAY_LINK_DOWNLOAD}>
              <Image src="images/google-play.png" w="184px" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  )
}

const FindTheOutliers = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#fff"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Join the Global Network
          </Text>
          <Text
            color="#8B9DFB"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            First to find the outliers.
          </Text>
        </VStack>

        <SmallText>
          As a Deploy Expert, you can recommend any startup to the network,
          helping them raise capital and get valuable feedback from our
          community. Our Expert Scouts submit and verify each deal, with
          Founders then following up with full information and funding
          requirements.
        </SmallText>

        <SmallText>
          You provide the connection and endorse the deal, but you are not
          responsible for the outcome. If it leads to an investment you level up
          on the deploy platform and earn either carry or commission depending
          on the deal.
        </SmallText>

        <SmallText>
          Our platform makes it as easy as possible to introduce a founder -
          simply download the app and share the QR code or link.
        </SmallText>
      </VStack>

      <Image src="images/planet.png" />
    </WithResponsiveLayout>
  )
}

const steps = [
  {
    title: 'Install App',
    color: '#4299E1',
    content:
      'Deploy is an invite-only expert network. If you’ve been invited by another Expert, install our mobile app to create an account with the invite code.',
  },
  {
    title: 'Scout',
    color: '#76E4F7',
    content:
      'When you meet and introduce a promising startup to Deploy, you’ll get rewarded if the company ends up getting invested in.',
  },
  {
    title: 'Evaluate',
    color: '#0BC5EA',
    content:
      'Review other startups in your domain or location, and get rewarded for your time and efforts. ',
  },
  {
    title: 'New Opportunities',
    color: '#582CFF',
    content:
      'Connect with investors and startup founders who are interested in your unique insights and experiences. ',
  },
]

const GetRewardedForIndustry = () => {
  return (
    <VStack
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
      alignItems="center"
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <VStack gap="32px" alignItems="center">
        <VStack gap="8px" alignItems="center">
          <Text
            color="#fff"
            fontSize={['32px', '32px', '32px', '56px', '56px', '56px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            How does it work?
          </Text>

          <Text
            color="#8B9DFB"
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontWeight="bold"
            lineHeight="110%"
            textAlign="center"
          >
            Get rewarded for your industry insights.
          </Text>
        </VStack>

        <SmallText textAlign="center">
          Turn your expertise and connections into capital.
        </SmallText>

        <WithResponsiveLayout
          gap="52px"
          w="full"
          pt={['40px', '40px', '40px', '80px', '80px', '80px']}
          px={['40px', '40px', '40px', '80px', '80px', '80px']}
        >
          {steps.map(({ title, content, color }, index) => (
            <VStack key={uniqueId()} gap="46px" alignSelf="stretch">
              <WithCenterLayout
                w="75px"
                h="73px"
                borderRadius="full"
                bg={color}
              >
                <Text
                  fontSize="36px"
                  lineHeight="3rem"
                  fontWeight="normal"
                  color="white"
                >
                  {index + 1}
                </Text>
              </WithCenterLayout>

              <VStack gap="15px" textAlign="center">
                <Text
                  fontSize="24px"
                  lineHeight="normal"
                  fontWeight="bold"
                  color="#E2E8F0"
                >
                  {title}
                </Text>
                <Text
                  color="#E2E8F0"
                  fontSize="18px"
                  lineHeight="normal"
                  fontWeight="normal"
                >
                  {content}
                </Text>
              </VStack>
            </VStack>
          ))}
        </WithResponsiveLayout>
      </VStack>
    </VStack>
  )
}

const GetRewarded = () => {
  return (
    <WithResponsiveLayout
      gap={['40px', '40px', '40px', '80px', '80px', '80px']}
      p={['40px', '40px', '40px', '80px', '80px', '80px']}
    >
      <Box
        pos="relative"
        flex="1"
        alignSelf="stretch"
        maxW="610px"
        minH="400px"
      >
        <Image
          src="images/outlier1.png"
          pos="absolute"
          top="13%"
          zIndex="1"
          left="0"
        />
        <Image src="images/outlier2.png" pos="absolute" top="0" right="0" />
      </Box>

      <VStack gap="32px" flex="1">
        <VStack gap="8px" alignItems="start" w="full">
          <Text
            color="#fff"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Leverage your expertise
          </Text>
          <Text
            color="#8B9DFB"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontWeight="bold"
            lineHeight="110%"
          >
            Get rewarded
          </Text>
        </VStack>

        <SmallText>
          Deploy Experts are an invite only pool of active industry
          professionals who are passionate about what they do. They come in all
          shapes and sizes, with only one pre-requisite: deep sector knowledge.
        </SmallText>

        <SmallText>
          Join as an Expert and level up each time you introduce or review a
          deal. Your comments are shared with the startup and investors, and you
          are rewarded through investment carry or commission.
        </SmallText>
      </VStack>
    </WithResponsiveLayout>
  )
}

const ExpertsPage = () => {
  return (
    <>
      <VStack gap="20px" maxW="1600px">
        <DeploySocialNetwork />
        <GetRewardedForIndustry />
        <FindTheOutliers />
        <GetRewarded />
      </VStack>
      <IntellectualCapital />
      <UnlockingPotentialPrivateCapital />
      <Footer />
    </>
  )
}

export default ExpertsPage
