import { ReactNode } from 'react'

import {
  Flex,
  FlexProps,
  StackProps,
  TextProps,
  VStack,
} from '@chakra-ui/react'

import Label from './Label'
import TextValue from './TextValue'

export type FlexField = {
  label: string
  value: ReactNode
}

export type FlexLayoutProps = {
  fields: FlexField[]
  numItemsPerRow?: number
  children?: ReactNode
  isChildInFlex?: boolean | undefined
  labelProps?: TextProps
  textProps?: TextProps
  wrapFieldProps?: StackProps
} & FlexProps

const FlexLayout = ({
  fields,
  numItemsPerRow = 4,
  labelProps,
  textProps,
  wrapFieldProps,
  children,
  isChildInFlex = true,
  ...rest
}: FlexLayoutProps) => {
  if (fields.length === 0) return null

  return (
    <Flex flexWrap="wrap" gap="1rem" w="full" {...rest}>
      {fields.map(({ label, value }: FlexField) => {
        return (
          <VStack
            key={`${label}-${value}`}
            gap="0.3rem"
            alignItems="start"
            flex={[
              1,
              1,
              1,
              `1 1 calc((100% - ${
                numItemsPerRow - 1
              } * 24px) / ${numItemsPerRow})`,
              `1 1 calc((100% - ${
                numItemsPerRow - 1
              } * 24px) / ${numItemsPerRow})`,
              `1 1 calc((100% - ${
                numItemsPerRow - 1
              } * 24px) / ${numItemsPerRow})`,
            ]}
            {...wrapFieldProps}
          >
            <Label
              fontSize={['sm', 'sm', 'md', 'md', 'md', 'md']}
              {...labelProps}
            >
              {label}
            </Label>
            <TextValue
              fontSize={['md', 'md', 'md', 'md', 'md', 'md']}
              {...textProps}
            >
              {value}
            </TextValue>
          </VStack>
        )
      })}
      {!children && fields?.length % 2 !== 0 && (
        <VStack
          flex={[
            1,
            1,
            1,
            `1 1 calc((100% - ${
              numItemsPerRow - 1
            } * 24px) / ${numItemsPerRow})`,
          ]}
        ></VStack>
      )}
      {children}
    </Flex>
  )
}

export default FlexLayout
