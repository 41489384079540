import { Fragment } from 'react'

import { Image, SlideFade, Stack, Text, VStack } from '@chakra-ui/react'

import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'

import CollectionEmpty from 'components/common/other/CollectionEmpty'
import { WithCenterLayout, WithSpinnerLoading } from 'components/hoc'

import DealDetail from 'modules/dashboard/components/DealDetail'

import { useDealQuery } from '../hooks'

interface DealParams {
  dealID: string
}

/**
 *  Deal component
 * **/
const Deal = () => {
  const { dealID } = useParams<DealParams>()
  const { deal, isLoading } = useDealQuery(dealID)
  /**
   *  Rendering component
   * **/
  return (
    <Fragment>
      <Helmet>
        <title>Deal Detail Page</title>
      </Helmet>

      <WithSpinnerLoading isLoading={isLoading}>
        {!deal?.deal_id && !isLoading && (
          <WithCenterLayout w="full" h="full">
            <Stack
              p="122.5px 204px"
              borderRadius="24px"
              bg="rgba(19, 20, 31, 0.80)"
            >
              <CollectionEmpty>
                <VStack gap="1rem">
                  <Image src="/images/Deploy_GE_arrow.png" w="140px" />

                  <Text color="#fff">No Deal Found</Text>
                </VStack>
              </CollectionEmpty>
            </Stack>
          </WithCenterLayout>
        )}
        {!isLoading && deal?.deal_id && <DealDetail deal={deal ?? {}} />}
        {/* <SlideFade in={true} offsetY="10rem"> */}
        {/* <VStack
            w="full"
            maxW={{ base: 'calc(100vw * 0.9)', md: 'calc(100vw * 0.8)' }}
            m="auto"
            gap={{ base: 12, md: 18.5 }}
          > */}
        {/* <DetailedDeal>
              {data?.deal_id ? (
                <>
                  <DetailedDeal.CompanyDetail deal={data} />
                  <DetailedDeal.CurrentRaise deal={data} />
                </>
              ) : (
                <DetailedDeal.NotFound />
              )}
            </DetailedDeal> */}
        {/* <DealDetail deal={deal ?? {}} /> */}
        {/* </VStack> */}
        {/* <DealDetail deal={deal ?? {}} /> */}
        {/* </SlideFade> */}
      </WithSpinnerLoading>
    </Fragment>
  )
}

export default Deal
