import { ReactNode, memo } from 'react'

import { HStack, StackProps, VStack, useColorModeValue } from '@chakra-ui/react'

import { GridHeaderText } from 'components/common/DLGrid/GridHeader'

import { useFormatCell } from 'modules/dashboard/hooks'

const Detail = ({
  label,
  value,
  type,
}: {
  index: number
  label: string
  value: string | number | null
  type?: string
  shouldBoldValue?: boolean
}) => {
  const colorByMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )
  const colorHeaderByMode = useColorModeValue(
    'rgba(255, 255, 255, 0.80)',
    'rgba(16, 16, 16, 0.80)',
  )
  const formattedValue = useFormatCell(value, type ?? '')

  return (
    <HStack
      w="full"
      justifyContent="space-between"
      gap={['0.25rem', '0.25rem', '0.5rem']}
    >
      <GridHeaderText
        text={label}
        color={colorHeaderByMode}
        stackStyles={{ alignItems: 'start' }}
      />
      <GridHeaderText
        text={formattedValue}
        stackStyles={{ alignItems: 'end' }}
        fontWeight="bold"
        color={colorByMode}
      />
    </HStack>
  )
}

const DetailedDealInSmView = ({
  children,
  ...rest
}: { children: ReactNode } & StackProps) => {
  return (
    <VStack w="full" gap="0.25rem" {...rest}>
      {children}
    </VStack>
  )
}

DetailedDealInSmView.Detail = Detail

export default DetailedDealInSmView
