import { Button, Text, VStack } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

import { PlaceholderImageBox } from '../other'

const UpgradeVenture = () => {
  return (
    <VStack
      p="20px"
      gap={['10px', '10px', '10px', '10px', '10px', '20px']}
      borderRadius={6}
      bg="#2A3BB7"
      color="white"
      alignItems="center"
      textAlign="center"
      justifyContent="space-between"
      w={['100%', '100%', '100%', '100%', '100%']}
    >
      <PlaceholderImageBox />
      <Text fontWeight="bold" fontSize="20px">
        Upgrade your Venture OS
      </Text>
      <Text fontWeight="normal" fontSize="16px">
        Join our mailing list to get the latest opportunities!
      </Text>
      <Text fontWeight="normal" fontSize="16px">
        We promise we won’t spam you!
      </Text>

      <WithCenterLayout w="full">
        <Button
          color="#DCE4FF"
          bg="transparent"
          // minH={['32px', '32px', '40px', '40px', '40px']}
          borderRadius="100px"
          border="1px solid #DCE4FF"
          fontSize="16px"
          fontWeight="bold"
          size={['sm', 'sm', 'md', 'md', 'md']}
        >
          Subscribe
        </Button>
      </WithCenterLayout>
    </VStack>
  )
}

export default UpgradeVenture
