import React from 'react'

import { FormLabelProps } from '@chakra-ui/react'

import { Controller, useFormContext } from 'react-hook-form'
import { TField } from 'types/form-fields'

import TextInput from 'components/common/DLInput/TextInput'

import Field from './Field'

type TControlledField = {
  component: React.FC<any>
  name: string
  label?: string
  placeholder?: string
  type?: string
  isInvalid?: boolean
  isValid?: boolean
  options?: any[]
  format?: Function
} & TField & {
    labelStyleProps?: FormLabelProps
    code?: string | undefined
    rules?: any
  }

export default function ControlledField({
  component: Component = TextInput,
  name = '',
  format,
  ...props
}: TControlledField) {
  const { control } = useFormContext()
  return (
    <Controller
      render={({ field }) => {
        if (typeof field.value === 'boolean') {
          Object.assign(field, { isChecked: field.value })
        }

        if (typeof format === 'function') {
          field.value = format(field.value)
        }

        return <Field component={Component} {...props} {...field} />
      }}
      control={control}
      name={name}
    />
  )
}
