import { ReactNode } from 'react'

import { Text, TextProps } from '@chakra-ui/react'

const SmallText = ({
  children,
  ...rest
}: { children: ReactNode } & TextProps) => {
  return (
    <Text
      color="#CBD5E1"
      fontSize="18px"
      fontWeight="normal"
      lineHeight="160%"
      {...rest}
    >
      {children}
    </Text>
  )
}

export default SmallText
