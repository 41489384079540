import {
  Badge,
  Box,
  Center,
  HStack,
  Icon,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react'

import { IoIosDocument } from 'react-icons/io'

const TText = ({
  children,
  ...rest
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Text
      as="p"
      pl={5}
      fontStyle="normal"
      fontSize={['md', 'md']}
      lineHeight={1.4}
      {...rest}
    >
      {children}
    </Text>
  )
}

const Terms = () => {
  return (
    <VStack as="div" gap={5} textAlign="left" alignItems="start">
      <Text as="h5" fontWeight="bold" fontSize="md">
        <Badge variant="boxy" size="xl" mb={1.5}>
          Terms of opting-in
        </Badge>
      </Text>

      <TText>
        In this declartion, I acknowledge and agree to the following:
      </TText>
      <TText>
        <Badge variant="boxy" size="xl" mb={1.5}>
          <b>Opt-In to Fundraising Platform</b>
        </Badge>{' '}
        <Text>
          I am willingly opting into the fundraising platform. I understand this
          involves receiving updates, information, and potential investment
          opportunities from various entities that participate in the deploy
        </Text>
        platform.
      </TText>
      <TText>
        <Badge variant="boxy" size="xl" mb={1.5}>
          <b>Decision-Making Authorization</b>
        </Badge>{' '}
        <Text>
          {' '}
          I confirm that I am duly authorized by the above to make inbound
          investment decisions on its behalf. I understand that these decisions
          may have significant financial implications for the organization.
        </Text>
      </TText>
      <TText>
        <Badge variant="boxy" size="xl" mb={1.5}>
          <b>Understanding of Risks</b>
        </Badge>{' '}
        <Text>
          I acknowledge that investments inherently involve risk, including the
          potential loss of capital.
        </Text>
        <Text>
          I confirm that I have sufficient knowledge and experience to evaluate
          potential investment opportunities and accept these risks on behalf of
          my organization.
        </Text>
      </TText>
      <TText>
        <Badge variant="boxy" size="xl" mb={1.5}>
          <b>Data Privacy</b>
        </Badge>{' '}
        <Text>
          I understand that our organization’s information will be used in
          accordance with the fundraising marketplace’s privacy policy. I have
          read and accepted the terms of this policy.
        </Text>
      </TText>
      <TText>
        <Badge variant="boxy" size="xl" mb={1.5}>
          <b>Compliance with Laws</b>
        </Badge>{' '}
        <Text>
          I agree to comply with all applicable laws and regulations in relation
          to our participation in the fundraising marketplace and any investment
          decisions made.
        </Text>
      </TText>
    </VStack>
  )
}

const TermsAndConditions = () => {
  return (
    <Center w="full" h="full">
      <VStack
        w={['full', '80%', '80%', '80%', '80%', 'calc(100% / 1.5)']}
        as="section"
        gap={6}
        color="white"
        alignItems="start"
        p={[3, 3, 8, 8, 10]}
        textAlign="left"
        lineHeight={5.5}
        bg="rgba(0, 0, 0, 0.20)"
        borderRadius={5}
      >
        <HStack color="accent.main01">
          <Icon
            as={IoIosDocument}
            boxSize={['1.75rem', '1.75rem', '43px', '43px', '43px']}
          />
          <Text
            as="h1"
            fontSize={['2xl', '3xl', '4xl', '5xl', '5xl']}
            fontWeight="bold"
            lineHeight={11}
          >
            Terms And Conditions
          </Text>
        </HStack>

        <Terms />
      </VStack>
    </Center>
  )
}

export default TermsAndConditions
