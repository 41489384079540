import { ReactElement, ReactNode, isValidElement } from 'react'

import {
  CloseButton,
  Heading,
  StackProps,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import { formatCurrency } from 'utils/numbers'

import { WithCenterLayout } from 'components/hoc'

const CardDetail = ({
  isEmpty,
  children,
  ...rest
}: { children: ReactNode; isEmpty?: boolean } & StackProps) => {
  const bgMode = useColorModeValue(
    // 'rgba(16, 16, 16, 0.52)',
    '#1F1F2F',
    'white',
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const colorMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )

  return (
    <VStack
      pos="relative"
      color="white"
      alignItems="start"
      bg={bgMode}
      borderRadius="1.5rem"
      p={[2.75, 2.75, 2.75, '1.5rem', '1.5rem']}
      {...rest}
    >
      {isEmpty && (
        <WithCenterLayout w="full" h="200px">
          <Text color={colorMode}>None of your selected deal</Text>
        </WithCenterLayout>
      )}
      {!isEmpty && children}
    </VStack>
  )
}

CardDetail.CloseButton = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const closeBtnColorMode = useColorModeValue('inherit', '#101010')

  return (
    <CloseButton
      pos="absolute"
      top="1rem"
      right="1rem"
      opacity="0.6"
      color={closeBtnColorMode}
    />
  )
}

CardDetail.ProminentField = ({
  label,
  value,
}: {
  label: string
  value: string | number | ReactElement | JSX.Element
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const colorMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const headingColorMode = useColorModeValue('accent.main01', 'accent.main01')

  return (
    <>
      <VStack
        flex={[1, 1, 1, '1 1 calc((100% - 3 * 2.5rem) / 4)']}
        alignItems="flex-start"
      >
        <Heading
          color={headingColorMode}
          fontSize="0.875rem"
          fontWeight="medium"
        >
          {label}
        </Heading>
        {isValidElement(value) && value}
        {!isValidElement(value) && (
          <Text color={colorMode} fontSize="lg" fontWeight="bold">
            {typeof value === 'number' ? formatCurrency(value) : value}
          </Text>
        )}
      </VStack>
    </>
  )
}

CardDetail.Field = ({
  label,
  value,
}: {
  label: string
  value: string | number | ReactElement
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const colorMode = useColorModeValue(
    'rgba(255, 255, 255, 0.80)',
    'rgba(16, 16, 16, 0.80)',
  )

  return (
    <VStack
      alignItems="flex-start"
      color={colorMode}
      fontSize="0.75rem"
      fontWeight="medium"
      flex={[1, 1, 1, '1 1 calc((100% - 3 * 2.5rem) / 4)']}
    >
      <Heading fontSize="0.75rem" noOfLines={3}>
        {label}
      </Heading>
      {isValidElement(value) && value}
      {!isValidElement(value) && (
        <Text>{typeof value === 'number' ? formatCurrency(value) : value}</Text>
      )}
    </VStack>
  )
}

export default CardDetail
