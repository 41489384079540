import {
  HStack,
  Heading,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { useDLToast } from 'hooks'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { getFirstSevenCharacters } from 'utils/string'

import { DLButton } from 'components/common/DLButton'
import { Field } from 'components/common/DLForms'
import { TextInput } from 'components/common/DLInput'

import {
  UPDATE_ACCOUNT_DETAILS_ERROR,
  UPDATE_ACCOUNT_DETAILS_SUCCESS,
} from '../constants/messages'
import { TUserSetting } from '../hooks/useUserSettings'

// const DeleteAccount = () => {
//   const colorByMode = useColorModeValue('white', '#101010')

//   return (
//     <VStack gap="16px" alignItems="start">
//       <Text fontSize="lg" color={colorByMode}>
//         Delete Account
//       </Text>
//       <Text fontSize="md" color={colorByMode} opacity={0.6}>
//         Deleting your account will remove all of your content and data
//         associated with it
//       </Text>
//       <Link>
//         <Text fontSize="md" color="badge.color.500">
//           I want to delete my account
//         </Text>
//       </Link>
//     </VStack>
//   )
// }

const AccountForm = ({
  isLoading,
  styleByMode: { colorByMode, bg },
}: {
  isLoading: boolean
  styleByMode: { colorByMode: string; bg: string }
}) => {
  return (
    <VStack w="full" h="full" gap="24px" alignItems="start">
      <Field
        variant="userAccount"
        label="User ID"
        name="user_id"
        component={TextInput}
        size={['sm', 'md', 'lg']}
        labelStyleProps={{
          fontSize: ['sm', 'md', 'lg'],
          fontWeight: 'bold',
          lineHeight: 1.4,
          color: colorByMode,
        }}
        isDisabled
        maxW={['full', 'full', 'full', 'calc(100% - 84px)']}
      />
      <HStack
        gap="12px"
        w="full"
        maxW={['full', 'full', 'full', 'calc(100% - 84px)']}
      >
        <Field
          variant="userAccount"
          label="First Name"
          name="first_name"
          component={TextInput}
          maxLength={256}
          size={['sm', 'md', 'lg']}
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />

        <Field
          variant="userAccount"
          label="Last Name"
          name="last_name"
          component={TextInput}
          maxLength={256}
          size={['sm', 'md', 'lg']}
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />
        {/* <WithCenterLayout pt="18px">
          <Link minW="72px">
            <Text fontWeight="bold" fontSize="md" color="badge.color.500">
              Edit Name
            </Text>
          </Link>
        </WithCenterLayout> */}
      </HStack>
      <Field
        variant="userAccount"
        label="Email"
        name="email"
        component={TextInput}
        maxLength={256}
        size={['sm', 'md', 'lg']}
        isDisabled
        labelStyleProps={{
          fontSize: ['sm', 'md', 'lg'],
          fontWeight: 'bold',
          lineHeight: 1.4,
          color: colorByMode,
        }}
        maxW={['full', 'full', 'full', 'calc(100% - 84px)']}
      />
      <DLButton
        type="submit"
        isLoading={isLoading}
        variant="filled"
        size={['sm', 'md', 'lg', 'lg']}
        w={['full', 'full', 'full', '50%']}
      >
        Update
      </DLButton>
    </VStack>
  )
}

interface IUserAccount {
  user_id: string | undefined
  first_name: string | undefined
  last_name: string | undefined
  email: string | undefined
}

const UserAccount = ({
  userSettings,
}: {
  userSettings?: TUserSetting | null | undefined
}) => {
  const [isLoading, toggle] = useBoolean()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const methods = useForm<IUserAccount>({
    defaultValues: {
      user_id:
        getFirstSevenCharacters(userSettings?.user_id ?? '') +
        '*****************',
      first_name: userSettings?.first_name,
      last_name: userSettings?.last_name,
      email: userSettings?.email,
    },
  })

  const colorByMode = useColorModeValue('white', '#101010')
  const bg = useColorModeValue(
    'activatedBackground',
    'rgba(255, 255, 255, 0.40)',
  )

  const handleSubmit: SubmitHandler<IUserAccount> = async (values) => {
    const { first_name, last_name } = values
    const payload = {
      first_name,
      last_name,
    }

    try {
      toggle.on()
      const { error } = await supabase
        .from('users')
        .update(payload)
        .eq('user_id', userSettings?.user_id)

      if (error) {
        showErrorToast(error.message)
        return
      }

      showSuccessToast(UPDATE_ACCOUNT_DETAILS_SUCCESS)
    } catch (err) {
      showErrorToast(UPDATE_ACCOUNT_DETAILS_ERROR)
    } finally {
      toggle.off()
    }
  }

  return (
    <VStack
      bg={bg}
      borderRadius="24px"
      p={[4, 4, '24px']}
      gap={[4, 4, 4, 6, 6, 6]}
      color={colorByMode}
      h="full"
    >
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods?.handleSubmit(handleSubmit)}
          autoComplete="off"
        >
          <VStack
            gap={[4, 4, 4, 6, 6, 6]}
            fontWeight="bold"
            alignItems="start"
            maxW={['full', 'full', 'full', '80%']}
          >
            <Heading fontSize={['lg', 'lg', '24px']}>Your Account</Heading>
            <AccountForm
              isLoading={isLoading}
              styleByMode={{ bg, colorByMode }}
            />
            {/* <DeleteAccount /> */}
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  )
}

export default UserAccount
