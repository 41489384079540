import { createContext, useContext, useEffect, useState } from 'react'

import supabase from 'config/supabase-client'

import {
  SUPABASE_EVENT_PASSWORD_RECOVERY,
  SUPABASE_EVENT_SIGNED_IN,
  SUPABASE_EVENT_SIGNED_OUT,
} from '../constants/supabase'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === SUPABASE_EVENT_PASSWORD_RECOVERY) {
        setAuth(false)
      } else if (event === SUPABASE_EVENT_SIGNED_IN) {
        session?.user && setUser(session?.user)
        setAuth(true)
      } else if (event === SUPABASE_EVENT_SIGNED_OUT) {
        setAuth(false)
        setUser(null)
      }
    })
    return () => {
      data.subscription.unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider value={[auth, user]}>{children}</AuthContext.Provider>
  )
}

export const useAuthState = () => useContext(AuthContext)
