import {
  Button,
  Checkbox,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { NAVBAR } from 'constants/navbar'
import { useAuth, useDLToast } from 'hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { formatDecimal } from 'utils/numbers'

import { ControlledField } from 'components/common/DLForms'
import { NumberInput } from 'components/common/DLInput'
import Disclaimer from 'components/common/Icons/Disclaimer'
import { WithCenterLayout } from 'components/hoc'

import {
  ADD_DEAL_TO_ACTIVE_INTROS_ERROR,
  ADD_DEAL_TO_ACTIVE_INTROS_SUCCESS,
} from 'modules/dashboard/constants/messages'

import { SHORTLIST_TABLE } from '../constants'
import { expressFormResolver } from '../validation/shortlist'

export interface ExpressForm {
  bid_amount: number
}

const ConfirmDisclaimerModal = ({
  dealID,
  isOpen,
  onClose,
}: {
  dealID: string | undefined
  isOpen: boolean
  onClose: () => void
}) => {
  const history = useHistory()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { getUser } = useAuth()

  const methods = useForm<ExpressForm>({
    defaultValues: { bid_amount: 0 },
    resolver: expressFormResolver,
    mode: 'onChange',
    shouldFocusError: true,
  })

  const { errors } = methods.formState

  const [isToggled, toggle] = useBoolean()
  const textMode = useColorModeValue(
    'rgba(16, 16, 16, 0.20)',
    'background.darkMode',
  )

  const onSubmit = async (values: ExpressForm) => {
    if (!dealID) return
    try {
      /** active deal **/
      const payload = {
        deal_id: dealID,
        user_id: getUser()?.user?.id,
        in_pipeline: true,
        bid_amount: values?.bid_amount ?? 0,
      }

      const { error } = await supabase
        .from(SHORTLIST_TABLE)
        .upsert(payload, { onConflict: 'deal_id, user_id' })

      if (error) {
        showErrorToast(error?.message)
        return
      }

      showSuccessToast(ADD_DEAL_TO_ACTIVE_INTROS_SUCCESS)
      history.push(NAVBAR.PATHS.ACTIVE_DEALS)
    } catch (err) {
      showErrorToast(ADD_DEAL_TO_ACTIVE_INTROS_ERROR)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggle.off()
        methods.reset()
        onClose()
      }}
      variant="white"
      isCentered
    >
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <WithCenterLayout gap="2rem" flexDir="column">
                <Disclaimer w="5.30488rem" h="4.94794rem" />
                <WithCenterLayout gap="2rem" w="full" flexDir="column">
                  <ControlledField
                    variant="userAccount"
                    label="Interest number"
                    name="bid_amount"
                    component={NumberInput}
                    size={['sm', 'md', 'lg']}
                    format={formatDecimal}
                    labelStyleProps={{
                      fontSize: ['sm', 'md', 'md'],
                      color: '#101010',
                    }}
                    sx={{
                      color: '#101010 !important',
                      border: 'none',
                    }}
                  />

                  <Checkbox
                    size="md"
                    onChange={toggle.toggle}
                    sx={{
                      '&>[data-checked].chakra-checkbox__control': {
                        bg: 'accent.main01',
                        borderColor: 'accent.main01',
                      },
                      '&>.chakra-checkbox__control': {
                        borderColor: textMode,
                        opacity: 0.6,
                      },
                    }}
                  >
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      opacity={isToggled ? 1 : 0.6}
                      lineHeight={1}
                    >
                      I have read and understood the{' '}
                      <Link
                        color="accent.main01"
                        target="_blank"
                        href="/terms-and-conditions"
                      >
                        disclaimer
                      </Link>
                    </Text>
                  </Checkbox>
                  {/* <Switch
                colorScheme="teal"
                size="sm"
                onChange={toggle.toggle}
                sx={{
                  '&>.chakra-switch__track': { bg: bgSwitchByMode },
                  '&>[data-checked]': { bg: '#8B9DFB' },
                }}
              /> */}
                </WithCenterLayout>

                <Button
                  type="submit"
                  isDisabled={!isToggled}
                  variant="filled"
                  size={['md', 'md', 'lg']}
                  w="full"
                  sx={{
                    _disabled: {
                      color: 'white',
                      bg: 'rgba(16, 16, 16, 0.20)',
                      _hover: {
                        color: 'white',
                        bg: 'rgba(16, 16, 16, 0.20)',
                      },
                    },
                  }}
                >
                  Confirm
                </Button>
              </WithCenterLayout>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDisclaimerModal
