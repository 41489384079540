import { useEffect, useRef, useState } from 'react'

import { Box } from '@chakra-ui/react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import useMounted from 'hooks/useMounted'
import { LatLng, LatLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { CircleMarker, MapContainer, TileLayer, Tooltip } from 'react-leaflet'

import { LEAFLET_TEMPLATES_API } from 'modules/dashboard/constants/endpoints'

import countriesJSON from '../../data/urban-areas.json'

type TTotalScoutsOnCountry = {
  total_scout: number
  iso2: string
  location_name: string
}

type TTotalScoutsOnCountryWithCoordinates = TTotalScoutsOnCountry & {
  lat: number | null
  lng: number | null
  total: number
}

const mapCountryAlongWithCoordinates = (countries: TTotalScoutsOnCountry[]) => {
  const total = countries.reduce(
    (total, country) => (total += country.total_scout),
    0,
  )
  return countries.map((country: TTotalScoutsOnCountry) => {
    const foundCountry = countriesJSON.ref_country_codes.find(
      (c) => c.alpha2 === country.iso2,
    )

    return {
      ...country,
      total,
      lat: foundCountry?.latitude ?? null,
      lng: foundCountry?.longitude ?? null,
    }
  })
}

/**
 * Fetches bubble map data from the database and maps countries with coordinates.
 *
 * @return {TTotalScoutsOnCountry[]} The new countries with coordinates.
 */
const fetchBubbleMapData = async () => {
  const { data, error }: PostgrestResponse<TTotalScoutsOnCountry> =
    await supabase.from('web_view_show_total_of_scouts_on_country').select('*')
  const newCountries = mapCountryAlongWithCoordinates(data ?? [])
  return newCountries
}

/**
 * Calculates the marker radius based on the total scouts and the maximum scouts.
 *
 * @param {number} totalScouts - The total number of scouts.
 * @param {number} maxScouts - The maximum number of scouts.
 * @return {number} The calculated radius for the marker.
 */
function calculateMarkerRadius(totalScouts: number, maxScouts: number) {
  const minScouts = 0
  const minRadius = 5
  const maxRadius = 20

  const radius =
    ((totalScouts - minScouts) / (maxScouts - minScouts)) *
      (maxRadius - minRadius) +
    minRadius
  return radius
}

const BubbleMap = () => {
  const tileUrl = LEAFLET_TEMPLATES_API
  const [bubbleData, setBubbleData] = useState<
    TTotalScoutsOnCountryWithCoordinates[] | undefined
  >()
  const isMounted = useMounted()

  const mapRef = useRef<any>(null)

  useEffect(() => {
    isMounted && fetchBubbleMapData().then((data) => setBubbleData(data))
  }, [isMounted])

  useEffect(() => {
    function handleResize() {
      if (mapRef.current) {
        mapRef.current?.leafletElement?.invalidateSize()
      }
    }

    window.addEventListener('resize', handleResize)

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'))
    }, 1000)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box
      h="196px"
      minW="100%"
      resize="both"
      overflow="auto"
      borderRadius={2}
      minH="100%"
    >
      <MapContainer
        ref={mapRef}
        center={[24.0479, 100.6197]}
        zoom={1.5}
        minZoom={2}
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <TileLayer url={tileUrl} minZoom={0} maxZoom={22} />
        {bubbleData?.map(
          (city, i) =>
            city?.lat &&
            city?.lng && (
              <CircleMarker
                center={[city?.lat, city?.lng]}
                radius={calculateMarkerRadius(city?.total_scout, city?.total)}
                key={i}
                fillOpacity={0.55}
                stroke={false}
                pathOptions={{ color: 'rgb(0, 201, 255)', weight: 2 }}
              >
                <Tooltip direction="right" offset={[-8, -2]} opacity={0.7}>
                  <span>{`${city['location_name']}: ${city['total_scout']} scouts finding deals`}</span>
                </Tooltip>
              </CircleMarker>
            ),
        )}
      </MapContainer>
    </Box>
  )
}

export default BubbleMap
