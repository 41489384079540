import { Center as ChakraCenter, Flex } from '@chakra-ui/react'

import FixedContainer from './FixedContainer'

type TCenter = {
  children: React.ReactNode
  container?: React.FC
}

const Center = ({
  container: Container = FixedContainer,
  children,
}: TCenter) => {
  return (
    <Flex
      pos="relative"
      h="100vh"
      top="0"
      left="0"
      alignItems="center"
      bgImage="radial-gradient(circle at center, #0846c2, #003b97, #002e6b, #001f41, #030d1a)"
    >
      {/* <Container /> */}
      <ChakraCenter
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {children}
      </ChakraCenter>
    </Flex>
  )
}

export default Center
