import ActiveIntrosRoutes from 'modules/active-intros/configs/routes'
import DashboardRoutes from 'modules/dashboard/configs/routes'
import DealRoutes from 'modules/deals/configs/routes'
import TermsAndConditions from 'modules/landing/components/TermsAndConditions'
import ScreenRoomRoutes from 'modules/screen-rooms/configs/routes'
import UserSettingsRoutes from 'modules/settings/configs/routes'

const PrivateRoutes = [
  ...DashboardRoutes,
  ...DealRoutes,
  ...UserSettingsRoutes,
  ...ScreenRoomRoutes,
  ...ActiveIntrosRoutes,
  {
    path: '/terms-and-conditions',
    profile: 'Investor',
    feature: 'Landing page',
    exact: true,
    component: TermsAndConditions,
  },
]

export default PrivateRoutes
