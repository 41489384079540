import { Button, Image, Link, Stack, Text, VStack } from '@chakra-ui/react'

import { CALENDAR_LINK } from 'constants/common'

import { WithResponsiveLayout } from 'components/hoc'

import SmallText from './SmallText'

const UnlockingPotentialPrivateCapital = () => {
  return (
    <Stack maxW="1600px" p={['20px', '20px', '20px', '64px', '64px', '64px']}>
      <WithResponsiveLayout
        gap="0"
        border="1px solid #475569"
        borderRadius="20px"
        p={['20px', '20px', '20px', '64px', '64px', '64px']}
        // pr="0"
      >
        <VStack gap="2rem" alignItems="start">
          <Text
            fontSize={['1.5rem', '1.5rem', '1.5rem', '2rem', '2rem', '2rem']}
            color="#FFF"
            lineHeight="normal"
          >
            Unlocking the potential of private capital.
          </Text>
          <SmallText>
            Private capital markets have grown at an unprecedented rate and
            we’re on a mission to capture and validate private market data.
          </SmallText>
          <Link href={CALENDAR_LINK}>
            <Button
              minW="300px"
              color="#1A202A"
              bg="#fff"
              minH="60px"
              borderRadius="60px"
              fontSize="20px"
            >
              request info.
            </Button>
          </Link>
        </VStack>

        <Image src="images/private-potential-capital.png" />
      </WithResponsiveLayout>
    </Stack>
  )
}

export default UnlockingPotentialPrivateCapital
