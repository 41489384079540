/* eslint-disable react-hooks/rules-of-hooks */
import { MouseEventHandler, ReactNode, useCallback, useState } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { DEFAULT_AVATAR } from 'constants/common'
import { TABLES_NAME } from 'constants/tables'
import { useDLToast, useSBStorageUpload } from 'hooks'
import { AVATAR_BUCKET } from 'hooks/useSBStorageUpload'
import { useDropzone } from 'react-dropzone'

import { ChevronRight } from 'components/common/Icons'

const UserProfileLeftBar = ({ children }: { children: ReactNode }) => {
  const bgByMode = useColorModeValue(
    'activatedBackground',
    'rgba(255, 255, 255, 0.40)',
  )

  return (
    <VStack
      bg={bgByMode}
      borderRadius="24px"
      p={[4, 4, '24px']}
      gap="36px"
      h="full"
    >
      {children}
    </VStack>
  )
}

UserProfileLeftBar.Avatar = ({
  userID,
  name,
  username,
  currentAvatar,
}: {
  userID: string | undefined
  name: string | undefined
  username: string | undefined
  currentAvatar: string | undefined | null
}) => {
  const [avatar, setAvatar] = useState<string | null | undefined>(currentAvatar)
  const { showErrorToast, showSuccessToast } = useDLToast()
  const [uploadToSBBacket] = useSBStorageUpload()
  const colorByMode = useColorModeValue('white', '#101010')
  const colorSubTextByMode = useColorModeValue(
    'textColor',
    'rgba(16, 16, 16, 0.60)',
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAvatarUpload = async (acceptedFiles: File[]) => {
    try {
      const avatarPath = await uploadToSBBacket(
        acceptedFiles?.[0],
        AVATAR_BUCKET,
      )

      if (!avatarPath || !userID) {
        return
      }

      const payload = {
        avatar: avatarPath,
      }

      const { error } = await supabase
        .from(TABLES_NAME.users)
        .update(payload)
        .eq('user_id', userID)

      if (error) {
        showErrorToast(error?.message)
        return
      }

      showSuccessToast('Update user avatar successfully')
      setAvatar(avatarPath ?? currentAvatar)
    } catch (error) {
      console.log(error)
      showSuccessToast('Update user avatar unsuccessfully')
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleAvatarUpload?.(acceptedFiles)
    },
    [handleAvatarUpload],
  )

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
  })

  return (
    <Card
      flexDirection={['row', 'row', 'row', 'row', 'row', 'row']}
      bg="transparent"
      gap="12px"
      maxH="60px"
      w="full"
      boxShadow="unset"
    >
      <CardHeader p={0} {...getRootProps()} onClick={open} cursor="pointer">
        <input {...getInputProps()} />
        <Image
          minW={['40px', '60px']}
          boxSize={['40px', '60px']}
          borderRadius="full"
          src={avatar ?? DEFAULT_AVATAR}
          alt={name}
        />
      </CardHeader>

      <CardBody p={0}>
        <VStack
          flexDirection="column"
          alignItems="start"
          justifyContent="center"
          color={colorByMode}
          gap="4px"
        >
          <Heading
            fontSize={['sm', '24px', '24px']}
            lineHeight={1.4}
            noOfLines={1}
          >
            {name ?? 'Vino Costa'}
          </Heading>
          <Text
            fontSize={['sm', 'md', 'lg']}
            color={colorSubTextByMode}
            noOfLines={2}
          >
            {username ?? 'Investor'}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  )
}

UserProfileLeftBar.Item = ({
  text,
  activatedIndex,
  index,
  onClick,
}: {
  text: string
  activatedIndex?: number
  index?: number
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
}) => {
  const colorByMode = useColorModeValue(
    'white',
    index === activatedIndex ? 'white' : '#101010',
  )
  const bgByMode = useColorModeValue(
    index === activatedIndex
      ? 'linear-gradient(92deg, #8B9DFB 4.09%, #4764FB 156.07%)'
      : 'rgba(255, 255, 255, 0.13)',
    index === activatedIndex
      ? 'linear-gradient(92deg, #8B9DFB 4.09%, #4764FB 156.07%)'
      : 'rgba(16, 16, 16, 0.04)',
  )

  return (
    <Flex
      as="li"
      cursor="pointer"
      w="full"
      borderRadius="1rem"
      alignItems="center"
      justifyContent="space-between"
      p={['12px', '12px', '16px 24px', '16px 24px', '16px 24px']}
      bg={bgByMode}
      color={colorByMode}
      onClick={onClick}
    >
      <Text fontSize="md" fontWeight="700" lineHeight={1.4}>
        {text}
      </Text>
      <ChevronRight boxSize={5} color={colorByMode} />
    </Flex>
  )
}

export default UserProfileLeftBar
