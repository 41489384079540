import { Box, HStack, VStack } from '@chakra-ui/react'

import { WithCenterLayout } from 'components/hoc'

import { PlaceholderImageBox, WrapperBox } from '../other'
import { SFW, SFW80P, SW80, SW148, SWH15 } from './Shapes'

const Progress = () => {
  return (
    <HStack w="full" pos="relative">
      <Box
        w="1.3rem"
        h="1.3rem"
        border="3px solid #171923"
        pos="absolute"
        right="20%"
        bg="#171923"
        borderRadius="50%"
        zIndex={2}
      >
        <Box bg="#546FFF" w="full" h="full" borderRadius="50%" />
      </Box>

      <SFW80P pos="absolute" h="8px" />
      <SFW h="8px" />
    </HStack>
  )
}

const Startups = ({ onClick }: { onClick?: () => void }) => {
  return (
    <WrapperBox
      w={[
        'full',
        'full',
        'full',
        'full',
        'full',
        // 'calc((100% - 20px * 2) / 3)',
        // 'calc((100% - 20px * 2) / 3)',
        // 'calc((100% - 20px * 2) / 3)',
      ]}
      // maxW="432px"
      h="320px"
      p="36px"
      borderRadius="10px"
      gap="20px"
      justifyContent="center"
      alignItems="start"
      onClick={onClick}
    >
      <PlaceholderImageBox />
      <WithCenterLayout gap="8px" alignItems="center" w="full">
        <VStack gap="1rem">
          <SW148 />
          <SW148 />
        </VStack>
      </WithCenterLayout>

      <VStack w="full">
        <HStack justifyContent="space-between" w="full">
          <SW80 />
          <SWH15 />
        </HStack>
        <Progress />
      </VStack>

      <HStack justifyContent="space-between" w="full">
        <HStack>
          <SWH15 />
          <SW80 />
        </HStack>

        <SW80 />
      </HStack>
    </WrapperBox>
  )
}

export default Startups
