import ContactInfo from '../components/ContactInfo'
import PasswordAndSecurity from '../components/PasswordAndSecurity'
import UserAccount from '../components/UserAccount'
import { TUserSetting } from '../hooks/useUserSettings'

export type TSettingItem = { text: string }

export const USER_SETTINGS_PATHNAME = '/user-settings'

export const SETTING_ITEMS: TSettingItem[] = [
  { text: 'Account details' },
  { text: 'Contact Info' },
  { text: 'Password & security' },
  // { text: 'Notifications' },
]

export const SETTING_ITEMS_MAP: Record<
  string,
  ({
    userSettings,
  }: {
    userSettings?: TUserSetting | null | undefined
  }) => JSX.Element
> = {
  'Account details': UserAccount,
  'Contact Info': ContactInfo,
  'Password & security': PasswordAndSecurity,
  // Notifications,
}
