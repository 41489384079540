import { useEffect } from 'react'

import { useBoolean } from '@chakra-ui/react'

const useMounted = () => {
  const [isMounted, setIsMounted] = useBoolean()
  useEffect(() => {
    setIsMounted.on()
    return () => setIsMounted.off()
  }, [])

  return isMounted
}

export default useMounted
