import { Icon } from '@chakra-ui/react'

const EyeIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0035 18.3199C17.9948 18.3199 21.9865 13.4933 21.9865 12.0138C21.9865 10.527 17.9874 5.70032 12.0035 5.70032C6.0929 5.70032 2.01331 10.527 2.01331 12.0138C2.01331 13.4933 6.08557 18.3199 12.0035 18.3199ZM12.0042 15.9618C9.79966 15.9618 8.04184 14.1601 8.03452 12.0141C8.0272 9.8095 9.79966 8.06634 12.0042 8.06634C14.1942 8.06634 15.974 9.8095 15.974 12.0141C15.974 14.1601 14.1942 15.9618 12.0042 15.9618ZM13.5183 12.0141C13.5183 12.8344 12.8298 13.5156 12.0022 13.5156C11.1672 13.5156 10.4861 12.8344 10.4861 12.0141C10.4861 11.1865 11.1672 10.5053 12.0022 10.5053C12.8298 10.5053 13.5183 11.1865 13.5183 12.0141Z"
      />
    </Icon>
  )
}

export default EyeIcon
