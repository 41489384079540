import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import { IDomainTaxonomy } from 'interfaces/models'

import useMounted from './useMounted'

type TSubDomain = Pick<IDomainTaxonomy, 'domain_name' | 'taxonomy_id'>
const LAYER_SUB_DOMAIN = 3
export default function useSubDomains() {
  const [domains, setDomains] = useState<TSubDomain[] | null>([])
  const isMounted = useMounted()
  const formatDomainsAsSelections = (
    domains: TSubDomain[] | null | undefined,
  ) => {
    return (
      domains?.map(({ domain_name, taxonomy_id }) => ({
        label: domain_name,
        value: taxonomy_id,
      })) ?? []
    )
  }

  useEffect(() => {
    /**
     * Fetches the subdomains from the 'domain_taxonomy' table in the database.
     *
     * @return {Promise<void>} Resolves with no value.
     */
    const fetchSubDomains = async () => {
      const SELECTED_FIELDS = 'taxonomy_id, domain_name'
      try {
        const { data, error }: PostgrestResponse<TSubDomain> = await supabase
          .from(TABLES_NAME.domain_taxonomy)
          .select(SELECTED_FIELDS)
          .eq('layer_type', LAYER_SUB_DOMAIN)

        if (error) throw error

        setDomains(data)
      } catch (e) {}
    }

    isMounted && fetchSubDomains()
  }, [isMounted])

  return { domains, formatDomainsAsSelections }
}
