import { ReactNode } from 'react'

import {
  Grid,
  StackProps,
  Text,
  TextProps,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import { ChevronDown } from '../Icons'

export interface IGridHeader {
  field: string
  label: string
  type?: string
  hasSorting?: boolean
}

export const GridHeaderText = ({
  text,
  stackStyles,
  hasSorting = false,
  onClick,
  ...rest
}: {
  hasSorting?: boolean
  text: string | number | null
  stackStyles?: StackProps
  onClick?: () => void
} & TextProps) => {
  const colorByMode = useColorModeValue(
    'header.color',
    'rgba(16, 16, 16, 0.80)',
  )

  const [isOpen, toggle] = useBoolean()

  const handleClick = () => {
    if (!hasSorting) return
    toggle.toggle()
    onClick?.()
  }

  return (
    <VStack
      w="full"
      h="full"
      alignItems="start"
      justifyContent="start"
      gap="0.2rem"
      {...stackStyles}
      cursor={hasSorting ? 'pointer' : 'default'}
      onClick={handleClick}
    >
      <Text
        fontWeight="medium"
        fontSize="sm"
        color={colorByMode}
        lineHeight="1.05rem"
        textAlign="start"
        {...rest}
      >
        {text}
      </Text>
      {hasSorting && (
        <ChevronDown
          w="1.1rem"
          h="fit-content"
          transitionDuration="0.3s"
          transform={`rotate(${isOpen ? 180 : 0}deg)`}
        />
      )}
    </VStack>
  )
}

const GridHeader = ({
  numCols,
  children,
}: {
  numCols: number
  children: ReactNode
}) => {
  const bgMode = useColorModeValue('header.bg', 'rgba(255, 255, 255, 0.40)')

  return (
    <Grid
      templateColumns={[
        `2.8rem repeat(${numCols - 2}, minmax(80px, 1fr)) 24px`,
        `2.8rem repeat(${numCols - 2}, minmax(80px, 1fr)) 24px`,
        `2.8rem repeat(${numCols - 2}, minmax(90px, 1fr)) 24px`,
        `2.8rem repeat(${numCols - 2}, minmax(90px, 1fr)) 24px`,
        `2.8rem repeat(${numCols - 2}, minmax(90px, 1fr)) 24px`,
        `2.8rem repeat(${numCols - 2}, minmax(100px, 1fr)) 24px`,
      ]}
      p="15px"
      bg={bgMode}
      borderRadius={4}
      w="full"
      gap={[4, 4, 4, '0.25rem', 4, 6]}
      as="header"
    >
      {children}
    </Grid>
  )
}

export default GridHeader
