const colors = {
  luckyCharm: {
    300: '#0DA55F',
    500: '#1C9D5E',
    700: '#157749',
  },
  tetra: {
    300: '#27F490',
    500: '#23D57E',
    700: '#1B9D5D',
  },
  marine: {
    300: '#42EAE2',
    500: '#3CD1CA',
    700: '#33AAA4',
  },
  ibiza: {
    300: '#ABF018',
    500: '#9FDE14',
    700: '#81B218',
  },
  mustard: {
    300: '#F2D840',
    500: '#D5BF40',
    700: '#B5A43D',
  },
  sunshine: {
    300: '#FEE16E',
    500: '#FECB04',
    700: '#E0BA25',
  },
  californiaOrange: {
    300: '#FEAD3B',
    500: '#FDA122',
    700: '#EF971D',
  },
  percival: {
    300: '#FFC7C7',
    500: '#FDADAD',
    700: '#F2A6A6',
  },
  sunrise: {
    300: '#FF8458',
    500: '#FD8458',
    700: '#F05C28',
    800: '#FD642E',
  },
  strawberry: {
    100: '#FC506B19',
    300: '#FC506B',
    500: '#EF3753',
    700: '#DE2E4A',
  },
  cherryBomb: {
    300: '#D1345B',
    500: '#B23050',
    700: '#6F483D',
  },
  willow: {
    300: '#986557',
    500: '#7F5347',
    700: '#6F483D',
  },
  barb: {
    300: '#FF55AB',
    500: '#FC158A',
    700: '#EA0F7F',
  },
  bubblegum: {
    300: '#FFB5F8',
    500: '#FAA1F1',
    700: '#E692DE',
  },
  coral: {
    300: '#FF9090',
    500: '#FD7575',
    700: '#E56D6D',
  },
  dusk: {
    300: '#5F36B1',
    500: '#401694',
    700: '#2D1165',
  },
  indigo: {
    300: '#767AEA',
    500: '#5559DF',
    700: '#4649B9',
  },
  primrose: {
    300: '#936AE3',
    500: '#784BD1',
    700: '#6741B4',
  },
  grunge: {
    300: '#9A4CA8',
    500: '#7E3B8A',
    700: '#70327B',
  },
  mauve: {
    300: '#B87AEB',
    500: '#A25DDC',
    700: '#8F50C4',
  },
  noir: {
    300: '#606060',
    500: '#333333',
    700: '#191818',
  },
  charcoal: {
    300: '#969696',
    500: '#808080',
    700: '#646262',
  },
  pebbel: {
    300: '#D0D0D0',
    500: '#C4C4C4',
    700: '#B5B4B4',
  },
  stone: {
    300: '#C3C4CB',
    500: '#C3C4CB',
    700: '#C3C4CB',
  },
  storm: {
    300: '#ACC0D1',
    500: '#9AADBD',
    700: '#8B9FAD',
  },
  iceberg: {
    300: '#77B8D8',
    500: '#68A1BD',
    700: '#598CA5',
  },
  szrMaya: {
    300: '#88D7FF',
    500: '#66CCFF',
    700: '#5CB8E6',
  },
  szrCerulean: {
    300: '#73ABFC',
    500: '#579BFC',
    700: '#4E8ADE',
  },
  szrDenim: {
    300: '#3366AD',
    500: '#225091',
    700: '#1C4277',
  },
  szrElectricBlue: {
    300: '#6DBDFD',
    500: '#4DABF7',
    700: '#449ADE',
  },
  szrNavy: {
    300: '#0F5083',
    500: '#023156',
    700: '#01213B',
  },
  szrLeaf: {
    300: '#88D660',
    500: '#6EBD45',
    700: '#41891B',
  },
  main: {
    300: '#CCE5FF',
    500: '#0973EA',
    700: '#0760B9',
  },
  positive: {
    300: '#BDE5CF',
    500: '#2A985A',
    700: '#0F773C',
  },
  negative: {
    300: '#F5CCD3',
    500: '#DE4359',
    700: '#AD3343',
  },
  light: {
    300: '#FAFAFA',
    500: '#F2F2F2',
    700: '#DEDEDE',
  },
  dark: {
    300: '#C3C4CB',
    500: '#676A74',
    700: '#303134',
  },
  red: {
    300: '#DE4259',
    500: '#DE4259',
    700: '#DE4259',
  },
  slzrLilac: {
    300: '#EDEEFC',
    500: '#DDDFFF',
    700: '#E5E7FF',
  },
  raisin: {
    300: '#222222',
  },
  creamsicle: {
    500: '#FFAD3B',
  },
  aconitePurple: {
    300: '#784BD14D',
  },
  halfAndCatchFire: {
    300: '#FD642E4D',
  },
  main01green: {
    200: '#AEFE2C',
    500: '#AEFE2C',
  },
  main01: {
    500: '#8B9DFB',
  },
  darkMain: {
    500: '#101010',
  },
  success: '#2A985A',
  error: '#DE4359',
  warning: '#FECB04',
  info: '#0973EA',
  white: '#FFFFFF',
  black: '#000000',
  boxShadow: '#00000034',
  bodyBg: 'rgba(25, 17, 67, 0.80)',
  mainBg:
    'radial-gradient(circle at center, #0846c2, #003b97, #002e6b, #001f41, #030d1a)',
  darkBlue: '#191143',
  purple: '#3d2697',
  cyanBlue: '#00c9ff',
  pink: '#ea15e0',
  newBgColor: '#101010',
  darkMode: '#101010',
  lightMode: '#fff',
  activatedBackground: 'rgba(16, 16, 16, 0.52)',
  textColor: 'rgba(255, 255, 255, 0.60)',

  //design colors
  general: {
    white: '#FFF',
    black: '#000',
  },

  progressColor: {
    200: '#00C9FF',
    500: '#00C9FF',
  },

  progressActiveIntrosColor: {
    200: '#747D80',
    500: '#FFFFFF',
  },

  accent: {
    main01: '#8B9DFB',
    main01green: '#AEFE2C',
    main02: 'linear-gradient(92deg, #8B9DFB 4.09%, #4764FB 156.07%)',
    main02Hover: 'linear-gradient(95deg, #4764FB -42.59%, #8B9DFB 109.16%)',
  },

  background: {
    darkMode: '#101010',
    lightMode: '#FFF',
  },

  gradientBg: {
    normalDarkMode: '#1D1E2E',
    darkMode: "url('images/bg-sp.png') no-repeat center center",
    lightMode:
      'linear-gradient(0deg, #F2F6FF 0%, rgba(204, 203, 255, 0.60) 99.1%)',
  },

  shape: {
    darkMode: 'rgba(16, 16, 16, 0.20)',
    lightMode: 'rgba(255, 255, 255, 0.40)',
  },
}

const select = {
  select: {
    bgDarkMode: 'rgba(16, 16, 16, 0.20)',
    bgLightMode: 'rgba(255, 255, 255, 0.20)',
  },
}

const contrastColor = {
  solid: {
    yellow: '#FFD97D',
    orange: '#FF7D83',
    green: '#8DFD7C',
    pink: '#F57DFF',
    grey: '#BAB8B4',
  },
  gradient: {
    yellow: 'linear-gradient(92deg, #FFD97D 4.09%, #FFC537 156.07%)',
    orange: 'linear-gradient(131deg, #FF7D83 -5.67%, #EC343C 101.66%)',
    green: 'linear-gradient(123deg, #8DFD7C -2.45%, #47D232 109.3%)',
    pink: 'linear-gradient(155deg, #F57DFF -7.25%, #E038EF 118.65%)',
    grey: 'linear-gradient(107deg, #BAB8B4 0.53%, #9D9A96 109.67%)',
  },
}

const userAccount = {
  userAccount: {
    input: {
      bg: 'rgba(255, 255, 255, 0.04)',
    },
  },
}

const input = {
  input: {
    borderColor: '#8B9DFB',
    background: 'rgba(255, 255, 255, 0.04)',
    disabled: 'rgb(112 111 111 / 25%)',
  },
}

const navBar = {
  navBar: {
    iconColor: '#FFF',
    textColor: 'rgba(255, 255, 255, 0.60)',
  },
}

const grid = {
  badge: {
    color: {
      500: '#8B9DFB',
    },
  },
  header: {
    color: 'rgba(255, 255, 255, 0.80)',
    bg: 'rgba(0, 0, 0, 0.20)',
  },
  row: {
    color: '#FFF',
    bg: 'rgba(0, 0, 0, 0.20)',
    activatedBackground: 'rgba(16, 16, 16, 0.52)',
  },
}

const landingPage = {
  landingPage: {
    prominentText: {
      dark: '#FFF',
      light: '#101010',
    },
    descriptionText: {
      dark: '#E0E7FF',
      light: 'rgba(16, 16, 16, 0.60)',
    },
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...colors,
  ...grid,
  ...navBar,
  ...input,
  ...select,
  ...userAccount,
  ...contrastColor,
  ...landingPage,
}
