function getFirstSevenCharacters(input: string, sign: string = '@'): string {
  let currentSign = sign ?? '@'
  if (input?.length < 7) {
    return `${currentSign}${input}`
  } else {
    const firstSevenChars = input?.substring(0, 7)
    return `${currentSign}${firstSevenChars}`
  }
}

export { getFirstSevenCharacters }
