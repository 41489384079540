import { useEffect, useState } from 'react'

import { useBoolean } from '@chakra-ui/react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { useAuth, useMounted } from 'hooks'
import {
  IDeal,
  IDealSubDomain,
  IInvestorShortlistedDeal,
} from 'interfaces/models'

import { VIEW_DOMAINS_FULL_PATH } from 'modules/dashboard/constants/leaderboard'
import { TDealSector } from 'modules/dashboard/hooks/useLeaderBoardQueries'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

import { SELECT_SHORTLIST_FIELDS, SHORTLIST_TABLE } from '../constants'

type TDeal = IInvestorShortlistedDeal & {
  deals: IDeal & { deal_subdomains: IDealSubDomain[] }
  web_view_deal_avg_maven_rating: { maven_rating_avg_in_sector: number }
  web_investor_view_deal_on_demand: {
    percent_left: number
    progress_value: number
  }
  web_view_expanded_deal_avg_maven_rating: { deal_rank: number }
}

interface Row {
  deal_id: string
  bid_amount: number
}

const getDomainQuery = () =>
  supabase.from(VIEW_DOMAINS_FULL_PATH).select('subdomain_id, path')

const useShortList = (isInPipeLine: boolean = false) => {
  const { getUser } = useAuth()
  const [fetchedDeals, setFetchedDeals] = useState<IDealRow[]>()
  const [isLoading, toggle] = useBoolean()
  const isMounted = useMounted()

  const dealQuery = supabase
    .from(SHORTLIST_TABLE)
    .select(SELECT_SHORTLIST_FIELDS)
    .eq('user_id', getUser().user?.id)
    .order('priority')

  if (isInPipeLine) {
    dealQuery.eq('in_pipeline', isInPipeLine)
  }

  const domainQuery = getDomainQuery()

  /**
   * Transforms an array of deals into an array of deal rows.
   *
   * @param {TDeal[]} deals - the array of deals to be transformed
   * @return {IDealRow[]} the transformed array of deal rows
   */
  const transformDeals = (
    deals: TDeal[],
    domains: TDealSector[] | null,
  ): IDealRow[] => {
    const transformedDeal = deals
      .filter((item: TDeal) => !!item?.deals?.deal_id)
      .map((item: TDeal) => {
        // item.deals.deal_subdomains.forEach(async (dealSubdomain) => {
        //   const domainName = dealSubdomain.domain_taxonomy.domain_name
        //   domainName && domains.push(domainName)
        // })
        const sectors_and_domains =
          item?.deals?.deal_subdomains
            ?.map(
              (domain: IDealSubDomain) =>
                domains?.filter(
                  ({ subdomain_id }) => subdomain_id === domain.subdomain_id,
                )[0]?.path,
            )
            ?.join(',') ?? ''

        return {
          ...item,
          ...item.deals,
          maven_rating_avg_in_sector:
            item?.web_view_deal_avg_maven_rating?.maven_rating_avg_in_sector ??
            0,
          ...item?.web_view_expanded_deal_avg_maven_rating,
          ...item?.web_investor_view_deal_on_demand,
          sectors_and_domains,
        }
      })

    return transformedDeal
  }

  /**
   * Calculates the sum of the bid amounts for a given deal ID.
   *
   * @param {string} dealId - The ID of the deal.
   * @return {Promise<number>} - The total sum of the bid amounts.
   */
  async function sumAmountByDealId(dealId: string): Promise<number> {
    let amount = 0
    try {
      const { data, error }: PostgrestResponse<Row> = await supabase
        .from(SHORTLIST_TABLE)
        .select('deal_id, bid_amount')
        .eq('deal_id', dealId)

      if (error) {
        throw error
      }

      // Perform grouping and summing on the client side
      const groupedData: Record<
        string,
        { deal_id: string; total_amount: number }
      > = data.reduce(
        (result, row) => {
          const dealId = row.deal_id

          if (!result[dealId]) {
            result[dealId] = {
              deal_id: dealId,
              total_amount: 0,
            }
          }

          result[dealId].total_amount += row.bid_amount

          return result
        },
        {} as Record<string, { deal_id: string; total_amount: number }>,
      )

      amount = Object.values(groupedData)?.[0]?.total_amount
    } catch (error) {
      console.error('Error fetching data:', error)
      amount = 0
    }

    return amount
  }

  /**
   * Fetches deals and sets the fetched deals in the state.
   *
   * @return {void}
   */
  const fetchDeals = async () => {
    try {
      toggle.on()
      const { data, error } = await dealQuery
      if (error) throw error

      const { data: fetchedDomains }: PostgrestResponse<TDealSector> =
        await domainQuery

      setFetchedDeals(
        transformDeals((data as unknown as TDeal[]) || [], fetchedDomains),
      )
    } catch (e) {
      setFetchedDeals([])
    } finally {
      toggle.off()
    }
  }

  useEffect(() => {
    isMounted && fetchDeals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])
  return {
    isLoading,
    fetchedDeals,
    fetchDeals,
    sumAmountByDealId,
  }
}

export default useShortList
