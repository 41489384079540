export type TDEPLOY_VENTURE = {
  title: string
  content: string
  icon: JSX.Element
}

export const DEPLOY_VENTURE_OS: TDEPLOY_VENTURE[] = [
  {
    title: 'Emerging Verticals',
    content:
      'Instant access to market intel and industry insights no matter how obscure.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_327)">
          <path
            d="M32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32 8V18.6667"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32 56V48"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 32H18.6667"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M56 32H48"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32 32V32.0267"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_327">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Geographic Coverage',
    content: 'Diversify into new geographies through our global network.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_81)">
          <path
            d="M48 16V16.0267"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.9999 34.6667L38.6666 21.3333C37.7681 19.7094 37.3089 17.8792 37.3343 16.0235C37.3596 14.1677 37.8688 12.3507 38.8113 10.752C39.7539 9.15328 41.0973 7.82814 42.7088 6.90753C44.3202 5.98691 46.144 5.50269 47.9999 5.50269C49.8558 5.50269 51.6796 5.98691 53.2911 6.90753C54.9025 7.82814 56.2459 9.15328 57.1885 10.752C58.1311 12.3507 58.6402 14.1677 58.6656 16.0235C58.691 17.8792 58.2317 19.7094 57.3333 21.3333L47.9999 34.6667Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M28 12.6667L24 10.6667L8 18.6667V53.3333L24 45.3333L40 53.3333L56 45.3333V40"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24 10.6667V45.3333"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 40V53.3333"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_81">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Data Integration',
    content:
      'Seamlessly plug into any existing workflow and teams to maintain your single source of truth.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_282)">
          <path
            d="M13.3333 21.3333C16.2789 21.3333 18.6667 18.9455 18.6667 16C18.6667 13.0545 16.2789 10.6667 13.3333 10.6667C10.3878 10.6667 8 13.0545 8 16C8 18.9455 10.3878 21.3333 13.3333 21.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.9998 21.3333C34.9454 21.3333 37.3332 18.9455 37.3332 16C37.3332 13.0545 34.9454 10.6667 31.9998 10.6667C29.0543 10.6667 26.6665 13.0545 26.6665 16C26.6665 18.9455 29.0543 21.3333 31.9998 21.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M50.6668 21.3333C53.6123 21.3333 56.0002 18.9455 56.0002 16C56.0002 13.0545 53.6123 10.6667 50.6668 10.6667C47.7213 10.6667 45.3335 13.0545 45.3335 16C45.3335 18.9455 47.7213 21.3333 50.6668 21.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.3333 53.3333C16.2789 53.3333 18.6667 50.9455 18.6667 48C18.6667 45.0545 16.2789 42.6667 13.3333 42.6667C10.3878 42.6667 8 45.0545 8 48C8 50.9455 10.3878 53.3333 13.3333 53.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.9998 53.3333C34.9454 53.3333 37.3332 50.9455 37.3332 48C37.3332 45.0545 34.9454 42.6667 31.9998 42.6667C29.0543 42.6667 26.6665 45.0545 26.6665 48C26.6665 50.9455 29.0543 53.3333 31.9998 53.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.3335 21.3333V42.6667"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32 21.3333V42.6667"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M50.6668 21.3333V26.6667C50.6668 28.0812 50.1049 29.4377 49.1047 30.4379C48.1045 31.4381 46.748 32 45.3335 32H13.3335"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_282">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Information Asymmetry',
    content: 'Protect your most important asset - knowledge and insights.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_307)">
          <path
            d="M45.3333 29.3333H18.6666C15.7211 29.3333 13.3333 31.7211 13.3333 34.6667V50.6667C13.3333 53.6122 15.7211 56 18.6666 56H45.3333C48.2788 56 50.6666 53.6122 50.6666 50.6667V34.6667C50.6666 31.7211 48.2788 29.3333 45.3333 29.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.9999 45.3333C33.4727 45.3333 34.6666 44.1394 34.6666 42.6667C34.6666 41.1939 33.4727 40 31.9999 40C30.5272 40 29.3333 41.1939 29.3333 42.6667C29.3333 44.1394 30.5272 45.3333 31.9999 45.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.3333 29.3333V18.6667C21.3333 15.8377 22.4571 13.1246 24.4574 11.1242C26.4578 9.12381 29.1709 8 31.9999 8C34.8289 8 37.542 9.12381 39.5424 11.1242C41.5428 13.1246 42.6666 15.8377 42.6666 18.6667V29.3333"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_307">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Instant Analytics',
    content:
      'With the Deploy Intel Engine - get instant conviction on obscure deals and industries.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_47)">
          <path
            d="M10.6667 10.6667H26.6667V32H10.6667V10.6667Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.6667 42.6667H26.6667V53.3333H10.6667V42.6667Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.3333 32H53.3333V53.3333H37.3333V32Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.3333 10.6667H53.3333V21.3333H37.3333V10.6667Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_47">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Custom Filters',
    content:
      'Enhance comprehensive team decision-making by specifying your own mandates and requirements.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
      >
        <g clip-path="url(#clip0_703_262)">
          <path
            d="M50.6667 13.3333H13.3333C10.3878 13.3333 8 15.7211 8 18.6667V45.3333C8 48.2788 10.3878 50.6667 13.3333 50.6667H50.6667C53.6122 50.6667 56 48.2788 56 45.3333V18.6667C56 15.7211 53.6122 13.3333 50.6667 13.3333Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.6665 40V24L23.9998 29.3333L29.3332 24V40"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M42.6668 40V24M37.3335 34.6667L42.6668 40L48.0002 34.6667H37.3335Z"
            stroke="#6C80EA"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_703_262">
            <rect width="64" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
]
