import { TRoute } from 'types/route'

import Elements from 'modules/landing/pages/elements'

import { USER_SETTINGS_PATHNAME } from '../constants/user-settings'
import UserSettings from '../pages/user-settings'

const UserSettingsRoutes: TRoute[] = [
  {
    path: USER_SETTINGS_PATHNAME,
    profile: 'Investor',
    feature: 'ViewUserProfile',
    exact: true,
    component: UserSettings,
  },
  {
    path: '/elements',
    profile: 'Investor',
    feature: 'ViewBaseElements',
    exact: true,
    component: Elements,
  },
]

export default UserSettingsRoutes
