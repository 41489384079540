import { ReactNode } from 'react'

import { Text, TextProps } from '@chakra-ui/react'

const TextValue = ({
  children,
  ...rest
}: { children: ReactNode } & TextProps) => {
  return (
    <Text
      color="white"
      fontSize={['sm', 'sm', 'sm', 'md', 'lg', 'lg']}
      fontWeight="bold"
      lineHeight="normal"
      // sx={{ textWrap: 'nowrap' }}
      {...rest}
    >
      {children}
    </Text>
  )
}

export default TextValue
