import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Badge,
  Box,
  Button,
  GridItem,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useBoolean,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

import { ChevronLeftIcon } from '@chakra-ui/icons'

import { PostgrestResponse } from '@supabase/supabase-js'
import { ActionMeta, SingleValue } from 'chakra-react-select'
import supabase from 'config/supabase-client'
import { HTTP_CODE_23505 } from 'constants/http-codes'
import { NAVBAR } from 'constants/navbar'
import {
  useAuth,
  useDLToast,
  useLocations,
  useMobile,
  useQueryParams,
  useSectors,
  useSubDomains,
} from 'hooks'
import { IInvestorShortlistedDeal } from 'interfaces/models'
import { ISelectOption } from 'interfaces/select'
import uniqueId from 'lodash/uniqueId'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'
import { breakpointValues } from 'theme/foundations/breakpoints'

import Grid from 'components/common/DLGrid/Grid'
import GridCell from 'components/common/DLGrid/GridCell'
import GridHeader, {
  GridHeaderText,
  IGridHeader,
} from 'components/common/DLGrid/GridHeader'
import GridRow from 'components/common/DLGrid/GridRow'
import { ChevronDown } from 'components/common/Icons'
import { WithResponsiveLayout, WithSpinnerLoading } from 'components/hoc'

import { SHORTLIST_TABLE } from 'modules/screen-rooms/constants'

import DealDetail from '../components/DealDetail'
import { DealSidePanel, StartupsSpotlight } from '../components/other'
import Select from '../components/other/Select'
import {
  DEAL_FIELDS_480px,
  DEAL_FIELDS_992px,
  FIELDS,
  FILTER_BY_DOMAIN,
  MIN_TICKET_SIZE_OPTIONS,
  TICKET_SIZE_FILTER_MAPPING,
  TLeaderBoardFilter,
  showDomainsCell,
} from '../constants/leaderboard'
import {
  ADD_DEAL_TO_SHORTLIST_DUPLICATE_ERROR,
  ADD_DEAL_TO_SHORTLIST_ERROR,
  ADD_DEAL_TO_SHORTLIST_SUCCESS,
} from '../constants/messages'
import { dealPriorityOptions, geoOptions } from '../constants/select'
import { DashboardProvider } from '../context'
import { useLeaderBoardQueries } from '../hooks'
import { IDealRow, IDomainSelect } from '../interfaces/deal-table'

const EXPANDED_COL = 1

const getHeaders = (currentBreakpoint: string | undefined) => {
  let headers: IGridHeader[] = FIELDS.map((field, index) => ({
    ...field,
    w: index === 0 ? '1.5rem' : 'auto',
  }))

  if (['md', 'lg'].includes(currentBreakpoint as string)) {
    headers = DEAL_FIELDS_992px.map((field, index) => ({
      ...field,
      w: index === 0 ? '1.5rem' : 'auto',
    }))
  }

  if (['sm', 'base'].includes(currentBreakpoint as string)) {
    headers = DEAL_FIELDS_480px.map((field, index) => ({
      ...field,
      w: index === 0 ? '1.5rem' : 'auto',
    }))
  }

  return headers
}

const Filter = ({
  selectedDomain,
  handleTicketSizeChange,
  handleDomainChange,
  handleSectorChange,
  handleLocationChange,
  handleRegionChange,
}: {
  selectedDomain: string | null | undefined
  handleTicketSizeChange: (ticketSize: string) => void
  handleDomainChange: (
    newValue: SingleValue<IDomainSelect>,
    actionMeta: ActionMeta<IDomainSelect>,
  ) => void
  handleSectorChange: (
    newValue: SingleValue<IDomainSelect>,
    actionMeta: ActionMeta<IDomainSelect>,
  ) => void
  handleLocationChange?: (value: ISelectOption) => void
  handleRegionChange?: (value: ISelectOption) => void
}) => {
  const [activatedButton, setActivatedButton] = useState<string | undefined>()
  const { domains, formatDomainsAsSelections } = useSubDomains()
  const { sectors, formatSectorsAsSelections } = useSectors()
  const { formatLocationsAsSelections } = useLocations()
  const { getUser } = useAuth()
  const user = getUser()?.metadata

  const bgTabs = useColorModeValue(
    'rgba(33, 34, 45, 0.52)',
    'rgba(16, 16, 16, 0.06)',
  )

  const colorMode = useColorModeValue(
    'background.lightMode',
    'background.darkMode',
  )

  const handleFilterByTicketSizeButtons = (value: ISelectOption) => {
    if (value?.value === activatedButton) {
      setActivatedButton?.(undefined)
      handleTicketSizeChange('')
      return
    }
    setActivatedButton?.(value?.value)
    handleTicketSizeChange(value?.value)
  }

  return (
    <WithResponsiveLayout
      w={['full']}
      gap="1rem"
      justifyContent="space-between"
      alignItems={['start', 'start', 'start', 'start', 'start', 'center']}
      mb={6}
    >
      {/* <HStack>
        <Text
          minW="100px"
          fontWeight="bold"
          fontSize={['sm', 'sm', 'sm', 'sm', 'lg']}
          color={colorMode}
          lineHeight="1.05rem"
          sx={{ textWrap: 'nowrap' }}
        >
          Hello, {user?.first_name ?? ''} {user?.last_name ?? ''}!
        </Text>
      </HStack> */}

      {/* <HStack w={['full']} gap={4} flexWrap="wrap"> */}
      {/* <ButtonGroup
          gap="0.5rem"
          p="0.25rem"
          bg={bgTabs}
          borderRadius={4}
          flex={['auto', 'auto', 'auto', 'unset']}
          justifyContent="center"
        >
          {MIN_TICKET_SIZE_OPTIONS.map(({ label, value }) => (
            <Button
              key={`${value}`}
              variant={value === activatedButton ? 'filled' : 'ghost'}
              padding="0.5rem 2.25rem"
              size={['sm', 'md', 'md', 'md', 'md']}
              onClick={() => handleFilterByTicketSizeButtons(value)}
              color="white"
            >
              {label}
            </Button>
          ))}
        </ButtonGroup> */}

      {/* <InputGroup
          flex={[
            '0 0 calc((100% - 1rem) / 2)',
            '0 0 calc((100% - 1rem) / 2)',
            '0 0 calc((100% - 2rem) / 3)',
            '0 0 calc((100% - 2rem) / 3)',
            '0 0 200px',
          ]}
        >
          <InputRightElement pointerEvents="none">
            <SearchIcon w="1.25rem" h="1.25rem" color={iconColor} />
          </InputRightElement>
          <Input
            bg={bgInput}
            color={colorMode}
            border="1px solid rgba(255, 255, 255, 0.12)"
            borderRadius={3}
            type="text"
            placeholder="Search..."
            _placeholder={{ color: colorMode }}
          />
        </InputGroup> */}
      {/* </HStack> */}
      <HStack
        w={['full']}
        gap={4}
        flexWrap="wrap"
        justifyContent="end"
        // display="none"
      >
        <Select
          placeholder="Min. Ticket Size"
          options={MIN_TICKET_SIZE_OPTIONS}
          onChange={handleFilterByTicketSizeButtons}
          isClearable
        />
        <Select
          placeholder="Location"
          options={formatLocationsAsSelections()}
          onChange={handleLocationChange}
          isClearable
        />
        <Select
          placeholder="Region"
          options={geoOptions}
          onChange={handleRegionChange}
          isClearable
        />
        <Select
          placeholder="Sectors"
          options={formatSectorsAsSelections(sectors)}
          onChange={handleSectorChange}
          isClearable
        />
        <Select
          placeholder="Domains"
          options={formatDomainsAsSelections(domains)}
          onChange={handleDomainChange}
          isClearable
        />
      </HStack>
    </WithResponsiveLayout>
  )
}

const LeaderBoard = () => {
  const history = useHistory()
  const { getUser } = useAuth()
  const isMobile = useMobile()

  const queryParams = useQueryParams()
  const [activatedRows, setActivatedRows] = useState<Record<number, boolean>>(
    {},
  )
  const [filters, setFilters] = useState<TLeaderBoardFilter>({})
  const [sorting, setSorting] = useState<Record<string, { desc: boolean }>>({})
  const [currentDeal, setCurrentDeal] = useState<IDealRow | null>()
  const [currentDealInPanel, setCurrentDealInPanel] =
    useState<IDealRow | null>()

  const [priority, setPriority] = useState<ISelectOption | null>()
  const [isInShortlist, toggle] = useBoolean()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentBreakpoint = useBreakpointValue(breakpointValues, {
    ssr: false,
  })
  const ref = useRef(null)
  const { isLoading, fetchedDeals } = useLeaderBoardQueries(
    filters,
    sorting,
    setCurrentDeal,
    ref,
  )

  /**
   *  helper for showing error toast
   * **/
  const { showErrorToast, showSuccessToast } = useDLToast()

  const headers = getHeaders(currentBreakpoint)
  const numOfColumns = headers.length + EXPANDED_COL

  const handleExpandEvent = useCallback(
    (idx: number) => {
      setActivatedRows({
        ...activatedRows,
        ...{
          [idx]: !activatedRows[idx],
        },
      })
    },
    [activatedRows],
  )

  const handleTicketSizeChange = (ticketSize: string) => {
    const filterValue =
      TICKET_SIZE_FILTER_MAPPING[ticketSize as keyof TLeaderBoardFilter]

    setFilters({
      ...filters,
      ...{ minimum_ticket_size: filterValue },
    })
    history.push({ search: queryParams.toString() })
    // const isChangedParam =
    //   !queryParams.has(FILTER_BY_DOMAIN) ||
    //   queryParams.get(FILTER_BY_DOMAIN) !== selected?.value
    // if (selected?.value && isChangedParam) {
    //   toggle.toggle()
    //   queryParams.set(FILTER_BY_DOMAIN, selected?.value)
    //   history.push({ search: queryParams.toString() })
    // }
  }

  const handleLocationChange = (value: ISelectOption) => {
    setFilters({
      ...filters,
      ...{ hq_id: value?.value ?? '' },
    })
  }

  const handleRegionChange = (value: ISelectOption) => {
    setFilters({
      ...filters,
      ...{ region_id: value?.value ?? '' },
    })
  }

  const handleDomainChange = (
    selected: SingleValue<ISelectOption>,
    meta: ActionMeta<ISelectOption>,
  ) => {
    setFilters({
      ...filters,
      ...{ subdomain_id: selected?.value ?? '' },
    })
    history.push({ search: queryParams.toString() })
  }

  const handleSectorChange = (
    selected: SingleValue<ISelectOption>,
    meta: ActionMeta<ISelectOption>,
  ) => {
    setFilters({
      ...filters,
      ...{ sector_id: selected?.value ?? '' },
    })
    history.push({ search: queryParams.toString() })
  }

  const handleSorting = (field: string) => {
    let sortKey = field === 'deal_rank' ? 'maven_rating_avg_in_sector' : field
    setSorting({
      // ...sorting,
      ...{ [sortKey]: { desc: !sorting[sortKey]?.desc } },
    })
  }

  const goBack = () => {
    setCurrentDeal(null)
  }

  /**
   * Function to add a deal to the shortlist.
   *
   * @param {} -
   * @return {Promise<void>}
   */
  const addDealToShortlist = useCallback(async (dealID?: string) => {
    try {
      const payload = {
        deal_id: dealID ?? currentDeal?.deal_id,
        user_id: getUser()?.user?.id,
        priority: priority?.value ?? dealPriorityOptions[2]?.value,
      }

      const { error } = await supabase.from(SHORTLIST_TABLE).upsert(payload)

      if (error) {
        const { code } = error
        if (code === HTTP_CODE_23505) {
          showErrorToast(ADD_DEAL_TO_SHORTLIST_DUPLICATE_ERROR)
          return
        }

        showErrorToast(ADD_DEAL_TO_SHORTLIST_ERROR)
        return
      }

      showSuccessToast(ADD_DEAL_TO_SHORTLIST_SUCCESS)
      history.push(NAVBAR.PATHS.SCREEN_ROOM)
    } catch (err) {
      showErrorToast(ADD_DEAL_TO_SHORTLIST_ERROR)
    }
  }, [])

  const checkDealInShortlist = async () => {
    try {
      const { data, error }: PostgrestResponse<IInvestorShortlistedDeal> =
        await supabase
          .from(SHORTLIST_TABLE)
          .select('*')
          .eq('deal_id', currentDeal?.deal_id)
          .eq('user_id', getUser()?.user?.id)

      !error && data && data?.length > 0 ? toggle.on() : toggle.off()
    } catch (error) {
      toggle.off()
    }
  }

  useEffect(() => {
    if (currentDeal?.deal_id) {
      checkDealInShortlist()
      // onOpen()
    }
  }, [currentDeal])

  useEffect(() => {
    if (currentDealInPanel?.deal_id) {
      onOpen()
    }
  }, [currentDealInPanel])

  return (
    <DashboardProvider>
      <Stack gap="20px">
        {!currentDeal && !isMobile && fetchedDeals?.rows?.length > 2 && (
          <StartupsSpotlight
            startups={fetchedDeals?.rows?.slice(0, 3) || []}
            onExpandDetail={setCurrentDealInPanel}
          />
        )}
        <DealSidePanel
          currentDeal={currentDealInPanel}
          isOpen={isOpen}
          onClose={onClose}
          addDealToShortlist={addDealToShortlist}
          setCurrentDeal={setCurrentDeal}
        />
        {currentDeal && (
          <VStack w="full" h="full">
            <HStack
              w="full"
              justifyContent="space-between"
              alignItems="start"
              color="white"
              flexDirection={['column', 'column', 'row', 'row', 'row']}
            >
              <Button
                leftIcon={<ChevronLeftIcon color="#A2A8B4" w="30px" h="35px" />}
                variant="simple"
                fontSize="xl"
                fontWeight="bold"
                pl="0"
                onClick={() => goBack?.()}
              >
                Back to Leaderboard
              </Button>

              {/* <HStack gap={{ base: '10px', md: '16px' }}>
                {isInShortlist && (
                  <Badge colorScheme="blue" size="lg" borderRadius="lg">
                    This deal is already in your shortlist
                  </Badge>
                )}

                {!isInShortlist && (
                  <>
                    <Select
                      placeholder="Priority"
                      options={dealPriorityOptions}
                      onChange={(newValue: SingleValue<ISelectOption>) => {
                        setPriority(newValue)
                      }}
                      value={dealPriorityOptions.filter(
                        (p) => p?.value === priority?.value,
                      )}
                      isClearable
                      customStyles={{
                        container: {
                          flex: '0 0 auto',
                          border: '1px solid #8B9DFB',
                          bg: 'darkMode ',
                        },
                        control: { border: '1px solid #8B9DFB !important' },
                        menu: { border: '1px solid #8B9DFB !important' },
                        option: (state: any) => {
                          return {
                            color: state?.data?.color,
                          }
                        },
                      }}
                    />

                    <Button
                      variant="filled"
                      size={['sm', 'sm', 'md']}
                      w="full"
                      borderRadius="1rem !important"
                      onClick={() => addDealToShortlist()}
                    >
                      Add to Shortlist
                    </Button>
                  </>
                )}
              </HStack> */}

              {isInShortlist && (
                <Badge colorScheme="blue" size="lg" borderRadius="lg">
                  This deal is already in your collection
                </Badge>
              )}

              {!isInShortlist && (
                <Button
                  variant="filled"
                  borderRadius={['16px !important']}
                  p={[
                    '15px 54px',
                    '15px 54px',
                    '15px 54px',
                    '15px 54px',
                    '15px 54px',
                  ]}
                  onClick={() =>
                    currentDeal?.deal_id &&
                    addDealToShortlist(currentDeal?.deal_id)
                  }
                >
                  Save to Collection
                </Button>
              )}
            </HStack>
            <DealDetail deal={currentDeal} />
          </VStack>
        )}
        {!currentDeal && (
          <Stack gap="20px">
            <Helmet>
              <title>LeaderBoard Page</title>
            </Helmet>

            <HStack color="white" w="full" justifyContent="space-between">
              <Heading fontSize="20px" fontWeight="bold">
                Top Rated Opportunities
              </Heading>

              <Button
                color="#DCE4FF"
                bg="transparent"
                borderRadius="100px"
                border="1px solid #DCE4FF"
                fontWeight="bold"
                size={['sm']}
              >
                View all
              </Button>
            </HStack>

            <Filter
              selectedDomain={queryParams.get(FILTER_BY_DOMAIN)}
              handleTicketSizeChange={handleTicketSizeChange}
              handleDomainChange={handleDomainChange}
              handleSectorChange={handleSectorChange}
              handleLocationChange={handleLocationChange}
              handleRegionChange={handleRegionChange}
            />
            <Grid>
              {!['sm', 'base'].includes(currentBreakpoint as string) && (
                <GridHeader numCols={numOfColumns}>
                  {headers.map(({ field, label, hasSorting }, index) => (
                    <GridItem key={field}>
                      <GridHeaderText
                        text={label}
                        hasSorting={hasSorting}
                        stackStyles={{
                          justifyContent: 'center',
                          flexDir: 'row',
                          alignItems: 'center',
                        }}
                        onClick={() => handleSorting(field)}
                      />
                    </GridItem>
                  ))}
                </GridHeader>
              )}
              <WithSpinnerLoading isLoading={isLoading}>
                {fetchedDeals?.rows.map(({ accordions, ...row }, idx) => {
                  return (
                    <GridRow
                      ref={idx === fetchedDeals?.rows?.length - 1 ? ref : null}
                      key={uniqueId(`row-deals-${idx}`)}
                      numCols={numOfColumns}
                      isOpen={activatedRows[idx]}
                      accordions={accordions}
                      {...row}
                    >
                      {headers.map(({ field, type }, index) => {
                        const cell = row[field]
                        const isLastCell = index === headers.length - 1
                        if (!isLastCell) {
                          return (
                            <GridCell
                              shouldBeGridItem
                              key={uniqueId(cell?.value)}
                              {...cell}
                              type={type}
                              onClick={() => {
                                setCurrentDealInPanel({ ...row?.originalRow })
                              }}
                            />
                          )
                        }

                        const domainsJsx = showDomainsCell(cell?.value)

                        return (
                          <GridCell
                            shouldBeGridItem
                            key={cell?.value}
                            {...cell}
                            type={type}
                            render={domainsJsx}
                            onClick={() => {
                              setCurrentDealInPanel({ ...row?.originalRow })
                            }}
                          />
                        )
                      })}

                      <GridCell
                        onClick={() => handleExpandEvent(idx)}
                        shouldBeGridItem
                        key={uniqueId()}
                        cellStyles={{
                          p: 0,
                          justifyContent: 'end',
                          alignItems: 'center',
                        }}
                      >
                        <ChevronDown
                          transitionDuration="0.3s"
                          transform={`rotate(${activatedRows[idx] ? 180 : 0}deg)`}
                          boxSize={[4, 4, 4, 5, 6]}
                          color="accent.main01"
                        />
                      </GridCell>
                    </GridRow>
                  )
                })}
              </WithSpinnerLoading>
              <Box ref={ref} visibility="collapse" w="1ch"></Box>
            </Grid>
          </Stack>
        )}
      </Stack>
    </DashboardProvider>
  )
}

export default LeaderBoard
