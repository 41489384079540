import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import { ILocation } from 'interfaces/models'

import useMounted from './useMounted'

export default function useLocations() {
  const [locations, setLocations] = useState<ILocation[] | null>([])
  const isMounted = useMounted()
  const formatLocationsAsSelections = (
    givenLocations?: ILocation[] | null | undefined,
  ) => {
    return (
      (givenLocations || locations)?.map(({ location_name, location_id }) => ({
        label: location_name,
        value: location_id,
      })) ?? []
    )
  }

  useEffect(() => {
    /**
     * Fetches all the locations from the 'locations' table in the database.
     *
     * @return {Promise<void>} Resolves with no value.
     */
    const fetchLocations = async () => {
      const SELECTED_FIELDS = '*'
      try {
        const { data, error }: PostgrestResponse<ILocation> = await supabase
          .from(TABLES_NAME.locations)
          .select(SELECTED_FIELDS)
          .order('location_name', { ascending: true })

        if (error) throw error

        setLocations(data)
      } catch (e) {}
    }

    isMounted && fetchLocations()
  }, [isMounted])

  return { locations, formatLocationsAsSelections }
}
