import { useEffect, useState } from 'react'

const useLocalStorage = (key: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    let value = defaultValue

    try {
      if (window.localStorage && key) {
        value = JSON.parse(window.localStorage.getItem(key) || defaultValue)
      }
    } catch (error: any) {
      console.log(error?.message)
    }
    return value
  })

  useEffect(() => {
    try {
      if (window.localStorage && storedValue) {
        window.localStorage.setItem(
          key,
          storedValue instanceof Object
            ? JSON.stringify(storedValue)
            : storedValue,
        )
        setStoredValue(storedValue || defaultValue)
      }
    } catch (error) {
      console.log("Window local storage isn't support...")
    }
  }, [storedValue, defaultValue, key])

  return [storedValue, setStoredValue]
}

export default useLocalStorage
