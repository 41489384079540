import { Icon } from '@chakra-ui/react'

const WaveIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.8477 25.7343C28.0977 25.7343 28.793 25.0546 28.793 23.8593V12.8203C28.793 11.6249 28.0977 10.9374 26.8477 10.9374H24.7305C23.4805 10.9374 22.793 11.6249 22.793 12.8203V23.8593C22.793 25.0546 23.4805 25.7343 24.7305 25.7343H26.8477ZM19.4492 25.7344C20.6992 25.7344 21.3867 25.0547 21.3867 23.8594V15.0938C21.3867 13.8984 20.6992 13.2188 19.4492 13.2188H17.3242C16.082 13.2188 15.3867 13.8984 15.3867 15.0938V23.8594C15.3867 25.0547 16.082 25.7344 17.3242 25.7344H19.4492ZM13.9922 23.8593C13.9922 25.0546 13.2969 25.7343 12.0469 25.7343H9.92969C8.6875 25.7343 7.99219 25.0546 7.99219 23.8593V17.3827C7.99219 16.1874 8.6875 15.5077 9.92969 15.5077H12.0469C13.2969 15.5077 13.9922 16.1874 13.9922 17.3827V23.8593Z"
        // fill="white"
      />
    </Icon>
  )
}

export default WaveIcon
