import { useState } from 'react'

import { Box, Button, HStack, Heading, Text, VStack } from '@chakra-ui/react'

import { SingleValue } from 'chakra-react-select'
import { ISelectOption } from 'interfaces/select'

import { WithCenterLayout } from 'components/hoc'

import { dealPriorityOptions } from 'modules/dashboard/constants/select'

import Select from './Select'

const DealStatus = ({ defaultPriority }: { defaultPriority?: string }) => {
  const [priority, setPriority] = useState<
    ISelectOption | string | null | undefined
  >(defaultPriority)

  return (
    <VStack
      p="24px"
      gap="36px"
      color="#F9FAFA"
      fontWeight="semibold"
      borderRadius="10px"
      bg="#0E0F1D"
      alignItems="start"
      w="full"
    >
      <VStack gap="12px" alignItems="start" w="full">
        <Heading fontSize="24px" fontWeight="semibold">
          Deal Status
        </Heading>
        <Select
          placeholder="Priority"
          options={dealPriorityOptions}
          onChange={(newValue: SingleValue<ISelectOption>) => {
            setPriority(newValue)
          }}
          value={dealPriorityOptions.filter((p) => p?.value === priority)}
          isClearable
          customStyles={{
            container: {
              flex: '0 0 auto',
              border: '1px solid #8B9DFB',
              bg: 'darkMode ',
            },
            control: { border: '1px solid #8B9DFB !important' },
            menu: { border: '1px solid #8B9DFB !important' },
            option: (state: any) => {
              return {
                color: state?.data?.color,
              }
            },
          }}
        />
      </VStack>

      <VStack gap="20px" alignItems="start" w="full">
        <Text fontSize="20px">Additional Files</Text>
        <VStack
          gap="4px"
          alignItems="start"
          fontSize="md"
          fontWeight="medium"
          w="full"
        >
          <HStack
            borderRadius="4px"
            bg="#1E293B"
            justifyContent="space-between"
            p="8px 20px"
            w="full"
          >
            <Text>FILE NAME.pdf</Text>
            <Text>1 July 2024</Text>
          </HStack>
          <HStack
            w="full"
            borderRadius="4px"
            bg="#1E293B"
            justifyContent="space-between"
            p="8px 20px"
          >
            <Text>SUPER LONG FILE NAM...</Text>
            <Text>20 July 2024</Text>
          </HStack>
        </VStack>

        <VStack
          gap="18px"
          color="#E7E7E8"
          fontWeight="medium"
          alignItems="start"
          w="full"
        >
          <Text fontSize="md" fontWeight="medium">
            Add supplement files
          </Text>

          <VStack w="full" gap="18px" alignItems="start">
            <WithCenterLayout
              w="full"
              border="1px dashed #546FFF"
              borderRadius="10px"
              p="24px 0px"
            >
              <Box opacity={0.28}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M28.8937 19.066L28.3603 25.7327C28.1603 27.7727 28.0003 29.3327 24.387 29.3327H7.61367C4.00034 29.3327 3.84034 27.7727 3.64034 25.7327L3.10701 19.066C3.00034 17.9593 3.34701 16.9327 3.97367 16.146C3.98701 16.1327 3.98701 16.1327 4.00034 16.1193C4.73367 15.226 5.84034 14.666 7.08034 14.666H24.9203C26.1603 14.666 27.2537 15.226 27.9737 16.0927C27.987 16.106 28.0003 16.1193 28.0003 16.1327C28.6537 16.9193 29.0137 17.946 28.8937 19.066Z"
                    stroke="#546FFF"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M4.66699 15.2404V8.3737C4.66699 3.84036 5.80033 2.70703 10.3337 2.70703H12.027C13.7203 2.70703 14.107 3.2137 14.747 4.06703L16.4403 6.3337C16.867 6.8937 17.1203 7.24036 18.2537 7.24036H21.6537C26.187 7.24036 27.3203 8.3737 27.3203 12.907V15.2937"
                    stroke="#546FFF"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5732 22.666H19.4266"
                    stroke="#546FFF"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
            </WithCenterLayout>

            <Text fontSize="sm" fontWeight="normal">
              *drag or browser from device
            </Text>
          </VStack>
        </VStack>
      </VStack>

      <Button
        w="full"
        p="12px 120px"
        borderRadius="10px"
        bg="#546FFF"
        fontSize="md"
      >
        Upload
      </Button>
    </VStack>
  )
}

export default DealStatus
