import { forwardRef } from 'react'

import { StackProps, VStack } from '@chakra-ui/react'

const FormContainer = forwardRef(
  (
    {
      children,
      ...rest
    }: {
      children: React.ReactNode
    } & StackProps,
    ref,
  ) => {
    return (
      <VStack
        as="div"
        justifyContent="center"
        alignItems="center"
        borderRadius={5}
        bgColor="rgba(25, 17, 67, 0.80)"
        w="full"
        ref={ref}
        {...rest}
      >
        {children}
      </VStack>
    )
  },
)

export default FormContainer
