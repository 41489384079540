import { useState } from 'react'

import { Grid, GridItem, VStack } from '@chakra-ui/react'

import { Helmet } from 'react-helmet'

import ToggleMode from 'components/common/other/ToggleMode'

import UserProfile from '../components/UserProfile'
import UserProfileLeftBar from '../components/UserProfileLeftBar'
import { SETTING_ITEMS, TSettingItem } from '../constants/user-settings'
import { useUserSettings } from '../hooks'

const UserSettings = () => {
  const [selectedSetting, setSelectedSetting] = useState<TSettingItem>(
    SETTING_ITEMS[0],
  )

  const { userSettings, isLoading } = useUserSettings()
  return (
    <>
      <Helmet>
        <title>User Settings Page</title>
      </Helmet>

      <UserProfile>
        <Grid
          templateColumns={['1fr', '1fr', '1fr', '1fr', '1fr 2.5fr']}
          gap={[3, 3, 3, '40px']}
          h="full"
        >
          <GridItem h="full">
            <UserProfile.LeftBar>
              {!isLoading && (
                <UserProfileLeftBar.Avatar
                  userID={userSettings?.user_id}
                  username={userSettings?.username}
                  name={`${userSettings?.first_name} ${userSettings?.last_name}`}
                  currentAvatar={userSettings?.avatar}
                />
              )}
              <VStack gap="24px" w="full" as="ul" alignItems="start">
                <ToggleMode />
                {SETTING_ITEMS.map(({ text }, index) => {
                  return (
                    <UserProfileLeftBar.Item
                      key={text}
                      text={text}
                      activatedIndex={SETTING_ITEMS.findIndex(
                        ({ text: itemText }) =>
                          itemText === selectedSetting?.text,
                      )}
                      index={index}
                      onClick={() => setSelectedSetting({ text })}
                    />
                  )
                })}
              </VStack>
            </UserProfile.LeftBar>
          </GridItem>
          <GridItem h="full">
            {userSettings?.user_id && (
              <UserProfile.Content
                selectedSetting={selectedSetting}
                userSettings={userSettings}
              />
            )}
          </GridItem>
        </Grid>
      </UserProfile>
    </>
  )
}

export default UserSettings
