import { Button, HStack, Stack, Text, VStack } from '@chakra-ui/react'

import { IViewScoutInSector } from 'interfaces/views'
import uniqueId from 'lodash/uniqueId'
import { getFirstSevenCharacters } from 'utils/string'

import { UserID } from 'components/common/other/deal-details/display'
import { DotTagForSectorPath } from 'components/common/other/deal-details/display/SectorsPath'
import { WithResponsiveLayout } from 'components/hoc'

const ModerateLayout = ({
  numComments,
  rank,
}: {
  numComments: number | null
  rank: string | number | null
}) => {
  return (
    <HStack
      w="full"
      p={3}
      borderRadius="12px"
      border="0.636px solid rgba(226, 232, 240, 0.40)"
      justifyContent="space-between"
    >
      <Text color="#F0C151" fontSize="12px" fontWeight="medium">
        Moderate
      </Text>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="118"
        height="30"
        viewBox="0 0 118 30"
        fill="none"
      >
        <path
          d="M1.00005 24.1303L8.22787 3.28649C9.10284 0.76322 12.7195 0.908755 13.3889 3.49417L17.9517 21.1171C18.297 22.4509 19.9432 22.93 20.9503 21.9897V21.9897C22.006 21.0041 23.734 21.5848 23.9803 23.0079L24.1551 24.0174C24.4642 25.8035 26.582 26.596 27.9876 25.4515L32.477 21.7966C33.6094 20.8747 35.281 21.8415 35.0465 23.2828V23.2828C34.7947 24.8309 36.7012 25.7678 37.7729 24.6227L38.364 23.991C38.7537 23.5746 39.3135 23.3611 39.8815 23.4122L42.4782 23.6458C43.4402 23.7324 44.375 23.2981 44.9294 22.5072L45.642 21.4905C46.9022 19.6926 49.6677 20.0724 50.3964 22.1435L50.5345 22.5358L51.9094 25.3219C52.4615 26.4406 53.7085 27.035 54.9252 26.7593L58.86 25.8675C59.5097 25.7202 60.0445 25.261 60.2883 24.6409L60.8666 23.1698C61.31 22.0419 62.8962 22.0142 63.3786 23.126V23.126C63.7913 24.077 65.0738 24.2346 65.7044 23.4118L68.4782 19.7928C69.2311 18.8104 70.6057 18.558 71.6585 19.2089V19.2089C72.4878 19.7216 72.9265 20.6835 72.77 21.6458L71.9514 26.6778C71.86 27.2399 72.0344 27.8126 72.4236 28.2283V28.2283C73.4397 29.3136 75.257 28.8052 75.5624 27.3502L75.6826 26.7778C76.1622 24.4934 79.4741 24.6493 79.737 26.9687V26.9687C79.9017 28.4222 81.4889 29.2434 82.7705 28.5383L84.3732 27.6565C84.9031 27.3649 85.3197 26.9037 85.5561 26.347L89.4791 17.1068C90.4962 14.7111 93.9808 15.0021 94.5865 17.5332L95.7819 22.5285C95.975 23.3355 96.5287 24.0088 97.2833 24.354L100.174 25.6764C101.251 26.1695 102.503 25.5017 102.693 24.3318V24.3318C102.999 22.4512 105.638 22.279 106.185 24.1039L106.369 24.7165C106.902 26.4903 109.466 26.3229 109.764 24.4949V24.4949C109.918 23.5434 110.815 22.8976 111.767 23.0524L117.288 23.9505"
          stroke="#BEE3F8"
          stroke-width="1.27193"
          stroke-linecap="round"
        />
      </svg> */}

      <HStack gap="0">
        <HStack gap="0.3rem">
          <Text fontSize="14px" fontWeight="bold">
            {rank}
          </Text>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M8.03516 10.7782L4.76961 12.7727L5.65739 9.05043L2.75183 6.56154L6.56572 6.25599L8.03516 2.72266L9.50461 6.25599L13.3191 6.56154L10.4129 9.05043L11.3007 12.7727L8.03516 10.7782Z"
              fill="#CBBFF8"
            />
          </svg>
        </HStack>
        {/* <Text lineHeight={1} fontSize="10px" color="rgba(255, 255, 255, 0.60)">
          {numComments ?? 0} Comments
        </Text> */}
      </HStack>
    </HStack>
  )
}

const ScoutCard = ({
  numComments,
  rank,
  user,
  userStats,
  expInSectors,
}: {
  numComments: number | null
  rank: string | number | null
  user: {
    avatar: string | null
    user_id: string | null
    location_name: string | null
  }
  userStats: {
    count_intros: number | null
    count_evals: number | null
  }
  expInSectors: IViewScoutInSector[] | []
}) => {
  return (
    <VStack p="20px" bg="#4A5568" borderRadius="8px" gap="20px">
      <HStack justifyContent="space-between" alignItems="center" w="full">
        <UserID
          avatar={user?.avatar ?? ''}
          id={getFirstSevenCharacters(user?.user_id ?? '')}
          location={user?.location_name}
          avatarProps={{
            w: ['40px', '40px', '40px', '48px', '48px', '48px'],
            h: ['40px', '40px', '40px', '48px', '48px', '48px'],
            sx: { '> img': { filter: 'blur(2.5px)' } },
          }}
        />

        <Button
          p="8px 16px"
          width="84px"
          bg="#DCE4FF"
          borderRadius="100px"
          border="1px solid #DCE4FF"
        >
          <Text color="#4A5568" fontSize="12px" fontWeight="bold">
            Connect
          </Text>
        </Button>

        {/* <VStack w="full" alignItems="end">
          <Text color="#8B9DFB" fontWeight="bold" fontSize="14px">
            {`Rank ${rank ?? 0}`}
          </Text>
        </VStack> */}
      </HStack>

      <VStack gap="10px" w="full">
        <HStack gap="8px" w="full" justifyContent="space-between">
          {/* <WithCenterLayout
          w="full"
          borderRadius="8px"
          border="0.5px solid #EAECF0"
          gap="8px"
          p="5px 0"
          bg="rgba(255, 255, 255, 0.20)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M17 21.5078C17 18.7464 14.7614 16.5078 12 16.5078C9.23858 16.5078 7 18.7464 7 21.5078M17 21.5078H17.8031C18.921 21.5078 19.48 21.5078 19.9074 21.29C20.2837 21.0983 20.5905 20.7915 20.7822 20.4152C21 19.9878 21 19.4288 21 18.3109V6.70472C21 5.58681 21 5.02701 20.7822 4.59961C20.5905 4.22329 20.2837 3.91755 19.9074 3.7258C19.4796 3.50781 18.9203 3.50781 17.8002 3.50781H6.2002C5.08009 3.50781 4.51962 3.50781 4.0918 3.7258C3.71547 3.91755 3.40973 4.22329 3.21799 4.59961C3 5.02743 3 5.5879 3 6.70801V18.308C3 19.4281 3 19.9874 3.21799 20.4152C3.40973 20.7915 3.71547 21.0983 4.0918 21.29C4.5192 21.5078 5.07899 21.5078 6.19691 21.5078H7M17 21.5078H7M12 13.5078C10.3431 13.5078 9 12.1647 9 10.5078C9 8.85096 10.3431 7.50781 12 7.50781C13.6569 7.50781 15 8.85096 15 10.5078C15 12.1647 13.6569 13.5078 12 13.5078Z"
              stroke="#8B9DFB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <Text color="#8B9DFB" fontSize="14px" fontWeight="medium">
            Profile
          </Text>
        </WithCenterLayout> */}

          <WithResponsiveLayout
            justifyContent="space-between"
            w="full"
            flexDir={['column', 'column', 'column', 'column', 'column', 'row']}
          >
            <ModerateLayout numComments={numComments} rank={rank} />
            <HStack
              gap={['1rem', '1rem', '1rem', '1rem', '1rem']}
              w="full"
              justifyContent="end"
            >
              <Stack gap="0" w="max-content">
                <Text fontSize="sm" fontWeight="normal" color="#CBD5E0">
                  Deals Sourced
                </Text>
                <Text fontSize="md" fontWeight="semibold" lineHeight={1}>
                  {userStats?.count_intros ?? 0}
                </Text>
              </Stack>

              <Stack gap="0" w="max-content">
                <Text fontSize="sm" fontWeight="normal" color="#CBD5E0">
                  Evaluations
                </Text>
                <Text fontSize="md" fontWeight="semibold" lineHeight={1}>
                  {userStats?.count_evals ?? 0}
                </Text>
              </Stack>
            </HStack>
          </WithResponsiveLayout>

          {/* <WithCenterLayout
          w="full"
          borderRadius="8px"
          border="0.5px solid #EAECF0"
          gap="8px"
          p="5px 0"
          bg="rgba(255, 255, 255, 0.20)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M11.6666 11.6738V6.67383H13.3333V11.6738H18.3333V13.3405H13.3333V18.3405H11.6666V13.3405H6.66663V11.6738H11.6666Z"
              fill="#8B9DFB"
            />
          </svg>
          <Text color="#8B9DFB" fontSize="14px" fontWeight="medium">
            Connect
          </Text>
        </WithCenterLayout> */}
        </HStack>

        <HStack gap="0.5rem" flex="1" w="full" flexWrap="nowrap">
          {expInSectors?.map((sector) => {
            return (
              <DotTagForSectorPath
                key={uniqueId()}
                sector={sector?.sector_name}
                w="full"
              >
                <Text
                  fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}
                  fontWeight="bold"
                >
                  {sector?.sector_name}
                </Text>
                <Text
                  fontSize={['sm', 'sm', 'sm', 'sm', 'sm', 'md']}
                  fontWeight="normal"
                  lineHeight="normal"
                  opacity={0.8}
                >
                  {sector?.exp_in_sector} XP
                </Text>
              </DotTagForSectorPath>
            )
          })}
        </HStack>
      </VStack>
    </VStack>
  )
}

export default ScoutCard
