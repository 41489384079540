import {
  EBusinessModel,
  ECompanyMaturity,
  EDealPriority,
  EFundingOrigin,
  EInvestmentInstruments,
  EProductType,
  ERegion,
  ERound,
  ETechnology,
} from 'enums/deal'
import { IGeography, IInstrument, IMaturity, IRound } from 'interfaces/select'

export const DEAL_FLOW_PROGRESS = {
  scoutToll: 3,
  dealtermsFormSent: 2,
  startupRegistered: 1,
  underMavenRating: 5,
}

export const OPERATING_CURRENCY = 'USD'

export const instrumentOptions: IInstrument[] = Object.entries(
  EInvestmentInstruments,
).map(([key, value]) => ({
  label: value,
  value,
}))

export const geoOptions: IGeography[] = Object.entries(ERegion).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

export const roundOptions: IRound[] = Object.entries(ERound).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

export const maturityOptions: IMaturity[] = Object.entries(
  ECompanyMaturity,
).map(([key, value]) => ({
  label: value,
  value,
}))

export const businessModelOptions = Object.entries(EBusinessModel).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

export const technologyOptions = Object.entries(ETechnology).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

export const productTypeOptions = Object.entries(EProductType).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

export const fundingOriginOptions = Object.entries(EFundingOrigin).map(
  ([key, value]) => ({
    label: value,
    value,
  }),
)

const PRIORITY_MAP = {
  [EDealPriority.High]: '🎯 Prioritize',
  [EDealPriority.Medium]: '👀 Monitor',
  [EDealPriority.Low]: '🤔 Wait and see',
}

const PRIORITY_MAP_COLOR = {
  [EDealPriority.High]: '#FF7D83',
  [EDealPriority.Medium]: '#F0C151',
  [EDealPriority.Low]: '#BAB8B4',
}

export const dealPriorityOptions = Object.entries(EDealPriority).map(
  ([key, value]) => ({
    color: PRIORITY_MAP_COLOR?.[value],
    label: PRIORITY_MAP?.[value],
    value,
  }),
)
