import supabase from 'config/supabase-client'
import { useAuth } from 'hooks'

type TUpload = [
  (
    acceptedFiles: File,
    bucketName: string,
  ) => Promise<string | null | undefined>,
]

export const AVATAR_BUCKET = 'profile_pics/pics/uploads'
export const LOGO_BUCKET = 'company_logos'
const BASE_PATH = `${process.env.REACT_APP_SUPEBASE_URL}/storage/v1/object/public/`

const useSBStorageUpload = (): TUpload => {
  const { isLogged, onLogout } = useAuth()

  const uploadToSBBacket = async (
    acceptedFiles: File | null,
    bucketName: string = LOGO_BUCKET,
  ) => {
    if (!acceptedFiles) return

    if (!isLogged()) {
      onLogout()
      return
    }

    const fileName = acceptedFiles?.name
    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(`${fileName}`, acceptedFiles, {
        cacheControl: '3600',
        upsert: true,
      })

    if (!error) {
      return `${BASE_PATH}/${bucketName}/${data?.path}`
    }

    return null
  }

  return [uploadToSBBacket]
}

export default useSBStorageUpload
