import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import useMounted from 'hooks/useMounted'
import { IDealExtraQs, IDealQResponses } from 'interfaces/models'

export type TDealExtraQuestions = IDealQResponses & {
  deal_extra_qs: IDealExtraQs
}

const useDealBonusQuestions = ({ dealId }: { dealId: string | undefined }) => {
  const isMounted = useMounted()
  const [questions, setQuestions] = useState<TDealExtraQuestions[]>([])
  const fetchDealQuestions = async (): Promise<TDealExtraQuestions[]> => {
    try {
      const { data, error }: PostgrestResponse<TDealExtraQuestions> =
        await supabase
          .from(TABLES_NAME.deal_q_responses)
          .select('*, deal_extra_qs(*)')
          .eq('deal_id', dealId)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const fetchData = async () => {
    const fetchedDealQuestions = await fetchDealQuestions()
    setQuestions(fetchedDealQuestions)
  }

  useEffect(() => {
    isMounted && dealId && fetchData()
  }, [dealId, isMounted])

  return questions
}

export default useDealBonusQuestions
