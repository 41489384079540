import { ReactNode } from 'react'

import { Box, BoxProps, Fade } from '@chakra-ui/react'

import { Variants, motion } from 'framer-motion'

const Bar = ({ children, ...rest }: { children: ReactNode } & BoxProps) => {
  return (
    <Box overflow="hidden" pos="relative" borderRadius="lg" {...rest}>
      {children}
    </Box>
  )
}

Bar.BarFilling = ({
  progressColor,
  variants,
}: {
  progressColor: string
  variants: Variants
}) => {
  return (
    <motion.div
      layout
      variants={variants}
      initial="enter"
      animate="animate"
      exit="enter"
      style={{
        backgroundColor: progressColor,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    ></motion.div>
  )
}

type TProgressBar = {
  percents: number
  duration?: number
  delay?: number
  easing?: 'easeInOut'
  barWidth?: number
  barHeight?: number
  progressColor?: string
  baseColor?: string
}

const ProgressBar = ({
  percents,
  duration = 1,
  delay = 0.1,
  easing = 'easeInOut',
  barWidth = 300,
  barHeight = 1,
  progressColor = 'pink',
  baseColor = 'indigo',
}: TProgressBar) => {
  const transition = {
    duration,
    delay,
    ease: easing,
  }

  const percentsOffset = (percents - 100) * (barWidth / 100)

  const variant = {
    enter: {
      x: -barWidth,
    },
    animate: {
      x: [-barWidth, percentsOffset],
      transition,
    },
  }
  // const [percentsOffset, setPercentsOffset] = useState(0)
  // const defaultVariant = useMemo(
  //   () => ({
  //     enter: {
  //       x: -barWidth,
  //     },
  //     animate: {
  //       x: [-barWidth, percentsOffset],
  //       transition: {
  //         duration,
  //         delay,
  //         ease: easing,
  //       },
  //     },
  //   }),
  //   [barWidth, delay, duration, easing, percentsOffset],
  // )
  // const [variants, setVariants] = useState<Variants>(defaultVariant)

  // useEffect(() => {
  //   percents > 0 && setPercentsOffset((percents - 100) * (barWidth / 100))
  // }, [barWidth, percents])

  // useEffect(() => {
  //   const newVariant = {
  //     ...defaultVariant,
  //     animate: {
  //       ...defaultVariant.animate,
  //       x: [-barWidth, percentsOffset],
  //     },
  //   }
  //   setVariants(newVariant)
  // }, [barWidth, defaultVariant, percentsOffset])

  return (
    <Fade in={true}>
      <Bar width={barWidth} height={barHeight} bg={baseColor}>
        <Bar.BarFilling
          variants={variant}
          progressColor={progressColor}
        ></Bar.BarFilling>
      </Bar>
    </Fade>
  )
}

export default ProgressBar
