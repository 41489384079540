import { Icon } from '@chakra-ui/react'

const Twitter = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM16.575 8.625C17.1 8.55 17.55 8.475 18 8.25C17.7 8.775 17.25 9.225 16.725 9.525C16.875 13.05 14.325 16.875 9.75 16.875C8.4 16.875 7.125 16.425 6 15.75C7.275 15.9 8.625 15.525 9.525 14.85C8.4 14.85 7.5 14.1 7.2 13.125C7.575 13.2 7.95 13.125 8.325 13.05C7.2 12.75 6.375 11.7 6.375 10.575C6.75 10.725 7.125 10.875 7.5 10.875C6.45 10.125 6.075 8.7 6.75 7.575C8.025 9.075 9.825 10.05 11.85 10.125C11.475 8.625 12.675 7.125 14.25 7.125C14.925 7.125 15.6 7.425 16.05 7.875C16.65 7.725 17.175 7.575 17.625 7.275C17.475 7.875 17.1 8.325 16.575 8.625Z"
        fill="white"
      />
    </Icon>
  )
}

export default Twitter
