import { Text } from '@chakra-ui/react'

import Center from './Center'

const PageNotFound = () => {
  return (
    <Center>
      <Text color="white">Page not found</Text>
    </Center>
  )
}

export default PageNotFound
