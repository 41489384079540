import { Icon } from '@chakra-ui/react'

const CircleError = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.6516 8.65165C12.9445 8.35876 12.9445 7.88388 12.6516 7.59099C12.3587 7.2981 11.8839 7.2981 11.591 7.59099L9.99998 9.18198L8.40899 7.59099C8.1161 7.2981 7.64123 7.2981 7.34833 7.59099C7.05544 7.88388 7.05544 8.35876 7.34833 8.65165L8.93932 10.2426L7.34833 11.8336C7.05544 12.1265 7.05544 12.6014 7.34833 12.8943C7.64123 13.1872 8.1161 13.1872 8.40899 12.8943L9.99998 11.3033L11.591 12.8943C11.8839 13.1872 12.3587 13.1872 12.6516 12.8943C12.9445 12.6014 12.9445 12.1265 12.6516 11.8336L11.0606 10.2426L12.6516 8.65165Z"
        // fill="#FE2C2C"
      />
    </Icon>
  )
}

export default CircleError
