import { NAVBAR } from 'constants/navbar'
import { TRoute } from 'types/route'

import Pipelines from '../pages/pipelines'

const ActiveIntrosRoutes: TRoute[] = [
  {
    path: NAVBAR.PATHS.ACTIVE_DEALS,
    profile: 'Investor',
    feature: 'ViewActiveIntrosPage',
    exact: true,
    component: Pipelines,
  },
]

export default ActiveIntrosRoutes
