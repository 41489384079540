import { useBreakpointValue } from '@chakra-ui/react'

const useMobile = (point?: string) => {
  const realPoint = point ?? 'xl'
  const isMobile = useBreakpointValue(
    {
      base: true,
      [realPoint]: false,
    },
    { ssr: false },
  )
  return isMobile
}

export default useMobile
