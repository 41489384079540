import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  VStack,
} from '@chakra-ui/react'

import { FieldError, useFormContext } from 'react-hook-form'
import { TField } from 'types/form-fields'

import TextInput from '../DLInput/TextInput'

export default function Field({
  component: Component = TextInput,
  name = '',
  label,
  isInlineError,
  showIcon = true,
  showStatus = true,
  errorMessageStyles,
  labelStyleProps,
  badgeForLabel,
  code,
  ...props
}: TField & {
  labelStyleProps?: FormLabelProps
  code?: string | undefined
  rules?: any
}) {
  const { register, resetField, setFocus, getFieldState, clearErrors } =
    useFormContext()
  const errorMessage = getFieldState?.(name)?.error
  const isInvalid = getFieldState?.(name)?.invalid
  const isValid = !isInvalid
  let placeholder: string | undefined | FieldError = props.placeholder

  if (isInvalid && isInlineError) {
    placeholder = errorMessage
  }

  const clearField = () => {
    resetField(name, {
      defaultValue: '',
    })
    setFocus(name)
  }

  const handleOnClearError = () => {
    clearErrors(name)
  }

  return (
    <FormControl isInvalid={isInvalid}>
      {label && (
        <VStack
          gap="0"
          mb={badgeForLabel ? ['4px'] : 0}
          // justifyContent="space-between"
          alignItems="start"
        >
          <FormLabel
            htmlFor={name}
            m={badgeForLabel ? 0 : 1}
            fontSize="sm"
            fontWeight="md"
            color="#fff"
            noOfLines={1}
            {...labelStyleProps}
          >
            {label}
          </FormLabel>
          {badgeForLabel}
        </VStack>
      )}
      <Component
        id={name}
        {...register(name)}
        isInvalid={showStatus && isInvalid}
        isValid={showStatus && isValid}
        onClear={clearField}
        onClearError={handleOnClearError}
        {...props}
        placeholder={placeholder}
        code={code}
      />
      {!isInlineError && (
        <FormErrorMessage {...errorMessageStyles}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
