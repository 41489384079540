import { Icon } from '@chakra-ui/react'

const Star = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      {...props}
    >
      <path
        d="M16.5298 18.4049C16.9022 18.1256 16.9738 17.6602 16.7733 17.0586L15.3195 12.7546L19.022 10.0977C19.5376 9.73242 19.7596 9.3099 19.6092 8.86588C19.4589 8.42187 19.0435 8.20703 18.4061 8.21419L13.8729 8.25L12.4908 3.91732C12.2974 3.30143 11.968 2.96484 11.5025 2.96484C11.037 2.96484 10.7147 3.30143 10.5142 3.91732L9.13203 8.25L4.59883 8.21419C3.96146 8.20703 3.54609 8.42187 3.3957 8.85872C3.23815 9.3099 3.46732 9.73242 3.98294 10.0977L7.68542 12.7546L6.23164 17.0586C6.03112 17.6602 6.10273 18.1256 6.47513 18.4049C6.85469 18.6914 7.31302 18.5983 7.83581 18.2187L11.5025 15.526L15.1691 18.2187C15.6919 18.5983 16.1503 18.6914 16.5298 18.4049Z"
        fill="#8B9DFB"
      />
    </Icon>
  )
}

export default Star
