import { TField } from 'types/form-fields'

import { PasswordInput, TextInput } from 'components/common/DLInput'

export const LOGIN_FORM_FIELDS: TField[] = [
  {
    label: 'Email *',
    name: 'email',
    type: 'email',
    placeholder: 'Your Email',
    component: TextInput,
    required: true,
    autoFocus: true,
  },
  {
    label: 'Password *',
    name: 'password',
    placeholder: 'Your password',
    component: PasswordInput,
    autoFocus: false,
    required: true,
  },
]

export const LOGIN_DEFAULT_VALUES = {
  password: '',
  confirmedPassword: '',
}

export const LOGIN_FAILED_ERROR_MESSAGE =
  'The credentials is not correct. Please try again'
