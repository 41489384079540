import React from 'react'

import { Text, useNumberInput } from '@chakra-ui/react'

import Input from './Input'

export default React.forwardRef(function PhoneNumberInput(
  { code, size, isInvalid, isValid, onClear, onChange, ...props },
  ref,
) {
  const { getInputProps } = useNumberInput(props)

  return (
    <Input.Group size={size}>
      <Input
        pl={code ? '50px !important' : 'inherit'}
        ref={ref}
        type="number"
        isInvalid={isInvalid}
        isValid={isValid}
        onChange={onChange}
        {...getInputProps({}, ref)}
        {...props}
      />

      <Input.LeftElement bg="rgba(16, 16, 16, 0.04);">
        <Text color="white" fontSize="md">
          {code ? `+${code}` : ''}
        </Text>
      </Input.LeftElement>
    </Input.Group>
  )
})
