import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME } from 'constants/tables'
import useAuth from 'hooks/useAuth'
import useMounted from 'hooks/useMounted'
import { IUser } from 'interfaces/models'

export type TInvestorTeams = IUser & {
  investor_teams: { team_id: string }[]
}

const useInvestorTeams = () => {
  const { getUser } = useAuth()
  const user = getUser()
  const isMounted = useMounted()

  const [investorTeams, setInvestorTeams] = useState<TInvestorTeams[]>([])
  const fetchInvestorITeams = async (): Promise<TInvestorTeams[]> => {
    try {
      const { data, error }: PostgrestResponse<TInvestorTeams> = await supabase
        .from(TABLES_NAME.users)
        .select('*, investor_teams(team_id)')
        .eq('user_id', user?.user?.id)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const fetchData = async () => {
    const fetchedInvestorITeams = await fetchInvestorITeams()
    setInvestorTeams(fetchedInvestorITeams)
  }

  useEffect(() => {
    isMounted && fetchData()
  }, [isMounted])

  return investorTeams
}

export default useInvestorTeams
