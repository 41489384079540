import {
  Collapse,
  Divider,
  HStack,
  HeadingProps,
  Stack,
  StackProps,
  useBoolean,
} from '@chakra-ui/react'

import { ChevronDown } from 'components/common/Icons'

import shadows from '../../../../../theme/foundations/shadows'
import FlexLayout, { FlexField } from '../FlexLayout'
import DHeading from '../Heading'
import WrapFieldSection from '../hoc/WrapFieldSection'
import AverageRating from './AverageRating'

const NUM_ITEM_PER_ROW = 5

const DetailedMavenRating = ({
  heading,
  avgRating,
  dataQuality,
  ratingFields,
  numItemsPerRow,
  headerProps,
  canCollapsible,
  ...rest
}: {
  canCollapsible?: boolean | undefined
  heading?: string
  avgRating: number
  dataQuality: number
  ratingFields: FlexField[]
  numItemsPerRow?: number | undefined
  headerProps?: HeadingProps
} & StackProps) => {
  const [shouldCollapse, setShouldCollapse] = useBoolean()
  return (
    <WrapFieldSection w="full" gap="36px" {...rest}>
      <HStack w="full" justifyContent="space-between">
        <DHeading
          w="full"
          {...headerProps}
          cursor={canCollapsible ? 'pointer' : 'auto'}
          onClick={canCollapsible ? setShouldCollapse.toggle : undefined}
        >
          {heading ?? 'Internal Review'}
        </DHeading>
        {canCollapsible && (
          <ChevronDown
            transitionDuration="0.3s"
            transform={`rotate(${shouldCollapse ? 180 : 0}deg)`}
            boxSize={[4, 4, 4, 6, 6, 6]}
            color="accent.main01"
          />
        )}
      </HStack>
      <Stack w="full" display={shouldCollapse ? 'none' : 'block'}>
        <Collapse in={!shouldCollapse} animateOpacity>
          <Stack w="full" gap="36px">
            <HStack justifyContent="space-between" w="full">
              <AverageRating
                label="Average Rating"
                rate={parseFloat((avgRating ?? 0) + '')?.toFixed(1)}
              />
              <AverageRating
                label="Data Quality"
                rate={parseFloat((dataQuality ?? 0) + '')?.toFixed(1)}
              />
            </HStack>
            <Divider w="full" h="1px" color="#343A46" opacity={0.4} />
            <FlexLayout
              numItemsPerRow={numItemsPerRow ?? NUM_ITEM_PER_ROW}
              fields={ratingFields}
            />
          </Stack>
        </Collapse>
      </Stack>
      shadows
    </WrapFieldSection>
  )
}

export default DetailedMavenRating
