import { Box, Button, HStack, Link, VStack, useBoolean } from '@chakra-ui/react'

import TextValue from '../TextValue'

const UpDownTriangleBox = () => (
  <Box position="relative" width="6px" height="6px" mt="6px">
    <Box
      position="absolute"
      top="0"
      left="50%"
      transform="translateX(-50%)"
      width="0"
      height="0"
      borderLeft="6px solid transparent"
      borderRight="6px solid transparent"
      borderBottom="6px solid #32D74B"
    />
  </Box>
)

const DownTriangleBox = () => (
  <Box position="relative" width="6px" height="6px" mt="6px">
    <Box
      position="absolute"
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      width="0"
      height="0"
      borderLeft="6px solid transparent"
      borderRight="6px solid transparent"
      borderTop="6px solid red"
    />
  </Box>
)

const MavenComments = ({
  evalComment,
  positiveComment,
  negativeComment,
  shouldWrapText = false,
  canExpandable = false,
}: {
  evalComment?: string | null | undefined
  positiveComment: string | null
  negativeComment: string | null
  shouldWrapText?: boolean | undefined
  canExpandable?: boolean | undefined
}) => {
  const [shouldSeeMorePositiveComment, togglePositive] = useBoolean()
  const [shouldSeeMoreNegativeComment, toggleNegative] = useBoolean()
  const [shouldSeeMoreComment, toggleComment] = useBoolean()

  const WRAP_VALUES = ['wrap', 'wrap', 'wrap', 'wrap', 'wrap', 'wrap']

  return (
    <VStack gap="1rem" alignItems="start">
      {!positiveComment && !negativeComment && evalComment && (
        <VStack alignItems="end">
          <TextValue
            w="full"
            fontWeight="normal"
            fontSize="15px"
            letterSpacing="-0.24px"
            {...(canExpandable
              ? {
                  noOfLines: shouldSeeMoreComment ? undefined : 3,
                }
              : {})}
            sx={{
              textWrap: shouldWrapText
                ? WRAP_VALUES
                : ['nowrap', 'nowrap', 'nowrap', 'nowrap', 'nowrap', 'pretty'],
            }}
          >
            {evalComment}
          </TextValue>
          {canExpandable && (
            <Link color="green" fontSize="sm" onClick={toggleComment.toggle}>
              {shouldSeeMoreComment ? 'See less' : 'See more'}
            </Link>
          )}
        </VStack>
      )}
      {positiveComment && (
        <VStack alignItems="end">
          <HStack alignItems="start" gap="1rem">
            {/* <Box mt="5px" borderRadius="full" w="8px" h="8px" bg="#32D74B"></Box> */}
            <UpDownTriangleBox />
            <TextValue
              w="full"
              fontWeight="normal"
              fontSize="15px"
              letterSpacing="-0.24px"
              {...(canExpandable
                ? {
                    noOfLines: shouldSeeMorePositiveComment ? undefined : 3,
                  }
                : {})}
              // noOfLines={shouldSeeMorePositiveComment ? undefined : 3}
              sx={{
                textWrap: shouldWrapText
                  ? WRAP_VALUES
                  : [
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'pretty',
                    ],
              }}
            >
              <b>Positives:</b> {positiveComment}
            </TextValue>
          </HStack>
          {canExpandable && (
            <Link color="green" fontSize="sm" onClick={togglePositive.toggle}>
              {shouldSeeMorePositiveComment ? 'See less' : 'See more'}
            </Link>
          )}
        </VStack>
      )}

      {negativeComment && (
        <VStack alignItems="end">
          <HStack alignItems="start" gap="1rem">
            <DownTriangleBox />
            <TextValue
              w="full"
              fontWeight="normal"
              fontSize="15px"
              letterSpacing="-0.24px"
              {...(canExpandable
                ? {
                    noOfLines: shouldSeeMoreNegativeComment ? undefined : 3,
                  }
                : {})}
              sx={{
                textWrap: shouldWrapText
                  ? WRAP_VALUES
                  : [
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'nowrap',
                      'pretty',
                    ],
              }}
            >
              <b>To improve:</b> {negativeComment}
            </TextValue>
          </HStack>
          {canExpandable && (
            <Link color="green" fontSize="sm" onClick={toggleNegative.toggle}>
              {shouldSeeMoreNegativeComment ? 'See less' : 'See more'}
            </Link>
          )}
        </VStack>
      )}
    </VStack>
  )
}

export default MavenComments
