import { useEffect } from 'react'

import { HStack, VStack, useBoolean, useColorModeValue } from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { useDLToast, useLocations } from 'hooks'
import { ILocation } from 'interfaces/models'
import { ISelectLocation } from 'interfaces/select'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form'

import { DLButton } from 'components/common/DLButton'
import { ControlledField, Field } from 'components/common/DLForms'
import { PhoneNumberInput, TextInput } from 'components/common/DLInput'
import { WithResponsiveLayout } from 'components/hoc'

import Select from 'modules/dashboard/components/other/Select'

import {
  UPDATE_CONTACT_INFO_ERROR,
  UPDATE_CONTACT_INFO_SUCCESS,
} from '../constants/messages'
import { TUserSetting } from '../hooks/useUserSettings'
import { resolver as contactFormResolver } from '../validation/contact-info'

const AccountForm = ({
  isLoading,
  formatLocationsAsSelections,
  styleByMode: { colorByMode },
}: {
  isLoading: boolean
  formatLocationsAsSelections: (
    givenLocations?: ILocation[] | null | undefined,
  ) => ISelectLocation[]
  styleByMode: { colorByMode: string; bg: string }
}) => {
  const {
    getValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useFormContext()
  return (
    <VStack w="full" h="full" gap={[4, 4, 4, 6, 6, 6]} alignItems="start">
      <WithResponsiveLayout gap="1.5rem" w="full">
        <ControlledField
          label="Location"
          name="location_id"
          placeholder="Select your location"
          labelStyleProps={{ fontSize: 'lg' }}
          component={(props) => (
            <Select
              {...props}
              customStyles={{ option: { fontSize: 'md' } }}
              size={['sm', 'md', 'lg']}
              isClearable
            />
          )}
          options={formatLocationsAsSelections()}
        />
        <Field
          variant="userAccount"
          label="Phone Number"
          name="phone_number"
          code={getValues('phone_code')}
          component={PhoneNumberInput}
          size={['sm', 'md', 'lg']}
          isInlineError
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />
      </WithResponsiveLayout>
      <WithResponsiveLayout gap="1.5rem" w="full">
        <Field
          variant="userAccount"
          label="First Name"
          name="first_name"
          component={TextInput}
          size={['sm', 'md', 'lg']}
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />
        <Field
          variant="userAccount"
          label="Last Name"
          name="last_name"
          component={TextInput}
          size={['sm', 'md', 'lg']}
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />
      </WithResponsiveLayout>
      <WithResponsiveLayout gap="1.5rem" w="full">
        <Field
          w={['full', 'full', 'full', '50%']}
          variant="userAccount"
          label="Bio"
          name="bio"
          component={TextInput}
          size={['sm', 'md', 'lg']}
          labelStyleProps={{
            fontSize: ['sm', 'md', 'lg'],
            fontWeight: 'bold',
            lineHeight: 1.4,
            color: colorByMode,
          }}
        />
        <HStack flex="1" display={['hide', 'hide', 'hide', 'show']}></HStack>
      </WithResponsiveLayout>

      <DLButton
        type="submit"
        isLoading={isLoading}
        variant="filled"
        size={['sm', 'md', 'lg', 'lg']}
        w={['full', 'full', 'full']}
      >
        Update
      </DLButton>
    </VStack>
  )
}

export interface IContactInfo {
  location_id: ISelectLocation | number | undefined | null
  phone_number: string | undefined
  phone_code: string | undefined | null
  first_name: string | undefined
  last_name: string | undefined
  bio: string | undefined | null
}

const ContactInfo = ({
  userSettings,
}: {
  userSettings?: TUserSetting | null | undefined
}) => {
  const [isLoading, toggle] = useBoolean()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { locations, formatLocationsAsSelections } = useLocations()

  const methods = useForm<IContactInfo>({
    resolver: contactFormResolver,
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      location_id: userSettings?.location_id
        ? {
            label: userSettings?.location_name,
            value: userSettings?.location_id,
          }
        : null,
      phone_code: userSettings?.phone_code,
      phone_number: userSettings?.phone_number,
      first_name: userSettings?.first_name,
      last_name: userSettings?.last_name,
      bio: userSettings?.bio,
    },
  })

  const colorByMode = useColorModeValue('white', '#101010')
  const bg = useColorModeValue(
    'activatedBackground',
    'rgba(255, 255, 255, 0.40)',
  )

  const handleSelectedLocation = (
    locationID?: string | number | undefined | null,
  ) => {
    const phoneCode = locations?.filter(
      (l) => l.location_id === parseInt(locationID + '' ?? ''),
    )[0]?.phone_code

    phoneCode &&
      methods.setValue(
        'phone_code',
        locations?.filter(
          (l) => l.location_id === parseInt(locationID + '' ?? ''),
        )[0]?.phone_code,
      )
  }

  const handleSubmit: SubmitHandler<IContactInfo> = async (values) => {
    const payload = {
      ...values,
      location_id:
        values.location_id instanceof Object
          ? values.location_id?.value
          : values.location_id,
      phone_number: values.phone_number || null,
    }

    try {
      toggle.on()
      const { error } = await supabase
        .from('users')
        .update(payload)
        .eq('user_id', userSettings?.user_id)

      if (error) {
        showErrorToast(UPDATE_CONTACT_INFO_ERROR)
        return
      }

      showSuccessToast(UPDATE_CONTACT_INFO_SUCCESS)
    } catch (err) {
      showErrorToast(UPDATE_CONTACT_INFO_ERROR)
    } finally {
      toggle.off()
    }
  }

  useEffect(() => {
    const location_id = methods.getValues('location_id') as ISelectLocation
    handleSelectedLocation(location_id?.value)
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('location_id'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('phone_code'),
  ])

  return (
    <VStack
      bg={bg}
      borderRadius="24px"
      p={[4, 4, '24px']}
      gap="36px"
      color={colorByMode}
      h="full"
    >
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods?.handleSubmit(handleSubmit)}
          autoComplete="off"
        >
          <VStack gap="36px" fontWeight="bold" alignItems="start" w="full">
            <AccountForm
              isLoading={isLoading}
              formatLocationsAsSelections={formatLocationsAsSelections}
              styleByMode={{ bg, colorByMode }}
            />
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  )
}

export default ContactInfo
