import { useEffect, useState } from 'react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { VIEWS } from 'constants/tables'

import { TViewCountUserStats } from '../interfaces/deal-detail'

const useCountUserStats = ({ userId }: { userId: string | undefined }) => {
  const [userStats, setUserStats] = useState<TViewCountUserStats[]>()
  const fetchUserStats = async (): Promise<TViewCountUserStats[]> => {
    try {
      const { data, error }: PostgrestResponse<TViewCountUserStats> =
        await supabase
          .from(VIEWS.view_count_user_stats)
          .select('*')
          .eq('user_id', userId)

      if (error) {
        return []
      }

      return data
    } catch (error) {
      return []
    }
  }

  const fetchData = async () => {
    const fetchedUserStats = await fetchUserStats()
    setUserStats(fetchedUserStats)
  }

  useEffect(() => {
    userId && fetchData()
  }, [userId])

  return userStats
}

export default useCountUserStats
