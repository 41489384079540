import { useEffect, useState } from 'react'

import { useBoolean } from '@chakra-ui/react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import useMounted from 'hooks/useMounted'

import {
  VIEW_DEALS_LEADER_BOARD_MAVEN_RATING,
  VIEW_DOMAINS_FULL_PATH,
} from 'modules/dashboard/constants/leaderboard'
import { TDealSector } from 'modules/dashboard/hooks/useLeaderBoardQueries'
import { IDealRow } from 'modules/dashboard/interfaces/deal-table'

function useDealQuery(dealID: string) {
  const isMounted = useMounted()
  const [isLoading, toggle] = useBoolean()
  const [deal, setDeal] = useState<IDealRow>()
  // return useQuery({
  //   queryKey: [`get-deal-${dealID}`],
  //   queryFn: () => getDealByID(dealID),
  //   select: (response): IDeal => {
  //     return response?.data[0]
  //   },
  // })

  useEffect(() => {
    const fetchData = async () => {
      try {
        toggle.on()
        const { data: deals, error }: PostgrestResponse<IDealRow> =
          await supabase
            .from(VIEW_DEALS_LEADER_BOARD_MAVEN_RATING)
            .select('*, deal_subdomains(subdomain_id)')
            .limit(1)
            .eq('deal_id', dealID)

        if (error) {
          return []
        }

        const deal: IDealRow = { ...deals?.[0] }

        const domainQuery = supabase
          .from(VIEW_DOMAINS_FULL_PATH)
          .select('subdomain_id, path')

        const { data: domains }: PostgrestResponse<TDealSector> =
          await domainQuery

        const subdomains = deal?.deal_subdomains ?? []
        deal.sectors_and_domains = subdomains
          .map(
            (domain: Record<string, any>) =>
              domains?.filter(
                ({ subdomain_id }) => subdomain_id === domain.subdomain_id,
              )[0]?.path,
          )
          ?.join(',')

        setDeal(deal || {})
      } catch (error) {
        return []
      }
      toggle.off()
    }
    isMounted && fetchData()
  }, [isMounted])

  return { isLoading, deal }
}

export default useDealQuery
