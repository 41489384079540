import { createContext, useContext, useReducer } from 'react'

import DeployErrorBoundary from 'error/error-boundaries'

import rootReducer from '../reducers'

const GlobalContext = createContext()

const initialState = {
  app: null,
}

export function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      <DeployErrorBoundary>{children}</DeployErrorBoundary>
    </GlobalContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalContext)
