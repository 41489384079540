import { ReactNode } from 'react'

import {
  Collapse,
  HStack,
  HeadingProps,
  Stack,
  StackProps,
  useBoolean,
} from '@chakra-ui/react'

import { ChevronDown } from 'components/common/Icons'

import FlexLayout, { FlexField, FlexLayoutProps } from './FlexLayout'
import DHeading from './Heading'
import WrapFieldSection from './hoc/WrapFieldSection'

const FieldSection = ({
  header,
  fields,
  numItemsPerRow = 4,
  isChildInFlex = false,
  children,
  headerProps,
  canCollapsible,
  ...rest
}: {
  canCollapsible?: boolean | undefined
  header: ReactNode
  fields: FlexField[]
  numItemsPerRow?: number
  isChildInFlex?: boolean | undefined
  children?: ReactNode
  headerProps?: HeadingProps
} & StackProps &
  Pick<FlexLayoutProps, 'labelProps'>) => {
  const [shouldCollapse, setShouldCollapse] = useBoolean(false)
  return (
    <WrapFieldSection {...rest}>
      <HStack
        w="full"
        aria-expanded={!shouldCollapse}
        onClick={!!canCollapsible ? setShouldCollapse.toggle : undefined}
        cursor={!!canCollapsible ? 'pointer' : 'auto'}
      >
        <DHeading w="full" {...headerProps}>
          {header}
        </DHeading>

        {canCollapsible && (
          <ChevronDown
            transitionDuration="0.3s"
            transform={`rotate(${shouldCollapse ? 180 : 0}deg)`}
            boxSize={[4, 4, 4, 6, 6, 6]}
            color="accent.main01"
          />
        )}
      </HStack>
      <Stack w="full" display={shouldCollapse ? 'none' : 'block'}>
        <Collapse animateOpacity in={!shouldCollapse}>
          <FlexLayout
            fields={fields}
            numItemsPerRow={numItemsPerRow}
            labelProps={rest?.labelProps}
          >
            {isChildInFlex && children}
          </FlexLayout>
          {!isChildInFlex && children}
        </Collapse>{' '}
      </Stack>
    </WrapFieldSection>
  )
}

export default FieldSection
