import { TField } from 'types/form-fields'

import { PasswordInput } from 'components/common/DLInput'

export const RESET_FORM_FIELDS: TField[] = [
  {
    label: 'New Password *',
    name: 'password',
    type: 'text',
    placeholder: 'Password',
    component: PasswordInput,
    required: true,
  },
  {
    label: 'Confirm New Password *',
    name: 'confirmedPassword',
    type: 'text',
    placeholder: 'Confirm your password',
    component: PasswordInput,
    required: true,
  },
]

export const RESET_DEFAULT_VALUES = {
  password: '',
  confirmedPassword: '',
}
