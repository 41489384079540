import countriesAndTimezones from 'countries-and-timezones'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useAuth } from 'hooks'

dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(utc)

const useDayjsWithUserTZ = () => {
  const { getUser } = useAuth()
  const user = getUser()

  try {
    const timezone =
      countriesAndTimezones.getTimezonesForCountry(
        user?.metadata?.locations?.iso2,
      )?.[0]?.name || 'US'

    dayjs.tz.setDefault(timezone)
  } catch (e) {
    console.error(e)
  }

  return { dayjsWithUserTz: dayjs }
}

export default useDayjsWithUserTZ
