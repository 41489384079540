import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import dayjs from 'dayjs'

import { WithResponsiveLayout } from 'components/hoc'

import { RATING_DETAIL_LABELS } from 'modules/dashboard/constants/deal-details'
import { TLatestDealActivities } from 'modules/dashboard/interfaces/deal-detail'

import FlexLayout from '../FlexLayout'
import Label from '../Label'
import TagWithDot from '../TagWithDot'
import { MavenComments } from '../display'
import AverageRating from '../display/AverageRating'
import MavenProfile from '../display/MavenProfile'
import { getRateColor } from '../utils'

const MavenRatingDetail = ({
  dealActivity,
  isOpen,
  onClose,
}: {
  dealActivity: TLatestDealActivities | undefined
  isOpen: boolean
  onClose: () => void
}) => {
  if (!dealActivity) return <></>

  const ratingFields = Object.entries(RATING_DETAIL_LABELS).map(
    ([key, label]) => {
      return {
        label,
        value: (
          <TagWithDot
            dotColor={getRateColor(
              (dealActivity[key as keyof TLatestDealActivities] ?? 0) as number,
            )}
          >
            {parseFloat(
              dealActivity[key as keyof TLatestDealActivities] + '',
            )?.toFixed(1) ?? dealActivity[key as keyof TLatestDealActivities]}
          </TagWithDot>
        ),
      }
    },
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="white" isCentered>
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent maxW="1000px" bg="#13141F">
        <ModalCloseButton color="white" />
        <ModalBody
          p={['10px', '10px', '10px', '40px', '40px', '40px']}
          pb="10px"
        >
          <VStack gap="2rem" w="full">
            <MavenProfile dealActivity={dealActivity} />
            <WithResponsiveLayout
              justifyContent="space-between"
              w="full"
              alignItems="start"
              gap="1rem"
            >
              <HStack justifyContent="space-between" gap="3rem">
                <AverageRating
                  label="Average Rating"
                  rate={parseFloat(dealActivity?.bs_average + '')?.toFixed(1)}
                />
                <AverageRating
                  label="Data Quality"
                  rate={parseFloat(dealActivity?.bs0_confidence + '')?.toFixed(
                    1,
                  )}
                />
              </HStack>

              <Label fontSize="sm">
                Evaluation Made: {dayjs(dealActivity?.created_at).fromNow()}
              </Label>
            </WithResponsiveLayout>
            <FlexLayout numItemsPerRow={5} fields={ratingFields} />
            <VStack alignItems="start" w="full" color="#ffffffa6">
              <Text fontSize="md" fontWeight="bold">
                Comments:
              </Text>
              <MavenComments
                evalComment={dealActivity.eval_comments}
                positiveComment={dealActivity?.positive_comment}
                negativeComment={dealActivity?.negative_comment}
                shouldWrapText
              />
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MavenRatingDetail
