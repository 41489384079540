import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import supabase from 'config/supabase-client'
import { NAVBAR } from 'constants/navbar'
import { useAuth, useDLToast } from 'hooks'
import { useHistory } from 'react-router'

import Disclaimer from 'components/common/Icons/Disclaimer'
import { WithCenterLayout } from 'components/hoc'

import {
  RESCIND_INTEREST_ERROR,
  RESCIND_INTEREST_SUCCESS,
} from 'modules/dashboard/constants/messages'
import { SHORTLIST_TABLE } from 'modules/screen-rooms/constants'

const RescindInterestModal = ({
  dealID,
  isOpen,
  onClose,
}: {
  dealID: string | undefined
  isOpen: boolean
  onClose: () => void
}) => {
  const history = useHistory()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { getUser } = useAuth()

  const [isToggled, toggle] = useBoolean()
  const bgSwitchByMode = useColorModeValue(
    'rgb(82,82,82)',
    'rgba(16, 16, 16, 0.13)',
  )

  const handleClickRescind = async () => {
    if (!dealID) return
    try {
      /** rescind interest on this deal **/
      const payload = {
        deal_id: dealID,
        user_id: getUser()?.user?.id,
        in_pipeline: false,
        bid_amount: 0,
        investor_tickets_reserved: 0,
      }

      const { error } = await supabase
        .from(SHORTLIST_TABLE)
        .upsert(payload, { onConflict: 'deal_id, user_id' })

      if (error) {
        showErrorToast(error?.message)
        return
      }

      showSuccessToast(RESCIND_INTEREST_SUCCESS)
      history.push(NAVBAR.PATHS.SCREEN_ROOM)
    } catch (err) {
      showErrorToast(RESCIND_INTEREST_ERROR)
    } finally {
      toggle.off()
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggle.off()
        onClose()
      }}
      variant="white"
      isCentered
    >
      <ModalOverlay backdropFilter="blur(20px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="2rem">
            <Disclaimer w="5.30488rem" h="4.94794rem" />
            <WithCenterLayout gap="0.5rem" w="full">
              <Switch
                colorScheme="teal"
                size="sm"
                onChange={toggle.toggle}
                sx={{
                  '&>.chakra-switch__track': { bg: bgSwitchByMode },
                  '&>[data-checked]': { bg: '#8B9DFB' },
                }}
              />
              <Text
                fontSize="md"
                fontWeight="medium"
                opacity={isToggled ? 1 : 0.6}
              >
                Are you sure to rescind interest on this deal?
              </Text>
            </WithCenterLayout>

            <Button
              isDisabled={!isToggled}
              variant="filled"
              size={['md', 'md', 'lg']}
              w="full"
              sx={{
                _disabled: {
                  color: 'white',
                  bg: 'rgba(16, 16, 16, 0.20)',
                  _hover: {
                    color: 'white',
                    bg: 'rgba(16, 16, 16, 0.20)',
                  },
                },
              }}
              onClick={handleClickRescind}
            >
              Confirm
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RescindInterestModal
