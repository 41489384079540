import React, { FC, ReactNode, createContext, useState } from 'react'

import { IViewScoutInSector } from 'interfaces/views'

import { useSectorExperience } from '../hooks'

interface DashboardContextType {
  scoutSectorExp: IViewScoutInSector[] | []
}

export const DashboardContext = createContext<DashboardContextType>({
  scoutSectorExp: [],
})

interface DashboardProviderProps {
  children: ReactNode
}

export const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const fetchedSectorExp = useSectorExperience()

  return (
    <DashboardContext.Provider value={{ scoutSectorExp: fetchedSectorExp }}>
      {children}
    </DashboardContext.Provider>
  )
}
