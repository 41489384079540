import { Box, Image, VStack } from '@chakra-ui/react'

const GradientBackground = () => {
  return (
    <VStack>
      <Box>
        <Image
          loading="lazy"
          src="images/blur-blue.png"
          pos="absolute"
          left="0%"
          top="50px"
          zIndex={1}
        />

        <Image
          loading="lazy"
          src="images/blur-greenn.svg"
          pos="absolute"
          right="0%"
          top="90px"
          zIndex={1}
        />
        <Image
          loading="lazy"
          src="images/blur-green.svg"
          pos="absolute"
          right="0%"
          top="200px"
          zIndex={1}
        />
      </Box>
      <Box>
        <Image
          loading="lazy"
          src="images/blur-blue.png"
          pos="absolute"
          bottom="200px"
          right="5%"
          zIndex={-1}
        />

        <Image
          loading="lazy"
          src="images/blur-greenn.svg"
          pos="absolute"
          bottom="200px"
          right="5%"
          zIndex={0}
        />
        <Image
          loading="lazy"
          src="images/blur-green.svg"
          pos="absolute"
          bottom="200px"
          right="5%"
          zIndex={0}
        />
      </Box>
    </VStack>
  )
}

export default GradientBackground
