import { WithCenterLayout } from 'components/hoc'

const PlaceholderImageBox = () => {
  return (
    <WithCenterLayout
      borderRadius="8px"
      // border="0.5px solid #f2f2f2"
      w="full"
      // h="120px"
    >
      <WithCenterLayout
        borderRadius={4}
        w={['32px', '32px', '32px', '32px', '32px', '96px']}
        h={['32px', '32px', '32px', '32px', '32px', '96px']}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M4.29996 31.6828L4.26663 31.7161C3.81663 30.7328 3.53329 29.6161 3.41663 28.3828C3.53329 29.5995 3.84996 30.6995 4.29996 31.6828Z"
            fill="white"
            fill-opacity="0.4"
          />
          <path
            d="M15 17.3005C17.1907 17.3005 18.9667 15.5246 18.9667 13.3339C18.9667 11.1431 17.1907 9.36719 15 9.36719C12.8093 9.36719 11.0333 11.1431 11.0333 13.3339C11.0333 15.5246 12.8093 17.3005 15 17.3005Z"
            fill="white"
            fill-opacity="0.4"
          />
          <path
            d="M26.9834 3.33398H13.0167C6.95004 3.33398 3.33337 6.95065 3.33337 13.0173V26.984C3.33337 28.8007 3.65004 30.384 4.26671 31.7173C5.70004 34.884 8.76671 36.6673 13.0167 36.6673H26.9834C33.05 36.6673 36.6667 33.0507 36.6667 26.984V23.1673V13.0173C36.6667 6.95065 33.05 3.33398 26.9834 3.33398ZM33.95 20.834C32.65 19.7173 30.55 19.7173 29.25 20.834L22.3167 26.784C21.0167 27.9007 18.9167 27.9007 17.6167 26.784L17.05 26.3173C15.8667 25.284 13.9834 25.184 12.65 26.084L6.41671 30.2673C6.05004 29.334 5.83337 28.2507 5.83337 26.984V13.0173C5.83337 8.31732 8.31671 5.83398 13.0167 5.83398H26.9834C31.6834 5.83398 34.1667 8.31732 34.1667 13.0173V21.0173L33.95 20.834Z"
            fill="white"
            fill-opacity="0.4"
          />
        </svg> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
        >
          <g clip-path="url(#clip0_587_3428)">
            <path
              d="M8.44409 47.6109C8.44409 57.7953 12.4898 67.5624 19.6912 74.7638C26.8926 81.9652 36.6598 86.0109 46.8441 86.0109C57.0284 86.0109 66.7956 81.9652 73.997 74.7638C81.1984 67.5624 85.2441 57.7953 85.2441 47.6109C85.2441 37.4266 81.1984 27.6594 73.997 20.458C66.7956 13.2566 57.0284 9.21094 46.8441 9.21094C36.6598 9.21094 26.8926 13.2566 19.6912 20.458C12.4898 27.6594 8.44409 37.4266 8.44409 47.6109Z"
              fill="#D9EDFF"
            />
            <path
              d="M23.0027 25.3203L9.77002 55.1667"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M35.1419 74.8729L9.77002 55.168"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M74.7302 45.3613L61.5801 68.4359"
              stroke="#00AED9"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.0023 25.3199L57.4193 17.9922"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M74.7303 45.3608L57.4196 17.9922"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M74.7303 45.3613L46.5687 51.6589L9.77002 55.1668"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.0023 25.3203L35.1415 74.8717"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M57.4194 17.9922L46.5685 51.6584"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.0023 25.3203L61.5799 68.4358"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M61.5801 68.4355L35.1417 74.8714"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.77002 55.1663L57.4196 17.9922"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.281006 53.3897C0.281006 54.7792 0.554695 56.1552 1.08645 57.4389C1.6182 58.7227 2.3976 59.8891 3.38014 60.8717C4.36269 61.8542 5.52915 62.6336 6.81291 63.1654C8.09667 63.6971 9.47259 63.9708 10.8621 63.9708C12.2517 63.9708 13.6276 63.6971 14.9113 63.1654C16.1951 62.6336 17.3616 61.8542 18.3441 60.8717C19.3267 59.8891 20.1061 58.7227 20.6378 57.4389C21.1696 56.1552 21.4432 54.7792 21.4432 53.3897C21.4432 52.0002 21.1696 50.6243 20.6378 49.3405C20.1061 48.0567 19.3267 46.8903 18.3441 45.9077C17.3616 44.9252 16.1951 44.1458 14.9113 43.614C13.6276 43.0823 12.2517 42.8086 10.8621 42.8086C9.47259 42.8086 8.09667 43.0823 6.81291 43.614C5.52915 44.1458 4.36269 44.9252 3.38014 45.9077C2.3976 46.8903 1.6182 48.0567 1.08645 49.3405C0.554695 50.6243 0.281006 52.0002 0.281006 53.3897Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5115 56.0837C16.5095 55.8538 16.4279 55.6318 16.2808 55.4552C16.1336 55.2787 15.9299 55.1585 15.7042 55.1151V54.469C15.7132 53.8269 15.5945 53.1894 15.3551 52.5936C15.1156 51.9978 14.7601 51.4555 14.3093 50.9983C13.8584 50.541 13.3212 50.1779 12.7288 49.9301C12.1364 49.6823 11.5007 49.5547 10.8586 49.5547C10.2164 49.5547 9.58069 49.6823 8.9883 49.9301C8.39591 50.1779 7.85869 50.541 7.40783 50.9983C6.95698 51.4555 6.6015 51.9978 6.36204 52.5936C6.12258 53.1894 6.00392 53.8269 6.01296 54.469V55.1151C5.77755 55.1591 5.56615 55.2872 5.41801 55.4754C5.26987 55.6635 5.19508 55.8991 5.20751 56.1383C5.21994 56.3775 5.31877 56.604 5.48562 56.7758C5.65247 56.9476 5.87601 57.0531 6.11472 57.0725C6.3422 58.1623 6.938 59.1406 7.80193 59.8428C8.66586 60.545 9.74523 60.9284 10.8586 60.9284C11.9719 60.9284 13.0513 60.545 13.9152 59.8428C14.7791 59.1406 15.3749 58.1623 15.6024 57.0725C15.8498 57.0503 16.08 56.9367 16.2481 56.7538C16.4162 56.571 16.5101 56.3321 16.5115 56.0837Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.63245 56.6897C7.63245 56.8504 7.69627 57.0045 7.80987 57.1181C7.92347 57.2317 8.07755 57.2955 8.23821 57.2955C8.39886 57.2955 8.55294 57.2317 8.66654 57.1181C8.78014 57.0045 8.84397 56.8504 8.84397 56.6897C8.84397 56.5291 8.78014 56.375 8.66654 56.2614C8.55294 56.1478 8.39886 56.084 8.23821 56.084C8.07755 56.084 7.92347 56.1478 7.80987 56.2614C7.69627 56.375 7.63245 56.5291 7.63245 56.6897Z"
              fill="#B0D9FF"
            />
            <path
              d="M12.8813 56.6897C12.8813 56.7693 12.897 56.8481 12.9275 56.9216C12.9579 56.9951 13.0025 57.0618 13.0588 57.1181C13.115 57.1743 13.1818 57.219 13.2553 57.2494C13.3288 57.2798 13.4076 57.2955 13.4871 57.2955C13.5667 57.2955 13.6454 57.2798 13.7189 57.2494C13.7924 57.219 13.8592 57.1743 13.9154 57.1181C13.9717 57.0618 14.0163 56.9951 14.0468 56.9216C14.0772 56.8481 14.0929 56.7693 14.0929 56.6897C14.0929 56.6102 14.0772 56.5314 14.0468 56.4579C14.0163 56.3844 13.9717 56.3177 13.9154 56.2614C13.8592 56.2052 13.7924 56.1605 13.7189 56.1301C13.6454 56.0997 13.5667 56.084 13.4871 56.084C13.4076 56.084 13.3288 56.0997 13.2553 56.1301C13.1818 56.1605 13.115 56.2052 13.0588 56.2614C13.0025 56.3177 12.9579 56.3844 12.9275 56.4579C12.897 56.5314 12.8813 56.6102 12.8813 56.6897Z"
              fill="#B0D9FF"
            />
            <path
              d="M9.65161 58.1387C10.0101 58.3781 10.4315 58.506 10.8627 58.506C11.2938 58.506 11.7152 58.3781 12.0737 58.1387"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.1114 53.2578C16.1208 52.5625 15.9919 51.8723 15.7324 51.2273C15.4728 50.5822 15.0877 49.9951 14.5994 49.5002C14.111 49.0052 13.5292 48.6122 12.8878 48.3439C12.2463 48.0756 11.5579 47.9375 10.8626 47.9375C10.1672 47.9375 9.47885 48.0756 8.83736 48.3439C8.19588 48.6122 7.61408 49.0052 7.12575 49.5002C6.63742 49.9951 6.25229 50.5822 5.99273 51.2273C5.73318 51.8723 5.60436 52.5625 5.61376 53.2578V54.4693C5.61376 54.5762 5.65624 54.6788 5.73186 54.7544C5.80747 54.83 5.91003 54.8725 6.01696 54.8725H6.82528C6.93222 54.8725 7.03477 54.83 7.11039 54.7544C7.186 54.6788 7.22848 54.5762 7.22848 54.4693C7.22887 53.9885 7.32586 53.5126 7.5137 53.07C7.70153 52.6274 7.97638 52.227 8.32192 51.8926C8.4102 51.806 8.51726 51.7408 8.6348 51.7022C8.75234 51.6635 8.87719 51.6525 8.99968 51.6699C9.50919 51.7334 9.98256 51.9664 10.3437 52.3314C10.8208 52.8234 11.45 53.14 12.1294 53.2301C12.8089 53.3201 13.4988 53.1784 14.0877 52.8277C14.2903 53.221 14.4204 53.6477 14.4717 54.0872C14.4832 54.1924 14.533 54.2896 14.6116 54.3604C14.6902 54.4312 14.7921 54.4707 14.8979 54.4712H15.7053C15.8123 54.471 15.9149 54.4283 15.9904 54.3525C16.066 54.2767 16.1085 54.1741 16.1085 54.067L16.1114 53.2578Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.1114 53.2578C16.1208 52.5625 15.9919 51.8723 15.7324 51.2273C15.4728 50.5822 15.0877 49.9951 14.5994 49.5002C14.111 49.0052 13.5292 48.6122 12.8878 48.3439C12.2463 48.0756 11.5579 47.9375 10.8626 47.9375C10.1672 47.9375 9.47885 48.0756 8.83736 48.3439C8.19588 48.6122 7.61408 49.0052 7.12575 49.5002C6.63742 49.9951 6.25229 50.5822 5.99273 51.2273C5.73318 51.8723 5.60436 52.5625 5.61376 53.2578V54.4693C5.61376 54.5762 5.65624 54.6788 5.73186 54.7544C5.80747 54.83 5.91003 54.8725 6.01696 54.8725H6.82528C6.93222 54.8725 7.03477 54.83 7.11039 54.7544C7.186 54.6788 7.22848 54.5762 7.22848 54.4693C7.22887 53.9885 7.32586 53.5126 7.51369 53.07C7.70153 52.6274 7.97638 52.227 8.32192 51.8926C8.4102 51.806 8.51726 51.7408 8.6348 51.7022C8.75234 51.6635 8.87719 51.6525 8.99968 51.6699C9.50919 51.7334 9.98256 51.9664 10.3437 52.3314C10.8208 52.8234 11.45 53.14 12.1294 53.2301C12.8089 53.3201 13.4988 53.1784 14.0877 52.8277C14.2903 53.221 14.4204 53.6477 14.4717 54.0872C14.4832 54.1924 14.533 54.2896 14.6116 54.3604C14.6902 54.4312 14.7921 54.4707 14.8979 54.4712H15.7053C15.8123 54.471 15.9149 54.4283 15.9904 54.3525C16.066 54.2767 16.1085 54.1741 16.1085 54.067L16.1114 53.2578Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0874 52.8271C14.13 52.8018 14.1643 52.7645 14.1859 52.7198C14.2075 52.6752 14.2155 52.6252 14.2088 52.576C14.2022 52.5269 14.1812 52.4808 14.1485 52.4435C14.1158 52.4062 14.0728 52.3793 14.025 52.3663C13.3913 52.1876 12.8428 51.7873 12.4794 51.2383"
              fill="#FFDF3D"
            />
            <path
              d="M14.0874 52.8271C14.13 52.8018 14.1643 52.7645 14.1859 52.7198C14.2075 52.6752 14.2155 52.6252 14.2088 52.576C14.2022 52.5269 14.1812 52.4808 14.1485 52.4435C14.1158 52.4062 14.0728 52.3793 14.025 52.3663C13.3913 52.1876 12.8428 51.7873 12.4794 51.2383"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.44031 55.0745C8.44031 55.1541 8.45598 55.2328 8.48642 55.3063C8.51686 55.3798 8.56148 55.4466 8.61773 55.5028C8.67398 55.5591 8.74076 55.6037 8.81425 55.6342C8.88775 55.6646 8.96652 55.6803 9.04607 55.6803C9.12562 55.6803 9.20439 55.6646 9.27788 55.6342C9.35138 55.6037 9.41816 55.5591 9.47441 55.5028C9.53066 55.4466 9.57527 55.3798 9.60572 55.3063C9.63616 55.2328 9.65183 55.1541 9.65183 55.0745C9.65183 54.995 9.63616 54.9162 9.60572 54.8427C9.57527 54.7692 9.53066 54.7024 9.47441 54.6462C9.41816 54.5899 9.35138 54.5453 9.27788 54.5149C9.20439 54.4844 9.12562 54.4688 9.04607 54.4688C8.96652 54.4688 8.88775 54.4844 8.81425 54.5149C8.74076 54.5453 8.67398 54.5899 8.61773 54.6462C8.56148 54.7024 8.51686 54.7692 8.48642 54.8427C8.45598 54.9162 8.44031 54.995 8.44031 55.0745Z"
              fill="#020064"
            />
            <path
              d="M12.0741 55.0745C12.0741 55.2352 12.1379 55.3892 12.2515 55.5028C12.3651 55.6165 12.5192 55.6803 12.6799 55.6803C12.8405 55.6803 12.9946 55.6165 13.1082 55.5028C13.2218 55.3892 13.2856 55.2352 13.2856 55.0745C13.2856 54.9139 13.2218 54.7598 13.1082 54.6462C12.9946 54.5326 12.8405 54.4688 12.6799 54.4688C12.5192 54.4688 12.3651 54.5326 12.2515 54.6462C12.1379 54.7598 12.0741 54.9139 12.0741 55.0745Z"
              fill="#020064"
            />
            <path
              d="M10.8628 48.007C11.5504 48.0059 12.2315 48.1403 12.8672 48.4025C13.5029 48.6648 14.0806 49.0497 14.5675 49.5353C14.7821 49.1987 14.8976 48.8084 14.9006 48.4092C14.9006 46.8483 13.0929 45.582 10.8628 45.582C8.63273 45.582 6.83081 46.8483 6.83081 48.4092C6.83236 48.808 6.94614 49.1982 7.15913 49.5353C7.64582 49.0498 8.22342 48.6649 8.85891 48.4027C9.4944 48.1404 10.1753 48.006 10.8628 48.007Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M46.3098 17.7979C46.3098 20.6042 47.4246 23.2956 49.409 25.2799C51.3933 27.2642 54.0847 28.379 56.8909 28.379C59.6972 28.379 62.3886 27.2642 64.3729 25.2799C66.3573 23.2956 67.4721 20.6042 67.4721 17.7979C67.4721 14.9916 66.3573 12.3003 64.3729 10.3159C62.3886 8.33159 59.6972 7.2168 56.8909 7.2168C54.0847 7.2168 51.3933 8.33159 49.409 10.3159C47.4246 12.3003 46.3098 14.9916 46.3098 17.7979Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.7539 20.5446C63.7514 20.2841 63.6588 20.0324 63.4919 19.8324C63.325 19.6324 63.0939 19.4963 62.838 19.4473V18.7148C62.838 17.2587 62.2596 15.8623 61.23 14.8327C60.2003 13.803 58.8039 13.2246 57.3478 13.2246C55.8917 13.2246 54.4952 13.803 53.4656 14.8327C52.436 15.8623 51.8575 17.2587 51.8575 18.7148V19.4473C51.5902 19.4967 51.3499 19.6415 51.1815 19.8549C51.0131 20.0683 50.928 20.3357 50.9421 20.6071C50.9562 20.8786 51.0685 21.1357 51.2581 21.3305C51.4477 21.5253 51.7017 21.6445 51.9727 21.6659C52.2299 22.9015 52.9048 24.0108 53.884 24.8071C54.8631 25.6035 56.0867 26.0382 57.3487 26.0382C58.6108 26.0382 59.8344 25.6035 60.8135 24.8071C61.7926 24.0108 62.4676 22.9015 62.7247 21.6659C63.0049 21.6401 63.2654 21.5111 63.4556 21.3038C63.6459 21.0966 63.7522 20.8259 63.7539 20.5446Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.7539 20.5446C63.7514 20.2841 63.6588 20.0324 63.4919 19.8324C63.325 19.6324 63.0939 19.4963 62.838 19.4473V18.7148C62.838 17.2587 62.2596 15.8623 61.23 14.8327C60.2003 13.803 58.8039 13.2246 57.3478 13.2246C55.8917 13.2246 54.4952 13.803 53.4656 14.8327C52.436 15.8623 51.8575 17.2587 51.8575 18.7148V19.4473C51.5902 19.4967 51.3499 19.6415 51.1815 19.8549C51.0131 20.0683 50.928 20.3357 50.9421 20.6071C50.9562 20.8786 51.0685 21.1357 51.2581 21.3305C51.4477 21.5253 51.7017 21.6445 51.9727 21.6659C52.2299 22.9015 52.9048 24.0108 53.884 24.8071C54.8631 25.6035 56.0867 26.0382 57.3487 26.0382C58.6108 26.0382 59.8344 25.6035 60.8135 24.8071C61.7926 24.0108 62.4676 22.9015 62.7247 21.6659C63.0049 21.6401 63.2654 21.5111 63.4556 21.3038C63.6459 21.0966 63.7522 20.8259 63.7539 20.5446V20.5446Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.6031 19.3993C54.6031 19.5813 54.6755 19.7559 54.8042 19.8846C54.9329 20.0134 55.1075 20.0857 55.2895 20.0857C55.4716 20.0857 55.6462 20.0134 55.7749 19.8846C55.9036 19.7559 55.9759 19.5813 55.9759 19.3993C55.9759 19.2172 55.9036 19.0427 55.7749 18.9139C55.6462 18.7852 55.4716 18.7129 55.2895 18.7129C55.1075 18.7129 54.9329 18.7852 54.8042 18.9139C54.6755 19.0427 54.6031 19.2172 54.6031 19.3993Z"
              fill="#020064"
            />
            <path
              d="M58.7203 19.3993C58.7203 19.5813 58.7927 19.7559 58.9214 19.8846C59.0501 20.0134 59.2247 20.0857 59.4067 20.0857C59.5888 20.0857 59.7634 20.0134 59.8921 19.8846C60.0208 19.7559 60.0931 19.5813 60.0931 19.3993C60.0931 19.2172 60.0208 19.0427 59.8921 18.9139C59.7634 18.7852 59.5888 18.7129 59.4067 18.7129C59.2247 18.7129 59.0501 18.7852 58.9214 18.9139C58.7927 19.0427 58.7203 19.2172 58.7203 19.3993Z"
              fill="#020064"
            />
            <path
              d="M53.6881 21.2294C53.6881 21.4114 53.7604 21.586 53.8892 21.7147C54.0179 21.8435 54.1925 21.9158 54.3745 21.9158C54.5566 21.9158 54.7311 21.8435 54.8599 21.7147C54.9886 21.586 55.0609 21.4114 55.0609 21.2294C55.0609 21.0473 54.9886 20.8727 54.8599 20.744C54.7311 20.6153 54.5566 20.543 54.3745 20.543C54.1925 20.543 54.0179 20.6153 53.8892 20.744C53.7604 20.8727 53.6881 21.0473 53.6881 21.2294Z"
              fill="#B0D9FF"
            />
            <path
              d="M59.6348 21.2294C59.6348 21.4114 59.7071 21.586 59.8358 21.7147C59.9645 21.8435 60.1391 21.9158 60.3212 21.9158C60.5032 21.9158 60.6778 21.8435 60.8065 21.7147C60.9352 21.586 61.0076 21.4114 61.0076 21.2294C61.0076 21.0473 60.9352 20.8727 60.8065 20.744C60.6778 20.6153 60.5032 20.543 60.3212 20.543C60.1391 20.543 59.9645 20.6153 59.8358 20.744C59.7071 20.8727 59.6348 21.0473 59.6348 21.2294Z"
              fill="#B0D9FF"
            />
            <path
              d="M55.9753 22.873C56.3817 23.144 56.8592 23.2886 57.3477 23.2886C57.8361 23.2886 58.3136 23.144 58.72 22.873"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M51.3997 14.5918V18.7092C51.3997 18.8305 51.4478 18.9468 51.5334 19.0327C51.6191 19.1185 51.7353 19.1669 51.8566 19.1672H52.3193L54.1433 14.5918H51.3997Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.2955 15.0525C63.2952 14.0818 62.9094 13.151 62.223 12.4647C61.5365 11.7785 60.6056 11.3929 59.635 11.3929H55.5175C55.3428 11.393 55.1716 11.3429 55.0245 11.2486C54.8773 11.1544 54.7602 11.0199 54.6871 10.8611C54.639 10.7547 54.5633 10.663 54.468 10.5955C54.3727 10.528 54.2611 10.4872 54.1447 10.4771H53.3671C52.6664 10.4635 51.9853 10.7097 51.4552 11.1682C50.9251 11.6268 50.5833 12.2652 50.4958 12.9606C50.4274 13.6785 50.6442 14.3945 51.0994 14.9539C51.5546 15.5133 52.2116 15.8711 52.9284 15.9501C53.0579 16.4358 53.3301 16.8714 53.7099 17.2007C54.0897 17.53 54.5595 17.7378 55.0587 17.7971H55.9745C56.0106 17.7968 56.0457 17.7847 56.0743 17.7626C56.1029 17.7406 56.1236 17.7098 56.1332 17.6749C56.1427 17.6401 56.1406 17.6031 56.1273 17.5695C56.1139 17.5359 56.0899 17.5076 56.059 17.4889C55.6432 17.2407 55.3405 16.8402 55.2151 16.3725C55.2009 16.3254 55.1979 16.2757 55.2063 16.2272C55.2147 16.1788 55.2344 16.133 55.2637 16.0936C55.293 16.0541 55.3311 16.022 55.375 16C55.4189 15.9779 55.4674 15.9664 55.5166 15.9664H59.2548C59.3751 15.9664 59.4941 15.9901 59.6051 16.0362C59.7162 16.0824 59.817 16.15 59.9019 16.2352L62.3796 18.712H62.8366C62.8968 18.7121 62.9564 18.7003 63.012 18.6774C63.0676 18.6544 63.1181 18.6207 63.1607 18.5781C63.2032 18.5356 63.2369 18.4851 63.2599 18.4294C63.2829 18.3738 63.2946 18.3142 63.2945 18.2541L63.2955 15.0525Z"
              fill="white"
            />
            <path
              d="M63.2955 15.0525C63.2952 14.0818 62.9094 13.151 62.223 12.4647C61.5365 11.7785 60.6056 11.3929 59.635 11.3929H55.5175C55.3428 11.393 55.1716 11.3429 55.0245 11.2486C54.8773 11.1544 54.7602 11.0199 54.6871 10.8611C54.639 10.7547 54.5633 10.663 54.468 10.5955C54.3727 10.528 54.2611 10.4872 54.1447 10.4771H53.3671C52.6664 10.4635 51.9853 10.7097 51.4552 11.1682C50.9251 11.6268 50.5833 12.2652 50.4958 12.9606C50.4274 13.6785 50.6442 14.3945 51.0994 14.9539C51.5546 15.5133 52.2116 15.8711 52.9284 15.9501C53.0579 16.4358 53.3301 16.8714 53.7099 17.2007C54.0897 17.53 54.5595 17.7378 55.0587 17.7971H55.9745C56.0106 17.7968 56.0457 17.7847 56.0743 17.7626C56.1029 17.7406 56.1236 17.7098 56.1332 17.6749C56.1427 17.6401 56.1406 17.6031 56.1273 17.5695C56.1139 17.5359 56.0899 17.5076 56.059 17.4889C55.6432 17.2407 55.3405 16.8402 55.2151 16.3725C55.2009 16.3254 55.1979 16.2757 55.2063 16.2272C55.2147 16.1788 55.2344 16.133 55.2637 16.0936C55.293 16.0541 55.3311 16.022 55.375 16C55.4189 15.9779 55.4674 15.9664 55.5166 15.9664H59.2548C59.3751 15.9664 59.4941 15.9901 59.6051 16.0362C59.7162 16.0824 59.817 16.15 59.9019 16.2352L62.3796 18.712H62.8366C62.8968 18.7121 62.9564 18.7003 63.012 18.6774C63.0676 18.6544 63.1181 18.6207 63.1607 18.5781C63.2032 18.5356 63.2369 18.4851 63.2599 18.4294C63.2829 18.3738 63.2946 18.3142 63.2945 18.2541L63.2955 15.0525Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M70.9363 67.8516L74.7778 64.0102L81.5001 70.7327L77.6585 74.5741L70.9363 67.8516Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M94.5601 87.4573L94.3767 87.6406C93.6347 88.3768 92.6318 88.7898 91.5865 88.7898C90.5412 88.7898 89.5383 88.3768 88.7963 87.6406L76.6667 75.5062C76.4213 75.2588 76.2836 74.9245 76.2836 74.576C76.2836 74.2275 76.4213 73.8932 76.6667 73.6457L80.57 69.7424C80.8173 69.497 81.1515 69.3594 81.4998 69.3594C81.8481 69.3594 82.1823 69.497 82.4295 69.7424L94.5601 81.872C94.9284 82.2378 95.2208 82.6729 95.4203 83.1522C95.6197 83.6315 95.7224 84.1455 95.7224 84.6646C95.7224 85.1838 95.6197 85.6978 95.4203 86.1771C95.2208 86.6563 94.9284 87.0914 94.5601 87.4573Z"
              fill="#B0D9FF"
            />
            <path
              d="M94.5601 87.4573L94.3767 87.6406C93.6347 88.3768 92.6318 88.7898 91.5865 88.7898C90.5412 88.7898 89.5383 88.3768 88.7963 87.6406L76.6667 75.5062C76.4213 75.2588 76.2836 74.9245 76.2836 74.576C76.2836 74.2275 76.4213 73.8932 76.6667 73.6457L80.57 69.7424C80.8173 69.497 81.1515 69.3594 81.4998 69.3594C81.8481 69.3594 82.1823 69.497 82.4295 69.7424L94.5601 81.872C94.9284 82.2378 95.2208 82.6729 95.4203 83.1522C95.6197 83.6315 95.7224 84.1455 95.7224 84.6646C95.7224 85.1838 95.6197 85.6978 95.4203 86.1771C95.2208 86.6563 94.9284 87.0914 94.5601 87.4573Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M42.0349 64.0788C44.8552 68.6876 49.3909 71.9873 54.6441 73.252C59.8973 74.5167 65.4377 73.6427 70.0465 70.8224C74.6554 68.0021 77.9551 63.4664 79.2197 58.2132C80.4844 52.96 79.6105 47.4196 76.7901 42.8108C73.9698 38.2019 69.4342 34.9022 64.181 33.6376C58.9278 32.3729 53.3873 33.2468 48.7785 36.0672C44.1697 38.8875 40.87 43.4231 39.6053 48.6763C38.3406 53.9295 39.2146 59.47 42.0349 64.0788Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M70.9371 41.9224C73.2163 44.2018 74.7684 47.1058 75.3972 50.2672C76.0259 53.4287 75.7031 56.7056 74.4695 59.6836C73.2359 62.6616 71.147 65.2069 68.4668 66.9977C65.7867 68.7885 62.6357 69.7443 59.4123 69.7443C56.1889 69.7443 53.0379 68.7885 50.3578 66.9977C47.6776 65.2069 45.5887 62.6616 44.3551 59.6836C43.1215 56.7056 42.7987 53.4287 43.4275 50.2672C44.0562 47.1058 45.6083 44.2018 47.8875 41.9224C49.4009 40.4089 51.1977 39.2083 53.1751 38.3892C55.1525 37.57 57.2719 37.1484 59.4123 37.1484C61.5527 37.1484 63.6721 37.57 65.6495 38.3892C67.627 39.2083 69.4237 40.4089 70.9371 41.9224Z"
              fill="#B0D9FF"
            />
            <path
              d="M59.0193 61.2578C57.1464 61.2684 55.308 61.7628 53.6826 62.6931C52.0571 63.6235 50.6996 64.9581 49.7418 66.5676C52.4489 68.5607 55.7056 69.6687 59.0664 69.7402C62.4273 69.8117 65.7282 68.8431 68.5175 66.9669C67.5942 65.2489 66.2246 63.8118 64.553 62.807C62.8813 61.8023 60.9696 61.2671 59.0193 61.2578Z"
              fill="white"
            />
            <path
              d="M59.0193 61.2578C57.1464 61.2684 55.308 61.7628 53.6826 62.6931C52.0571 63.6235 50.6996 64.9581 49.7418 66.5676C52.4489 68.5607 55.7056 69.6687 59.0664 69.7402C62.4273 69.8117 65.7282 68.8431 68.5175 66.9669C67.5942 65.2489 66.2246 63.8118 64.553 62.807C62.8813 61.8023 60.9696 61.2671 59.0193 61.2578V61.2578Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linejoin="round"
            />
            <path
              d="M62.2402 61.7812C62.0674 61.7262 61.893 61.6769 61.717 61.6334C61.5136 61.582 61.3333 61.4642 61.2046 61.2986C61.0759 61.133 61.0062 60.9292 61.0066 60.7194V57.4766H57.1886V60.683C57.1886 60.8938 57.1176 61.0986 56.9872 61.2643C56.8568 61.4301 56.6745 61.5472 56.4696 61.5969C56.2488 61.6506 56.029 61.7102 55.812 61.7783L55.7899 61.8071C55.8464 62.6264 56.2117 63.3937 56.812 63.954C57.4124 64.5144 58.203 64.826 59.0242 64.826C59.8454 64.826 60.636 64.5144 61.2363 63.954C61.8366 63.3937 62.2019 62.6264 62.2584 61.8071L62.2402 61.7812Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M67.6156 49.44C67.6156 51.7315 66.7053 53.9291 65.085 55.5494C63.4647 57.1698 61.267 58.08 58.9756 58.08C56.6841 58.08 54.4865 57.1698 52.8662 55.5494C51.2459 53.9291 50.3356 51.7315 50.3356 49.44C50.3356 44.6631 52.774 40.9152 55.2546 40.2029C55.4613 40.1435 55.6801 40.1408 55.8882 40.195C56.0963 40.2492 56.2859 40.3584 56.4373 40.5111C59.1234 43.2125 67.6156 39.8458 67.6156 49.44Z"
              fill="white"
            />
            <path
              d="M67.6156 49.44C67.6156 51.7315 66.7053 53.9291 65.085 55.5494C63.4647 57.1698 61.267 58.08 58.9756 58.08C56.6841 58.08 54.4865 57.1698 52.8662 55.5494C51.2459 53.9291 50.3356 51.7315 50.3356 49.44C50.3356 44.6631 52.774 40.9152 55.2546 40.2029C55.4613 40.1435 55.6801 40.1408 55.8882 40.195C56.0963 40.2492 56.2859 40.3584 56.4373 40.5111C59.1234 43.2125 67.6156 39.8458 67.6156 49.44Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M66.183 51.6011L64.7324 52.3211L63.686 48.6597C63.5882 48.3081 63.3596 48.0072 63.0471 47.8187C62.7345 47.6302 62.3617 47.5684 62.0051 47.6459C60.0115 48.1208 57.9343 48.1208 55.9407 47.6459C55.5841 47.5686 55.2114 47.6305 54.8989 47.819C54.5864 48.0074 54.3578 48.3082 54.2598 48.6597L53.2134 52.3211L51.7849 51.6011C49.7295 51.6011 49.8649 55.2011 52.1439 55.2011H52.1507C52.6017 56.6611 53.5086 57.9382 54.7383 58.8454C55.968 59.7525 57.4559 60.2419 58.9839 60.2419C60.512 60.2419 61.9999 59.7525 63.2296 58.8454C64.4593 57.9382 65.3661 56.6611 65.8172 55.2011C68.0991 55.2011 68.2374 51.6011 66.183 51.6011Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M55.8921 51.7822C55.8921 52.0078 55.9817 52.2241 56.1412 52.3836C56.3007 52.5431 56.5171 52.6328 56.7426 52.6328C56.9682 52.6328 57.1846 52.5431 57.3441 52.3836C57.5036 52.2241 57.5932 52.0078 57.5932 51.7822C57.5932 51.5566 57.5036 51.3403 57.3441 51.1808C57.1846 51.0213 56.9682 50.9316 56.7426 50.9316C56.5171 50.9316 56.3007 51.0213 56.1412 51.1808C55.9817 51.3403 55.8921 51.5566 55.8921 51.7822Z"
              fill="#020064"
            />
            <path
              d="M60.3882 51.7822C60.3882 52.0078 60.4778 52.2241 60.6373 52.3836C60.7968 52.5431 61.0132 52.6328 61.2387 52.6328C61.4643 52.6328 61.6807 52.5431 61.8402 52.3836C61.9997 52.2241 62.0893 52.0078 62.0893 51.7822C62.0893 51.5566 61.9997 51.3403 61.8402 51.1808C61.6807 51.0213 61.4643 50.9316 61.2387 50.9316C61.0132 50.9316 60.7968 51.0213 60.6373 51.1808C60.4778 51.3403 60.3882 51.5566 60.3882 51.7822Z"
              fill="#020064"
            />
            <path
              d="M55.3918 53.761C55.3918 53.9035 55.3495 54.0427 55.2704 54.1611C55.1912 54.2796 55.0787 54.3718 54.9471 54.4263C54.8155 54.4808 54.6707 54.495 54.531 54.4671C54.3913 54.4393 54.263 54.3706 54.1623 54.2698C54.0617 54.169 53.9932 54.0406 53.9655 53.9009C53.9378 53.7612 53.9522 53.6164 54.0069 53.4848C54.0615 53.3533 54.1539 53.2409 54.2725 53.1619C54.391 53.0829 54.5303 53.0408 54.6727 53.041C54.7672 53.041 54.8608 53.0596 54.9481 53.0958C55.0354 53.132 55.1147 53.1851 55.1815 53.252C55.2483 53.3188 55.3012 53.3982 55.3373 53.4856C55.3734 53.5729 55.3919 53.6665 55.3918 53.761Z"
              fill="#B0D9FF"
            />
            <path
              d="M64.0322 53.761C64.0322 53.9034 63.9899 54.0426 63.9108 54.161C63.8317 54.2794 63.7193 54.3717 63.5877 54.4262C63.4561 54.4807 63.3114 54.495 63.1717 54.4672C63.032 54.4394 62.9037 54.3708 62.803 54.2701C62.7024 54.1694 62.6338 54.0411 62.606 53.9015C62.5782 53.7618 62.5925 53.617 62.647 53.4855C62.7015 53.3539 62.7938 53.2415 62.9122 53.1624C63.0306 53.0832 63.1698 53.041 63.3122 53.041C63.5031 53.041 63.6863 53.1169 63.8213 53.2519C63.9563 53.3869 64.0322 53.5701 64.0322 53.761Z"
              fill="#B0D9FF"
            />
            <path
              d="M57.1941 56.0957C57.7271 56.4506 58.3532 56.64 58.9936 56.64C59.634 56.64 60.2601 56.4506 60.7931 56.0957"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M70.9371 41.9224C73.2163 44.2018 74.7684 47.1058 75.3972 50.2672C76.0259 53.4287 75.7031 56.7056 74.4695 59.6836C73.2359 62.6616 71.147 65.2069 68.4668 66.9977C65.7867 68.7885 62.6357 69.7443 59.4123 69.7443C56.1889 69.7443 53.0379 68.7885 50.3578 66.9977C47.6776 65.2069 45.5887 62.6616 44.3551 59.6836C43.1215 56.7056 42.7987 53.4287 43.4275 50.2672C44.0562 47.1058 45.6083 44.2018 47.8875 41.9224C49.4009 40.4089 51.1977 39.2083 53.1751 38.3892C55.1525 37.57 57.2719 37.1484 59.4123 37.1484C61.5527 37.1484 63.6721 37.57 65.6495 38.3892C67.627 39.2083 69.4237 40.4089 70.9371 41.9224V41.9224Z"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.1003 73.2862C28.1003 75.0715 28.8096 76.7837 30.072 78.0461C31.3344 79.3085 33.0466 80.0177 34.8319 80.0177C36.6172 80.0177 38.3294 79.3085 39.5918 78.0461C40.8542 76.7837 41.5634 75.0715 41.5634 73.2862C41.5634 71.5009 40.8542 69.7887 39.5918 68.5263C38.3294 67.2639 36.6172 66.5547 34.8319 66.5547C33.0466 66.5547 31.3344 67.2639 30.072 68.5263C28.8096 69.7887 28.1003 71.5009 28.1003 73.2862Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.666 73.1295C32.666 73.7039 32.8942 74.2548 33.3004 74.6609C33.7065 75.0671 34.2574 75.2953 34.8318 75.2953C35.4062 75.2953 35.957 75.0671 36.3632 74.6609C36.7694 74.2548 36.9975 73.7039 36.9975 73.1295V72.0466C36.9975 71.4722 36.7694 70.9214 36.3632 70.5152C35.957 70.109 35.4062 69.8809 34.8318 69.8809C34.2574 69.8809 33.7065 70.109 33.3004 70.5152C32.8942 70.9214 32.666 71.4722 32.666 72.0466V73.1295Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M39.3321 78.2912C38.0967 79.4014 36.4944 80.0155 34.8335 80.0155C33.1726 80.0155 31.5704 79.4014 30.335 78.2912C30.7046 77.404 31.3285 76.6459 32.128 76.1124C32.9275 75.5789 33.8669 75.2938 34.8281 75.293C35.7893 75.2921 36.7292 75.5757 37.5296 76.1078C38.33 76.6399 38.9551 77.397 39.3263 78.2836"
              fill="#B0D9FF"
            />
            <path
              d="M39.3321 78.2912C38.0967 79.4014 36.4944 80.0155 34.8335 80.0155C33.1726 80.0155 31.5704 79.4014 30.335 78.2912C30.7046 77.404 31.3285 76.6459 32.128 76.1124C32.9275 75.5789 33.8669 75.2938 34.8281 75.293C35.7893 75.2921 36.7292 75.5757 37.5296 76.1078C38.33 76.6399 38.9551 77.397 39.3263 78.2836"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.6835 71.767C32.7403 71.3303 32.929 70.9213 33.2243 70.5945C33.5195 70.2678 33.9074 70.0388 34.3362 69.9382C34.7649 69.8375 35.2142 69.87 35.624 70.0312C36.0339 70.1925 36.3848 70.4748 36.63 70.8406C36.1176 71.3376 35.4798 71.6863 34.7848 71.8494C34.0898 72.0126 33.3636 71.9841 32.6835 71.767Z"
              fill="#B0D9FF"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5358 24.7237C16.5358 26.509 17.245 28.2212 18.5074 29.4836C19.7698 30.746 21.482 31.4552 23.2673 31.4552C25.0526 31.4552 26.7648 30.746 28.0272 29.4836C29.2896 28.2212 29.9988 26.509 29.9988 24.7237C29.9988 22.9384 29.2896 21.2262 28.0272 19.9638C26.7648 18.7014 25.0526 17.9922 23.2673 17.9922C21.482 17.9922 19.7698 18.7014 18.5074 19.9638C17.245 21.2262 16.5358 22.9384 16.5358 24.7237Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.1002 24.5673C21.1121 25.1339 21.3456 25.6733 21.7506 26.0698C22.1555 26.4663 22.6997 26.6884 23.2665 26.6884C23.8332 26.6884 24.3774 26.4663 24.7824 26.0698C25.1873 25.6733 25.4208 25.1339 25.4327 24.5673V23.4844C25.4208 22.9178 25.1873 22.3784 24.7824 21.9819C24.3774 21.5853 23.8332 21.3633 23.2665 21.3633C22.6997 21.3633 22.1555 21.5853 21.7506 21.9819C21.3456 22.3784 21.1121 22.9178 21.1002 23.4844V24.5673Z"
              fill="white"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.7669 29.7307C26.5312 30.8402 24.9291 31.4539 23.2683 31.4539C21.6076 31.4539 20.0055 30.8402 18.7698 29.7307C19.1394 28.8435 19.7633 28.0854 20.5628 27.5519C21.3623 27.0184 22.3017 26.7332 23.2629 26.7324C24.2241 26.7316 25.164 27.0151 25.9644 27.5473C26.7648 28.0794 27.39 28.8364 27.7611 29.723"
              fill="#B0D9FF"
            />
            <path
              d="M27.7669 29.7307C26.5312 30.8402 24.9291 31.4539 23.2683 31.4539C21.6076 31.4539 20.0055 30.8402 18.7698 29.7307C19.1394 28.8435 19.7633 28.0854 20.5628 27.5519C21.3623 27.0184 22.3017 26.7332 23.2629 26.7324C24.2241 26.7316 25.164 27.0151 25.9644 27.5473C26.7648 28.0794 27.39 28.8364 27.7611 29.723"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.1196 23.2054C21.1762 22.7685 21.3647 22.3592 21.66 22.0322C21.9553 21.7052 22.3434 21.4761 22.7723 21.3755C23.2013 21.2749 23.6507 21.3076 24.0606 21.4693C24.4705 21.6309 24.8213 21.9137 25.0662 22.28C24.5536 22.7767 23.9158 23.1252 23.2208 23.2881C22.5258 23.4511 21.7997 23.4225 21.1196 23.2054Z"
              fill="#B0D9FF"
              stroke="#020064"
              stroke-width="1.50648"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_587_3428">
              <rect width="96" height="96" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </WithCenterLayout>
    </WithCenterLayout>
  )
}

export default PlaceholderImageBox
