import { Icon, IconProps } from '@chakra-ui/react'

const ChevronRight = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="M7.47396 16.1582C7.72786 16.1582 7.92969 16.0671 8.10547 15.8978L13.099 11.0215C13.3203 10.8001 13.4245 10.5723 13.431 10.2923C13.431 10.0124 13.3268 9.77799 13.099 9.56315L8.10547 4.68034C7.92969 4.51107 7.72135 4.41992 7.47396 4.41992C6.96615 4.41992 6.5625 4.82357 6.5625 5.32487C6.5625 5.57227 6.66667 5.80664 6.84896 5.98893L11.276 10.2923L6.84896 14.5892C6.66667 14.7715 6.5625 15.0059 6.5625 15.2533C6.5625 15.7546 6.96615 16.1582 7.47396 16.1582Z" />
    </Icon>
  )
}

export default ChevronRight
