import React from 'react'

import { Button, Image, Text, VStack } from '@chakra-ui/react'

import { useHistory } from 'react-router'

import { WithCenterLayout } from 'components/hoc'

const CollectionEmpty = ({ children }: { children?: React.ReactNode }) => {
  const history = useHistory()
  return (
    <WithCenterLayout flexDir="column" gap="32px">
      {!children && (
        <VStack gap="1rem">
          <Image src="/images/Deploy_GE_arrow.png" w="140px" />
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="white"
            textAlign="center"
          >
            Collection Empty
          </Text>
          <Text
            fontSize="15px"
            fontWeight="normal"
            color="#A0AEC0"
            textAlign="center"
          >
            You have not added any companies to your Shortlist.
          </Text>
        </VStack>
      )}
      {children}
      <Button
        bg="#00C9FF"
        p="0px 24px"
        maxH="40px"
        borderRadius="8px"
        onClick={() => history.push('/leaderboard')}
      >
        Go to leaderboard
      </Button>
    </WithCenterLayout>
  )
}

export default CollectionEmpty
