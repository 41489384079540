import { HStack, VStack } from '@chakra-ui/react'

import Label from '../Label'
import TextValue from '../TextValue'
import { getRateColor } from '../utils'

const AverageRating = ({
  label,
  rate,
  total = 5,
}: {
  label: string
  rate: number | string
  total?: number | string
}) => {
  return (
    <VStack gap="0.5rem" alignItems="start">
      <Label>{label}</Label>
      <HStack gap="1rem">
        <HStack gap="0.3rem">
          <TextValue color={getRateColor(+rate)}>{rate}</TextValue>
          <TextValue>/ {total}</TextValue>
        </HStack>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.7499 4.89697C11.6589 4.89697 11.4349 4.92197 11.3159 5.15997L9.48992 8.81097C9.20092 9.38797 8.64392 9.78897 7.99992 9.88097L3.91192 10.47C3.64192 10.509 3.54992 10.709 3.52192 10.793C3.49692 10.874 3.45692 11.08 3.64292 11.258L6.59892 14.098C7.06992 14.551 7.28392 15.204 7.17192 15.843L6.47592 19.853C6.43292 20.104 6.58992 20.25 6.65992 20.3C6.73392 20.356 6.93192 20.467 7.17692 20.339L10.8319 18.444C11.4079 18.147 12.0939 18.147 12.6679 18.444L16.3219 20.338C16.5679 20.465 16.7659 20.354 16.8409 20.3C16.9109 20.25 17.0679 20.104 17.0249 19.853L16.3269 15.843C16.2149 15.204 16.4289 14.551 16.8999 14.098L19.8559 11.258C20.0429 11.08 20.0029 10.873 19.9769 10.793C19.9499 10.709 19.8579 10.509 19.5879 10.47L15.4999 9.88097C14.8569 9.78897 14.2999 9.38797 14.0109 8.80997L12.1829 5.15997C12.0649 4.92197 11.8409 4.89697 11.7499 4.89697ZM6.94692 21.897C6.53392 21.897 6.12392 21.767 5.77292 21.511C5.16692 21.067 4.86992 20.334 4.99892 19.596L5.69492 15.586C5.72092 15.437 5.66992 15.286 5.55992 15.18L2.60392 12.34C2.05992 11.819 1.86492 11.049 2.09492 10.334C2.32692 9.61097 2.94092 9.09397 3.69792 8.98597L7.78592 8.39697C7.94392 8.37497 8.07992 8.27797 8.14792 8.13997L9.97492 4.48797C10.3119 3.81497 10.9919 3.39697 11.7499 3.39697C12.5079 3.39697 13.1879 3.81497 13.5249 4.48797L15.3529 8.13897C15.4219 8.27797 15.5569 8.37497 15.7139 8.39697L19.8019 8.98597C20.5589 9.09397 21.1729 9.61097 21.4049 10.334C21.6349 11.049 21.4389 11.819 20.8949 12.34L17.9389 15.18C17.8289 15.286 17.7789 15.437 17.8049 15.585L18.5019 19.596C18.6299 20.335 18.3329 21.068 17.7259 21.511C17.1109 21.962 16.3099 22.023 15.6309 21.669L11.9779 19.776C11.8349 19.702 11.6639 19.702 11.5209 19.776L7.86792 21.67C7.57592 21.822 7.26092 21.897 6.94692 21.897Z"
            fill="white"
          />
        </svg>
      </HStack>
    </VStack>
  )
}

export default AverageRating
