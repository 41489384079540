import {
  Heading,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import { AuthTokenResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { useAuth, useDLToast } from 'hooks'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { DLButton } from 'components/common/DLButton'
import { Field } from 'components/common/DLForms'
import { PasswordInput } from 'components/common/DLInput'

import {
  CURRENT_PASSWORD_IS_NOT_CORRECT,
  PASSWORD_DOES_NOT_MATCH,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
} from '../constants/messages'
import { TUserSetting } from '../hooks/useUserSettings'

// const FormToggle = () => {
//   const colorByMode = useColorModeValue(
//     'rgba(255, 255, 255, 0.60)',
//     'rgba(16, 16, 16, 0.60)',
//   )

//   const bgSwitchByMode = useColorModeValue(
//     'rgb(82,82,82)',
//     'rgba(16, 16, 16, 0.13)',
//   )
//   return (
//     <HStack alignItems="center" gap="0.75rem">
//       <Text fontSize="md" color={colorByMode}>
//         Two-factor authentication
//       </Text>
//       <Switch
//         colorScheme="teal"
//         size="sm"
//         onChange={console.log}
//         sx={{
//           '&>.chakra-switch__track': { bg: bgSwitchByMode },
//           '&>[data-checked]': { bg: '#8B9DFB' },
//         }}
//       />
//     </HStack>
//   )
// }

const Form = ({
  isLoading,
  styleByMode: { colorByMode },
}: {
  isLoading: boolean
  styleByMode: { colorByMode: string }
}) => {
  return (
    <VStack w="full" h="full" gap={[4, 4, 4, 6, 6, 6]} alignItems="start">
      {/* <FormToggle /> */}

      <Field
        variant="userAccount"
        label="Current Password"
        name="current_password"
        component={PasswordInput}
        size={['sm', 'md', 'lg']}
        labelStyleProps={{
          fontSize: ['sm', 'md', 'lg'],
          fontWeight: 'bold',
          lineHeight: 1.4,
          color: colorByMode,
        }}
      />

      <Field
        variant="userAccount"
        label="New Password"
        name="password"
        component={PasswordInput}
        maxLength={256}
        size={['sm', 'md', 'lg']}
        labelStyleProps={{
          fontSize: ['sm', 'md', 'lg'],
          fontWeight: 'bold',
          lineHeight: 1.4,
          color: colorByMode,
        }}
      />

      <Field
        variant="userAccount"
        label="Confirm New Password"
        name="confirmed_password"
        component={PasswordInput}
        maxLength={256}
        size={['sm', 'md', 'lg']}
        labelStyleProps={{
          fontSize: ['sm', 'md', 'lg'],
          fontWeight: 'bold',
          lineHeight: 1.4,
          color: colorByMode,
        }}
      />

      <DLButton
        type="submit"
        isLoading={isLoading}
        variant="filled"
        size={['sm', 'md', 'lg', 'lg']}
        w={['full', 'full', 'full']}
      >
        Update
      </DLButton>
    </VStack>
  )
}

interface IPasswordAndSecurity {
  current_password: string
  password: string
  confirmed_password: string
}

const PasswordAndSecurity = ({
  userSettings,
}: {
  userSettings?: TUserSetting | null | undefined
}) => {
  const [isLoading, toggle] = useBoolean()
  const { showErrorToast, showSuccessToast } = useDLToast()
  const { login } = useAuth()
  const methods = useForm<IPasswordAndSecurity>({
    defaultValues: {},
  })

  const colorByMode = useColorModeValue('white', '#101010')
  const bg = useColorModeValue(
    'activatedBackground',
    'rgba(255, 255, 255, 0.40)',
  )

  const checkCurrentPassword = async (
    currentPassword: string,
  ): Promise<boolean> => {
    if (!userSettings?.email) return false
    const res: AuthTokenResponse = await login(
      userSettings?.email,
      currentPassword,
    )
    return !res?.error
  }

  const handleSubmit: SubmitHandler<IPasswordAndSecurity> = async (values) => {
    const { current_password, password, confirmed_password } = values

    try {
      toggle.on()
      if (password !== confirmed_password) {
        showErrorToast(PASSWORD_DOES_NOT_MATCH)
        return
      }

      const isCorrectPassword = await checkCurrentPassword(current_password)
      if (!isCorrectPassword) {
        showErrorToast(CURRENT_PASSWORD_IS_NOT_CORRECT)
        return
      }

      const { error } = await supabase.auth.updateUser({ password })
      if (error) {
        showErrorToast(error.message)
        return
      }

      showSuccessToast(UPDATE_PASSWORD_SUCCESS)
    } catch (err) {
      showErrorToast(UPDATE_PASSWORD_ERROR)
    } finally {
      toggle.off()
    }
  }

  return (
    <VStack
      bg={bg}
      borderRadius="24px"
      p={[4, 4, '24px']}
      gap="36px"
      color={colorByMode}
      h="full"
    >
      <FormProvider {...methods}>
        <form
          style={{ width: '100%' }}
          onSubmit={methods?.handleSubmit(handleSubmit)}
          autoComplete="off"
        >
          <VStack
            gap="36px"
            fontWeight="bold"
            alignItems="start"
            maxW={['full', 'full', 'full', 'full']}
          >
            <Heading fontSize={['lg', 'lg', '24px']}>
              Password & security
            </Heading>
            <Form styleByMode={{ colorByMode }} isLoading={isLoading} />
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  )
}

export default PasswordAndSecurity
