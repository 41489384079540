import { HStack, Text } from '@chakra-ui/react'

const FormHeader = ({ text = '' }) => {
  return (
    <HStack textAlign="center">
      <Text
        as="h1"
        color="#ffffff"
        fontSize={['1.75rem', '1.75rem', '3xl']}
        fontWeight="bold"
      >
        {text}
      </Text>
    </HStack>
  )
}

export default FormHeader
