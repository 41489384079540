export const TABLES_NAME = {
  domain_taxonomy: 'domain_taxonomy',
  deals: 'deals',
  deal_fields: 'deal_fields',
  deal_scout_comments: 'deal_scout_comments',
  deal_subdomains: 'deal_subdomains',
  investor_shortlisted_deals: 'investor_shortlisted_deals',
  dealflow_progress: 'dealflow_progress',
  locations: 'locations',
  users: 'users',
  roles: 'roles',
  deal_maven_ratings: 'deal_maven_ratings',
  investor_action_logs: 'investor_action_logs',
  team_notes: 'team_notes',
  deal_q_responses: 'deal_q_responses',
  contact_forms: 'contact_forms',
}

export const VIEWS = {
  domains_with_full_path: 'matview_subdomain_with_full_path',
  web_view_deal_avg_evals: 'web_view_deal_avg_evals',
  view_scout_exp_in_sector: 'view_scout_exp_in_sectors',
  view_count_user_stats: 'view_count_user_stats',
}
