import YouTube, { YouTubeProps } from 'react-youtube'

interface YouTubePlayerProps {
  url: string
}

const extractVideoId = (url: string): string | undefined => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = url.match(regex)
  return match ? match[1] : undefined
}

const YoutubePlayer: React.FC<YouTubePlayerProps> = ({ url }) => {
  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  }

  const videoId = extractVideoId(url)

  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      style={{ width: '100%', height: '100%', borderRadius: '10px' }}
      iframeClassName="ytb_iframe"
    />
  )
}

export default YoutubePlayer
